import React, { useState, useReducer, useEffect, useContext, useRef } from "react";
import TopGraySectionEmpty from "../figures/TopGraySectionEmpty";
import { Alert, Col, Row } from "react-bootstrap";
import "./downloads.scss";
import {
    listingInitialState,
    listingReducer,
} from "../../../../../reducers/listingReducer";
import axios from "../../../../../config/axios";
import { AuthContext } from "../../../../../context/AuthContext";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CMSFormTextArea from "../../../../UI/shared/formFields/CMSFormTextArea";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { getAttendeeById, handleFormatArrayId } from "../../../../../helper/commonHelper";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import Loading from "../../../../UI/shared/loading/Loading";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import CMSFormFile from "../../../../UI/shared/formFields/CMSFormFile";
import ModalBody from "../../../../UI/shared/modal/ModalBody";
import EditDownload from "./EditDownload";
import DeleteDownload from "./DeleteDownload";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const Downloads = () => {
    const [grouped, setGrouped] = useState({});
    const [filters, setFilters] = useState({ group: "", type: "" });
    const [show, setShow] = useState({
        editModel: false,
        editDetails: null,
        deleteId: null,
        deleteShow: false
    });
    const { userAuthState } = useContext(AuthContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );
    const handleClose = () => setShow((prevState) => ({
        ...prevState,
        editModel: false,
    }));
    const handleShow = (item) => setShow((prevState) => ({
        ...prevState,
        editModel: true,
        editDetails: item,
    }));
    const handleDeleteClose = () => setShow((prevState) => ({
        ...prevState,
        deleteShow: false,
    }));
    const handleDeleteShow = (id) => setShow((prevState) => ({
        ...prevState,
        deleteShow: true,
        deleteId: id
    }));
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const [deleteState, deleteDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const addNoteFormRef = useRef(null);

    const scrollToForm = () => {
        if (addNoteFormRef.current) {
            addNoteFormRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function groupByGroup(items) {
        return items.reduce((result, item) => {
            (result[item.group] = result[item.group] || []).push(item);
            return result;
        }, {});
    }
    const loadDownloads = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });
        await sleep(100)
        try {
            const res = await axios.get(
                `get-downloads/${userAuthState.user && userAuthState.user.activityId}`
            );

            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
            const groupedItems = groupByGroup(res.data && res.data.item);
            setGrouped(groupedItems)
        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const onSubmit = async (data, { resetForm }) => {

        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.post(`add-downloads/${userAuthState.user && userAuthState.user.activityId}`, data);

            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });

            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });

            }, 2000);
            loadDownloads();
            resetForm();

        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };
    const handleDelete = async (id) => {

        deleteDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.delete(`downloads/${id}`);

            deleteDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            loadDownloads();

        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            deleteDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                deleteDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };

    const handleDownload = (file) => {
        const downloadUrl = `${process.env.REACT_APP_BACKEND_URL + "/public/" + file}`;
        window.open(downloadUrl, '_blank');
    };
    useEffect(() => {
        loadDownloads();
        updateDispatch({
            type: "FETCH_SUCCESS",
        });
        deleteDispatch({
            type: "FETCH_SUCCESS",
        });
    }, []);
    const filteredGroups = Object.keys(grouped)
        .filter(filters.group === "" ? (group) => group : (group) => group === filters.group)
        .reduce((result, group) => {
            result[group] = grouped[group].filter(filters.type === "" ? (item) => item : (item) => item.uploaderType === filters.type); // Adjust uploaderType filter as needed
            return result;
        }, {});
    const uniqueGroups = [...new Set(listingState.data && listingState.data.item && listingState.data.item.map(item => item.group))];

    const groupLabelsAndValues = uniqueGroups.map(group => ({ label: group, value: group }));
    return (
        <>
            {listingState.isLoading && <Loading />}
            <>
                <TopGraySectionEmpty>
                    {listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
                        <Row className="notesTopSection w-100 mx-auto">
                            <Col className="leftSection">
                                <Formik
                                    initialValues={{ group: "", type: "" }}
                                    validationSchema={yup.object().shape({
                                        group: yup.string(),
                                        type: yup.string(),
                                    })}
                                    enableReinitialize={true}
                                    validateOnChange={true}
                                    validateOnBlur={true}
                                    onSubmit={(values) => {
                                        setFilters((prevState) => ({
                                            ...prevState,
                                            group: values.group,
                                            type: values.type
                                        }))
                                    }}
                                >
                                    {(form) => (
                                        <Form className="formWidth">
                                            <div className={`downloadFilterRow`}>



                                                <div className="pe-1 pb-1">
                                                    <CMSFormSelect
                                                        name="group"
                                                        placeholder={{ label: "All", value: "" }}
                                                        form={form}
                                                        onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                                                        options={groupLabelsAndValues}

                                                    />
                                                </div>
                                                <div className="pe-1 pb-1">
                                                    <CMSFormSelect
                                                        name="type"
                                                        placeholder={{ label: "All", value: "" }}
                                                        form={form}
                                                        onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                                                        options={[{ label: "Organizers", value: "organizer" },]}

                                                    />
                                                </div>


                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                            {checkPermission("uploadDocument") && listingState.data && listingState.data.item && listingState.data.item.length > 2 &&
                                <Col xl={3} lg={2} md={3} sm={6} xs={12} className="rightSection">

                                    <div className="uploadButton" onClick={scrollToForm}>
                                        <span className="me-2">Upload Document</span>
                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                    </div>
                                </Col>}
                        </Row>}
                </TopGraySectionEmpty>
                <CustomScrollbars style={{ height: `calc(100vh - 180px) ` }}>
                    {listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
                        <Row className="w-100 mx-auto" >
                            <Col className="downloadListing mx-2 mt-2">

                                {Object.keys(filteredGroups).map((group) => (
                                    <div key={group} >
                                        {filteredGroups[group].length > 0 &&
                                            <div className="groupHead">
                                                {group}
                                            </div>}
                                        {filteredGroups[group].map(item => (
                                            <div class="content_holder">

                                                <div class="holder_info">

                                                    <h3 class="file_title ">{item.fileName}</h3>
                                                    {item.uploaderName &&
                                                        <h5 class="holder_name">Added by:&nbsp; <strong class="">{item.uploaderName}</strong></h5>}
                                                    {item.date && item.time &&
                                                        <h5 class="date_time ">{item.date} | {item.time}</h5>}
                                                    {/* {item.isSystemGenerated === "1" &&
                                                        <h5 class="source" ><i class="fa fa-desktop"></i> (System Generated)</h5>} */}

                                                </div>

                                                <div class="action_buttons">

                                                    <div class="top_action_buttons">
                                                        {checkPermission("editDocument") &&
                                                            <span class="rename_file" onClick={() => { handleShow(item) }}><i class="fa fa-pencil"></i></span>} &nbsp;
                                                        {checkPermission("deleteDocument") &&
                                                            <span class="delete_file" onClick={() => { handleDeleteShow(item._id) }}><i class="fa fa-times"></i></span>}

                                                    </div>
                                                    <div class="bottom_action_buttons">

                                                        <div class="fileTypeHolder">

                                                            File Type:

                                                            <div class="fileType noclick ">

                                                                {item.fileType}

                                                            </div>

                                                        </div>
                                                        {checkPermission("downloadDocument") &&
                                                            <a key={item._id} href="#" onClick={(e) => { e.preventDefault(); handleDownload(item.file) }} target="_blank">
                                                                <i className="fa fa-download" aria-hidden="true" title="Download Document"></i>
                                                            </a>}



                                                    </div>

                                                </div>

                                            </div>
                                        ))}

                                    </div>
                                ))}


                                {(
                                    (filters.group !== "" || filters.type !== "") &&
                                    listingState.data.item.filter((item) => {
                                        if (filters.group !== "" && filters.type !== "") {
                                            return item.group === filters.group && item.uploaderType === filters.type;
                                        } else if (filters.group !== "") {
                                            return item.group === filters.group;
                                        } else if (filters.type !== "") {
                                            return item.uploaderType === filters.type;
                                        } else {
                                            return item
                                        }
                                    }).length <= 0
                                ) && (
                                        <h3 className="my-5 text-center">No Records Found!</h3>
                                    )}




                            </Col>
                        </Row>}

                    <Row className="addNoteForm w-100 mx-auto" ref={addNoteFormRef}>
                        {updateState.isSubmitted ? <Col className="px-0">
                            <div className="successMessage">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                                <h2>Document Added Successfully</h2>

                            </div>
                        </Col> :
                            <Col className="px-0">
                                {checkPermission("uploadDocument") && <>
                                    <div className="uploadButton">
                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                        <span className="ms-2">Upload Document</span>
                                    </div>
                                    <div>

                                        <Formik
                                            initialValues={{
                                                activityId: userAuthState.user && userAuthState.user.activityId,
                                                group: "",
                                                file: "",
                                                fileName: "",
                                                uploaderType: userAuthState.user && userAuthState.user.type ? userAuthState.user && userAuthState.user.type : "",
                                                uploaderName: userAuthState.user && userAuthState.user.name ? userAuthState.user && userAuthState.user.name : "",
                                                time: "",
                                                date: "",
                                                isSystemGenerated: "0"
                                            }}
                                            validationSchema={yup.object().shape({
                                                activityId: yup.string().required("Required"),
                                                group: yup.string().required("Required"),
                                                time: yup.string(),
                                                date: yup.string(),
                                                fileName: yup.string(),
                                                isSystemGenerated: yup.string(),
                                                file: yup.mixed().required("Required"),

                                            })}
                                            enableReinitialize={true}
                                            validateOnChange={true}
                                            validateOnBlur={true}
                                            onSubmit={(values, { resetForm }) => {

                                                var today = new Date();
                                                var hours = today.getHours();
                                                var minutes = String(today.getMinutes()).padStart(2, '0');
                                                var formattedHours = String(hours).padStart(2, '0');
                                                var time = formattedHours + ":" + minutes;
                                                const month = monthNames[today.getMonth()];
                                                const day = today.getDate();
                                                const year = today.getFullYear();
                                                const formattedDate = `${month} ${day}, ${year}`;
                                                values.time = time;
                                                values.date = formattedDate;

                                                const formData = new FormData();
                                                for (let name in values) {
                                                    if (Array.isArray(values[name])) {
                                                        formData.append(
                                                            name,
                                                            JSON.stringify(values[name])
                                                        );
                                                    } else {
                                                        formData.append(name, values[name]);
                                                    }
                                                }

                                                onSubmit(formData, { resetForm });
                                            }}
                                        >
                                            {(form) => (
                                                <Form onSubmit={form.handleSubmit}>
                                                    <Row
                                                        className={`notesFormRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`}
                                                    >

                                                        <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                                            <CMSFormFile
                                                                name="file"
                                                                required={true}
                                                                form={form}
                                                            />
                                                        </Col>
                                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                            <CMSFormInput
                                                                name="fileName"
                                                                placeholder="File Name"
                                                                form={form}
                                                            />
                                                        </Col>
                                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                            <CMSFormSelect
                                                                name="group"
                                                                placeholder={{ label: "Select Group", value: "" }}
                                                                form={form}
                                                                required={true}
                                                                onChange={form.handleChange}
                                                                options={[
                                                                    { label: "Reports", value: "Reports" },
                                                                    { label: "Photos", value: "Photos" },
                                                                    { label: "Others", value: "Others" },
                                                                ]}
                                                            />
                                                        </Col>

                                                        <Col
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="mt-3 mb-3 d-flex align-items-center justify-content-start"
                                                        >

                                                            <button
                                                                className="formSubmissionButton dark "
                                                                type="submit"
                                                                onClick={(e) => {
                                                                    form.handleSubmit(e);
                                                                }}
                                                            >
                                                                SAVE
                                                            </button>
                                                        </Col>

                                                        <Col
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed"
                                                        >
                                                            {!updateState.isLoading &&
                                                                updateState.status === "submitFailed" && (
                                                                    <p className="mb-0">
                                                                        <i
                                                                            className="fa fa-exclamation-circle me-2"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        Action Failed! Try again.
                                                                    </p>
                                                                )}
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </>}
                            </Col>
                        }
                    </Row>
                </CustomScrollbars>
                <ModalBody
                    handleClose={handleDeleteClose}
                    handleShow={handleDeleteShow}
                    heading="Confirm!"
                    className="undoCheckInModal"
                    show={show.deleteShow}
                    CloseButton={true}
                    content={<DeleteDownload deleteId={show.deleteId} deleteDownload={handleDelete} handleClose={handleDeleteClose} />}
                />
                <ModalBody
                    handleClose={handleClose}
                    handleShow={handleShow}
                    heading="Edit Document"
                    show={show.editModel}
                    CloseButton={true}
                    content={<EditDownload details={show.editDetails} handleClose={handleClose} loadDownloads={loadDownloads} />}
                />
            </>
        </>
    );
};

export default Downloads;
