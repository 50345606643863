import React, { createContext, useState } from 'react';
export const CombinedContext = createContext({});
const CombinedContextProvider = (props) => {
    const [combined, setCombined] = useState({
        typeSelection: "",
        peopleGroupSelection: undefined,
        graphPartSelection: undefined,
        activeSession:"",
        listSort: undefined,
        attendeeListFilters: {},
        companyListFilters: {},
        countryListFilters: {},
        reset: false,
        day: null,
        breakout: null,
    })
    return (
        <CombinedContext.Provider value={{ combined, setCombined }}>
            {props.children}
        </CombinedContext.Provider>
    );
};
export default CombinedContextProvider;

