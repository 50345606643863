import React, { useEffect } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import "./media.scss";
import { BsFillPlayCircleFill } from "react-icons/bs";
import Loading from '../loading/Loading';

const Gallery = ({ show, listingState, handleDeleteShow, handleShow, filter }) => {
    useEffect(() => {
        if (listingState.data && listingState.data.items && listingState.data.items.length > 0) {
            Fancybox.bind('[data-fancybox="gallery"]', {
                Toolbar: {
                    display: [
                        { id: "counter", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close"
                    ],
                },
                Thumbs: {
                    autoStart: true,
                },
            });
        }

        return () => {
            Fancybox.destroy();
        };
    }, [listingState]);

    return (
        <>
            {listingState.isLoading && <Loading />}
            {listingState.data && listingState.data.items && listingState.data.items.length > 0 ? (
                <>

                    <div className="gallery">
                        {listingState.data.items.filter(filter === "" ? (item) => item : (item) => item.type === filter).map((item, index) => (
                            <div className="mediaHolder" key={index}>
                                {item.type === "video" ? (
                                    <>
                                        <a
                                            data-fancybox="gallery"
                                            href={`${process.env.REACT_APP_BACKEND_URL}/public/${item.video}`}
                                            data-caption={item.title}
                                        >
                                            <div className='videoIcon'><BsFillPlayCircleFill /></div>
                                            <img
                                                alt=""
                                                src={`${process.env.REACT_APP_BACKEND_URL}/public/${item.image}`}
                                            />
                                        </a>
                                        {show && (
                                            <div className="bottomActions">
                                                <span className="rename_file" onClick={() => { handleShow(item) }}>
                                                    <i className="fa fa-pencil"></i>
                                                </span> &nbsp;
                                                <span className="delete_file" onClick={() => { handleDeleteShow(item._id) }}>
                                                    <i className="fa fa-trash"></i>
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <a
                                            data-fancybox="gallery"
                                            href={`${process.env.REACT_APP_BACKEND_URL}/public/${item.image}`}
                                            data-caption={item.title}
                                        >
                                            <img
                                                alt=""
                                                src={`${process.env.REACT_APP_BACKEND_URL}/public/${item.image}`}
                                            />
                                        </a>
                                        {show && (
                                            <div className="bottomActions">
                                                <span className="rename_file" onClick={() => { handleShow(item) }}>
                                                    <i className="fa fa-pencil"></i>
                                                </span> &nbsp;
                                                <span className="delete_file" onClick={() => { handleDeleteShow(item._id) }}>
                                                    <i className="fa fa-trash"></i>
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}

                    </div>
                    {listingState.data.items.filter(filter === "" ? (item) => item : (item) => item.type === filter).length <= 0 && <h3 className='my-5 pt-5 text-center'>No Records found!</h3>}
                </>
            ) : (
                <h3 className='my-5 pt-5 text-center'>No Records found!</h3>
            )}
        </>
    );
};

export default Gallery;
