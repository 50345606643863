import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import BadgeItem from './BadgeItem'
import { TotalAttendees, getAvailablePeopleGroups, getClientName, getColorOfPeopleGroup, getTotalAccountsFigures, headerItems } from '../../../../../helper/sideHeaderHelper'
import { HomeScreenContext } from '../../../../../context/HomeScreenContext'
import { FiguresContext } from '../../../../../context/FiguresContext'
import { AttendeeContext } from '../../../../../context/AttendeeContext'

const AllCounts = () => {
    const availablePeopleGroups = getAvailablePeopleGroups();
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { attendee } = useContext(AttendeeContext);
    const { figures, setFigures } = useContext(FiguresContext);
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    return (
        <Row className="countsRow">
            <Col xs={12} className='col showOnMobile'>
                {availablePeopleGroups.includes("Partners") &&
                    <div className="detailsColMobile" onClick={() => {
                        setFigures((prevState) => ({
                            ...prevState,
                            peopleGroupSelection: "Partners",
                            graphPartSelection: undefined,
                            typeSelection: "attendees",
                        })); setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            leftFullDisplay: true,
                            activePage: "figuresDetails",
                            activePageItems: headerItems("figuresDetails")
                        }))
                    }} style={{ border: `1px solid ${getColorOfPeopleGroup("background", "Partners")}` }}>
                        <h1>{TotalAttendees("Yes", "Partners", undefined)} Partners</h1>

                    </div>}
            </Col>
            {availablePeopleGroups.includes("Owners") &&
                <Col xs={12} className='col showOnMobile'>

                    <div className="detailsColMobile" onClick={() => {
                        setFigures((prevState) => ({
                            ...prevState,
                            peopleGroupSelection: "Owners",
                            graphPartSelection: undefined,
                            typeSelection: "attendees",
                        })); setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            leftFullDisplay: true,
                            activePage: "figuresDetails",
                            activePageItems: headerItems("figuresDetails")
                        }))
                    }} style={{ border: `1px solid ${getColorOfPeopleGroup("background", "Owners")}` }}>
                        <h1>{TotalAttendees("Yes", "Owners", undefined)} Owners</h1>

                    </div>
                </Col>}
            {availablePeopleGroups.includes("Customers") &&
                <Col xs={12} className='col showOnMobile'>

                    <div className="detailsColMobile" onClick={() => {
                        setFigures((prevState) => ({
                            ...prevState,
                            peopleGroupSelection: "Customers",
                            graphPartSelection: undefined,
                            typeSelection: "attendees",
                        })); setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            leftFullDisplay: true,
                            activePage: "figuresDetails",
                            activePageItems: headerItems("figuresDetails")
                        }))
                    }} style={{ border: `1px solid ${getColorOfPeopleGroup("background", "Customers")}` }}>
                        <h1>{TotalAttendees("Yes", "Customers", undefined)} Customers</h1>

                    </div>
                </Col>}
            {availablePeopleGroups.includes("Sponsors") &&
                <Col xs={12} className='col showOnMobile'>

                    <div className="detailsColMobile" onClick={() => {
                        setFigures((prevState) => ({
                            ...prevState,
                            peopleGroupSelection: "Sponsors",
                            graphPartSelection: undefined,
                            typeSelection: "attendees",
                        })); setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            leftFullDisplay: true,
                            activePage: "figuresDetails",
                            activePageItems: headerItems("figuresDetails")
                        }))
                    }} style={{ border: `1px solid ${getColorOfPeopleGroup("background", "Sponsors")}` }}>
                        <h1>{TotalAttendees("Yes", "Sponsors", undefined)} Sponsors</h1>

                    </div>
                </Col>}
            <Col xs={12} className='col showOnMobile'>

                <div className="detailsColMobile" onClick={() => {
                    setFigures((prevState) => ({
                        ...prevState,
                        peopleGroupSelection: undefined,
                        graphPartSelection: undefined,
                         attendeeListFilters: { peopleGroup: "", registrationType: "" },
                        typeSelection: "attendees",
                    })); setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        leftFullDisplay: true,
                        activePage: "figuresDetails",
                        activePageItems: headerItems("figuresDetails")
                    }))
                }}>
                    <h1>{TotalAttendees("Yes", undefined, undefined)} Total Attendees </h1>

                </div>
            </Col>
            <Col xs={12} className='col showOnMobile'>

                <div className="detailsColMobile" onClick={() => {
                    setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        leftFullDisplay: true,
                        activePage: "figures",
                        activePageItems: headerItems("figures")
                    }))
                }}>
                    <h1>View All</h1>

                </div>
            </Col>
            {attendees && attendees.length > 0 ? <>

                <Col className='hideOnMobile m-0'>
                    {availablePeopleGroups[0] &&
                        <div className="detailsCol" onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: availablePeopleGroups[0],
                                graphPartSelection: undefined,
                                typeSelection: "attendees",
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <h1>{TotalAttendees("Yes", availablePeopleGroups[0], undefined)}</h1>
                            <p className='mb-2'>{availablePeopleGroups[0] === "Owners" ? getClientName() : availablePeopleGroups[0]}</p> 
                            {
                                <BadgeItem text={availablePeopleGroups[0] === "Owners" ? "Owner" : "Accounts"} group={availablePeopleGroups[0]} value={getTotalAccountsFigures("Yes", availablePeopleGroups[0], undefined)} color={getColorOfPeopleGroup("foreground", availablePeopleGroups[0])} background={getColorOfPeopleGroup("background", availablePeopleGroups[0])} />}

                        </div>}
                    {availablePeopleGroups[2] &&
                        <div className="detailsCol" onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: availablePeopleGroups[2],
                                graphPartSelection: undefined,
                                typeSelection: "attendees",
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <h1>{TotalAttendees("Yes", availablePeopleGroups[2], undefined)}</h1>
                            <p className='mb-2'>{availablePeopleGroups[2] === "Owners" ? getClientName(): availablePeopleGroups[2]}</p>
                            {
                                <BadgeItem text={availablePeopleGroups[2] === "Owners" ? "Owner" : "Accounts"} group={availablePeopleGroups[2]} value={getTotalAccountsFigures("Yes", availablePeopleGroups[2], undefined)} color={getColorOfPeopleGroup("foreground", availablePeopleGroups[2])} background={getColorOfPeopleGroup("background", availablePeopleGroups[2])} />}

                        </div>}
                </Col>
                <Col className='hideOnMobile m-0'>

                    <div className="detailsCol main" onClick={() => {
                        setFigures((prevState) => ({
                            ...prevState,
                            peopleGroupSelection: undefined,
                            graphPartSelection: undefined,
                            attendeeListFilters: { peopleGroup: "", registrationType: "" },
                            typeSelection: "attendees"
                        })); setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            leftFullDisplay: true,
                            activePage: "figuresDetails",
                            activePageItems: headerItems("figuresDetails")
                        }))
                    }}>
                        <h1>{TotalAttendees("Yes", undefined, undefined)}</h1>
                        <p className='mb-2'>Total Attendees </p>
                        <BadgeItem text="Accounts" value={getTotalAccountsFigures("Yes", undefined, undefined)} color={getColorOfPeopleGroup("foreground", "total")} background={getColorOfPeopleGroup("background", "total")} />

                    </div>
                </Col>
                <Col className='hideOnMobile m-0'>
                    {availablePeopleGroups[1] &&
                        <div className="detailsCol" onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: availablePeopleGroups[1],
                                graphPartSelection: undefined,
                                typeSelection: "attendees",
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <h1>{TotalAttendees("Yes", availablePeopleGroups[1], undefined)}</h1>
                            <p className='mb-2'>{availablePeopleGroups[1] === "Owners" ? getClientName() : availablePeopleGroups[1]}</p>
                            {
                                <BadgeItem text={availablePeopleGroups[1] === "Owners" ? "Owner" : "Accounts"} group={availablePeopleGroups[1]} value={getTotalAccountsFigures("Yes", availablePeopleGroups[1], undefined)} color={getColorOfPeopleGroup("foreground", availablePeopleGroups[1])} background={getColorOfPeopleGroup("background", availablePeopleGroups[1])} />}
                        </div>}
                    {availablePeopleGroups[3] &&
                        <div className="detailsCol m-0" onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: availablePeopleGroups[3],
                                graphPartSelection: undefined,
                                typeSelection: "attendees",
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <h1>{TotalAttendees("Yes", availablePeopleGroups[3], undefined)}</h1>
                            <p className='mb-2'>{availablePeopleGroups[3] === "Owners" ? getClientName() : availablePeopleGroups[3]}</p>
                            {
                                <BadgeItem text={availablePeopleGroups[3] === "Owners" ? "Owner" : "Accounts"} group={availablePeopleGroups[3]} value={getTotalAccountsFigures("Yes", availablePeopleGroups[3], undefined)} color={getColorOfPeopleGroup("foreground", availablePeopleGroups[3])} background={getColorOfPeopleGroup("background", availablePeopleGroups[3])} />}
                        </div>}
                </Col>
            </> :
                <Col className='hideOnMobile m-0'>
                    <div className="detailsCol main d-fke">
                        <h3 className='mb-2 text-white text-center'>Stats Not Available</h3>
                    </div>
                </Col>
            }
        </Row >
    )
}

export default AllCounts