import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
function CMSFormCheckBoxSubmit(props) {
    const { label, name, options, form, required, onChange, disabled, ...rest } = props;

    return (
        <div>

            <div
                className={` ${form.touched[name] && form.errors[name] ? "is-invalid " : " "
                    }`}
            >
                <label className={`checkBoxLabel ${disabled ? "text-tertiaryColor":""} ${form.touched[name] && form.errors[name]
                    ? "is-invalidText ms-2 my-0 text-capital"
                    : "ms-2 my-0 text-capital"
                    }`}>
                    <Field type="checkbox" disabled={disabled} name={name} onChange={e => {
                        onChange(e)
                        form.handleChange(e)
                    }} checked={form.values[name]} />
                    {" "}{label}
                </label>



            </div>
            {form.touched[name] && form.errors[name] && (
                <span id={`${name}-error"`} className="error invalid-feedback">
                    <ErrorMessage name={name} />
                </span>
            )}
        </div>
    );
}

export default CMSFormCheckBoxSubmit;
