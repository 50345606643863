import React, { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import dummy from '../../../../../assets/images/dummy.jpg';
import { PiMicrophoneStageFill } from "react-icons/pi";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SpeakerContext } from '../../../../../context/SpeakerContext';
const SpeakerDetailsModal = ({ handleClose, details, mode }) => {
    const { speaker, setSpeaker } = useContext(SpeakerContext);
    const item = details
    const sessions = details.speakerSessions
    const day = details.speakerSessions.reduce((acc, d) => {
        if (Object.keys(acc).includes(d.day && d._id)) return acc;

        acc[d.day] = sessions.filter(
            (g) => g.day === d.day
        );
        return acc;
    }, {});
    delete day.null;

    let newDirectory = Object.values(
        sessions.reduce((acc, item) => {
            if (!acc[item.day])
                acc[item.day] = {
                    day: item.day,
                    title: [],
                };
            acc[item.day].title.push(item.title);
            return acc;
        }, {})
    );
    return (
        <>
            <Row className="mx-auto w-100 g-0">
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className=''>
                    <div className="speakerDetailsHolder Small">
                        <div className="speakerImage">
                            <img alt="" class="" src={details.photo ? process.env.REACT_APP_BACKEND_URL + "/public/" + details.photo : dummy} />
                        </div>
                        <div className="speakerDetails">
                            <h3 class="holder_name ">{details.firstName}{" "}{details.lastName}</h3>
                            <h4 class="holder_designation Small">{details.designation}</h4>
                            <h5 class="holder_company Small">{details.company}</h5>
                            {details.peopleGroup &&
                                <p class="speaker_types ng-binding" >{details.peopleGroup}</p>}
                            {mode === "max" && <>
                                {details.country &&
                                    <p class="fs-6" >{details.country}</p>}
                                {details.nationality &&
                                    <p class="fs-7" >{details.nationality}</p>}
                            </>}
                            {(details.email || details.mobile) &&
                                <div className="emailPhone">
                                    {speaker.speakerSettings && speaker.speakerSettings.showEmail === "1" && details.email &&
                                        <a href={`mailto:${details.email}`} class="d-flex align-items-center me-3 small" > <i class="fa fa-envelope me-2" aria-hidden="true"></i> {details.email}</a>}
                                    {speaker.speakerSettings && speaker.speakerSettings.showPhone === "1" && details.mobile &&
                                        <a class="d-flex align-items-center small" href={`tel:${details.mobile}`} >  <i class="fa fa-phone me-2" aria-hidden="true"></i> {details.mobile}</a>}
                                </div>}
                            {mode === "max" && details.introduction && <div className='info' dangerouslySetInnerHTML={{ __html: details.introduction }} />}
                        </div>
                        {(details.facebookProfile || details.instagramProfile || details.linkedinProfile || details.twitterProfile || details.youtubeProfile) ?
                            <div className='speakerSocial'>
                                <div>
                                    {details.facebookProfile && <a className=" icon" href={details.facebookProfile} target="_blank" title="View facebook"><FaFacebook className=" icon" /></a>}
                                    {details.instagramProfile && <a className=" icon" href={details.instagramProfile} target="_blank" title="View instagram"><FaInstagram className=" icon" /></a>}
                                    {details.linkedinProfile && <a className=" icon" href={details.linkedinProfile} target="_blank" title="View linkedin"><FaLinkedin className=" icon" /></a>}
                                    {details.twitterProfile && <a className=" icon" href={details.twitterProfile} target="_blank" title="View twitter"><FaSquareXTwitter className=" icon" /></a>}
                                    {details.youtubeProfile && <a className=" icon" href={details.youtubeProfile} target="_blank" title="View youtube"><FaYoutube className=" icon" /></a>}
                                </div>

                            </div> : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='speakerSessions'>
                    {newDirectory.filter((e) => e.day !== undefined).length > 0 &&
                        <h5 className="px-4 sessionHead">Session(s)</h5>
                    }
                    {newDirectory.filter((e) => e.day !== undefined).map((item) => (
                        <div className="box px-2 pt-1">
                            <h6 className=" dayHeading mb-0">
                                Day {item.day}
                            </h6>
                            {day[item.day] && day[item.day].map((day, index) => {
                                if (day._id) return (
                                    <>

                                        <div className={`session ${day.isDone === "1" ? "done" : ""}`}>
                                            <div className={`time`}>
                                                <p className="mb-0">
                                                    {day.startTime}
                                                </p>
                                            </div>
                                            <div className="ps-0 title">
                                                {day.isActive ===
                                                    "1" &&
                                                    <span className="current_animationHolder">
                                                        <span className="current_animation">

                                                        </span>
                                                    </span>
                                                }
                                                <p className={"mb-0"}>
                                                    {day.title} <span class="moderatingText">{day.isModerator === "1" ? <>(<PiMicrophoneStageFill className="mx-1 fs-8" /> Moderating this session )</> : null}</span>
                                                </p>


                                            </div>
                                        </div>

                                    </>
                                )
                            })}

                        </div>
                    ))}
                </Col>
            </Row>
        </>
    )
}

export default SpeakerDetailsModal