
export const listingInitialState = {
    data: {},
    isLoading: true,
    status: 'fetching',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
    error: "",
};

export const listingReducer = (state, action) => {
    switch (action.type) {

        case "FETCH_REQUEST":
            return {
                ...state,
                isLoading: true,
                status: 'fetching',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed

            };

        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                status: 'fetched',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
                data: action.payload
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                status: 'fetchFailed',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
                error: action.payload
            };


        case "BLOCK_REQUEST":
            return {
                ...state,
                isLoading: true,
                status: 'actionPerforming',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
            };
        case "BLOCK_FAILURE":
            return {
                ...state,
                isLoading: false,
                status: 'actionFailed',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
                error: action.payload
            };



        case "BULK_ACTION_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasFetchingError: false,
                hasError: false,
            };
        case "BULK_ACTION_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false,
                error: action.payload
            };




        case "REMOVE_ERROR":
            return {
                ...state,
                error: '',
                status: 'fetched'
            };
        default:
            return state;
    }
};