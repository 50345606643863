import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import CMSFormSearch from "../../../../UI/shared/formFields/CMSFormSearch";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import { getAvailableInitialLetters } from "../../../../../helper/sideHeaderHelper";
import { isEmpty, titleOfBreakout, titleOfDay } from "../../../../../helper/commonHelper";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
const SearchArea = () => {
    const { attendee, setAttendee } = useContext(AttendeeContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    let activeDay = localStorage.getItem("activeDay");
    let activeBreakout = localStorage.getItem("activeBreakout");
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const [showAtoZ, setShowAtoZ] = useState(false);
    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',];
    const lettersArabic = ['ا', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ي'];
    const availableLetters = getAvailableInitialLetters();
    return (
        <div className="whiteHeader">
            {attendees && attendees.length > 0 && <>
                <Formik
                    initialValues={{
                        search: attendee.searchTerm,
                    }}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {

                        // onSubmit(values);
                    }}
                >
                    {(form) => (
                        <Form className="widthInherit" onSubmit={form.handleSubmit}>
                            <div className="row">
                                <div className="searchArea  p-0">
                                    <CMSFormSearch name="search" form={form} required={true} />

                                </div>

                                <div className="searchButtons ps-0 pe-1">
                                    <button className={`${attendee.showAtoZ ? "active" : ""}`} onClick={() => {
                                        setAttendee((prevState) => ({
                                            ...prevState,
                                            showAtoZ: !attendee.showAtoZ
                                        }));
                                    }}>
                                        <i class="icon icon-atoz"></i>
                                    </button>
                                    <button onClick={() => {
                                        setAttendee((prevState) => ({
                                            ...prevState,
                                            searchTerm: "",
                                            reset: true,
                                            showAtoZ: false,
                                            activeLetter: attendee.allAttendees.length > 100 ? availableLetters[0] : "",
                                            filters: {}
                                        })); form.resetForm()
                                    }} className={`${(attendee.searchTerm != "" || !isEmpty(attendee.filters) || attendee.activeLetter != (attendees.length > 100 ? availableLetters[0] : "")) ? "" : "disabled"}`}>
                                        <i class="fa fa-refresh" aria-hidden="true"></i>
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <Row className="filterDetailsRow py-0">
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="p-0">
                        <div className="countShowing">
                            <p>Total<span>({attendees.length})</span></p>
                            <p>Filtered<span>({attendee.allAttendees && attendee.allAttendees.length})</span></p>
                            {attendee.activeLetter != "" &&
                                <p>Showing results with initial letter<span>"{attendee.activeLetter}"</span></p>}
                        </div>
                    </Col>
                    <Col className="p-0">
                        <div className="activeSession">
                            {(attendee.activeBreakout != null) ? <p className="mb-2">{titleOfBreakout(activeBreakout)}<span className='ms-1'>({titleOfDay(activeDay)})</span></p> : <p className="mb-2">{titleOfDay(activeDay)}</p>}

                        </div>
                    </Col>
                    {attendee.showAtoZ &&
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="p-0 selectInitialLetter">
                            <div className="aToZ">
                                <div className="aToZHolder">
                                    {letters && letters.map((letter, i) => {
                                        return (
                                            <div className={attendee.activeLetter === letter ? "active aToZItem" : availableLetters.includes(letter) ? "aToZItem enabled" : "aToZItem disabled"} onClick={() => {
                                                setAttendee((prevState) => ({
                                                    ...prevState,
                                                    searchTerm: "",
                                                    activeLetter: letter,
                                                    showAtoZ: false,
                                                    filters: {}
                                                }));
                                            }} key={i}>
                                                {letter}
                                            </div>
                                        )
                                    })}

                                </div>
                                {lettersArabic.length > 0 && availableLetters.some(letter => lettersArabic.includes(letter)) &&
                                    <div className="aToZHolder arabic">

                                        {lettersArabic && lettersArabic.map((letter, i) => {
                                            return (
                                                <div className={attendee.activeLetter === letter ? "active aToZItem" : availableLetters.includes(letter) ? "aToZItem enabled" : "aToZItem disabled"} onClick={() => {
                                                    setAttendee((prevState) => ({
                                                        ...prevState,
                                                        searchTerm: "",
                                                        activeLetter: letter,
                                                        showAtoZ: false,
                                                        filters: {}
                                                    }));
                                                }} key={i}>
                                                    {letter}
                                                </div>
                                            )
                                        })}
                                    </div>}

                            </div>
                        </Col>
                    }
                </Row>
            </>}

        </div>
    );
};

export default SearchArea;
