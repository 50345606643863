import { ErrorMessage } from "formik";
import React, { useState, useEffect, useReducer } from "react";
import {
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { updateInitialState, updateReducer } from "../../../../reducers/updateReducer";
const CMSFormFile = (props) => {
  const { name, label, form, required, info, reLoad, moduleName, id } = props;
  let initialImageURL =
    form.values[name] != "null"
      ? process.env.REACT_APP_BACKEND_URL + "/public/" + form.values[name]
      : null;
  const [removeState, removeDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(initialImageURL);
  const [fileName, setFileName] = useState("Add File");
  useEffect(() => {
    if (!image || !image.name) {
      setFileName(null);
    }
    else {
      setImageURL(URL.createObjectURL(image));
      setFileName(image.name);
    }

  }, [image]);
  useEffect(() => {
    if (!form.values[name] || !form.values[name].name) {
      setFileName("Add File");
    }
    else {
      setFileName(form.values[name].name);
    }

  }, [form.values[name]]);
  const onImageChange = (e) => {
    if (e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      form.setFieldValue(name, e.target.files[0]);
    } else {
      clearImage();
    }
  };

  const clearImage = async () => {
    setImage(null);
    await form.setFieldValue(name, "", true);
    setImageURL(null);
    setFileName(null);
    form.setFieldTouched(name, true);
  };

  return (
    <>
      <div className="form-group ">



        <div
          className={`input-group ${form.touched[name] && form.errors[name] ? "is-invalid" : ""
            }`}
        >

          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="file"
              aria-describedby="inputGroupFileAddon01"
              onChange={(e) => onImageChange(e)}
              onBlur={form.handleBlur}
            />
            <label
              className={` ${fileName != "Add File" ? "pink" : ""} ${form.touched[name] && form.errors[name]
                ? "is-invalid custom-file-label"
                : "custom-file-label"
                }`}
              htmlFor="inputGroupFile01"
            >
              <p className='mb-0'>{fileName}</p>
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
            </label>
          </div>
          {required === true && <div className="required"></div>}
          {form.touched[name] && form.errors[name] && (
          <span id={`${name}-error"`} className="errorMessage">
            <ErrorMessage name={name} />
          </span>
        )}
        </div>

       
      </div>



    </>
  );
};

export default CMSFormFile;
