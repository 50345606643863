import React, { useState, useEffect, useRef } from 'react';

const Download = ({ normal, lighter, className, style }) => {
    const [show, setShow] = useState(false);
    const dropDownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropDownRef} onClick={() => setShow(!show)} className="dropDownHolder">
            <i
                className={`fa fa-download cursor-pointer fs-4 ${className}`}
                aria-hidden="true"
                title="Download Attendees list"
            ></i>
            {show && (
                <div style={style} className="dropDown">
                    <ul>
                        {normal && (
                            <li onClick={normal}>Download Normal Version</li>
                        )}
                        {/* {lighter && (
                            <li onClick={lighter}>Download Lighter Version</li>
                        )} */}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Download;
