import React, { useReducer, useEffect, useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { updateInitialState, updateReducer } from "../../../reducers/updateReducer";
import axios from "../../../config/axios";
import CMSFormInput from "../../UI/shared/formFields/CMSFormInput";
import { AuthContext } from "../../../context/AuthContext";
import { sleep } from "../../../helper/commonHelper";

const AddUsheretteName = ({ handleClose }) => {
    const [nameState, nameDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const { userAuthDispatch } = useContext(AuthContext);
    const userSaved = JSON.parse(localStorage.getItem("user"));
    const onSubmitMessage = async (data) => {
        nameDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });
        await sleep(1000)
        try {
            const res = await axios.post(`edit-usherette/${userSaved._id}`, data);
            nameDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            localStorage.setItem("user", JSON.stringify({ ...userSaved, name: data.name }));
            userAuthDispatch({
                type: "LOGIN_SUCCESS",
                payload: { user: { ...userSaved, name: data.name } }
            });
            setTimeout(() => {
                handleClose();
                nameDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);




        } catch (error) {
            console.error("There was a problem with the operation:", error);
            alert("Error: " + error.message);
            nameDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
        }
    };
    useEffect(() => {
        nameDispatch({
            type: "FETCH_SUCCESS",
        });
    }, []);


    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                }}
                validationSchema={yup.object().shape({
                    name: yup.string().required("Enter Name"),
                })}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values) => {

                    onSubmitMessage(values)
                }}
            >
                {(form) => (
                    <Form onSubmit={form.handleSubmit}>
                        {nameState.isSubmitting &&
                            <div className="overlay">

                            </div>}
                        <Row className="pt-1 pb-2 mx-auto w-100">
                            <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                <CMSFormInput
                                    name="name"
                                    label="Enter your name to continue"
                                    placeholder="Enter your name"
                                    form={form}
                                    required={true}
                                />
                            </Col>

                            <Col
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="mt-2 mb-0 d-flex align-items-center justify-content-start"
                            >
                                <button
                                    className="formSubmissionButton"
                                    type="submit"
                                    onClick={(e) => {
                                        form.handleSubmit(e);
                                    }}
                                >
                                    {nameState.isSubmitting ? "PROCESSING" : "CONTINUE"}
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddUsheretteName