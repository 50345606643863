import React, { useCallback, useContext, useReducer, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { AttendeeContext } from '../../../../../context/AttendeeContext';
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import { AuthContext } from '../../../../../context/AuthContext';
import axios from '../../../../../config/axios';
import { getCheckinValue, highestIdObject } from '../../../../../helper/sideHeaderHelper';
import { updateInitialState, updateReducer } from '../../../../../reducers/updateReducer';

const CheckinQuarantinedAttendee = ({ data, handleClose, item, setItem, loadItems }) => {
    const { attendee, setAttendee } = useContext(AttendeeContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { userAuthState } = useContext(AuthContext);
    let attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });
        setItem((prevState) => ({
            ...prevState,
            loading: true,
        }));
        const values = {
            activityId: userAuthState.user && userAuthState.user.activityId,
            dayId: parseInt(attendee.activeDay),
            breakoutId: attendee.activeBreakout === null || attendee.activeBreakout === "null" ? null : attendee.activeBreakout,
            attendeeId: data._id,
            undo: 0,

        }

        var today = new Date();
        var hours = String(today.getHours()).padStart(2, '0'); // Ensures hours are always two digits
        var minutes = String(today.getMinutes()).padStart(2, '0'); // Ensures minutes are always two digits
        var time = hours + ":" + minutes;
        const newData = {
            undo: values.undo === 1 ? true : false,
            id: highestIdObject() + 1,
            attendeeId: values.attendeeId,
            activityId: userAuthState.user && userAuthState.user.activityId,
            dayId: attendee.activeDay,
            breakoutId: attendee.activeBreakout === null || attendee.activeBreakout === "null" ? null : attendee.activeBreakout,
            personId: data._id,
            checkin: "Yes",
            checkinTime: time,
            performerId: userAuthState.user && userAuthState.user._id,
            performerType: userAuthState.user && userAuthState.user.type,
            personName: userAuthState.user && userAuthState.user.name,
            appType: "Web"
        }
        attendeesContent.personActions.push(newData);
        const updatedUpdates = { ...attendeesContent };

        localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));

        setAttendee((prevState) => ({
            ...prevState,
            attendees: updatedUpdates.attendee,
            personActions: updatedUpdates.personActions
        }))

        try {
            const updateResponse = await axios.post(`check-in-quarantined/${userAuthState.user && userAuthState.user.activityId}`, newData);
            console.log(updateResponse)

            setLayout((prevState) => ({
                ...prevState,
                reloadAttendees: true,
            }))
            setItem((prevState) => ({
                ...prevState,
                loading: false,
            }));
            loadItems();
            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: updateResponse.data,
            });
        } catch (error) {
            console.error('There was a problem with the delete operation:', error)
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
        }
    };
    const handleDoubleClick = useCallback((data) => {
        if (layout.isServerBusy) {
            setTimeout(() => {
                onSubmit(data);

            }, 1000);
        } else {
            onSubmit(data);
        }
    }, [layout.isServerBusy]);
    useEffect(() => {

        updateDispatch({
            type: "FETCH_SUCCESS",
        });
    }, [])
    return (
        <Row className="mx-auto w-100 g-0">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mb-3'>
                Are you sure you want to check in this attendee as walkin?
            </Col>

            <Col className='d-flex align-items-center justify-content-end'>
                <Button className="modalButton lightGray me-3" onClick={handleClose} >
                    CLOSE
                </Button>
                <Button className="modalButton pink me-3" onClick={(e) => {
                    handleClose(e); handleDoubleClick(data)
                }}>
                    YES
                </Button>
            </Col>
        </Row>
    )
}

export default CheckinQuarantinedAttendee