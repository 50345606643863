import React, { useContext, useReducer, useEffect, useState } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import "./attendeesList.scss";
import DetailsTopIcons from "./DetailsTopIcons";
import { Button, Modal } from "react-bootstrap";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import {
  getAttendeeDetails,
  getCheckinOnADay,
  getColorOfPeopleGroup,
} from "../../../../../helper/sideHeaderHelper";
import dummyUser from "../../../../../images/dummyUser.png";
import {
  updateInitialState,
  updateReducer,
} from "../../../../../reducers/updateReducer";
import { getAttendeeInfoMissing, sleep } from "../../../../../helper/commonHelper";
import axios from "../../../../../config/axios";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import ModalBody from "../../../../UI/shared/modal/ModalBody";
import RemoveCustomFlag from "./RemoveCustomFlag";
import RegistrationTypeButton from "./RegistrationTypeButton";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { getFilteredAttendees, resolveRegistrationType } from "../../../../../helper/attendeeHelper";
import { listingInitialState, listingReducer } from "../../../../../reducers/listingReducer";
import Loading from "../../../../UI/shared/loading/Loading";
import ModuleDisplayDateTime from "../../../../UI/shared/display/ModuleDisplayDateTime";
import ModuleDisplayDate from "../../../../UI/shared/display/ModuleDisplayDate";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const AttendeeDetails = () => {
  const { attendee, setAttendee } = useContext(AttendeeContext);
  const { layout, setLayout } = useContext(HomeScreenContext);
  const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const details = getAttendeeDetails(attendee.activeAttendeeDetailsId);
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  function groupByDayAndTitle(data) {
    const groupedData = {};

    data.forEach(item => {
      // Check if the day already exists in the groupedData object
      if (!groupedData[item.day]) {
        groupedData[item.day] = {};
      }

      // Find or add the title to the day's group
      let titlesGroup = groupedData[item.day];
      if (!titlesGroup[item.title]) {
        titlesGroup[item.title] = [];
      }

      // Add the item to the corresponding title group
      titlesGroup[item.title].push(item);
    });

    return groupedData;
  }

  let breakoutByGroup = null;
  if (eventData) {
    if (eventData.eventBreakouts) {
      breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)
    }
  }
  const [show, setShow] = useState({
    flagShow: false,
    registrationShow: false,
  });
  const handleClose = () => setShow((prevState) => ({
    ...prevState,
    flagShow: false,
  }));
  const handleShow = () => setShow((prevState) => ({
    ...prevState,
    flagShow: true,
  }));
  const handleRegistrationClose = () => setShow((prevState) => ({
    ...prevState,
    registrationShow: false,
  }));
  const handleRegistrationShow = () => setShow((prevState) => ({
    ...prevState,
    registrationShow: true,
  }));
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [flagState, flagDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const loadAttendeeData = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    await sleep(100)
    try {
      const res = await axios.get(
        `get-attendee-data/${details._id}`
      );

      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadAttendeeData();
  }, []);
  useEffect(() => {
    loadAttendeeData();
  }, [layout.badgePrintingAttendee]);
  function updateAttendeeById(data) {
    return (
      attendeesContent &&
      attendeesContent.attendees.map((attendee) => {
        if (attendee._id === data._id) {
          // Return a new object with the updated properties
          return { ...attendee, ...data };
        }
        // Return the original object if the id does not match

        return attendee;
      })
    );
  }

  const onSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    delete data.checkin;
    delete data.checkinTime;
    const updatedData = {
      ...data,
      registrationType:
        resolveRegistrationType(data.registrationType) === "Walkin" ? "Created" : "Walkin",
    };
    const oldData = attendeesContent;
    const updatedUpdates = {
      ...attendeesContent,
      attendees: updateAttendeeById(updatedData),
    };
    localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
    try {
      const res = await axios.post(`edit-attendee/${data._id}`, updatedData);
      setAttendee((prevState) => ({
        ...prevState,
        detailUpdate: true
      }));
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 1000);
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      alert("Error: " + error.message);
      localStorage.setItem("attendeesContent", JSON.stringify(oldData));
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
    }
  };
  const onSubmitMessage = async (data) => {
    flagDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    delete data.checkin;
    delete data.checkinTime;
    const updatedData = {
      ...data,
      customMessage: "",
    };
    const oldData = attendeesContent;
    const updatedUpdates = {
      ...attendeesContent,
      attendees: updateAttendeeById(updatedData),
    };
    localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
    try {
      const res = await axios.post(`edit-attendee/${data._id}`, updatedData);
      setAttendee((prevState) => ({
        ...prevState,
        detailUpdate: true
      }));
      flagDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      setTimeout(() => {
        flagDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 1000);
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      alert("Error: " + error.message);
      localStorage.setItem("attendeesContent", JSON.stringify(oldData));
      flagDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
    });
    flagDispatch({
      type: "FETCH_SUCCESS",
    });
  }, []);

  function concatenateStrings(str1, str2, str3, str4, str5) {
    // Create an array of the strings
    let strings = [str1, str2, str3, str4, str5];

    // Filter out empty strings
    let nonEmptyStrings = strings.filter(function (str) {
      return str !== "";
    });

    // Join the non-empty strings with a comma
    return nonEmptyStrings.join(",");
  }
  return (
    <div>
      <div className={`topAlertMessage ${updateState.isSubmitted ? "" : "hide"}`}>
        Registration type changed successfully
      </div>
      <div className={`topAlertMessage ${flagState.isSubmitted ? "" : "hide"}`}>
        Custom flag removed successfully
      </div>

      {layout.reloadAttendees && <Loading />}
      {details != undefined && (
        <>
          <GrayHeader />
          <CustomScrollbars style={{ height: `calc(100vh - 193px)` }}>
            <div className="detailsHolder">
              <DetailsTopIcons details={details} />
              <div className="attendeeImageDetails borderBottom">
                <div className="imageHolder">
                  <img
                    src={details.photo ? process.env.REACT_APP_BACKEND_URL + "/public/" + details.photo : dummyUser}
                    alt="Attendee Image"
                    style={{
                      border: `3px solid ${getColorOfPeopleGroup(
                        "background",
                        details.peopleGroup
                      )}`,
                    }}
                    width="125"
                    height="125"
                  />
                  {details.checkin === "Yes" && (
                    <i
                      className="fa fa-check-circle checkMark"
                      aria-hidden="true"
                    ></i>
                  )}
                </div>
                <div className="details">
                  <p className="name">{details.fullName} {details.nameArabic && `(${details.nameArabic})`}  {(details.isSpeaker === "1" || details.isSpeaker === 1) ? <span className='speakerBadge ms-2'>- <i class="icon ms-1 me-1 icon-speakers text-secondary iconSwiper "></i> speaker</span> : ""}</p>
                  <p className="designation">{details.designation}</p>
                  <p className="company">{details.company} {details.companyArabic && (`(${details.companyArabic})`)}</p>
                  <p
                    className="peopleGroup"
                    style={{
                      color: getColorOfPeopleGroup(
                        "background",
                        details.peopleGroup
                      ),
                    }}
                  >
                    {details.peopleGroup}
                  </p>
                  <p className="gender">{details.gender}</p>
                  <p className="country">{details.country}</p>
                </div>
              </div>
              {getAttendeeInfoMissing(details) &&
                <div className="infoMissingRow registration borderBottom">
                  <p className="infoMissing mb-0"><i className="fa fa-exclamation-triangle te" aria-hidden="true"></i> Information Required</p>
                </div>
              }

              <div className="registration borderBottom">
                Registration Type :{" "}
                <span className="text-primaryColor">
                  {resolveRegistrationType(details.registrationType)} {`(${details.srcOfReg})`}
                </span>
                {checkPermission("changeAttendeeRegistrationType") &&
                  <RegistrationTypeButton details={details} updateState={updateState} detail={details} onSubmit={onSubmit} handleClose={handleRegistrationClose}
                    handleShow={handleRegistrationShow}
                    show={show.registrationShow} />}

                {/* <Button
                onClick={() => onSubmit(details)}
                className="btn-primary ms-3"
                disabled={updateState.isSubmitting ? true : false}
              >
                {
                  details.registrationType === "Walkin"
                    ? "Mark as Registered"
                    : "Mark as Walkin"}
              </Button> */}
              </div>
              {details.customMessage && (
                <div className="customFlag borderBottom" onClick={checkPermission("removeAttendeeCustomMessage") ? () => handleShow() : () => { return false }}>
                  {checkPermission("removeAttendeeCustomMessage") && <i className="fa fa-times-circle me-1" aria-hidden="true"></i>}
                  {details.customMessage}
                </div>
              )}
              {details.sector && details.sector != "" && (
                <div className="checkInDetails borderBottom">
                  <h6 className="text-primaryColor">Sector</h6>
                  <div className="details">
                    <span className="me-3">{details.sector}</span>
                  </div>
                </div>
              )}
              {(details.email || details.mobile || details.telephone) &&
                (details.email != "" ||
                  details.mobile != "" ||
                  details.telephone != "") && (
                  <div className="contactDetails borderBottom">
                    <h6 className="text-primaryColor">Contact Details</h6>
                    <div className="details">
                      {details.email && (
                        <a href={`mailto:${details.email}`} className="email">
                          {details.email}
                        </a>
                      )}
                      {details.mobile && (
                        <a href={`tel:${details.mobile}`} className="mobile">
                          {details.mobile}
                        </a>
                      )}
                      {details.telephone && (
                        <a href={`tel:${details.telephone}`} className="mobile">
                          {details.telephone}
                        </a>
                      )}
                    </div>
                  </div>
                )}
              {(details.city || details.address || details.country) &&
                (details.country != "" ||
                  details.city != "" ||
                  details.address != "") && (
                  <div className="addressDetails borderBottom">
                    <h6 className="text-primaryColor">Country</h6>
                    <div className="details">
                      {details.address && (
                        <p className="address">{details.address}</p>
                      )}
                      {details.city && details.address ? (
                        <p className="me-1">,</p>
                      ) : (
                        ""
                      )}
                      {details.city && <p className="city">{details.city}</p>}
                      {(details.city || details.address) && details.country ? (
                        <p className="me-1">,</p>
                      ) : (
                        ""
                      )}
                      {details.country && (
                        <p className="country">{details.country}</p>
                      )}
                    </div>
                  </div>
                )}
              {((details.extraInfo1 && details.extraInfo1 != "") || (details.extraInfo2 && details.extraInfo2 != "") || (details.extraInfo3 && details.extraInfo3 != "") || (details.extraInfo4 && details.extraInfo4 != "") || (details.extraInfo5 && details.extraInfo5 != "")) && (
                <div className="checkInDetails borderBottom">
                  <h6 className="text-primaryColor">Additional Info</h6>
                  <div className="details">
                    <span className="me-3">{concatenateStrings(details.extraInfo1, details.extraInfo2, details.extraInfo3, details.extraInfo4, details.extraInfo5)}</span>
                  </div>
                </div>
              )}
              {details.checkin === "Yes" && (
                <div className="checkInDetails borderBottom">
                  <h6 className="text-primaryColor">Checkin Details</h6>
                  <div className="details">
                    <>
                      <p className="me-3">
                        Time: <span>{details.checkinTime}</span>
                      </p>
                      {/* <p>
                        Source: <span>{details.srcOfReg}</span>
                      </p> */}
                    </>
                  </div>
                </div>
              )}
              {listingState.data && listingState.data.attendeeNotes && listingState.data.attendeeNotes.length > 0 && (
                <div className="checkInDetails borderBottom">
                  <h6 className="text-primaryColor">Attendee's Note(s)</h6>
                  {listingState.data.attendeeNotes.map((item, i) => {
                    return (
                      <div className="noteList">
                        {item.note}
                        <div className="bottomSection">
                          <div className="time">
                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                            {item.time}
                          </div>
                          <div className="organizerName">
                            {item.organizerName}
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>
              )}
              {(eventData &&
                eventData.eventDays &&
                eventData.eventDays.length > 1 || breakoutByGroup[1]) &&
                <div className="participation borderBottom">
                  <h6 className="text-primaryColor">Participation History</h6>
                  {eventData &&
                    eventData.eventDays &&
                    eventData.eventDays.length > 0 &&
                    eventData.eventDays.map((item, index) => {
                      return (
                        <div className="agenda mt-2">
                          <div className="day pb-2 borderBottom">
                            <h6 className="text-dark mb-0 ">{item.title}</h6>
                            <div className="mark_block">
                              {getCheckinOnADay(details._id, item.day, null) ? (
                                <i
                                  className="fa fa-check green"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa  fa-times red"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                          <div className="breakout">
                            <ul>
                              {eventData &&
                                eventData.eventBreakouts &&
                                eventData.eventBreakouts.length > 0 &&
                                eventData.eventBreakouts.map((breakout, index) => {
                                  if (item.day === breakout.day)
                                    return (
                                      <li>

                                        <p>{breakout.sessionTitle}</p>
                                        <div className="mark_block">
                                          {getCheckinOnADay(
                                            details._id,
                                            item.day,
                                            breakout._id
                                          ) ? (
                                            <i
                                              className="fa fa-check green"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa  fa-times red"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </div>
                                      </li>
                                    );
                                })}
                            </ul>
                          </div>
                        </div>
                      );
                    })}


                </div>}
              {checkPermission("attendeeBadgePrintingLog") && listingState.data && listingState.data.attendeeBadgePrintLog && listingState.data.attendeeBadgePrintLog.length > 0 && (
                <div className="checkInDetails borderBottom">
                  <h6 className="text-primaryColor">Badge Printing Details</h6>
                  <p className="badgeText">Badge Printed <span className="badgeGreen">{listingState.data.attendeeBadgePrintLog.length}</span> Time(s)</p>
                  {listingState.data.attendeeBadgePrintLog.map((item, i) => {
                    return (
                      <div className="badgeList">

                        <div className="bottomSection">
                          <div className="time">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            <ModuleDisplayDate value={item.createdAt} />
                          </div>
                          <div className="time">
                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                            {item.time}
                          </div>
                          <div className="time">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            {item.personName}
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>
              )}
            </div>
          </CustomScrollbars>

        </>
      )}
      <ModalBody
        handleClose={handleClose}
        handleShow={handleShow}
        closeButton={false}
        heading="Confirm!"
        className="undoCheckInModal"
        show={show.flagShow}
        content={<RemoveCustomFlag handleClose={handleClose} removeCustomFlag={() => onSubmitMessage(details)} />}
      />
    </div>
  );
};

export default AttendeeDetails;
