import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import "../../figures.scss"
import { CombinedContext } from '../../../../../../../context/CombinedContext';
import { BuildGraphDataCombined, headerItems } from '../../../../../../../helper/sideHeaderHelper';
import Loading from '../../../../../../UI/shared/loading/Loading';
import { HomeScreenContext } from '../../../../../../../context/HomeScreenContext';
const PieChart = () => {
    const { combined, setCombined } = useContext(CombinedContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [chartOptions, setChartOptions] = useState(null);
    useEffect(() => {
        if (combined) {
            setChartOptions(null);
            const updatedChartOptions = BuildGraphDataCombined(combined.typeSelection, combined.graphPartSelection, combined, setCombined)?.chartConfig;
            setTimeout(() => {
                setChartOptions(updatedChartOptions);
            }, 500);
        }
    }, [combined]);
    return (
        <>

            {chartOptions === null ?
                <div className='loadingHolder'>
                    {/* <Loading style={{ background: ' rgba(255, 255, 255, 0)' }} />  */}
                </div> :
                <div className='pieHolderDiv'>
                    <span className='hideChart' onClick={() => setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        leftFullDisplay: false,
                        activePage: "combinedReport",
                        activePageItems: headerItems("combinedReport")
                    }))}>Hide Chart</span>
                    {chartOptions != undefined ?
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} /> : <p className='text-white'>Graph not avilable</p>}
                </div>}
        </>

    );
};

export default PieChart;