import React, { useContext, useReducer, useEffect, useState } from 'react'
import TopGraySection from '../figures/TopGraySection'
import { Col, Row } from 'react-bootstrap'
import "./organizerLog.scss"
import { listingInitialState, listingReducer } from '../../../../../reducers/listingReducer'
import { AuthContext } from '../../../../../context/AuthContext'
import axios from '../../../../../config/axios'
import Loading from '../../../../UI/shared/loading/Loading'
import { FiguresContext } from '../../../../../context/FiguresContext'
import { OrganizerContext } from '../../../../../context/OrganizerContext'
import { HomeScreenContext } from '../../../../../context/HomeScreenContext'
import { headerItems } from '../../../../../helper/sideHeaderHelper'
const OrganizerLog = () => {
    const { userAuthState } = useContext(AuthContext);
    const { figures } = useContext(FiguresContext);
    const { organizer, setOrganizer } = useContext(OrganizerContext);
    const { layout, setLayout } = useContext(HomeScreenContext);

    const [item, setItem] = useState({
        items: [],
        users: []
    })
    const [loading, setLoading] = useState(false)
    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );
    const loadItems = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });
        try {
            const res = await axios.get(
                `get-organizers-log/${userAuthState.user && userAuthState.user.activityId}`
            );


            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
            setItem((prevState) => ({
                ...prevState,
                items: res.data && res.data.items,
                users: res.data && res.data.users,
            }));
        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };
    useEffect(() => {
        loadItems();
    }, []);
    const groupedByPerformerType = item.items && item.items.reduce((acc, item) => {
        const { performerId, performerType } = item;
        if (!acc[performerId]) {
            acc[performerId] = [];
        }
        acc[performerId].push(item);
        return acc;
    }, {});

    const getLengthByAction = (groupedItems, type, action) => {
        let totalLength = 0;
        const filteredItems = groupedItems[type].filter(obj => (parseInt(obj.dayId) === parseInt(figures.activeDay)) && (obj.breakoutId === figures.activeBreakout) && (obj.action === action));
        totalLength += filteredItems.length;
        return totalLength;
    };
    const getListByAction = (groupedItems, type, action) => {
        if (type) {
            const filteredItems = groupedItems[type].filter(obj => (parseInt(obj.dayId) === parseInt(figures.activeDay)) && (obj.breakoutId === figures.activeBreakout) && (obj.action === action));
            return filteredItems;
        }
        return groupedItems;
    };
    const getNameById = (id) => {
        const filteredItems = item.users && item.users.filter(obj => (obj._id === id));

        return filteredItems[0] && filteredItems[0].name;
    };
    const getTypeById = (id) => {
        const filteredItems = item.users && item.users.filter(obj => (obj._id === id));
        return filteredItems[0] && filteredItems[0].type;
    };
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [figures.activeDay, figures.activeBreakout])
    useEffect(() => {


    }, [])
    useEffect(() => {
        if (layout.activePage === "organizerLog") {
            setOrganizer((prevState) => ({
                ...prevState,
                list: [],
                name: "",
                type: "",
                action: "",
            }));
        }

    }, [layout.activePage])
    return (
        <>

            <TopGraySection dayOverview={false} />

            <Row className='w-100 mx-auto'>
                {loading && <Loading />}
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                    {Object.keys(groupedByPerformerType).map(type => (
                        <div key={type}>
                            <div className='itemHold'>
                                <div className='nameSection'>
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    <span className='ms-2'>{getNameById(type)} {getTypeById(type) ? `(${getTypeById(type)})` : ""}</span>
                                </div>
                                <div className='dataSection'>
                                    <div className='subData' onClick={() => {
                                        setOrganizer((prevState) => ({
                                            ...prevState,
                                            action: "Checkin",
                                            name: getNameById(type),
                                            typeRaw: type,
                                            type: getTypeById(type),
                                            list: getListByAction(groupedByPerformerType, type, "Checkin"),
                                            AllList: groupedByPerformerType,
                                        })); setLayout((prevState) => ({
                                            ...prevState,
                                            leftSideMenu: true,
                                            rightSideMenu: false,
                                            activePage: "organizerLogDetails",
                                            activePageItems: headerItems("organizerLogDetails")
                                        }))
                                    }}>
                                        <div className='heading'>
                                            Check-In
                                        </div>
                                        <div className='value'>
                                            {getLengthByAction(groupedByPerformerType, type, "Checkin")}
                                        </div>

                                    </div>
                                    <div className='subData' onClick={() => {
                                        setOrganizer((prevState) => ({
                                            ...prevState,
                                            action: "Checkout",
                                            name: getNameById(type),
                                            typeRaw: type,
                                            type: getTypeById(type),
                                            list: getListByAction(groupedByPerformerType, type, "Checkout"),
                                            AllList: groupedByPerformerType,
                                        })); setLayout((prevState) => ({
                                            ...prevState,
                                            leftSideMenu: true,
                                            rightSideMenu: false,
                                            activePage: "organizerLogDetails",
                                            activePageItems: headerItems("organizerLogDetails")
                                        }))
                                    }}>
                                        <div className='heading'>
                                            Check-Out
                                        </div>
                                        <div className='value'>
                                            {getLengthByAction(groupedByPerformerType, type, "Checkout")}
                                        </div>
                                    </div>

                                    <div className='subData' onClick={() => {
                                        setOrganizer((prevState) => ({
                                            ...prevState,
                                            action: "Print Badge",
                                            name: getNameById(type),
                                            typeRaw: type,
                                            type: getTypeById(type),
                                            list: getListByAction(groupedByPerformerType, type, "Print Badge"),
                                            AllList: groupedByPerformerType,
                                        })); setLayout((prevState) => ({
                                            ...prevState,
                                            leftSideMenu: true,
                                            rightSideMenu: false,
                                            activePage: "organizerLogDetails",
                                            activePageItems: headerItems("organizerLogDetails")
                                        }))
                                    }}>
                                        <div className='heading'>
                                            Badge print
                                        </div>
                                        <div className='value'>
                                            {getLengthByAction(groupedByPerformerType, type, "Print Badge")}
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>
                    ))}
                    {Object.keys(groupedByPerformerType).length <= 0 && <h3 className="my-5 pt-5 text-center">No Records Found!</h3>}


                </Col>
            </Row >

        </>
    )
}

export default OrganizerLog