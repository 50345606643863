import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { SpeakerContext } from '../../../../../context/SpeakerContext';
import dummy from '../../../../../assets/images/dummy.jpg';
import { PiMicrophoneStageFill } from "react-icons/pi";
const SpeakerDetails = () => {
    const { speaker, setSpeaker } = useContext(SpeakerContext);
    const item = speaker.activeSpeaker
    const sessions = speaker.activeSpeaker && speaker.activeSpeaker.speakerSessions
    const day = speaker.activeSpeaker && speaker.activeSpeaker.speakerSessions.reduce((acc, d) => {
        if (Object.keys(acc).includes(d.day && d._id)) return acc;

        acc[d.day] = sessions.filter(
            (g) => g.day === d.day
        );
        return acc;
    }, {});
    delete day.null;

    let newDirectory = Object.values(
        sessions.reduce((acc, item) => {
            if (!acc[item.day])
                acc[item.day] = {
                    day: item.day,
                    title: [],
                };
            acc[item.day].title.push(item.title);
            return acc;
        }, {})
    );
    return (
        <>
            {item && item != null &&
                <Row>
                    <Col>
                        <div className="speakerDetailsHolder">
                            <div className="speakerImage">
                                <img alt="" class="" src={item.photo ? process.env.REACT_APP_BACKEND_URL + "/public/" + item.photo : dummy} />
                            </div>
                            <div className="speakerDetails noPointer">
                                <h3 class="holder_name ng-binding">{item.firstName}{" "}{item.lastName} </h3>
                                <h4 class="holder_designation ng-binding">{item.designation}</h4>
                                <h5 class="holder_company ng-binding">{item.company}</h5>
                                {item.peopleGroup &&
                                    <p class="speaker_types ng-binding" >{item.peopleGroup}</p>}
                                {item.country &&
                                    <p class="" >{item.country}</p>}
                                {item.nationality &&
                                    <p class="" >{item.nationality}</p>}
                                {(item.email || item.phone) &&
                                    <div className="emailPhone">
                                        {item.email &&
                                            <a class="" href={`mailto:${item.email}`} > <i class="fa fa-envelope" aria-hidden="true"></i> {item.email}</a>}
                                        {item.phone &&
                                            <a class="" href={`tel:${item.phone}`}>  <i class="fa fa-phone" aria-hidden="true"></i> {item.phone}</a>}
                                    </div>}
                                {item.introduction && <div className='info' dangerouslySetInnerHTML={{ __html: item.introduction }} />}
                            </div>
                        </div>

                    </Col>
                </Row>
            }
            <Row>
                <Col className='speakerSessions'>
                    {newDirectory.filter((e) => e.day !== undefined).length > 0 &&
                        <h5 className="px-4 sessionHead">Session(s)</h5>
                    }
                    {newDirectory.filter((e) => e.day !== undefined).map((item) => (
                        <div className="box px-2 pt-1 px-4">
                            <h6 className=" dayHeading mb-0">
                                Day {item.day}
                            </h6>
                            {day[item.day] && day[item.day].map((day, index) => {
                                if (day._id) return (
                                    <>

                                        <div className="session">
                                            <div className={`time`}>
                                                <p className="mb-0">
                                                    {day.startTime}
                                                </p>
                                            </div>
                                            <div className="ps-0">
                                                <p className={"mb-0"}>
                                                    {day.title} <span class="moderatingText">{day.isModerator === "1" ? <>(<PiMicrophoneStageFill className="mx-1 fs-8" /> Moderating this session )</> : null}</span>
                                                </p>


                                            </div>
                                        </div>

                                    </>
                                )
                            })}

                        </div>
                    ))}
                </Col>
            </Row>


        </>
    )
}

export default SpeakerDetails