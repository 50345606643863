import { ErrorMessage } from 'formik';
import React, { useState, useEffect, useCallback, useReducer } from 'react'
import ReactFancyBox from 'react-fancybox'
import { OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import { updateInitialState, updateReducer } from '../../../../reducers/updateReducer';
import axios from '../../../../config/axios';
import { getCroppedImg } from '../../../../helper/canvasUtils';
import "../../../../App.scss"
const CMSFormImageWithCrop = (props) => {

  const { name, label, form, aspect, outputSize, required, reLoad, className, moduleName, id, helpMessage } = props

  let initialImageURL = form.values[name] ? process.env.REACT_APP_BACKEND_URL + '/public/' + form.values[name] : null;

  const [removeState, removeDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(initialImageURL);

  const [showCropping, setShowCropping] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const [fileName, setFileName] = useState(form.values[name] ? form.values[name] : "Add File");
  useEffect(() => {
    if (!image || !image.name) return;
    setImageURL(URL.createObjectURL(image));
    setFileName(image.name);
  }, [image]);

  const onImageChange = (e) => {
    if (e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      form.setFieldValue(name, e.target.files[0]);
    } else {
      clearImage();
    }
  };
  const onRemoveFile = async (data) => {


    removeDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`/remove-file`, data); //
      removeDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      reLoad();
      clearImage();

    } catch (error) {
      clearImage();
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;


      removeDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        removeDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const clearImage = async () => {

    setImage(null);
    await form.setFieldValue(name, "");
    setImageURL(null);
    setFileName("Add File");
    form.setFieldTouched(name, false);
  };


  const cropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageURL,
        croppedAreaPixels,
        outputSize,
        form.values[name].type
      )

      setImageURL(croppedImage.fileUrl)
      setShowCropping(false)

      const newImage = new File([croppedImage.blob], "croppedImage." + (image.type === 'image/png' ? 'png' : 'jpeg'), { type: image.type }, { fileUrl: croppedImage.fileUrl });
      form.setFieldValue(name, newImage)

    } catch (e) {
      console.error(e)
    }
  }, [imageURL, croppedAreaPixels])

  return (
    <>
      {/* {
        (label !== "") &&
        <>
          {
            (required === true)
              ? <OverlayTrigger
                key={`tooltip-${name}`}
                placement='right'
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    Required field.
                  </Tooltip>
                }
              ><label htmlFor={name} className={`required ${className} ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
              : <label htmlFor={name} className={` ${className}  ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
          }
        </>
      } */}



      {!showCropping &&
        <div className="form-group image">
          <>
            <div className={`input-group ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}>

              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id={name}
                  name={name}
                  aria-describedby="inputGroupFileAddon01"
                  accept="image/*"
                  onChange={(e) => onImageChange(e)}
                  onBlur={form.handleBlur}
                />
                <label
                  className={` ${fileName != "Add File" ? "pink" : ""} ${form.touched[name] && form.errors[name]
                    ? "is-invalid custom-file-label"
                    : "custom-file-label"
                    }`}
                  htmlFor="inputGroupFile01"
                >
                  <p className='mb-0'>{fileName}</p>
                  <i class="fa fa-plus-circle" aria-hidden="true"></i>
                </label>
                {required === true && <div className="required"></div>}
              </div>
              {(form.errors[name]) &&
                <span id={`${name}-error"`} className="errorMessage"  >
                  <ErrorMessage name={name} />
                </span>
              }
            </div>


          </>
        </div>
      }


      {showCropping && <Row className='croppingRow'>
        <div className="croppingArea">
          <Cropper
            image={imageURL}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            //restrictPosition={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="imageActionsContainerBack">
          <div className="imageAction">
            <i className="fa fa-arrow-left" onClick={() => setShowCropping(false)} />
          </div>
          <div className="imageAction ">
            <i className="fa fa-crop " onClick={() => cropImage()} />
          </div>
        </div>
      </Row>}
      {(imageURL && !showCropping) && <Row className="formImagePreviewRow">

        <div className="formImagePreview">
          <img src={imageURL} className="thumbnail" />
          {/* <ReactFancyBox
thumbnail={imageURL}
image={imageURL} /> */}

        </div>

        <div className="imageActionsContainer">
          <div className="imageAction">
            <i class="fa fa-times" onClick={() => onRemoveFile({ moduleName: moduleName, fieldName: name, id: id })} />
          </div>
          <div className="imageAction">
            <i class="fa fa-crop" onClick={() => setShowCropping(true)} />
          </div>
        </div>

      </Row>}

    </>
  )
}

export default CMSFormImageWithCrop