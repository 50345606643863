import React, { useRef ,useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import "./print.scss"
const Print = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const manageCredentialsData = JSON.parse((localStorage.getItem("manageCredentialsData")));
    const manageCredentialsType = localStorage.getItem("manageCredentialsType");
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            // Skip the effect on the first render
            isFirstRender.current = false;
            return;
        }
        setTimeout(() => {
            document.querySelector('.closeIcon').classList.add('print');
            window.print();
            document.querySelector('.closeIcon').classList.remove('print'); // Remove the class after print
        }, 500);
    }, [])
    const handleCloseTab = () => {
        window.close();
      };
    return (
        <Row className="w-100 mx-auto">
            <Col className="topSection px-3" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="details">
            <h3 className='eventName'>{eventData.title}</h3>
            <p className='section'>Credentials {`>`} Selected Roles : <span className='subSection'>{manageCredentialsType}</span></p>
            </div>
            <div className='closeIcon' onClick={handleCloseTab}>
            <i class="fa fa-times" onClick={handleCloseTab} aria-hidden="true"></i>

            </div>
                
            </Col>
            <Col className="listSection" xl={12} lg={12} md={12} sm={12} xs={12}>
                {manageCredentialsData && manageCredentialsData.map((item, i) => {
                    if (manageCredentialsType === "All" || manageCredentialsType === item.type) return (
                        <div className="listSectionDiv">

                            <div className="note">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className={"noteText text-quaternaryColor mb-1"}>
                                        Type: <span>{item.type}</span>
                                    </div>

                                </div>

                                <div className="noteText text-quaternaryColor mb-1">
                                    Name: {item.name}
                                </div>
                                <div className="noteText text-quaternaryColor mb-1">
                                    Email: {item.email}
                                </div>
                                <div className="noteText text-quaternaryColor mb-1">
                                    Username: <span className="text-primaryColor">{item.userName}</span>
                                </div>
                                <div className="noteText text-quaternaryColor">
                                    Password: <span className="text-primaryColor">{item.password}</span>
                                </div>
                            </div>

                        </div>
                    )
                })}



            </Col>

        </Row>
    )
}

export default Print