import React, { useContext, useEffect, useState, useReducer } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Col, Row } from "react-bootstrap";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { SpeakerContext } from "../../../../../context/SpeakerContext";
import Loading from "../../../../UI/shared/loading/Loading";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import { getAvailableCountries, getAvailablePeopleGroupSpeakers, getAvailablePeopleGroups, getAvailableSectors } from "../../../../../helper/sideHeaderHelper";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";


const Filters = () => {
  const { speaker, setSpeaker } = useContext(SpeakerContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (speaker.filters) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [speaker.filters])
  return (
    <>
      {speaker.allSpeakers && speaker.allSpeakers.length > 0 &&
        <Formik
          initialValues={{ peopleGroup: speaker.filters.peopleGroup, search: speaker.searchTerm }}
          validationSchema={yup.object().shape({
            peopleGroup: yup.string(),
            search: yup.string(),
          })}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            setSpeaker((prevState) => ({
              ...prevState,
              filters: { peopleGroup: values.peopleGroup },
              searchTerm: values.search
            }))
          }}
        >
          {(form) => (
            <Form className="w-100">
              <div className={`attendeeFiltersRow`}>

                {getAvailablePeopleGroupSpeakers(speaker.allSpeakers) && getAvailablePeopleGroupSpeakers(speaker.allSpeakers).length > 0 &&
                  <div className="pe-1 pb-1">
                    <CMSFormSelect
                      name="peopleGroup"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={handleFormatArray(getAvailablePeopleGroupSpeakers(speaker.allSpeakers))}

                    />
                  </div>}

                <div className="pe-1 pb-1">
                  <CMSFormInput
                    name="search"
                    onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                    placeholder="Start typing here"
                    form={form}

                  />
                </div>


              </div>

            </Form>
          )}
        </Formik>}
    </>


  );
};

export default Filters;
