import React, { useState, useReducer, useEffect, useContext, useRef } from "react";
import TopGraySectionEmpty from "../figures/TopGraySectionEmpty";
import { Col, Row } from "react-bootstrap";
import "./notes.scss";
import {
    listingInitialState,
    listingReducer,
} from "../../../../../reducers/listingReducer";
import axios from "../../../../../config/axios";
import { AuthContext } from "../../../../../context/AuthContext";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CMSFormTextArea from "../../../../UI/shared/formFields/CMSFormTextArea";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { getAttendeeById, handleFormatArrayId } from "../../../../../helper/commonHelper";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import Loading from "../../../../UI/shared/loading/Loading";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const Notes = () => {
    const [active, setActive] = useState("All");
    const { userAuthState } = useContext(AuthContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const addNoteFormRef = useRef(null);

    const scrollToForm = () => {
        if (addNoteFormRef.current) {
            addNoteFormRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const loadNotes = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });
        await sleep(100)
        try {
            const res = await axios.get(
                `get-notes/${userAuthState.user && userAuthState.user.activityId}`
            );

            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };

    const onSubmit = async (data, { resetForm }) => {
        var today = new Date();
        var hours = today.getHours();
        var minutes = String(today.getMinutes()).padStart(2, '0'); // Ensures minutes are always two digits

        var period = hours >= 12 ? 'PM' : 'AM'; // Determine AM/PM
        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

        var formattedHours = String(hours).padStart(2, '0'); // Ensures hours are always two digits
        var time = formattedHours + ":" + minutes + " " + period;
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.post(`add-note/${userAuthState.user && userAuthState.user.activityId}`, { ...data, time: time });

            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            loadNotes();
            resetForm();

        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };
    useEffect(() => {
        loadNotes();
        updateDispatch({
            type: "FETCH_SUCCESS",
        });
    }, []);
    return (
        <>
            {listingState.isLoading && <Loading />}
            <>
                <TopGraySectionEmpty>
                    {listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
                        <Row className="notesTopSection">
                            <Col className="leftSection">
                                <div className="notesType">
                                    <p
                                        className={active === "All" ? "active" : ""}
                                        onClick={() => setActive("All")}
                                    >
                                        All
                                    </p>
                                    <p
                                        className={active === "Attendee Notes" ? "active" : ""}
                                        onClick={() => setActive("Attendee Notes")}
                                    >
                                        Attendee Notes
                                    </p>
                                    <p
                                        className={active === "Activity Notes" ? "active" : ""}
                                        onClick={() => setActive("Activity Notes")}
                                    >
                                        Event Notes
                                    </p>
                                </div>
                            </Col>
                            {checkPermission("addNote") &&
                                <Col xl={3} lg={2} md={3} sm={4} xs={4} className="rightSection">
                                    {listingState.data.item.length > 2 &&
                                        <div className="addNoteButton" onClick={scrollToForm}>
                                            <span className="me-2">Add Note</span>
                                            <i className="icon icon-addnote" aria-hidden="true"></i>
                                        </div>}
                                </Col>}
                        </Row>}
                </TopGraySectionEmpty>
               
                <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
                    {listingState.data && listingState.data.item && listingState.data.item.length > 0 ?
                        <Row className="w-100 mx-auto" >
                            <Col className="notesListing mx-2">
                                {listingState.data.item.map((item, i) => {
                                    if (active === "All" || active === item.noteType) return (
                                        <div className="noteList">
                                            <div className="topSection_">

                                                <div className="holderInfo">

                                                    {item.attendeeId && getAttendeeById(item.attendeeId) && <>
                                                        <p className="name">{getAttendeeById(item.attendeeId) && getAttendeeById(item.attendeeId).fullName}</p>
                                                        <p className="designation">{getAttendeeById(item.attendeeId) && getAttendeeById(item.attendeeId).designation}</p>
                                                        <p className="company">{getAttendeeById(item.attendeeId) && getAttendeeById(item.attendeeId).company}</p>
                                                    </>}
                                                </div>
                                                <div className="noteType">
                                                    {item.noteType}
                                                </div>

                                            </div>
                                            <div className="note">
                                                <div className={item.attendeeId != null ? "mt-3 noteText" : "noteText"}>
                                                    {item.note}
                                                </div>
                                                <div className="time">
                                                    {item.time}
                                                </div>

                                            </div>
                                            <div className="organizerName">
                                                <div>
                                                    Added By: {item.organizerName}

                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}


                            </Col>
                        </Row> :
                        <h4 className="text-center py-5 mt-5">No Records Found!</h4>
                    }
                    {checkPermission("addNote") &&
                        <Row className="addNoteForm w-100 mx-auto" ref={addNoteFormRef}>
                            <Col className="px-0">
                                <div className="addNoteButton">
                                    <i className="icon icon-addnote" aria-hidden="true"></i>
                                    <span className="ms-1">Add Note</span>
                                </div>
                                <div>
                                    <Formik
                                        initialValues={{
                                            note: "",
                                            activityId: userAuthState.user && userAuthState.user.activityId,
                                            noteType: layout.noteType ? layout.noteType : "",
                                            organizerName: userAuthState.user && userAuthState.user.name ? userAuthState.user && userAuthState.user.name : "",
                                            attendeeId: layout.noteAttendeeId ? layout.noteAttendeeId : null,
                                        }}
                                        validationSchema={yup.object().shape({
                                            note: yup.string().required("Enter Note").max(200, 'Note should not exceed 200 characters'),
                                            noteType: yup.string().required("Select Note Type"),
                                            attendeeId: yup.string().nullable().when("noteType", {
                                                is: "Attendee Notes",
                                                then: () => yup
                                                    .string()
                                                    .required("Select Attendee")

                                            }),

                                        })}
                                        enableReinitialize={true}
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        onSubmit={(values, { resetForm }) => {
                                            onSubmit(values, { resetForm });
                                        }}
                                    >
                                        {(form) => (
                                            <Form onSubmit={form.handleSubmit}>
                                                <Row
                                                    className={`notesFormRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`}
                                                >
                                                    <Col xl={12} lg={12} md={12} xs={12} className="pb-4 mb-4 px-0">
                                                        <CMSFormTextArea
                                                            name="note"
                                                            placeholder="Note/ Comment"
                                                            form={form}
                                                            maxLength={200}
                                                            required={true}
                                                        />
                                                    </Col>
                                                    {layout.noteAttendeeId === null &&
                                                        <Col xl={12} lg={12} md={12} xs={12} className="pb-4  px-0">
                                                            <div className="noteTypeSelection">
                                                                <div className={form.values.noteType === "Activity Notes" ? "active me-3 noteTypeButton" : " me-3 noteTypeButton"} onClick={() => form.setFieldValue("noteType", "Activity Notes")}>
                                                                    Add Activity Note
                                                                </div>
                                                                {handleFormatArrayId().length > 0 &&
                                                                    <div className={form.values.noteType === "Attendee Notes" ? "active me-3 noteTypeButton" : " me-3 noteTypeButton"} onClick={() => form.setFieldValue("noteType", "Attendee Notes")}>
                                                                        Add Attendee Note
                                                                    </div>}

                                                            </div>

                                                        </Col>}
                                                    {form.values.noteType === "Activity Notes" && <Col xl={10} lg={10} md={10} xs={12} className="pb-1 px-0 mx-auto">
                                                        <p className="text-center mb-0">Note will be added as an Event General Note</p>
                                                    </Col>}
                                                    {form.values.noteType === "Attendee Notes" && handleFormatArrayId().length > 0 &&
                                                        <Col xl={10} lg={10} md={10} xs={12} className="pb-2  px-0 mx-auto">
                                                            <CMSFormSelect
                                                                name="attendeeId"
                                                                placeholder={{ label: "Select Attendee", value: "" }}
                                                                form={form}
                                                                onChange={(e) => { form.handleChange(e) }}
                                                                options={handleFormatArrayId()}
                                                                required={form.values.noteType === "Attendee Notes" ? true : false}
                                                            />

                                                        </Col>}
                                                    <Col
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className="mt-4 mb-3 d-flex align-items-center justify-content-center"
                                                    >
                                                        <button
                                                            className="formSubmissionButton bg-secondaryColor me-2"
                                                            type="submit"
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default form submission
                                                                //form.resetForm(); // Reset form values to initial state
                                                                form.setFieldValue("note", "")
                                                                form.setFieldValue("noteType", "Activity Notes")
                                                                form.setFieldValue("attendeeId", null)
                                                                setLayout((prevState) => ({
                                                                    ...prevState,
                                                                    noteType: "Activity Notes",
                                                                    noteAttendeeId: null,
                                                                }))
                                                            }}
                                                        >
                                                            RESET
                                                        </button>
                                                        <button
                                                            className="formSubmissionButton dark "
                                                            type="submit"
                                                            onClick={(e) => {
                                                                form.handleSubmit(e);
                                                            }}
                                                        >
                                                            ADD
                                                        </button>
                                                    </Col>

                                                    <Col
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed"
                                                    >
                                                        {!updateState.isLoading &&
                                                            updateState.status === "submitFailed" && (
                                                                <p className="mb-0">
                                                                    <i
                                                                        className="fa fa-exclamation-circle me-2"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    Action Failed! Try again.
                                                                </p>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>}
                </CustomScrollbars>
            </>
        </>
    );
};

export default Notes;
