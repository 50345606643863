import React, { useContext, useState, useEffect } from "react";
import "./leftSideSection.scss";
import PinkHeader from "./partials/pinkHeader/PinkHeader";
import { HomeScreenContext } from "../../../../context/HomeScreenContext";
import Loading from "../loading/Loading";
const LeftSideSection = () => {
  const { layout, setLayout } = useContext(HomeScreenContext);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(false);
    setTimeout(() => {
      setShow(false);
    }, 100);
  };
  useEffect(() => {
    handleShow();
  }, [layout.activePage]);

  return (
    <>

      <div
        className={`leftSideSection ${layout.imageExpandView ? "footerHide" : ""} ${layout.noAnimation ? "noAnime" : ""} ${layout.leftFullDisplay ? "full" : "half"
          } ${layout.leftSideMenu ? "show" : "hideMenu"}`}
      >
        <PinkHeader />
   
        <div className="componentHolder">
          {!show ? (
            <>{layout.activePageItems && layout.activePageItems.component}</>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
};

export default LeftSideSection;
