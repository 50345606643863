import { Formik } from "formik";
import React, { useContext, useEffect, useState, useReducer } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import CMSFormSelect from "../../../../../../UI/shared/formFields/CMSFormSelect";
import { GiExpand } from "react-icons/gi";
import {
  arraySortByProperty,
  getCompanies,
  getConditionForFigures,
  getCountOfRegistered,
  getCountOfTotal,
  getCountOfTotalCountries,
  getCountries,
  getFiguresList,
  getFilteredAttendeesForFigures,
  getFilteredCompaniesForFigures,
  getGroupByCompany,
  getListBasedOnCompany,
  getListBasedOnCountry,
} from "../../../../../../../helper/figuresListHelper";
import "../../figures.scss";
import { FiguresContext } from "../../../../../../../context/FiguresContext";
import {
  TotalAttendees,
  getAvailablePeopleGroups,
  getCheckinValue,
  getColorOfPeopleGroup,
  getTotalAccounts,
  getTotalCountries,
} from "../../../../../../../helper/sideHeaderHelper";
import Loading from "../../../../../../UI/shared/loading/Loading";
import { HomeScreenContext } from "../../../../../../../context/HomeScreenContext";
import PieChart from "./PieChart";
import { handleFormatArray, titleOfBreakout, titleOfDay } from "../../../../../../../helper/commonHelper";
import axios from "../../../../../../../config/axios";
import { updateInitialState, updateReducer } from "../../../../../../../reducers/updateReducer";
import { AuthContext } from "../../../../../../../context/AuthContext";
import CustomScrollbars from "../../../../../../UI/shared/customScrollbar/CustomScrollbar";
import { CombinedContext } from "../../../../../../../context/CombinedContext";
import { getCombinedList, getConditionForCombined, getCountOfAttendedCombined, getCountOfAttendedCombinedCountries, getFilteredAttendeesForCombined, getListOfAttended, getListOfAttendedCountry, getListOfNotAttended, getListOfNotAttendedCountry } from "../../../../../../../helper/combinedReportHelper";
import Download from "../../../../partials/downloadButton/Download";
import useApiUpload from "../../../../../../UI/shared/addToDownload/AddToDownload";
import { checkPermission } from "../../../../../../../helper/permissionsHelper";
import { resolveRegistrationType } from "../../../../../../../helper/attendeeHelper";
const ListSection = () => {
  const { combined, setCombined } = useContext(CombinedContext);
  const { layout, setLayout } = useContext(HomeScreenContext);
  const condition = getConditionForCombined(combined, setCombined);
  const [activeCompany, setActiveCompany] = useState("");
  const [activeCountry, setActiveCountry] = useState("")
  const attendees = getCombinedList(
    condition.checkin,
    condition.type, combined.day, combined.breakout
  );
  const availablePeopleGroups = getAvailablePeopleGroups();
  const [filteredAttendees, setFilteredAttendees] = useState(attendees);
  const [filteredCompanies, setFilteredCompanies] = useState();
  const [filteredCountries, setFilteredCountries] = useState();
  const { userAuthState, setUser } = useContext(AuthContext);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  function generateArrayOfIds(array) {
    return array.map(obj => obj._id);
  }
  const { SaveToDownloads } = useApiUpload();
  const handleDownload = async () => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    const attendees = arraySortByProperty(filteredAttendees, combined.listSort);
    const ids = generateArrayOfIds(attendees)

    try {
      const res = await axios.post(`download/${userAuthState.user && userAuthState.user.activityId}`, { ids: ids, dayId: combined.day === "" ? null : combined.day, breakoutId: combined.breakout === "" ? null : combined.breakout, type: "combined" });
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      const name = combined.breakout != null ? `${titleOfBreakout(combined.breakout) + ` - ` + titleOfDay(combined.day)}` : titleOfDay(combined.day)
      const peopleGroupSelected = (combined.graphPartSelection && combined.graphPartSelection !== "" && combined.graphPartSelection !== "undefined") ? combined.graphPartSelection : "";
      const utf8Bom = '\uFEFF'; // UTF-8 BOM
      const csvContent = utf8Bom + res.data;
      const blob = new Blob([csvContent], { type: "text/csv; charset=utf-8" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const filename = `Combined Report(Total ${peopleGroupSelected})-${combined.activeSession}.csv`;
      const a = document.createElement("a");
      const file = new File([blob], filename, { type: "text/csv;charset=utf-8", lastModified: Date.now() });
      try {
        const result = await SaveToDownloads(file, filename);

      } catch (error) {
        console.error(error); // Handle failure
      }
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
      // setTimeout(() => {
      //     updateDispatch({
      //         type: "FETCH_SUCCESS",
      //     });
      // }, 4000);

    } catch (error) {
      console.error("There was a problem with the download operation:", error);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 2000);
    }
  };

  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
    });

  }, [])

  useEffect(() => {
    const filteredAttendees2 = getFilteredAttendeesForCombined(
      attendees,
      combined.attendeeListFilters
    );
    setFilteredAttendees(filteredAttendees2);
    setFilteredCompanies(getCompanies(attendees, undefined));
    setFilteredCountries(getCountries(attendees, undefined));
  }, [combined]);
  useEffect(() => {
    const filteredAttendees2 = getFilteredAttendeesForCombined(
      attendees,
      combined.attendeeListFilters
    );
    setFilteredAttendees(filteredAttendees2);
    setFilteredCompanies(getCompanies(attendees, undefined));
    setFilteredCountries(getCountries(attendees, undefined));
  }, []);
  const [state, setState] = useState({
    loading: false,
    chartOnMobile: false,
  });
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }, 500);
  }, []);
  const totalAccounts = getTotalAccounts(
    undefined,
    undefined,
    undefined
  );
  const totalAccountsShows = getTotalAccounts(
    "Yes",
    undefined,
    undefined
  );
  const totalCountries = getTotalCountries(
    undefined,
    undefined,
    undefined
  );
  const totalCountriesShows = getTotalCountries(
    "Yes",
    undefined,
    undefined
  );
  const totalCountriesNoShows = totalCountries - totalCountriesShows;
  const totalAccountsNoShows = totalAccounts - totalAccountsShows;
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  let days = eventData.eventDays;
  let sessions = eventData.eventBreakouts
  let result = [];
  days.forEach(day => {
    const matchingSessions = sessions.filter(session => session.day === day.day);
    let dayObj = { label: `${day.title}`, value: `day_${day.day}`, day: day.day, breakout: null };
    result.push(dayObj);
    matchingSessions.forEach(session => {
      let sessionObj = { label: `-${session.sessionTitle}`, value: `breakout_${session._id}`, day: session.day, breakout: session._id };
      result.push(sessionObj);
    });
  });
  function getLastPart(str) {
    return str.split("_").pop();
  }
  return (
    <div
      className={`rightSectionHolder ${layout.showFigureListViewFullScreen ? "fixed" : ""
        }`}
    >
      {state.loading && <Loading />}
      {!state.loading && (
        <Row className="g-0">
          {state.chartOnMobile ? (
            <Col className="chartOnMobile">
              <PieChart />
            </Col>
          ) : (
            <Col>
              <div className="darkGrayHeader">
                <>
                  <Formik
                    initialValues={{
                      session:
                        combined.breakout &&
                          combined.breakout != null
                          ? `breakout_${combined.breakout}`
                          : `day_${combined.day}`,
                      registrationType:
                        combined.graphPartSelection &&
                          combined.graphPartSelection !== undefined
                          ? combined.graphPartSelection
                          : "",
                      checkin: combined.typeSelection === "country" ? combined.countryListFilters &&
                        combined.countryListFilters.checkin
                        ? combined.countryListFilters.checkin
                        : "" : combined.typeSelection === "company" ?
                        combined.companyListFilters &&
                          combined.companyListFilters.checkin
                          ? combined.companyListFilters.checkin
                          : "" : "",
                    }}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {
                      delete values.checkin;
                      if (combined.typeSelection === "registered") {
                        setCombined((prevState) => ({
                          ...prevState,
                          attendeeListFilters: {},
                        }));
                      }
                      else {
                        setCombined((prevState) => ({
                          ...prevState,
                          attendeeListFilters: values,
                        }));
                      }

                    }}
                  >
                    {(form) => (
                      <Form className="" onSubmit={form.handleSubmit}>
                        <Row className="">
                          {(combined.typeSelection === "dayBreakout" || combined.typeSelection === "attendees") && (
                            <>
                              <Col className="pe-0 me-0">
                                <CMSFormSelect
                                  name="session"
                                  placeholder={{ label: "All", value: "" }}
                                  form={form}
                                  onChange={(e) => {
                                    form.handleChange(e);
                                    form.handleSubmit();
                                    const selectedOption = result.find(option => option.value === e.target.value);
                                    console.log(selectedOption, e.target.value)
                                    if (selectedOption != undefined) {
                                      setCombined((prevState) => ({
                                        ...prevState,
                                        activeSession: selectedOption.label,
                                        day: e.target.value === "" ? "" : selectedOption.day,
                                        breakout: e.target.value === "" ? "" : selectedOption.breakout,
                                        graphPartSelection: combined.graphPartSelection,
                                        typeSelection: e.target.value === "" ? "attendees" : "dayBreakout",
                                        attendeeListFilters: { checkin: "Yes" }
                                      }));
                                    } else {
                                      setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: combined.graphPartSelection,
                                        typeSelection: "attendees",
                                      }));
                                    }
                                    // setCombined((prevState) => ({
                                    //   ...prevState,
                                    //   day: e.target.value === "" ? "" : e.target.value.includes("day") ? getLastPart(e.target.value) : combined.day,
                                    //   breakout: e.target.value === "" ? "" : e.target.value.includes("breakout") ? getLastPart(e.target.value) : null,
                                    //   graphPartSelection: undefined,
                                    //   typeSelection: e.target.value === "" ? "attendees" : "dayBreakout",
                                    //   attendeeListFilters: { checkin: "Yes" }
                                    // }));
                                  }}
                                  options={result}
                                />
                              </Col>

                              {(combined.typeSelection === "attendees" || combined.typeSelection === "dayBreakout") && (
                                <Col>

                                  <CMSFormSelect
                                    name="registrationType"
                                    onChange={(e) => {
                                      form.handleChange(e);
                                      form.handleSubmit();
                                      setCombined((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: e.target.value === "" ? undefined : e.target.value,
                                        typeSelection: combined.typeSelection,
                                        attendeeListFilters: { checkin: "Yes" },

                                      }));
                                    }}
                                    placeholder={{ label: "All", value: "" }}
                                    form={form}
                                    options={[
                                      {
                                        label: "Registered",
                                        value: "Registered",
                                      },
                                      { label: "Walkin", value: "Walkin" },
                                    ]}
                                  />
                                </Col>
                              )}
                            </>
                          )}

                          {(combined.typeSelection === "company") && (
                            <Col>
                              <CMSFormSelect
                                name="checkin"
                                onChange={(e) => {
                                  form.handleChange(e);
                                  form.handleSubmit();
                                  setCombined((prevState) => ({
                                    ...prevState,
                                    graphPartSelection:
                                      e.target.value === ""
                                        ? undefined
                                        : e.target.value,
                                    companyListFilters: {
                                      checkin: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder={{ label: "All", value: "" }}
                                form={form}
                                options={[
                                  { label: "Shows", value: "Shows-Accounts" },
                                  {
                                    label: "No-Shows",
                                    value: "No-Shows-Accounts",
                                  },
                                ]}
                              />
                            </Col>
                          )}

                          {(combined.typeSelection === "country") && (
                            <Col>
                              <CMSFormSelect
                                name="checkin"
                                onChange={(e) => {
                                  form.handleChange(e);
                                  form.handleSubmit();
                                  setCombined((prevState) => ({
                                    ...prevState,
                                    graphPartSelection:
                                      e.target.value === ""
                                        ? undefined
                                        : e.target.value,
                                    countryListFilters: {
                                      checkin: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder={{ label: "All", value: "" }}
                                form={form}
                                options={[
                                  { label: "Shows", value: "Shows-Accounts" },
                                  {
                                    label: "No-Shows",
                                    value: "No-Shows-Accounts",
                                  },
                                ]}
                              />
                            </Col>
                          )}
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <div className="d-flex">
                    {checkPermission("downloadReports") && !layout.showFigureListViewFullScreen && filteredAttendees &&
                      filteredAttendees.length > 0 && combined.typeSelection != "company" && (
                        <Download normal={() => handleDownload()} lighter={() => handleDownload()} />
                      )}

                    <div
                      className="ms-2 showOnMobile"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          chartOnMobile: true,
                        }))
                      }
                    >
                      <i
                        className="icon icon-figures"
                        aria-hidden="true"
                        title="view chart"
                      ></i>
                    </div>
                  </div>
                  {layout.showFigureListViewFullScreen && (
                    <div
                      onClick={() =>
                        setLayout((prevState) => ({
                          ...prevState,
                          showFigureListViewFullScreen: false,
                        }))
                      }
                    >
                      <i
                        className="fa fa-times text-primaryColor cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    </div>
                  )}
                </>

              </div>
            </Col>
          )}
        </Row>
      )}
      {combined.typeSelection != "company" && combined.typeSelection != "country" && filteredAttendees &&
        filteredAttendees.length > 0 && (
          <Row className="g-0">
            <Col>
              <div className="sorting">
                <div className="allSortings">
                  <a
                    className={`${combined.listSort === "name+" || combined.listSort === "name-"
                      ? "active"
                      : ""
                      } sortItem`}
                    onClick={() => {
                      setCombined((prevState) => ({
                        ...prevState,
                        listSort:
                          combined.listSort === "name+" ? "name-" : "name+",
                      }));
                    }}
                  >
                    Name{" "}
                    {combined.listSort === "name+" ? (
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    ) : combined.listSort === "name-" ? (
                      <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    )}
                  </a>
                  <a
                    className={`${combined.listSort === "company+" ||
                      combined.listSort === "company-"
                      ? "active"
                      : ""
                      } sortItem`}
                    onClick={() => {
                      setCombined((prevState) => ({
                        ...prevState,
                        listSort:
                          combined.listSort === "company+"
                            ? "company-"
                            : "company+",
                      }));
                    }}
                  >
                    Company{" "}
                    {combined.listSort === "company+" ? (
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    ) : combined.listSort === "company-" ? (
                      <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    )}
                  </a>

                  <a
                    className="sortItem"
                    onClick={() => {
                      setCombined((prevState) => ({
                        ...prevState,
                        listSort: undefined,
                      }));
                    }}
                  >
                    Clear <i className="fa fa-refresh" aria-hidden="true"></i>
                  </a>
                </div>
                {checkPermission("viewReportsInFullScreen") && !layout.showFigureListViewFullScreen && (
                  <div
                    className="viewFullScreen hideOnMobile"
                    onClick={() =>
                      setLayout((prevState) => ({
                        ...prevState,
                        showFigureListViewFullScreen: true,
                      }))
                    }
                  >
                    <GiExpand className="pinkHeaderIcon" />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}

      {combined.typeSelection != "company" && combined.typeSelection != "country" && (
        <CustomScrollbars style={{ height: `calc(100vh - 335px)` }}>
          <Row className="g-0">
            <Col className="figureListHolder">

              {filteredAttendees &&
                filteredAttendees.length > 0 &&
                arraySortByProperty(filteredAttendees, combined.listSort).map(
                  (item, i) => {
                    return (
                      <div
                        className={`${item.companions > 0 ? "pb-4" : ""
                          } attendeeListItemTop`}
                      >
                        <div className="attendeeListItem2">
                          <div className="checkBoxContainer noClick">
                            <div
                              className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                ? "red"
                                : ""
                                } active }`}
                            >
                              <i className="fa fa-check"></i>
                            </div>
                            <div
                              className="peopleGroup"
                              style={{
                                color: getColorOfPeopleGroup(
                                  "background",
                                  item.peopleGroup
                                ),
                              }}
                            >
                              {item.peopleGroup}
                            </div>
                          </div>
                          <div className="details">
                            <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                            <p className="designation">{item.designation}</p>

                            <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                          </div>

                        </div>

                        {item.infoReq === "Yes" && (
                          <div className="infoMissingRow">
                            <p className="infoMissing">
                              <i
                                className="fa fa-exclamation-triangle"
                                aria-hidden="true"
                              ></i>{" "}
                              Information Required
                            </p>
                          </div>
                        )}
                        {item.companions > 0 && (
                          <div className="infoMissingRow companion">
                            <p className="infoMissing">
                              <i className="fa fa-users" aria-hidden="true"></i>{" "}
                              {item.companions} Companion(s)
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              {filteredAttendees && filteredAttendees.length <= 0 && (
                <h3 className="text-center py-5">No Items Found!</h3>
              )}
            </Col>
          </Row>
        </CustomScrollbars>
      )}
      {combined.typeSelection === "company" && activeCompany != "" && <>
        <CustomScrollbars style={{ height: `calc(100vh - 255px)` }}>
          <Row className="g-0 mt-3">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Row className="d-flex align-items-center justify-content-between mx-3 pb-3">
                <Col>
                  <div className="d-flex align-items-center"><p className="text-primaryColor mb-0 me-2 cursor-pointer" onClick={(e) => {
                    setCombined((prevState) => ({
                      ...prevState,
                      companyListFilters: {
                        companyCheckin: "",
                      },
                    }));
                    setActiveCompany("")
                  }}>{`<< `}Back To Companies List </p></div>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <Formik
                    initialValues={{
                      companyCheckin:
                        combined.companyListFilters &&
                          combined.companyListFilters.companyCheckin
                          ? combined.companyListFilters.companyCheckin
                          : "",
                    }}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {

                    }}
                  >
                    {(form) => (
                      <Form className="" onSubmit={form.handleSubmit}>
                        <Row className="">


                          {(combined.typeSelection === "company") && (
                            <Col>
                              <CMSFormSelect
                                name="companyCheckin"
                                onChange={(e) => {
                                  form.handleChange(e);
                                  form.handleSubmit();
                                  setCombined((prevState) => ({
                                    ...prevState,
                                    companyListFilters: {
                                      companyCheckin: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder={{ label: "All", value: "" }}
                                form={form}
                                options={[
                                  { label: "Check-In", value: "Yes" },
                                  {
                                    label: "Not Checked In",
                                    value: "No",
                                  },
                                ]}
                              />
                            </Col>
                          )}
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>

            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="figureListHolder">

              {combined.companyListFilters && combined.companyListFilters.companyCheckin === "Yes" ?
                <>

                  {getListOfAttended(activeCompany) &&
                    getListOfAttended(activeCompany).length > 0 &&
                    getListOfAttended(activeCompany).map(
                      (item, i) => {
                        return (
                          <div
                            className={`${item.companions > 0 ? "pb-4" : ""
                              } attendeeListItemTop`}
                          >
                            <div className="attendeeListItem2">
                              <div className="checkBoxContainer noClick">
                                <div
                                  className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                    ? "red"
                                    : ""
                                    } active`}
                                >
                                  <i className="fa fa-check"></i>
                                </div>
                                <div
                                  className="peopleGroup"
                                  style={{
                                    color: getColorOfPeopleGroup(
                                      "background",
                                      item.peopleGroup
                                    ),
                                  }}
                                >
                                  {item.peopleGroup}
                                </div>
                              </div>
                              <div className="details">
                                <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                                <p className="designation">{item.designation}</p>

                                <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                              </div>

                            </div>

                            {item.infoReq === "Yes" && (
                              <div className="infoMissingRow">
                                <p className="infoMissing">
                                  <i
                                    className="fa fa-exclamation-triangle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Information Required
                                </p>
                              </div>
                            )}
                            {item.companions > 0 && (
                              <div className="infoMissingRow companion">
                                <p className="infoMissing">
                                  <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                  {item.companions} Companion(s)
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                </>

                : combined.companyListFilters && combined.companyListFilters.companyCheckin === "No" ? getListOfNotAttended(activeCompany) &&
                  getListOfNotAttended(activeCompany).length > 0 &&
                  getListOfNotAttended(activeCompany).map(
                    (item, i) => {
                      return (
                        <div
                          className={`${item.companions > 0 ? "pb-4" : ""
                            } attendeeListItemTop`}
                        >
                          <div className="attendeeListItem2">
                            <div className="checkBoxContainer noClick">
                              <div
                                className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                  ? "red"
                                  : ""
                                  } }`}
                              >
                                <i className="fa fa-check"></i>
                              </div>
                              <div
                                className="peopleGroup"
                                style={{
                                  color: getColorOfPeopleGroup(
                                    "background",
                                    item.peopleGroup
                                  ),
                                }}
                              >
                                {item.peopleGroup}
                              </div>
                            </div>
                            <div className="details">
                              <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                              <p className="designation">{item.designation}</p>

                              <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                            </div>

                          </div>

                          {item.infoReq === "Yes" && (
                            <div className="infoMissingRow">
                              <p className="infoMissing">
                                <i
                                  className="fa fa-exclamation-triangle"
                                  aria-hidden="true"
                                ></i>{" "}
                                Information Required
                              </p>
                            </div>
                          )}
                          {item.companions > 0 && (
                            <div className="infoMissingRow companion">
                              <p className="infoMissing">
                                <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                {item.companions} Companion(s)
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    }
                  ) : getListBasedOnCompany(activeCompany) &&
                  getListBasedOnCompany(activeCompany).length > 0 &&
                getListBasedOnCompany(activeCompany).map(
                  (item, i) => {
                    return (
                      <div
                        className={`${item.companions > 0 ? "pb-4" : ""
                          } attendeeListItemTop`}
                      >
                        <div className="attendeeListItem2">
                          <div className="checkBoxContainer noClick">
                            <div
                              className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                ? "red"
                                : ""
                                } ${getCheckinValue(item._id) ? "active" : ""}`}
                            >
                              <i className="fa fa-check"></i>
                            </div>
                            <div
                              className="peopleGroup"
                              style={{
                                color: getColorOfPeopleGroup(
                                  "background",
                                  item.peopleGroup
                                ),
                              }}
                            >
                              {item.peopleGroup}
                            </div>
                          </div>
                          <div className="details">
                            <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                            <p className="designation">{item.designation}</p>

                            <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                          </div>

                        </div>

                        {item.infoReq === "Yes" && (
                          <div className="infoMissingRow">
                            <p className="infoMissing">
                              <i
                                className="fa fa-exclamation-triangle"
                                aria-hidden="true"
                              ></i>{" "}
                              Information Required
                            </p>
                          </div>
                        )}
                        {item.companions > 0 && (
                          <div className="infoMissingRow companion">
                            <p className="infoMissing">
                              <i className="fa fa-users" aria-hidden="true"></i>{" "}
                              {item.companions} Companion(s)
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}

              {getListBasedOnCompany(activeCompany) &&
                getListBasedOnCompany(activeCompany).length <= 0 && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}
            </Col>
          </Row>
        </CustomScrollbars>
      </>}

      {combined.typeSelection === "company" && activeCompany === "" && (
        <CustomScrollbars style={{ height: `calc(100vh - 165px)` }}>
          <Row className="g-0 mt-2">
            <Col className="figureListHolder">

              {filteredCompanies &&
                filteredCompanies.length > 0 &&
                filteredCompanies.map((item, index) => {
                  if (
                    (combined.companyListFilters &&
                      combined.companyListFilters.checkin === "") ||
                    (combined.companyListFilters &&
                      !combined.companyListFilters.checkin)
                  )
                    return (
                      <div onClick={(e) => setActiveCompany(item)}
                        className={`companyItem cursor-pointer ${getCountOfAttendedCombined(item) > 0 ? "" : "noshow"
                          } `}
                      >
                        <p className="me-2 ">{item}</p>
                        <p className="text-nowrap">
                          Total({getCountOfTotal(item)}){" "}
                          <span className="ms-3">
                            Attended({getCountOfAttendedCombined(item)})
                          </span>
                        </p>
                      </div>
                    );
                  if (
                    combined.companyListFilters &&
                    combined.companyListFilters.checkin === "Shows-Accounts" &&
                    getCountOfAttendedCombined(item) > 0
                  )
                    return (
                      <div onClick={(e) => setActiveCompany(item)}
                        className={`companyItem cursor-pointer ${getCountOfAttendedCombined(item) > 0 ? "" : "noshow"
                          } `}
                      >
                        <p className="me-2 ">{item}</p>
                        <p className="text-nowrap">
                          Total({getCountOfTotal(item)}){" "}
                          <span className="ms-3">
                            Attended({getCountOfAttendedCombined(item)})
                          </span>
                        </p>
                      </div>
                    );
                  if (
                    combined.companyListFilters &&
                    combined.companyListFilters.checkin ===
                    "No-Shows-Accounts" &&
                    getCountOfAttendedCombined(item) <= 0
                  )
                    return (
                      <div onClick={(e) => setActiveCompany(item)}
                        className={`companyItem cursor-pointer ${getCountOfAttendedCombined(item) > 0 ? "" : "noshow"
                          } `}
                      >
                        <p className="me-2">{item}</p>
                        <p className="text-nowrap">  Total({getCountOfTotal(item)}){" "} </p>
                      </div>
                    );
                })}


              {((combined.companyListFilters &&
                combined.companyListFilters.checkin === "") ||
                (combined.companyListFilters &&
                  !combined.companyListFilters.checkin)) && (totalAccounts <= 0) && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}
              {combined.companyListFilters &&
                combined.companyListFilters.checkin === "Shows-Accounts" && totalAccountsShows <= 0 && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}
              {combined.companyListFilters &&
                combined.companyListFilters.checkin ===
                "No-Shows-Accounts" && (totalAccountsNoShows <= 0) && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}

            </Col>
          </Row>
        </CustomScrollbars>
      )}

      {combined.typeSelection === "country" && activeCountry != "" && <>
        <CustomScrollbars style={{ height: `calc(100vh - 255px)` }}>
          <Row className="g-0 mt-3">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Row className="d-flex align-items-center justify-content-between mx-3 pb-3">
                <Col>
                  <div className="d-flex align-items-center"><p className="text-primaryColor mb-0 me-2 cursor-pointer" onClick={(e) => {
                    setCombined((prevState) => ({
                      ...prevState,
                      countryFilters: {
                        companyCheckin: "",
                      },
                    }));
                    setActiveCountry("")
                  }}>{`<< `}Back To Companies List </p></div>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <Formik
                    initialValues={{
                      companyCheckin:
                        combined.countryFilters &&
                          combined.countryFilters.companyCheckin
                          ? combined.countryFilters.companyCheckin
                          : "",
                    }}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {

                    }}
                  >
                    {(form) => (
                      <Form className="" onSubmit={form.handleSubmit}>
                        <Row className="">


                          {(combined.typeSelection === "country") && (
                            <Col>
                              <CMSFormSelect
                                name="companyCheckin"
                                onChange={(e) => {
                                  form.handleChange(e);
                                  form.handleSubmit();
                                  setCombined((prevState) => ({
                                    ...prevState,
                                    countryListFilters: {
                                      companyCheckin: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder={{ label: "All", value: "" }}
                                form={form}
                                options={[
                                  { label: "Check-In", value: "Yes" },
                                  {
                                    label: "Not Checked In",
                                    value: "No",
                                  },
                                ]}
                              />
                            </Col>
                          )}
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>

            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="figureListHolder">

              {combined.countryListFilters && combined.countryListFilters.companyCheckin === "Yes" ?
                <>

                  {getListOfAttendedCountry(activeCountry) &&
                    getListOfAttendedCountry(activeCountry).length > 0 &&
                    getListOfAttendedCountry(activeCountry).map(
                      (item, i) => {
                        return (
                          <div
                            className={`${item.companions > 0 ? "pb-4" : ""
                              } attendeeListItemTop`}
                          >
                            <div className="attendeeListItem2">
                              <div className="checkBoxContainer noClick">
                                <div
                                  className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                    ? "red"
                                    : ""
                                    } active`}
                                >
                                  <i className="fa fa-check"></i>
                                </div>
                                <div
                                  className="peopleGroup"
                                  style={{
                                    color: getColorOfPeopleGroup(
                                      "background",
                                      item.peopleGroup
                                    ),
                                  }}
                                >
                                  {item.peopleGroup}
                                </div>
                              </div>
                              <div className="details">
                                <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                                <p className="designation">{item.designation}</p>

                                <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                              </div>

                            </div>

                            {item.infoReq === "Yes" && (
                              <div className="infoMissingRow">
                                <p className="infoMissing">
                                  <i
                                    className="fa fa-exclamation-triangle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Information Required
                                </p>
                              </div>
                            )}
                            {item.companions > 0 && (
                              <div className="infoMissingRow companion">
                                <p className="infoMissing">
                                  <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                  {item.companions} Companion(s)
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                </>

                : combined.countryListFilters && combined.countryListFilters.companyCheckin === "No" ? getListOfNotAttendedCountry(activeCountry) &&
                  getListOfNotAttendedCountry(activeCountry).length > 0 &&
                  getListOfNotAttendedCountry(activeCountry).map(
                    (item, i) => {
                      return (
                        <div
                          className={`${item.companions > 0 ? "pb-4" : ""
                            } attendeeListItemTop`}
                        >
                          <div className="attendeeListItem2">
                            <div className="checkBoxContainer noClick">
                              <div
                                className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                  ? "red"
                                  : ""
                                  } }`}
                              >
                                <i className="fa fa-check"></i>
                              </div>
                              <div
                                className="peopleGroup"
                                style={{
                                  color: getColorOfPeopleGroup(
                                    "background",
                                    item.peopleGroup
                                  ),
                                }}
                              >
                                {item.peopleGroup}
                              </div>
                            </div>
                            <div className="details">
                              <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                              <p className="designation">{item.designation}</p>

                              <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                            </div>

                          </div>

                          {item.infoReq === "Yes" && (
                            <div className="infoMissingRow">
                              <p className="infoMissing">
                                <i
                                  className="fa fa-exclamation-triangle"
                                  aria-hidden="true"
                                ></i>{" "}
                                Information Required
                              </p>
                            </div>
                          )}
                          {item.companions > 0 && (
                            <div className="infoMissingRow companion">
                              <p className="infoMissing">
                                <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                {item.companions} Companion(s)
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    }
                  ) : getListBasedOnCountry(activeCountry) &&
                  getListBasedOnCountry(activeCountry).length > 0 &&
                getListBasedOnCountry(activeCountry).map(
                  (item, i) => {
                    return (
                      <div
                        className={`${item.companions > 0 ? "pb-4" : ""
                          } attendeeListItemTop`}
                      >
                        <div className="attendeeListItem2">
                          <div className="checkBoxContainer noClick">
                            <div
                              className={`checkBox ${resolveRegistrationType(item.registrationType) === "Registered"
                                ? "red"
                                : ""
                                } ${getCheckinValue(item._id) ? "active" : ""}`}
                            >
                              <i className="fa fa-check"></i>
                            </div>
                            <div
                              className="peopleGroup"
                              style={{
                                color: getColorOfPeopleGroup(
                                  "background",
                                  item.peopleGroup
                                ),
                              }}
                            >
                              {item.peopleGroup}
                            </div>
                          </div>
                          <div className="details">
                            <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                            <p className="designation">{item.designation}</p>

                            <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                          </div>

                        </div>

                        {item.infoReq === "Yes" && (
                          <div className="infoMissingRow">
                            <p className="infoMissing">
                              <i
                                className="fa fa-exclamation-triangle"
                                aria-hidden="true"
                              ></i>{" "}
                              Information Required
                            </p>
                          </div>
                        )}
                        {item.companions > 0 && (
                          <div className="infoMissingRow companion">
                            <p className="infoMissing">
                              <i className="fa fa-users" aria-hidden="true"></i>{" "}
                              {item.companions} Companion(s)
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}

              {getListBasedOnCountry(activeCountry) &&
                getListBasedOnCountry(activeCountry).length <= 0 && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}
            </Col>
          </Row>
        </CustomScrollbars>
      </>}

      {combined.typeSelection === "country" && activeCountry === "" && (
        <CustomScrollbars style={{ height: `calc(100vh - 165px)` }}>
          <Row className="g-0 mt-2">
            <Col className="figureListHolder">

              {filteredCountries &&
                filteredCountries.length > 0 &&
                filteredCountries.map((item, index) => {
                  if (
                    (combined.countryListFilters &&
                      combined.countryListFilters.checkin === "") ||
                    (combined.countryListFilters &&
                      !combined.countryListFilters.checkin)
                  )
                    return (
                      <div onClick={(e) => setActiveCountry(item)}
                        className={`companyItem cursor-pointer ${getCountOfAttendedCombinedCountries(item) > 0 ? "" : "noshow"
                          } `}
                      >
                        <p className="me-2 ">{item}</p>
                        <p className="text-nowrap">
                          Total({getCountOfTotalCountries(item)}){" "}
                          <span className="ms-3">
                            Attended({getCountOfAttendedCombinedCountries(item)})
                          </span>
                        </p>
                      </div>
                    );
                  if (
                    combined.countryListFilters &&
                    combined.countryListFilters.checkin === "Shows-Accounts" &&
                    getCountOfAttendedCombinedCountries(item) > 0
                  )
                    return (
                      <div onClick={(e) => setActiveCountry(item)}
                        className={`companyItem cursor-pointer ${getCountOfAttendedCombinedCountries(item) > 0 ? "" : "noshow"
                          } `}
                      >
                        <p className="me-2 ">{item}</p>
                        <p className="text-nowrap">
                          Total({getCountOfTotalCountries(item)}){" "}
                          <span className="ms-3">
                            Attended({getCountOfAttendedCombinedCountries(item)})
                          </span>
                        </p>
                      </div>
                    );
                  if (
                    combined.countryListFilters &&
                    combined.countryListFilters.checkin ===
                    "No-Shows-Accounts" &&
                    getCountOfAttendedCombinedCountries(item) <= 0
                  )
                    return (
                      <div onClick={(e) => setActiveCountry(item)}
                        className={`companyItem cursor-pointer ${getCountOfAttendedCombinedCountries(item) > 0 ? "" : "noshow"
                          } `}
                      >
                        <p className="me-2">{item}</p>
                        <p className="text-nowrap">  Total({getCountOfTotalCountries(item)}){" "} </p>
                      </div>
                    );
                })}


              {((combined.countryListFilters &&
                combined.countryListFilters.checkin === "") ||
                (combined.countryListFilters &&
                  !combined.countryListFilters.checkin)) && (totalCountries <= 0) && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}
              {combined.countryListFilters &&
                combined.countryListFilters.checkin === "Shows-Accounts" && totalCountriesShows <= 0 && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}
              {combined.countryListFilters &&
                combined.countryListFilters.checkin ===
                "No-Shows-Accounts" && (totalCountriesNoShows <= 0) && (
                  <h3 className="text-center py-5">No Items Found!</h3>
                )}

            </Col>
          </Row>
        </CustomScrollbars>
      )}

    </div>
  );
};

export default ListSection;
