import React, { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { IoMdArrowRoundBack } from "react-icons/io";
import { GiExpand } from "react-icons/gi";
import { HomeScreenContext } from '../../../../../../context/HomeScreenContext';
import { headerItems } from '../../../../../../helper/sideHeaderHelper';
import { TotalAttendeesCombined } from '../../../../../../helper/combinedReportHelper';
const PinkHeader = () => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    const activePage = layout.activePage;
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    function groupByDayAndTitle(data) {
        const groupedData = {};

        data.forEach(item => {
            // Check if the day already exists in the groupedData object
            if (!groupedData[item.day]) {
                groupedData[item.day] = {};
            }

            // Find or add the title to the day's group
            let titlesGroup = groupedData[item.day];
            if (!titlesGroup[item.title]) {
                titlesGroup[item.title] = [];
            }

            // Add the item to the corresponding title group
            titlesGroup[item.title].push(item);
        });

        return groupedData;
    }

    let breakoutByGroup = null;
    if (eventData) {
        if (eventData.eventBreakouts) {
            breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)
        }
    }
    const fullDisplay = (eventData && eventData.eventDays && eventData.eventDays.length > 1 || (breakoutByGroup && breakoutByGroup[1]))
    return (
        <Row className="pinkHeaderHolder g-0">

            <Col xl={2} lg={2} md={2} xs={2}>
                {layout.activePageItems && layout.activePageItems.goBack && layout.activePageItems.backPage ? <a onClick={() => setLayout((prevState) => ({
                    ...prevState,
                    leftSideMenu: true,
                    activePage: activePage === "combinedDetails" ? layout.combinedDetailBack : layout.activePageItems.backPage,
                    activePageItems: activePage === "combinedDetails" ? headerItems(layout.combinedDetailBack) : headerItems(layout.activePageItems.backPage)
                }))}>
                    <i className="fa fa-arrow-left pinkHeaderIcon" aria-hidden="true"></i>
                </a> :
                    <a onClick={() => setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: false,
                        leftFullDisplay: false,
                        activePage: null,
                        activePageItems: null,
                    }))}>
                        <i className="fa fa-arrow-left pinkHeaderIcon" aria-hidden="true"></i>
                    </a>}
            </Col>
            <Col className='textCenter headerText'>
                <h3 className='mb-0'> {layout.activePageItems && layout.activePageItems.icon && <i className={`${layout.activePageItems.icon} me-2`} aria-hidden="true"></i>}  {layout.activePageItems && layout.activePageItems.name}</h3>
            </Col>
            <Col className="rightButtons textRight" xl={!fullDisplay ? 2 : layout.leftFullDisplay ? 3 : TotalAttendeesCombined("Yes", undefined, undefined) > 0 && (layout.activePageItems && layout.activePageItems.combinedReport || layout.activePageItems.overviewReport) ? 6 : 2} lg={!fullDisplay ? 2 :layout.leftFullDisplay ? 3 : TotalAttendeesCombined("Yes", undefined, undefined) > 0 && layout.activePageItems && (layout.activePageItems.combinedReport || layout.activePageItems.overviewReport) ? 6 : 2} md={TotalAttendeesCombined("Yes", undefined, undefined) > 0 && layout.activePageItems && layout.activePageItems.combinedReport && layout.activePageItems.overviewReport ? 3 : 2} xs={TotalAttendeesCombined("Yes", undefined, undefined) > 0 && layout.activePageItems && layout.activePageItems.combinedReport && layout.activePageItems.overviewReport ? 3 : 2}>
                {fullDisplay && TotalAttendeesCombined("Yes", undefined, undefined) > 0 && layout.activePageItems && layout.activePageItems.combinedReport && <a onClick={() => setLayout((prevState) => ({
                    ...prevState,
                    leftFullDisplay: false,
                    activePage: "combinedReport",
                    activePageItems: headerItems("combinedReport")
                }))}>
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                    <span>Combined Report</span>

                </a>}
                {fullDisplay && TotalAttendeesCombined("Yes", undefined, undefined) > 0 && layout.activePageItems && layout.activePageItems.overviewReport && <a onClick={() => setLayout((prevState) => ({
                    ...prevState,
                    activePage: "overviewReport",
                    leftFullDisplay: true,
                    activePageItems: headerItems("overviewReport")
                }))}>
                    <i className="fa fa-list" aria-hidden="true"></i>
                    <span>Overview Report</span>

                </a>}
                {layout.activePageItems && layout.activePageItems.figures && <a onClick={() => setLayout((prevState) => ({
                    ...prevState,
                    activePage: "figures",
                    leftFullDisplay: false,
                    activePageItems: headerItems("figures")
                }))}>
                    <i className="icon icon-figures" aria-hidden="true"></i>
                    <span>Figures</span>

                </a>}
                {layout.activePageItems && layout.activePageItems.showExpand &&
                    <a className='hideOnMobile' onClick={() => setLayout((prevState) => ({
                        ...prevState,
                        leftFullDisplay: !layout.leftFullDisplay,
                        rightFullDisplay: false,
                        rightSideMenu: false,
                    }))}>
                        <GiExpand className='pinkHeaderIcon' />
                    </a>
                }
            </Col>




        </Row >
    )
}

export default PinkHeader