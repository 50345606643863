import React, { useContext, useState, useReducer, useEffect } from "react";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { bottomSwiperItemsAll, headerItems } from "../../../../../helper/sideHeaderHelper";
import { getPrintBadgeValue, sleep } from "../../../../../helper/commonHelper";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import ModalBody from "../../../../UI/shared/modal/ModalBody";
import AddCustomFlag from "./AddCustomFlag";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import axios from "../../../../../config/axios";
import AddRemoveSpeaker from "./AddRemoveSpeaker";
import useApiPrintAction from "../../../../UI/shared/addToDownload/PrintBadgeAction";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const DetailsTopIcons = ({ details }) => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const clientId = eventData.clientId
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [show, setShow] = useState({
        customFlag: false,
        speakerShow: false,
    });
    const handleClose = () => setShow((prevState) => ({
        ...prevState,
        customFlag: false,
    }));
    const handleSpeakerShow = () => setShow((prevState) => ({
        ...prevState,
        speakerShow: true,
    }));
    const handleSpeakerClose = () => setShow((prevState) => ({
        ...prevState,
        speakerShow: false,
    }));
    const handleShow = () => setShow((prevState) => ({
        ...prevState,
        customFlag: true,
    }));
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState,

    );
    const { PrintBadgeAction } = useApiPrintAction();
    const speakerData = {
        clientId: clientId,
        attendeeId: details._id,
        status: details.isSpeaker === 1 ? 0 : 1
    };
    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const addRemoveAttendeeSpeaker = async () => {
            try {
                const res = await axios.post(`add-remove-attendee-speaker`, data);

                if (res.status === 200) {

                    updateDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                        payload: res.data,
                    });
                    setLayout((prevState) => ({
                        ...prevState,
                        reloadAttendees: true,
                    }));
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });

                    }, 4000);
                } else {
                    updateDispatch({
                        type: "FORM_SUBMISSION_FAILURE",
                        payload: "error",
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                    }, 2000);
                }

            } catch (error) {
                console.error("There was a problem with the operation:", error);
                updateDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: error,
                });
                setTimeout(() => {
                    updateDispatch({
                        type: "FETCH_SUCCESS",
                    });
                }, 2000);
            }
        }
        if (layout.isServerBusy === true) {
            setTimeout(() => {
                addRemoveAttendeeSpeaker();
            }, 2000);
        } else {
            addRemoveAttendeeSpeaker();
        }

    };
    const features = bottomSwiperItemsAll();
    console.log(features);
    const notesPermission = features?.find(item => item.feature === "notes");
    const borderBottom = (checkPermission("editAttendee") || checkPermission("printBadge") || checkPermission("showModuleNotes") || checkPermission("addAttendeeCustomMessage") || checkPermission("removeAttendeeAsSpeaker") || checkPermission("markAttendeeAsSpeaker"))
    return (
        <>
            <div className={`topAlertMessage ${updateState.isSubmitted ? "" : "hide"}`}>
                Attendee {speakerData.status === 0 ? "added as speaker." : "removed from speaker(s)."}
            </div>

            <div className={`detailsIconsHolder ${borderBottom === true ? "borderBottom" : ""}`}>
                <div className="right contact_buttons">
                    {/* <div className="chat contact_buttons_icon ">
            <i className="fa fa-comments" aria-hidden="true"></i>
        </div> 
        <div className="like contact_buttons_icon">
            <i className="icon icon-like" aria-hidden="true"></i>
        </div> */}
                    {checkPermission("editAttendee") &&
                        <div
                            className="edit contact_buttons_icon"
                            title="Edit Attendee"
                            onClick={() => {
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    activePage: "editAttendee",
                                    activePageItems: headerItems("editAttendee"),
                                }));
                            }}
                        >
                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </div>}
                    {checkPermission("printBadge") && getPrintBadgeValue() && (
                        <div className="print contact_buttons_icon" title="Print Badge" onClick={() => {
                            setLayout((prevState) => ({
                                ...prevState,

                                badgePrint: true,
                                badgePrintingAttendee: details,
                                badgeBack: "attendeeDetails"
                            })); PrintBadgeAction(details._id)
                        }
                        }>
                            <i className="fa fa-print" aria-hidden="true"></i>
                        </div>
                    )}
                    {checkPermission("showModuleNotes") && notesPermission.status === "1" &&
                        <div className="note contact_buttons_icon" title="Add Note" onClick={() => {
                            setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: false,
                                noteType: "Attendee Notes",
                                noteAttendeeId: details._id,
                                activePage: "notes",
                                activePageItems: headerItems("notes")
                            }))
                        }}>
                            <i className="icon icon-notes" aria-hidden="true"></i>
                        </div>}
                    {checkPermission("addAttendeeCustomMessage") && <>
                        {details.customFlag && details.customFlag != "" ? null : (
                            <div
                                className="contact_buttons_icon"
                                title="Add Custom Flag"
                                onClick={handleShow}
                            >
                                <i className="fa fa-tag open" aria-hidden="true"></i>
                            </div>
                        )}
                    </>}
                    {details.isSpeaker !== 1 && checkPermission("removeAttendeeAsSpeaker") &&
                        <div className="speaker contact_buttons_icon" onClick={handleSpeakerShow} title="Mark as speaker">
                            <i className="icon icon-speakers" aria-hidden="true"></i>

                            <i className="fa fa-check smallIcon green" aria-hidden="true"></i>
                        </div>}

                    {details.isSpeaker === 1 && checkPermission("markAttendeeAsSpeaker") &&
                        <div className="speaker contact_buttons_icon" onClick={handleSpeakerShow} title="Remove from speaker" >
                            <i className="icon icon-speakers" aria-hidden="true"></i>
                            <i className="fa fa-times smallIcon red" aria-hidden="true"></i>
                        </div>}
                </div>
            </div>
            <ModalBody
                handleClose={handleClose}
                handleShow={handleShow}
                heading="Add Custom Flag"
                show={show.customFlag}
                content={<AddCustomFlag details={details} handleClose={handleClose} />}
            />
            <ModalBody
                handleClose={handleSpeakerClose}
                handleShow={handleSpeakerShow}
                CloseButton={true}
                heading="Confirm!"
                className="undoCheckInModal"
                show={show.speakerShow}
                content={<AddRemoveSpeaker onClick={() => onSubmit(speakerData)} status={speakerData.status} handleClose={handleSpeakerClose} />}
            />
        </>
    );
};

export default DetailsTopIcons;
