import React, { useContext } from "react";
import "./homelayout.scss";
import Footer from "./footer/Footer";
import RightSideMenu from "./rightSideMenu/RightSideMenu";
import { Row } from "react-bootstrap";
import LeftSideSection from "./leftSideSection/LeftSideSection";
import { HomeScreenContext } from "../../../context/HomeScreenContext";
const HomeLayoutError = ({ children }) => {
  const { layout, setLayout } = useContext(HomeScreenContext);

  return (
    <div className="homeLayout">
      <Row className="mainRow g-0">
     

        <div
          className={`mainSection ${layout.leftFullDisplay ? "notHalf" : ""} ${layout.leftSideMenu ? "half" : "full"
            }`}
        >
          <div
            className={`homeSectionHolder ${layout.leftFullDisplay ? "fixBackground1" : ""
              }`}
          >

            {children}
          </div>

        </div>
      
      </Row>


  

     
    </div>
  );
};

export default HomeLayoutError;
