import { resolveRegistrationType } from "./attendeeHelper";
import { updatedAttendeesList, updatedAttendeesListForFigures } from "./sideHeaderHelper";

export const getFiguresList = (checkinStatus, peopleGroup, peopleType) => {

    const attendees = updatedAttendeesListForFigures();

    const filter = (array, condition) => array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
        condition.peopleGroup = peopleGroup;
    }

    if (peopleType !== undefined) {
        condition.registrationType = peopleType;
    }

    const filteredAttendees = filter(attendees, condition);

    return filteredAttendees;
}
export const getConditionForFigures = (figures, setFigures) => {
    let checkin = undefined;
    let peopleGroup = undefined;
    let type = undefined;

    if (figures.typeSelection === "attendees") {
        checkin = "Yes";
        if (figures.peopleGroupSelection != undefined && figures.peopleGroupSelection != "") {
            peopleGroup = figures.peopleGroupSelection;
        }
        if (figures.graphPartSelection != undefined && figures.graphPartSelection != "") {
            type = figures.graphPartSelection;
        }
    }
    if (figures.typeSelection === "registered") {
        type = "Registered";
        if (figures.peopleGroupSelection != undefined && figures.peopleGroupSelection != "") {
            peopleGroup = figures.peopleGroupSelection;
        }
        if (figures.graphPartSelection != undefined && figures.graphPartSelection != "") {
            checkin = figures.graphPartSelection === "Shows-Accounts" ? "Yes" : "No";
        }
    }
    return { checkin, peopleGroup, type }; // Return an object
}

export const arraySortByProperty = (array, property) => {
    // Make a copy of the array to avoid mutating the original array
    const newArray = [...array];
    // Sort the array based on the specified property
    if (property === "name+") {
        newArray.sort((a, b) => a.fullName.localeCompare(b.fullName));
    }
    if (property === "name-") {
        newArray.sort((a, b) => b.fullName.localeCompare(a.fullName));
    }
    if (property === "company+") {
        newArray.sort((a, b) => a.company.localeCompare(b.company));
    }
    if (property === "company-") {
        newArray.sort((a, b) => b.company.localeCompare(a.company));
    } if (property === "checkin+") {
        newArray.sort((a, b) => a.checkinTime.localeCompare(b.checkinTime));
    }
    if (property === "checkin-") {
        newArray.sort((a, b) => b.checkinTime.localeCompare(a.checkinTime));
    }
    if (property === "reverse-") {
        newArray.reverse();
    }


    return newArray;
}

export const getFilteredAttendeesForFigures = (array, filters) => {

    return array && array.filter(attendee => {

        // Check if peopleGroup matches the filters (if provided)
        if (filters.peopleGroup !== undefined && filters.peopleGroup !== "" && attendee.peopleGroup !== filters.peopleGroup) {
            return false;
        }

        // Check if registrationType matches the filters (if provided)
        if (filters.registrationType !== undefined && filters.registrationType !== "" && attendee.registrationType !== filters.registrationType) {
            return false;
        }

        // All filters match, return true to keep this attendee
        return true;
    });
}
export const getFilteredCompaniesForFigures = (array, filters) => {

    return array.filter(attendee => {

        // Check if peopleGroup matches the filters (if provided)

        if (filters.checkin !== undefined && filters.checkin !== "" && attendee.checkin !== (filters.checkin === "Shows-Accounts" ? "Yes" : "No")) {
            return false;
        }

        // All filters match, return true to keep this attendee
        return true;
    });
}
export const getCompanies = (array, peopleGroup) => {

    const accounts = [];
    if (peopleGroup !== undefined) {
        const filteredAttendees = array.filter((attendee) =>
            attendee.peopleGroup === peopleGroup && attendee.checkin === "Yes"
        );
        filteredAttendees.forEach((attendee) => {
            if (attendee.company) {
                let isExisting = false;

                accounts.forEach((companyName) => {
                    if (companyName.toLowerCase() === attendee.company.toLowerCase()) {
                        isExisting = true;
                        return;
                    }
                });

                if (!isExisting) {
                    accounts.push(attendee.company);
                }
            }
        });

    }
    else {
        array.forEach((attendee) => {
            if (attendee.company) {
                let isExisting = false;

                accounts.forEach((companyName) => {
                    if (companyName.toLowerCase() === attendee.company.toLowerCase()) {
                        isExisting = true;
                        return;
                    }
                });

                if (!isExisting) {
                    accounts.push(attendee.company);
                }
            }
        });

    }


    return accounts;
};
export const getCountries = (array, peopleGroup) => {

    const accounts = [];
    if (peopleGroup !== undefined) {
        const filteredAttendees = array.filter((attendee) =>
            attendee.peopleGroup === peopleGroup && attendee.checkin === "Yes"
        );
        filteredAttendees.forEach((attendee) => {
            if (attendee.country) {
                let isExisting = false;

                accounts.forEach((countryName) => {
                    if (countryName.toLowerCase() === attendee.country.toLowerCase()) {
                        isExisting = true;
                        return;
                    }
                });

                if (!isExisting) {
                    accounts.push(attendee.country);
                }
            }
        });

    }
    else {
        array.forEach((attendee) => {
            if (attendee.country) {
                let isExisting = false;

                accounts.forEach((countryName) => {
                    if (countryName.toLowerCase() === attendee.country.toLowerCase()) {
                        isExisting = true;
                        return;
                    }
                });

                if (!isExisting) {
                    accounts.push(attendee.country);
                }
            }
        });

    }


    return accounts;
};
export const getCountOfRegistered = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.company.toLowerCase() === companyName.toLowerCase() &&
        resolveRegistrationType(attendee.registrationType) === 'Registered'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCountOfRegisteredCountry = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.country.toLowerCase() === companyName.toLowerCase() &&
        resolveRegistrationType(attendee.registrationType) === 'Registered'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCountOfTotal = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.company.toLowerCase() === companyName.toLowerCase()
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCountOfTotalCountries = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.country.toLowerCase() === companyName.toLowerCase()
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getListBasedOnCompany = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.company.toLowerCase() === companyName.toLowerCase()
    );

    // Return the count of filtered attendees
    return filteredAttendees;
}
export const getListBasedOnCountry = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.country.toLowerCase() === companyName.toLowerCase()
    );

    // Return the count of filtered attendees
    return filteredAttendees;
}
export const getCountOfAttended = (companyName) => {
    const attendees = updatedAttendeesListForFigures();
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.company.toLowerCase() === companyName.toLowerCase() &&
        attendee.checkin === 'Yes'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCountOfAttendedCountry = (companyName) => {
    const attendees = updatedAttendeesListForFigures();
    const filteredAttendees = attendees.filter((attendee) =>
        attendee.country.toLowerCase() === companyName.toLowerCase() &&
        attendee.checkin === 'Yes'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}