import React, { useReducer, useEffect, useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { sleep } from "../../../../../helper/commonHelper";
import * as yup from "yup";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import CMSFormTextArea from "../../../../UI/shared/formFields/CMSFormTextArea";
import {
    updateInitialState,
    updateReducer,
} from "../../../../../reducers/updateReducer";
import axios from "../../../../../config/axios";
const AddCustomFlag = ({ details, handleClose }) => {
    const [flagState, flagDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const { attendee, setAttendee } = useContext(AttendeeContext);
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    function updateAttendeeById(data) {
        return (
            attendeesContent &&
            attendeesContent.attendees.map((attendee) => {
                if (attendee._id === details._id) {
                    // Return a new object with the updated properties
                    return { ...attendee, ...data };
                }
                // Return the original object if the id does not match
                return attendee;
            })
        );
    }
    const onSubmitMessage = async (data) => {
        flagDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });
        delete data.checkin;
        delete data.checkinTime;
        const updatedData = {
            ...data
        };
        const oldData = attendeesContent;
        const updatedUpdates = {
            ...attendeesContent,
            attendees: updateAttendeeById(updatedData),
        };
        localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
        try {
            const res = await axios.post(`edit-attendee/${data._id}`, updatedData);
            setAttendee((prevState) => ({
                ...prevState,
                detailUpdate: true
            }));
            flagDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
           

            setTimeout(() => {
                handleClose();
                flagDispatch({
                    type: "FETCH_SUCCESS",
                });

            }, 1000);
        } catch (error) {
            console.error("There was a problem with the operation:", error);
            alert("Error: " + error.message);
            localStorage.setItem("attendeesContent", JSON.stringify(oldData));
            flagDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
        }
    };
    useEffect(() => {

        flagDispatch({
            type: "FETCH_SUCCESS",
        });
    }, []);


    return (
        <>
            {flagState.isSubmitted ?
                <div className="successMessage">
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    <h2>Custom flag updated successfully</h2>
                </div> :
                <Formik
                    initialValues={{
                        customFlag: "",
                    }}
                    validationSchema={yup.object().shape({
                        customFlag: yup.string().required("Enter custom flag message"),
                    })}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {

                        onSubmitMessage({ ...details, customMessage: values.customFlag })
                    }}
                >
                    {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                            {flagState.isSubmitting &&
                                <div className="overlay">

                                </div>}
                            <Row className="pt-3 pb-2 mx-auto w-100">
                                <Col xl={12} lg={12} md={12} xs={12} className="pb-3">
                                    <CMSFormTextArea
                                        name="customFlag"
                                        placeholder=" Write Custom Flag Message"
                                        form={form}
                                        required={true}
                                    />
                                </Col>

                                <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="mt-2 mb-0 d-flex align-items-center justify-content-center"
                                >
                                    <button
                                        className="formSubmissionButton"
                                        type="submit"
                                        onClick={(e) => {
                                            form.handleSubmit(e);
                                        }}
                                    >
                                        {flagState.isSubmitting ? "PROCESSING" : "SEND"}
                                    </button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>}
        </>
    )
}

export default AddCustomFlag