import React, { createContext, useEffect, useState, useContext } from 'react';
import { attendeesList, getAvailableInitialLetters, personActions, searchByName, showOnIndex, updatedAttendeesList } from '../helper/sideHeaderHelper';
import { getFilteredAttendees } from '../helper/attendeeHelper';
import { setDefaultDay } from '../helper/commonHelper';
import { HomeScreenContext } from './HomeScreenContext';
import { act } from '@testing-library/react';
export const AttendeeContext = createContext({});
const AttendeeContextProvider = (props) => {
    let activeDay = localStorage.getItem("activeDay");
    const { layout, setLayout } = useContext(HomeScreenContext);
    let activeBreakout = localStorage.getItem("activeBreakout");
    let attendeesContent = localStorage.getItem("attendeesContent");
    const activeDayNumber = parseInt(activeDay);
    const activeBreakoutNumber = activeBreakout;
    const availableLetters = getAvailableInitialLetters();
    const [attendee, setAttendee] = useState({
        allAttendees: updatedAttendeesList(),
        personActions: personActions(),
        searchTerm: "",
        filters: {},
        reset: false,
        detailUpdate: false,
        activeLetter: updatedAttendeesList() && updatedAttendeesList().length > 100 ? availableLetters[0] : "",
        activeAttendeeDetailsId: null,
        showAtoZ: false,
        activeDay: (activeDay === "null" || activeDay === null) ? null : activeDayNumber,
        activeBreakout: (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakoutNumber,
    })

    useEffect(() => {
        setAttendee((prevState) => ({
            ...prevState,
            activeDay: (activeDay === "null" || activeDay === null) ? null : activeDayNumber,
        }))
        // localStorage.setItem("activeDay", attendee.activeDay);
    }, [activeDay])
    useEffect(() => {
        setAttendee((prevState) => ({
            ...prevState,
            activeBreakout: (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakoutNumber,
        }))
        // localStorage.setItem("activeDay", attendee.activeDay);
    }, [activeBreakout])
    useEffect(() => {

        if (attendee.searchTerm != "") {
            setAttendee((prevState) => ({
                ...prevState,
                allAttendees: searchByName(getFilteredAttendees(attendee.filters), attendee.searchTerm,"attendee"),
                //activeLetter: updatedAttendeesList() && updatedAttendeesList().length > 100 ? availableLetters[0] : "",
            }))
        }
        else if (attendee.activeLetter != "") {
            setAttendee((prevState) => ({
                ...prevState,
                allAttendees: showOnIndex(getFilteredAttendees(attendee.filters), attendee.activeLetter),

            }))
        }
        else {
            setAttendee((prevState) => ({
                ...prevState,
                allAttendees: getFilteredAttendees(attendee.filters),
                //activeLetter: updatedAttendeesList() && updatedAttendeesList().length > 100 ? availableLetters[0] : "",
            }))
        }

        // localStorage.setItem("activeDay", attendee.activeDay != NaN ? attendee.activeDay : setDefaultDay());
        // localStorage.setItem("activeBreakout", attendee.activeBreakout);
    }, [attendee.filters, attendee.activeDay, attendee.activeLetter, attendee.activeBreakout, attendee.personActions, attendee.searchTerm,attendeesContent])
    useEffect(() => {
        if (attendee.reset) {
            if (attendee.searchTerm != "") {
                setAttendee((prevState) => ({
                    ...prevState,
                    allAttendees: searchByName(getFilteredAttendees(attendee.filters), attendee.searchTerm,"attendee"),
                    activeLetter: updatedAttendeesList() && updatedAttendeesList().length > 100 ? availableLetters[0] : "",
                }))
            }
            else if (attendee.activeLetter != "") {
                setAttendee((prevState) => ({
                    ...prevState,
                    allAttendees: showOnIndex(getFilteredAttendees(attendee.filters), attendee.activeLetter),

                }))
            }
            else {
                setAttendee((prevState) => ({
                    ...prevState,
                    allAttendees: getFilteredAttendees(attendee.filters),
                    activeLetter: updatedAttendeesList() && updatedAttendeesList().length > 100 ? availableLetters[0] : "",
                }))
            }
            // localStorage.setItem("activeDay", attendee.activeDay);
            setTimeout(() => {
                setAttendee((prevState) => ({
                    ...prevState,
                    reset: false,
                }))
            }, 1000);
        }

    }, [attendee.reset])
    useEffect(() => {
        if (attendee.detailUpdate) {
            if (attendee.searchTerm != "") {
                setAttendee((prevState) => ({
                    ...prevState,
                    allAttendees: searchByName(getFilteredAttendees(attendee.filters), attendee.searchTerm,"attendee"),
                }))
            }
            else if (attendee.activeLetter != "") {
                setAttendee((prevState) => ({
                    ...prevState,
                    allAttendees: showOnIndex(getFilteredAttendees(attendee.filters), attendee.activeLetter),

                }))
            }
            else {
                setAttendee((prevState) => ({
                    ...prevState,
                    allAttendees: getFilteredAttendees(attendee.filters),
                }))
            }
            // localStorage.setItem("activeDay", attendee.activeDay);
            setTimeout(() => {
                setAttendee((prevState) => ({
                    ...prevState,
                    detailUpdate: false,
                }))
            }, 1000);
        }

    }, [attendee.detailUpdate])
    useEffect(() => {
        if (layout.activePage === "attendeesList" || layout.activePage === "addAttendee" || layout.activePage === "editAttendee" || layout.activePage === "attendeeDetails") {
            setAttendee((prevState) => ({
                ...prevState,
                searchTerm: attendee.searchTerm,
                showAtoZ: attendee.showAtoZ,
                activeLetter: attendee.activeLetter,
                filters: attendee.filters,
            }));

        } else {
            setAttendee((prevState) => ({
                ...prevState,
                searchTerm: "",
                reset: true,
                showAtoZ: false,
                activeLetter: attendee.allAttendees && attendee.allAttendees.length > 100 ? availableLetters[0] : "",
                filters: {}
            }));
        }

    }, [layout.activePage])


    return (
        <AttendeeContext.Provider value={{ attendee, setAttendee }}>
            {props.children}
        </AttendeeContext.Provider>
    );
};
export default AttendeeContextProvider;

