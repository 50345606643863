
var userPermissions = {
    'admin': {
        modeSelection: true,
        showModuleAttendees: true,
        addAttendee: true,
        editAttendee: true,
        printBadge: true,
        checkinAttendee: true,
        checkoutAttendee: true,
        removeAttendeeCustomMessage: true,
        removeAttendeeInvalidTag: true,
        attendeeBadgePrintingLog: true,
        showModuleNotes: true,
        addNote: true,
        showModuleChat: true,
        sendMessage: true,
        showModuleDownloads: true,
        uploadDocument: true,
        downloadDocument: true,
        showModuleFigures: true,
        downloadReports: true,
        viewReportsInFullScreen: true,
        showModuleAgenda: true,
        updateAgenda:true,
        showModuleSpeakers: true,
        addSpeaker:true,
        editSpeaker: true,
        deleteSpeaker: true,
        showModuleMediaGallery: true,
        addMedia:true,
        editMedia:true,
        deleteMedia:true,
        showModuleUsefulDocuments: true,
        addUsefulDocuments:true,
        editUsefulDocuments:true,
        deleteUsefulDocuments:true,
        manageSocialWall: true,
        activityScreensLink: true,
        dynamicScreensLink: true,
        showQuizzes: true
    },
    'usherette': {
        showModuleAttendees: true,
        checkinAttendee: true,
        showModuleNotes: true,
        addNote: true,
        showModuleChat: true,
        sendMessage: true,
        showModuleFigures: true,
        showModuleAgenda: true,
        showModuleSpeakers: true,
        showModuleMediaGallery: true,
        showModuleUsefulDocuments: true,
        showModuleDownloads: true,
    },
    'client': {
        showModuleAttendees: true,
        showModuleNotes: true,
        showModuleChat: true,
        sendMessage: true,
        showModuleDownloads: true,
        uploadDocument: true,
        downloadDocument: true,
        showModuleFigures: true,
        downloadReports: true,
        viewReportsInFullScreen: true,
        showModuleAgenda: true,
        showModuleSpeakers: true,
        showModuleMediaGallery: true,
        showModuleUsefulDocuments: true,
        showRightSidebar: true,
        aboutAepOsma: true,
        showQuestions: true,
        selectQuestionForScreen: true,
        setAnsweredFlag: true,
        setNotRelevantFlag: true,
        editQuestion: true,
        showQuizzes: true
    },
    'speaker': {
        modeSelection: true,
        showModuleQuestions: true,
        selectQuestionForScreen: true,
        setAnsweredFlag: true,
        showModuleAgenda: true,
        showModuleSpeakers: true,
        setNotRelevantFlag: true,
        editQuestion: true
    },
    'superspeaker': {
        modeSelection: true,
        showModuleQuestions: true,
        selectQuestionForScreen: true,
        setAnsweredFlag: true,
        setNotRelevantFlag: true,
        editQuestion: true,
        showModuleAgenda: true,
        showModuleSpeakers: true,
    }
}

export const checkPermission = (section) => {
    const userSaved = JSON.parse(localStorage.getItem("user"));
    const user = userSaved && userSaved.role && userSaved.role.toLowerCase();

    if (user === "superadmin") {
        if (section === "aboutAepOsma") {
            return false;
        }
        return true;
    }
    else {
        if (userPermissions[user] && userPermissions[user][section] && userPermissions[user][section] !== undefined) {
            return userPermissions[user][section];

        } else {
            return false;
        }

    }

}
