import React, { useContext } from "react";
import { CombinedContext } from "../../../../../../../context/CombinedContext";
import { Col, Row } from "react-bootstrap";
import { TotalAttendees, TotalAttendeesByDayBreakout, findPercentage, getTotalAccounts, getTotalCountries } from "../../../../../../../helper/sideHeaderHelper";
import { TotalAttendeesCombined } from "../../../../../../../helper/combinedReportHelper";
import { titleOfBreakout, titleOfDay } from "../../../../../../../helper/commonHelper";

const SingleGroupCountDetails = () => {
    const { combined, setCombined } = useContext(CombinedContext);
    return (
        <>
            {(combined.typeSelection === "attendees" || combined.typeSelection === "company" || combined.typeSelection === "country") ? <>

                <Row className="combinedCount singleDetail  g-0 py-3">
                    <Col
                        xl={12} lg={12} md={12} sm={12} xs={12}
                        className={`col main ${combined.graphPartSelection === undefined && combined.typeSelection === "attendees"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: undefined,
                                typeSelection: "attendees",
                                attendeeListFilters: { checkin: "Yes" }
                            }));
                        }}
                    >
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <p>Total Attendees </p>
                        <p className="bigCount">
                            {TotalAttendeesCombined("Yes", undefined, undefined)}
                        </p>
                    </Col>
                    <Col
                        className={`col ${combined.graphPartSelection === "Registered"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: "Registered",
                                typeSelection: "attendees",
                            }));
                        }}
                    >
                        <h1>{TotalAttendeesCombined("Yes", undefined, "Registered")}</h1>
                        <p>Registered</p>
                        <p>
                            {findPercentage(
                                TotalAttendeesCombined("Yes", undefined, "Registered"),
                                TotalAttendeesCombined("Yes", undefined, undefined)
                            )}
                        </p>
                    </Col>
                    <Col
                        className={`col ${combined.graphPartSelection === "Walkin"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: "Walkin",
                                typeSelection: "attendees",
                            }));
                        }}
                    >
                        <h1>{TotalAttendeesCombined("Yes", undefined, "Walkin")}</h1>
                        <p>Walkin</p>
                        <p>
                            {findPercentage(
                                TotalAttendeesCombined("Yes", undefined, "Walkin"),
                                TotalAttendeesCombined("Yes", undefined, undefined)
                            )}
                        </p>
                    </Col>
                </Row>

                <Row className="combinedCount singleDetail g-0 py-3 mb-2" style={{
                    color: '#fff',
                    backgroundColor: '#44939a',
                    position: "relative",
                }}>
                    <Col
                        xl={12} xs={12} lg={12} md={12}
                        className={`col  main ${combined.graphPartSelection === undefined && combined.typeSelection === "company"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: undefined,
                                typeSelection: "company",
                            }));
                        }}

                    >
                        <span
                            className="has-arrow has-arrow-top"
                            style={{
                                borderBottomColor: '#44939a',
                            }}
                        ></span>
                        <p>Total Accounts</p>
                        <p className="bigCount">
                            {getTotalAccounts(undefined, undefined, undefined)}
                        </p>
                    </Col>
                    <Col
                        className={`col  ${combined.graphPartSelection === "Shows-Accounts" && combined.typeSelection === "company"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: "Shows-Accounts",
                                typeSelection: "company",
                                companyListFilters: { checkin: "Shows-Accounts" }
                            }));
                        }}
                    >
                        <h1>{getTotalAccounts("Yes", undefined, undefined)}</h1>
                        <p>Shows</p>
                        <p>({findPercentage(
                            getTotalAccounts("Yes", undefined, undefined),
                            getTotalAccounts(undefined, undefined, undefined)
                        )})</p>
                    </Col>
                    <Col
                        className={`col  ${combined.graphPartSelection === "No-Shows-Accounts" && combined.typeSelection === "company"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                typeSelection: "company",
                                graphPartSelection: "No-Shows-Accounts",
                                companyListFilters: { checkin: "No-Shows-Accounts" }
                            }));
                        }}
                    >
                        <h1>{getTotalAccounts(undefined, undefined, undefined) - getTotalAccounts("Yes", undefined, undefined)}</h1>
                        <p>No-Shows</p>
                        <p>({findPercentage(
                            (getTotalAccounts(undefined, undefined, undefined) - getTotalAccounts("Yes", undefined, undefined)),
                            getTotalAccounts(undefined, undefined, undefined)
                        )})</p>
                    </Col>
                </Row>
                <Row className="combinedCount singleDetail g-0 py-3 mb-2" style={{
                    color: '#fff',
                    backgroundColor: '#44939a',
                    position: "relative",
                }}>
                    <Col
                        xl={12} xs={12} lg={12} md={12}
                        className={`col  main ${combined.graphPartSelection === undefined && combined.typeSelection === "country"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: undefined,
                                typeSelection: "country",
                            }));
                        }}

                    >
                        <span
                            className="has-arrow has-arrow-top"
                            style={{
                                borderBottomColor: '#44939a',
                            }}
                        ></span>
                        <p>Total Countries</p>
                        <p className="bigCount">
                            {getTotalCountries(undefined, undefined, undefined)}
                        </p>
                    </Col>
                    <Col
                        className={`col  ${combined.graphPartSelection === "Shows-Accounts" && combined.typeSelection === "country"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                graphPartSelection: "Shows-Accounts",
                                typeSelection: "country",
                                countryListFilters: { checkin: "Shows-Accounts" }
                            }));
                        }}
                    >
                        <h1>{getTotalCountries("Yes", undefined, undefined)}</h1>
                        <p>Shows</p>
                        <p>({findPercentage(
                            getTotalCountries("Yes", undefined, undefined),
                            getTotalCountries(undefined, undefined, undefined)
                        )})</p>
                    </Col>
                    <Col
                        className={`col  ${combined.graphPartSelection === "No-Shows-Accounts" && combined.typeSelection === "country"
                            ? "active"
                            : ""
                            }`}
                        onClick={() => {
                            setCombined((prevState) => ({
                                ...prevState,
                                day: "",
                                breakout: "",
                                typeSelection: "country",
                                graphPartSelection: "No-Shows-Accounts",
                                countryListFilters: { checkin: "No-Shows-Accounts" }
                            }));
                        }}
                    >
                        <h1>{getTotalCountries(undefined, undefined, undefined) - getTotalCountries("Yes", undefined, undefined)}</h1>
                        <p>No-Shows</p>
                        <p>({findPercentage(
                            (getTotalCountries(undefined, undefined, undefined) - getTotalCountries("Yes", undefined, undefined)),
                            getTotalCountries(undefined, undefined, undefined)
                        )})</p>
                    </Col>
                </Row>
            </> : combined.typeSelection === "dayBreakout" ? <Row className="combinedCount dayBreakout singleDetail g-0 py-3 mt-1 mb-2">
                <Col
                    xl={12} xs={12} lg={12} md={12}
                    className={`col main ${combined.graphPartSelection === undefined && combined.typeSelection === "dayBreakout"
                        ? "active"
                        : ""
                        }`}
                    onClick={() => {
                        setCombined((prevState) => ({
                            ...prevState,
                            day: combined.day,
                            breakout: combined.breakout,
                            graphPartSelection: undefined,
                            typeSelection: "dayBreakout",
                            attendeeListFilters: {}
                        }));
                    }}
                >

                    <p className="bigCount">
                        {TotalAttendeesByDayBreakout("Yes", undefined, undefined, combined.day, combined.breakout)}
                    </p>
                    <p>{combined.breakout != null ? titleOfBreakout(combined.breakout) : titleOfDay(combined.day) + "(Plenary)"}</p>
                </Col>
                <Col
                    className={`col  ${combined.graphPartSelection === "Registered"
                        ? "active"
                        : ""
                        }`}
                    onClick={() => {
                        setCombined((prevState) => ({
                            ...prevState,
                            day: combined.day,
                            breakout: combined.breakout,
                            graphPartSelection: "Registered",
                            typeSelection: "dayBreakout",
                            attendeeListFilters: {}
                        }));
                    }}
                >
                    <h1>{TotalAttendeesByDayBreakout("Yes", undefined, "Registered", combined.day, combined.breakout)}</h1>
                    <p>Registered</p>

                </Col>
                <Col
                    className={`col  ${combined.graphPartSelection === "Walkin"
                        ? "active"
                        : ""
                        }`}
                    onClick={() => {
                        setCombined((prevState) => ({
                            ...prevState,
                            day: combined.day,
                            breakout: combined.breakout,
                            graphPartSelection: "Walkin",
                            typeSelection: "dayBreakout",
                            attendeeListFilters: {}
                        }));
                    }}
                >
                    <h1>{TotalAttendeesByDayBreakout("Yes", undefined, "Walkin", combined.day, combined.breakout)}</h1>
                    <p>Walkin</p>

                </Col>
            </Row> : null}
        </>


    );
};

export default SingleGroupCountDetails;
