import React, { createContext, useState, useReducer, useContext } from "react";
import { authReducer, userAuthInitialState } from "../reducers/authReducer";
import axios from "../config/axios";
import { sleep } from "../helper/commonHelper";
import { AttendeeContext } from "./AttendeeContext";
import { error } from "highcharts";
export const AuthContext = createContext({});

const AuthContextProvider = (props) => {

    const userSaved = JSON.parse(localStorage.getItem("user"));
    const [user, setUser] = useState(null)
    const [userAuthState, userAuthDispatch] = useReducer(authReducer, userAuthInitialState);
    const login = async (data) => {

        // Dispatch action to indicate login request has started
        userAuthDispatch({
            type: 'LOGIN_REQUEST'
        });

        try {
            // Attempt to post data to the auth/signin endpoint
            const apiResult = await axios.post(`auth/signin`, data);
            console.log(apiResult,"-------------")
           

            // Log the result
          

            // Extract user data from the response
            const user = apiResult.data && apiResult.data.user;

            // Check if user exists and has an _id property
            if (apiResult && user && user._id) {
                // Dispatch action indicating successful login
                userAuthDispatch({
                    type: "LOGIN_SUCCESS",
                    payload: { user: user }
                });

                // Return true to indicate success
                return true;
            } else {
                // Dispatch action indicating failure due to missing user data
                console.log(error)
                userAuthDispatch({
                    type: "LOGIN_FAILURE",
                    payload: apiResult.error
                });

                // Reset after a delay
                setTimeout(() => {
                    userAuthDispatch({
                        type: "LOGIN_RESET"
                    });
                }, 4000);

                // Return false to indicate failure
                return false;
            }
        } catch (error) {
            // Handle any errors that occurred during the API call
            console.error("Error during login:", error);

            // Dispatch action indicating failure due to an error
            userAuthDispatch({
                type: "LOGIN_FAILURE",
                payload: error.message
            });

            // Optionally reset after a delay
            setTimeout(() => {
                userAuthDispatch({
                    type: "LOGIN_RESET"
                });
            }, 4000);

            // Return false to indicate failure
            return false;
        }


    };
    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
    
        for (let c of cookies) {
            const eqPos = c.indexOf("=");
            const name = eqPos > -1 ? c.substr(0, eqPos) : c;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        }
    };
    const logout = async () => {
        userAuthDispatch({
            type: 'LOGOUT_REQUEST'
        })
        await sleep(1000);
        const apiResult = await axios.post(`auth/signout`);

        if (apiResult.status) {
            clearCookies();
            localStorage.setItem("activeBreakout", null);
            localStorage.clear();
            userAuthDispatch({ type: "LOGOUT_SUCCESS" })
        } else {
            userAuthDispatch({ type: "LOGOUT_FAILURE" })
        }
    };
    return (<AuthContext.Provider value={{
        user,
        setUser,
        login,
        logout,
        userAuthState,
        userAuthDispatch,
    }}>
        {props.children}
    </AuthContext.Provider>);
};



export default AuthContextProvider;
