import React from 'react'

const BadgeItem = ({ text, value, group, color, background }) => {
    return (<>
        {group != "Owners" ?
            <div className="badgeItem" style={{ color: color, border: `2px solid ${color}`, background: background }}>
                {text}{" "}{value} 
            </div> :
            <div className="badgeItem" style={{ color: color, border: `2px solid ${color}`, background: background }}>
                {text} 
            </div>}
    </>
    )
}

export default BadgeItem