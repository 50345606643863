import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CMSFormTextArea = (props) => {
  const {
    label,
    name,
    theme,
    className,
    required,
    description,
    placeholder,
    form,
    maxLength,
    ...rest
  } = props;
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <div className="form-group textArea position-relative">
      {/* <label
        htmlFor={name}
        className={` ${required == true ? "mandatory_field ps-2 " : " "
          } ps-1 mb-0`}
      >
        {" "}
        {label}
      </label> */}

      <Field
        as="textarea"
        id={name}
        name={name}
        rows={10}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`form-control customInput ${className} textArea ${form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
        {...rest}
      />
      {required === true && <div className="required"></div>}
      {maxLength &&
        <div
          className={`maxLength mt-1 ${theme === "light" ? "cream" : ""} ${form.touched[name] && form.errors[name] ? "error" : ""
            } end`}
        >

          {maxLength - form.values[name].length +
            " Characters"}
        </div>}
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormTextArea;
