import React, { useRef, useState, useContext, useEffect, useReducer } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { Col, Row } from "react-bootstrap";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import { headerItems } from "../../../../../helper/sideHeaderHelper";
import axios from "../../../../../config/axios";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { AuthContext } from "../../../../../context/AuthContext";
import CMSFormTextArea from "../../../../UI/shared/formFields/CMSFormTextArea";
import CMSFormImage from "../../../../UI/shared/formFields/CMSFormImage";
import { SpeakerContext } from "../../../../../context/SpeakerContext";
import CMSFormImageWithCrop from "../../../../UI/shared/formFields/CMSFormImageWithCrop";
import CMSFormVideoInput from "../../../../UI/shared/formFields/CMSFormVideoInput";
import { imageTypeCheck, imageSizeCheck } from "../../../../../helper/yupAdditionalValidations"
const AddMedia = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const { userAuthState } = useContext(AuthContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const myRef = useRef(null);
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );

    const handleScroll = (error) => {
        if (Object.keys(error).length > 0) {
            const element = document.getElementsByName(Object.keys(error)[0])[0];
            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 20);
        }
    };

    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const AddMedia = async () => {
            try {
                const res = await axios.post(`add-media-gallery/${userAuthState.user && userAuthState.user.activityId}`, data);

                if (res.status === 201) {


                    updateDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                        payload: res.data,
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                        setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            activePage: "mediaGallery",
                            activePageItems: headerItems("mediaGallery")
                        }))
                    }, 4000);
                } else {
                    updateDispatch({
                        type: "FORM_SUBMISSION_FAILURE",
                        payload: "error",
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                    }, 2000);
                }



            } catch (error) {
                console.error("There was a problem with the delete operation:", error);
                updateDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: error,
                });
                setTimeout(() => {
                    updateDispatch({
                        type: "FETCH_SUCCESS",
                    });
                }, 2000);
            }
        }
        if (layout.isServerBusy === true) {
            setTimeout(() => {
                AddMedia();
            }, 2000);
        } else {
            AddMedia();
        }

    };

    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });

    }, [])

    let imageRequirement = {
        dimension: {
            width: 600,
            height: 600,
        },
        allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
        size: 1024 * 1024 * 2,
    };
    let videoRequirement = {
        allowedExtensions: ["video/mp4", "video/quicktime", "video/x-msvideo"],
        size: 1024 * 1024 * 20,
    };
    return (
        <>
            <GrayHeader />
            <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
                {updateState.isSubmitted ?
                    <div className="successMessage">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>Media Added Successfully</h2>

                    </div> :
                    <Formik

                        initialValues={{
                            activityId: userAuthState.user && userAuthState.user.activityId,
                            type: "image",
                            title: "",
                            file: null,
                            status: "1",
                        }}
                        validationSchema={yup.object({
                            type: yup.string().required("Required"),
                            title: yup.string().required("Required"),
                            file: yup.mixed()
                                .required("Required")
                                .test('file-type', 'Invalid file type', value => {
                                    if (!value) return true; // No file provided
                                    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                                    const validVideoTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo'];

                                    if (validImageTypes.includes(value.type)) {
                                        return true
                                    } else if (validVideoTypes.includes(value.type)) {
                                        return true; // For now, we assume all video types pass
                                    } else {
                                        return false;
                                    }
                                })
                                .test('image-size', 'File size should not exceed 2 MB', value => {
                                    if (!value) return true; // No file provided
                                    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                                    if (validImageTypes.includes(value.type)) {
                                        return value.size <= imageRequirement.size;
                                    }
                                    return true;
                                })
                                .test('video-duration', 'File length should not exceed 30 seconds', async value => {
                                    if (!value || !value.type.startsWith('video/')) return true; // No video file provided or not a video
                                    const url = URL.createObjectURL(value);
                                    const videoElement = document.createElement('video');
                                    videoElement.src = url;

                                    return new Promise(resolve => {
                                        videoElement.onloadedmetadata = () => {
                                            const duration = Math.floor(videoElement.duration);
                                            URL.revokeObjectURL(url);
                                            resolve(duration <= 30); // Assuming duration is in seconds
                                        };
                                    });
                                })
                                .test('video-size', 'File size should not exceed 20 MB', value => {
                                    if (!value) return true; // No file provided
                                    if (value.type.startsWith('video/')) {
                                        return value.size <= videoRequirement.size;
                                    }
                                    return true;
                                }),
                            status: yup.string().required("Required"),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values) => {
                            const formData = new FormData();
                            for (let name in values) {
                                if (Array.isArray(values[name])) {
                                    formData.append(
                                        name,
                                        JSON.stringify(values[name])
                                    );
                                } else {
                                    formData.append(name, values[name]);
                                }
                            }
                            onSubmit(formData);
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                               

                                <Row className={`editAttendeeRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`} ref={myRef}>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="title"
                                            placeholder="Title"
                                            form={form}
                                            required={true}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="type"
                                            placeholder={{ label: "Select Type", value: "" }}
                                            form={form}
                                            required={true}
                                            onChange={(e) => { form.handleChange(e); form.setFieldValue("file", "", true) }}
                                            options={[
                                                { label: "Image", value: "image" },
                                                { label: "Video", value: "video" },
                                            ]}
                                        />

                                    </Col>


                                    {(form.values.type === 'image') && <>
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormImageWithCrop
                                                placeholder="Image"
                                                name="file"
                                                required={true}
                                                form={form}
                                            />
                                        </Col>
                                    </>}


                                    {(form.values.type === 'video') &&
                                        <>
                                            <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                <CMSFormVideoInput
                                                    placeholder="Video"
                                                    name="file"
                                                    required={true}
                                                    form={form}
                                                />
                                            </Col>

                                        </>}
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="status"
                                            placeholder={{ label: "Select Status", value: "" }}
                                            form={form}
                                            required={true}
                                            onChange={form.handleChange}
                                            options={[
                                                { label: "Active", value: "1" },
                                                { label: "Archived", value: "0" },
                                            ]}
                                        />
                                    </Col>


                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-4 mb-3 d-flex align-items-center justify-content-center"
                                    >
                                        {/* <button
                                            className="formSubmissionButton lightGray me-4"
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default form submission
                                                form.resetForm(); // Reset form values to initial state

                                            }}
                                        >
                                            RESET
                                        </button> */}
                                        <button
                                            className="formSubmissionButton "
                                            type="submit"
                                            onClick={(e) => {
                                                form.handleSubmit(e);
                                            }}
                                        >
                                            {updateState.isLoading ? "PROCESSING" : "SAVE"}
                                        </button>
                                    </Col>

                                    <Col xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed">
                                        {!updateState.isLoading && updateState.status === "submitFailed" && <p className="mb-0">
                                            <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                                            Action Failed! Try again.
                                        </p>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>}
            </CustomScrollbars>




        </>
    );
};

export default AddMedia;
