import React, { useContext, useEffect, useState } from "react";
import "./figures.scss";
import OnlyFigures from "./OnlyFigures";
import TopGraySection from "./TopGraySection";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import { FiguresContext } from "../../../../../context/FiguresContext";
import Loading from "../../../../UI/shared/loading/Loading";
const Figures = () => {
    const { allAttendees } = useContext(AttendeeContext);
    const { figures, setFigures } = useContext(FiguresContext);
    let activeDay = localStorage.getItem("activeDay");
    let activeBreakout = localStorage.getItem("activeBreakout");
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 100);
    }, [])
    useEffect(() => {

        if (figures.reset) {
            setFigures((prevState) => ({
                ...prevState,
                activeDay: (activeDay === "null" || activeDay === null) ? null : activeDay,
                activeBreakout: (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakout,
            }))
            localStorage.setItem("figuresActiveDay", activeDay);
            localStorage.setItem("figuresActiveBreakout", activeBreakout);
        }

    }, [figures.reset]);
    return (
        <>
            {loading && <Loading />}
            { attendees && attendees.length > 0 ? <>
                <TopGraySection />
                <OnlyFigures />
            </> : !loading && <h4 className="d-flex align-items-center justify-content-center py-5 my-5">Stats Not Available</h4>}
        </>
    );
};

export default Figures;
