import React, { useRef, useEffect, useState } from 'react';

const AttendeeName = ({ item }) => {
  const nameRef = useRef(null);
  const [fontSize, setFontSize] = useState('22px');

  useEffect(() => {
    const adjustFontSize = () => {
      if (!nameRef.current) return; // Ensure the ref is attached

      const containerWidth = nameRef.current.offsetWidth;
      const text = `${item.firstName} ${item.lastName} (${item.nameArabic})`;
      let newFontSize = 22; // Start with a base font size

      // Create a temporary span to measure text width
      const tempSpan = document.createElement('span');
      tempSpan.style.visibility = 'hidden';
      tempSpan.style.whiteSpace = 'nowrap';
      tempSpan.style.fontSize = `${newFontSize}px`;
      tempSpan.textContent = text;
      document.body.appendChild(tempSpan);
      // Adjust the font size until the text fits within the container
      while (((tempSpan.offsetWidth + 25) > containerWidth) && newFontSize > 17) {
        newFontSize -= 1;
        tempSpan.style.fontSize = `${newFontSize}px`;
      }

      // Clean up
      document.body.removeChild(tempSpan);

      // Set the adjusted font size
      setFontSize(`${newFontSize}px`);
    };

    // Call adjustFontSize initially to set the font size
    adjustFontSize();

    // Add event listener for window resize
    window.addEventListener('resize', adjustFontSize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', adjustFontSize);
    };
  }, []); // Removed item from dependencies to prevent unnecessary recalculations

  return (
    <p
      className="name attendeeName"
      ref={nameRef}
      style={{ fontSize }}
    >

      {item.firstName} {item.lastName} {item.nameArabic && `(${item.nameArabic})`} {(item.isSpeaker === "1" || item.isSpeaker === 1) ? <span className='speakerBadge ms-2'>- <i class="icon ms-1 me-1 icon-speakers text-secondary iconSwiper "></i> speaker</span> : ""}

    </p>
  );
};

export default AttendeeName;
