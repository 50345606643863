import React, { createContext, useEffect, useState } from 'react';
export const OverviewReportContext = createContext({});
const OverviewReportContextProvider = (props) => {
    const [overview, setOverview] = useState({
        peopleGroupSelection: undefined,
        graphPartSelection: undefined,
        showFigureListViewFullScreen: false,
        day: "",
        breakout: "",
        
    })

    return (
        <OverviewReportContext.Provider value={{ overview, setOverview }}>
            {props.children}
        </OverviewReportContext.Provider>
    );
};
export default OverviewReportContextProvider;

