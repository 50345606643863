import React, { useState, useReducer, useEffect, useContext } from 'react'
import "./duplicate.scss"
import TopGraySectionEmpty from '../figures/TopGraySectionEmpty'
import { Col, Row } from 'react-bootstrap'
import { duplicateAttendees } from '../../../../../helper/sideHeaderHelper'
import { updateInitialState, updateReducer } from '../../../../../reducers/updateReducer'
import axios from '../../../../../config/axios'
import ModalBody from '../../../../UI/shared/modal/ModalBody'
import DeleteDuplicateAttendee from './DeleteDuplicateAttendee'
import Loading from '../../../../UI/shared/loading/Loading'
import { HomeScreenContext } from '../../../../../context/HomeScreenContext'
import CustomScrollbars from '../../../../UI/shared/customScrollbar/CustomScrollbar'
const DuplicateAttendee = () => {
    const [active, setActive] = useState("name");
    const { layout, setLayout } = useContext(HomeScreenContext);
    const attendees = duplicateAttendees();
    function calculateFilledPercentage(obj) {
        const totalFields = Object.keys(obj).length;
        const nonEmptyFields = Object.values(obj).filter(value => value !== "").length;
        const percentageFilled = (nonEmptyFields / totalFields) * 100;
        return percentageFilled.toFixed(0) + "%";
    }
    const [show, setShow] = useState({
        delete: false,
        deleteId: null,
    });
    const [deleteState, deleteDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const handleDeleteClose = () => setShow((prevState) => ({
        ...prevState,
        delete: false,
    }));
    const handleDeleteShow = (data) => setShow((prevState) => ({
        ...prevState,
        delete: true,
    }));
    const handleDelete = async (id) => {
        deleteDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });
        try {
            const res = await axios.delete(`delete-duplicate-attendee/${id}`);
            setLayout((prevState) => ({
                ...prevState,
                reloadAttendees: true,
            }))
            deleteDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
        } catch (error) {
            deleteDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });

        }
    };
    useEffect(() => {

        deleteDispatch({
            type: "FETCH_SUCCESS",
        });
    }, [])

    return (
        <>
            {deleteState.isLoading && <Loading />}
            <TopGraySectionEmpty>
                <Row className="notesTopSection">
                    <Col className="leftSection">
                        <div className="notesType">
                            <p
                                className={active === "name" ? "active" : ""}
                                onClick={() => setActive("name")}
                            >
                                By Name
                            </p>
                            <p
                                className={active === "email" ? "active" : ""}
                                onClick={() => setActive("email")}
                            >
                                By Email
                            </p>

                        </div>
                    </Col>

                </Row>
            </TopGraySectionEmpty>
            <CustomScrollbars style={{ height: `calc(100vh - 190px)` }}>
                <Row>
                    <Col>
                        <div className='m-4'>
                            {attendees && attendees.length > 0 && attendees.filter((attendee) => attendee.type === active).map((item, index) => {
                                return (
                                    <div className="duplicate_attendees_block ng-scope" >
                                        {item.item1 &&
                                            <div className="duplicate_attendees_item ng-scope">
                                                <ul>
                                                    <li className={`holder_name ${active === "name" ? "red" : ""}`} >{item.item1.fullName}</li>
                                                    <li className={`holder_email ${active === "email" ? "red" : ""}`} >{item.item1.email}</li>
                                                    <li className="holder_designation">{item.item1.designation}</li>
                                                    <li className="holder_company">{item.item1.company}</li>

                                                    <div className="Progress_Status">
                                                        <div className="myprogressBar" style={{ width: calculateFilledPercentage(item.item1) }}></div>
                                                        <div className="percentage_figure">{calculateFilledPercentage(item.item1)}</div>
                                                    </div>

                                                </ul>
                                                <div className="" onClick={() => {
                                                    setShow((prevState) => ({
                                                        ...prevState,
                                                        deleteId: item.item1._id,
                                                    }));
                                                    handleDeleteShow()

                                                }}>
                                                    <span className="btn icon-only icon-large dark-icon" >
                                                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>}
                                        {item.item2 &&
                                            <div className="duplicate_attendees_item ng-scope">
                                                <ul>
                                                    <li className={`holder_name ${active === "name" ? "red" : ""}`}>{item.item2.fullName}</li>
                                                    <li className={`holder_email ${active === "email" ? "red" : ""}`} ng-className="{duplicate_entry:duplicateFilters.field == 'email'}">{item.item2.email}</li>
                                                    <li className="holder_designation">{item.item2.designation}</li>
                                                    <li className="holder_company">{item.item2.company}</li>

                                                    <div className="Progress_Status">
                                                        <div className="myprogressBar" style={{ width: calculateFilledPercentage(item.item2) }}></div>
                                                        <div className="percentage_figure">{calculateFilledPercentage(item.item2)}</div>
                                                    </div>

                                                </ul>
                                                <div className="" onClick={() => {
                                                    setShow((prevState) => ({
                                                        ...prevState,
                                                        deleteId: item.item2._id,
                                                    }));
                                                    handleDeleteShow()

                                                }}>
                                                    <span className="btn icon-only icon-large dark-icon" >
                                                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>}
                                    </div>
                                )
                            })}
                            {attendees.filter((attendee) => attendee.type === active).length <= 0 && <h3 className="my-5 pt-5 text-center">No Records Found!</h3>}

                        </div>

                    </Col>
                </Row>
            </CustomScrollbars>
            <ModalBody
                handleClose={handleDeleteClose}
                handleShow={handleDeleteShow}
                closeButton={false}
                heading="Confirm!"
                className="undoCheckInModal"
                show={show.delete}
                content={<DeleteDuplicateAttendee handleClose={handleDeleteClose} deleteAttendee={() => handleDelete(show.deleteId)} />}
            />


        </>
    )
}

export default DuplicateAttendee