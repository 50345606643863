import React, { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
const TopGraySectionEmpty = ({ children }) => {

  return (
    <>
      <Row className='infoRow figuresSection'>
        {children}
      </Row>
    </>
  )
}

export default TopGraySectionEmpty