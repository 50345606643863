import React from 'react'

const GrayHeader = ({children}) => {
  return (
    <div className="grayHeader">
      {children}
    </div>
  )
}

export default GrayHeader