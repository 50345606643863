import React, { useState } from 'react';

const FeatureToggle = ({ feature, onSubmit }) => {
  const [isChecked, setIsChecked] = useState(feature.isAllowedInBasic === "1");

  const handleChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onSubmit({ ...feature, isAllowedInBasic: checked ? "1" : "0" });
  };

  return (
    <div className="setting_select_app_mode">
      <p className={!isChecked ? "active mb-0" : "mb-0"}>Advanced</p>
      <label className="switch">
        <input
          type="checkbox"
          defaultChecked={isChecked}
          onChange={handleChange}
          className="ng-pristine ng-untouched ng-valid ng-empty"
        />
        <span className="slider round"></span>
      </label>
      <p className={isChecked ? "active mb-0" : "mb-0"}>Basic</p>
    </div>
  );
};

export default FeatureToggle;
