import React, { useEffect, useState, useContext } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import Home from '../components/module/home/Home';
import Login from '../components/module/login/Login';
import { AuthContext } from '../context/AuthContext';
import PrivateRoute from './PrivateRoute';
import Print from '../components/module/print/Print';

const ApplicationRoutes = () => {

    return (
        <Routes>

            <Route exact forceRefresh={true} path="/login" element={<Login />} />
           

                <Route
                    exact
                    path="/"
                    forceRefresh={true}
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route
                    exact
                    path="/print"
                    forceRefresh={true}
                    element={
                        <PrivateRoute>
                            <Print />
                        </PrivateRoute>
                    }
                />
          
        </Routes>
    );
};

export default ApplicationRoutes;