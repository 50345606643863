import React, { useState, useReducer, useEffect, useContext } from 'react'
import TopGraySectionEmpty from '../figures/TopGraySectionEmpty'
import { Col, Row, Table } from 'react-bootstrap'
import { listingInitialState, listingReducer } from '../../../../../reducers/listingReducer';
import axios from '../../../../../config/axios';
import { AuthContext } from '../../../../../context/AuthContext';
import CustomScrollbars from '../../../../UI/shared/customScrollbar/CustomScrollbar';
import "./agenda.scss"
import { getStatusByDay } from '../../../../../helper/sideHeaderHelper';
import ModalBody from '../../../../UI/shared/modal/ModalBody';
import SpeakerDetailsModal from './SpeakerDetailsModal';
import { PiMicrophoneStageFill } from "react-icons/pi";
import { MdOutlineFileDownload } from "react-icons/md";
import { HiUser } from "react-icons/hi";
import AgendaListingMarking from './AgendaListingMarking';
import Loading from '../../../../UI/shared/loading/Loading';
import { checkPermission } from '../../../../../helper/permissionsHelper';
const Agenda = () => {
    const [active, setActive] = useState({ type: "All", day: "1", agenda: [], speakerModal: false, speakerDetails: null, showActions: false });
    const [agendaData, setAgendaData] = useState([])
    const { userAuthState } = useContext(AuthContext);
    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );
    const handleClose = () => setActive((prevState) => ({
        ...prevState,
        speakerModal: false,
    }));
    const handleShow = () => setActive((prevState) => ({
        ...prevState,
        speakerModal: true,
    }));
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function hasDuplicateStartTimes(array) {
        const x = array.some((obj, index) => {
            const firstIndex = array.findIndex(item => item.startTime === obj.startTime);
            return firstIndex !== index;
        });
        return x
    }

    const loadAgenda = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });
        await sleep(100)
        try {
            const res = await axios.get(
                `get-agenda/${userAuthState.user && userAuthState.user.activityId}`
            );

            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });

            if (res.data) {
                if (res.data.agendaSettings) {
                    if (res.data.agendaSettings.type === "Per Event") {
                        setActive((prevState) => ({
                            ...prevState,
                            agenda: res.data.items.filter(item => item.day === 1),
                        }));
                    }
                    else {

                        setActive((prevState) => ({
                            ...prevState,
                            agenda: res.data.items,
                        }));
                    }
                }

            }
        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };
    useEffect(() => {
        loadAgenda()
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            // Assuming state.agenda is available here
            const sortedAgenda = active.agenda;
            const updatedAgenda = await Promise.all(sortedAgenda && sortedAgenda.map(async (agenda) => {
                const dayStatus = getStatusByDay(parseInt(agenda.day));
                return { ...agenda, dayStatus };
            }));

            setAgendaData(updatedAgenda);
            setActive((prevState) => ({
                ...prevState,
                agenda: updatedAgenda
            }));
        };
        if (listingState.data && listingState.data.items) {
            fetchData();
        }


    }, [listingState.data && listingState.data.items]);
    const array = [];
    agendaData &&
        agendaData
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(function (item) {
                if (item.day === active.day) return array.push(item);
            });
    const breakouts = agendaData.reduce((acc, d) => {
        if (Object.keys(acc).includes(d.breakoutId)) return acc;

        acc[d.breakoutId] = agendaData.filter(
            (g) => g.breakoutId === d.breakoutId
        );
        return acc;
    }, {});
    delete breakouts.null;
    let result = agendaData.filter((e) => e.dayStatus != "0").map(({ day }) => day);
    const max = result.reduce((a, b) => Math.max(a, b), -Infinity);
    const getFileFromDay = (array, dayToFind) => {

        if (array) {
            const foundDay = array.find(days => (days.day === parseInt(dayToFind)));
            if (foundDay && foundDay.file && foundDay.file != "") {
                return process.env.REACT_APP_BACKEND_URL + "/public/" + foundDay.file;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }

    };
    const handleDownload = (file) => {
        const downloadUrl = `${file}`;
        window.open(downloadUrl, '_blank');
    };
    return (
        <>
            {listingState.isLoading && <Loading />}
            {listingState.data && listingState.data.items && listingState.data.items.length > 0 &&
                <TopGraySectionEmpty>
                    <Row className="agendaTopSection w-100 mx-auto">
                        <Col className="leftSection p-0">
                            <div className="type">
                                <p
                                    className={active.type === "All" ? "active" : ""}
                                    onClick={() => setActive((prevState) => ({
                                        ...prevState,
                                        type: "All",
                                    }))}
                                >
                                    All
                                </p>
                                <p
                                    className={active.type === "Upcoming" ? "active" : ""}
                                    onClick={() => setActive((prevState) => ({
                                        ...prevState,
                                        type: "Upcoming",
                                    }))}
                                >
                                    Upcoming
                                </p>
                                <p
                                    className={active.type === "Happening Now" ? "active" : ""}
                                    onClick={() => setActive((prevState) => ({
                                        ...prevState,
                                        type: "Happening Now",
                                    }))}
                                >
                                    Happening Now
                                </p>
                                <p
                                    className={active.type === "Done" ? "active" : ""}
                                    onClick={() => setActive((prevState) => ({
                                        ...prevState,
                                        type: "Done",
                                    }))}
                                >
                                    Done
                                </p>
                            </div>
                        </Col>
                        {checkPermission("updateAgenda") &&
                            <Col xl={3} lg={2} md={3} sm={12} xs={12} className="rightSection p-0">

                                <div className="actionButton d-flex align-items-center justify-content-end" onClick={() => setActive((prevState) => ({
                                    ...prevState,
                                    showActions: !active.showActions,
                                }))}>
                                    <span className="me-2 w-100">{active.showActions ? "Hide Actions" : "Show Actions"} {active.showActions ? <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                        : <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    }</span>
                                </div>
                            </Col>}

                    </Row>
                </TopGraySectionEmpty>
            }

            {listingState.data && listingState.data.items && listingState.data.items.length > 0 &&
                <CustomScrollbars desktopHeight="calc(100vh - 200px)" mobileHeight="calc(100vh - 210px)">
                    <Row className='w-100 mx-auto'>
                        <Col className='agendaHolder'>


                            {max > 1 &&
                                <div className="dayItemHolder">
                                    <>
                                        {Array.from({ length: max }, (_, i) => (
                                            <div
                                                key={i + 1}
                                                className={active.day === `${i + 1}` ? "dayItem active" : "dayItem"}
                                                onClick={() => setActive((prevState) => ({
                                                    ...prevState,
                                                    day: `${i + 1}`,
                                                }))}
                                            >
                                                Day {i + 1}
                                            </div>
                                        ))}


                                    </>
                                </div>}

                            {getFileFromDay(listingState.data && listingState.data.files, active.day) != null &&
                                <div className="mt-2 me-2 d-flex align-items-center justify-content-end">
                                    <a href="#" className='downloadAgenda' onClick={(e) => { e.preventDefault(); handleDownload(getFileFromDay(listingState.data && listingState.data.files, active.day)) }} target="_blank">
                                        Download Agenda <MdOutlineFileDownload className='icon' />
                                    </a>
                                </div>}


                            <div className={max > 1 ? "mt-2 mb-3" : "mb-3"}>
                                <Table
                                    size="sm"
                                    className={`agendaTable ${max > 1 ? "mt-0" : "mt-0"}`}
                                >
                                    {active.agenda && active.agenda.length > 0 && active.agenda.filter(agendaItem => {
                                        if (active.type === "All") {
                                            return true;
                                        } else if (active.type === "Upcoming") {
                                            return agendaItem.isActive === "0" && agendaItem.isDone === "0";
                                        }
                                        else if (active.type === "Happening Now") {
                                            return agendaItem.isActive === "1";
                                        }
                                        else if (active.type === "Done") {
                                            return agendaItem.isDone === "1";
                                        }
                                    }).map((agenda, i) => {
                                        if (agenda.day === parseInt(active.day) && agenda.status === "1") return (
                                            <tr>
                                                {(agenda.type === "Session" || agenda.type === "Panel Discussion") &&
                                                    <>
                                                        <td className={`text-left timeTd ${agenda.isDone === "1" ? "done" : ""} ${agenda.isHeading === "1" ? "bold" : ""}`}>
                                                            {agenda.startTime}
                                                        </td>
                                                        <td
                                                            className={`agendaItem  ${agenda.isDone === "1" ? "done" : ""} ${agenda.isActive === "1"
                                                                ? "activeNow"
                                                                : ""
                                                                } ${agenda.isHeading === "1"
                                                                    ? "heading"
                                                                    : ""
                                                                }`}
                                                        >
                                                            <div className='w-100'>
                                                                <div className='d-flex align-items-center justify-content-start'>
                                                                    {agenda.isActive ===
                                                                        "1" &&
                                                                        <span className="current_animationHolder">
                                                                            <span className="current_animation">

                                                                            </span>
                                                                        </span>
                                                                    }
                                                                    {agenda.title} {" "}
                                                                </div>


                                                                {agenda.subTitle &&
                                                                    <div className="agendaSubTitle">
                                                                        <small className="text-italic">
                                                                            {agenda.subTitle}
                                                                        </small>

                                                                    </div>}
                                                                {agenda.description &&
                                                                    <div className="agendaDescription">
                                                                        <div className="text-italic" dangerouslySetInnerHTML={{ __html: agenda.description }}>

                                                                        </div>

                                                                    </div>}
                                                                {agenda.speakers && agenda.speakers.length > 0 && agenda.speakers.map((speaker, i) => {
                                                                    if (speaker.isModerator === "1") return (
                                                                        <div className={`agendaSpeaker ms-2`} onClick={() => {
                                                                            setActive((prevState) => ({
                                                                                ...prevState,
                                                                                speakerDetails: speaker,
                                                                            }));
                                                                            handleShow();

                                                                        }}>
                                                                            <div className="speaker">
                                                                                <PiMicrophoneStageFill className="icon" /> {speaker.firstName}{" "}{speaker.lastName} <span className="ms-1 moderator">( Moderator )</span>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                    else return (
                                                                        <div className={`agendaSpeaker ms-2`} onClick={() => {
                                                                            setActive((prevState) => ({
                                                                                ...prevState,
                                                                                speakerDetails: speaker,
                                                                            }));
                                                                            handleShow();

                                                                        }}>
                                                                            <div className="speaker">
                                                                                <HiUser className="icon" /> {speaker.firstName}{" "}{speaker.lastName}
                                                                            </div>

                                                                        </div>
                                                                    )

                                                                })
                                                                }
                                                                {checkPermission("updateAgenda") && active.showActions &&
                                                                    <div className='actions d-flex align-items-center justify-content-end w-100'>

                                                                        <AgendaListingMarking itemId={agenda._id} displayClass="d-flex align-items-center justify-content-end" hideActive={true} isDone={agenda.isDone} isActive={agenda.isActive} loadItems={loadAgenda} />
                                                                    </div>}
                                                            </div>


                                                        </td>
                                                    </>}
                                                {agenda.type === "Breakout" &&
                                                    breakouts[
                                                    agenda.breakoutId
                                                    ] && (
                                                        <>
                                                            <td className={`text-left timeTd ${agenda.isHeading === "1" ? "bold" : ""}`}>
                                                                <p className="pt-2 mb-0 text-primaryColor">
                                                                    {hasDuplicateStartTimes(breakouts[
                                                                        agenda.breakoutId
                                                                    ]) === true ? agenda.startTime : null}

                                                                    {/* - {agenda.endTime} */}
                                                                </p>
                                                            </td>
                                                            <td className="paddingNo " >

                                                                <Table
                                                                    borderless
                                                                    className="mb-0 breakoutTable"
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ backgroundColor: "#eee" }} colSpan={breakouts[agenda.breakoutId].length}>
                                                                                <div className={`${agenda.isDone === "1" ? "done" : ""} d-flex align-items-center justify-content-start`}>
                                                                                    {agenda.isActive ===
                                                                                        "1" &&
                                                                                        <span className="current_animationHolder">
                                                                                            <span className="current_animation">

                                                                                            </span>
                                                                                        </span>
                                                                                    }
                                                                                    <p className="mb-0 py-1 breakoutName">{agenda.breakoutName}</p>
                                                                                    <br />


                                                                                </div>
                                                                                {agenda.breakoutSubTitle &&
                                                                                    <div className="agendaSubTitle">
                                                                                        <small className="text-italic">
                                                                                            {agenda.breakoutSubTitle}
                                                                                        </small>

                                                                                    </div>}

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            {breakouts[
                                                                                agenda
                                                                                    .breakoutId
                                                                            ] &&
                                                                                breakouts[
                                                                                    agenda
                                                                                        .breakoutId
                                                                                ].map(
                                                                                    (
                                                                                        value,
                                                                                        index2
                                                                                    ) => (
                                                                                        <React.Fragment key={index2}>
                                                                                            <td
                                                                                                className={`breakoutTd${breakouts[
                                                                                                    value
                                                                                                        .breakoutId
                                                                                                ]
                                                                                                    .length
                                                                                                    } ${agenda.isActive ===
                                                                                                        "1"
                                                                                                        ? "activeNow"
                                                                                                        : ""
                                                                                                    } ${agenda.isDone ===
                                                                                                        "1"
                                                                                                        ? "done"
                                                                                                        : ""
                                                                                                    }  ${value.isHeading ===
                                                                                                        "1"
                                                                                                        ? "heading"
                                                                                                        : ""
                                                                                                    }`}
                                                                                            >
                                                                                                <div>
                                                                                                    <div className='d-flex align-items-center justify-content-start'>

                                                                                                        {value.title} {" "}
                                                                                                    </div>
                                                                                                    {value.subTitle &&
                                                                                                        <div className="agendaSubTitle">
                                                                                                            <small className="text-italic">
                                                                                                                {value.subTitle}
                                                                                                            </small>

                                                                                                        </div>}
                                                                                                    {hasDuplicateStartTimes(breakouts[
                                                                                                        agenda.breakoutId
                                                                                                    ]) === false && value.startTime && value.endTime &&
                                                                                                        <div className="agendaSubTime">
                                                                                                            <small className="text-italic">
                                                                                                                {value.startTime} {value.startTime && value.endTime ? "-" : ""} {value.endTime}
                                                                                                            </small>

                                                                                                        </div>}
                                                                                                    {value.description &&
                                                                                                        <div className="agendaDescription">
                                                                                                            <div className="text-italic" dangerouslySetInnerHTML={{ __html: value.description }}>

                                                                                                            </div>

                                                                                                        </div>}
                                                                                                    {value.speakers && value.speakers.length > 0 && value.speakers.map((speaker, i) => {
                                                                                                        return (
                                                                                                            <div className={`agendaSpeaker ms-2`} onClick={() => {
                                                                                                                setActive((prevState) => ({
                                                                                                                    ...prevState,
                                                                                                                    speakerDetails: speaker,
                                                                                                                }));
                                                                                                                handleShow();

                                                                                                            }}>
                                                                                                                <div className="speaker">
                                                                                                                    <HiUser className="icon" />   {speaker.firstName}{" "}{speaker.lastName} {speaker.isModerator === "1" && <span className="ms-1 moderator">(  Moderator )</span>}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                    }

                                                                                                </div>

                                                                                            </td>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                )}
                                                                            {
                                                                                delete breakouts[
                                                                                agenda
                                                                                    .breakoutId
                                                                                ]
                                                                            }
                                                                        </tr>

                                                                        {active.showActions &&
                                                                            <tr>
                                                                                <td colSpan={10}>
                                                                                    <div className={`actions d-flex align-items-center justify-content-end ${agenda.isDone === "1" ? "done" : ""}`}>
                                                                                        <AgendaListingMarking itemId={agenda._id} displayClass="d-flex align-items-center justify-content-end" hideActive={true} isDone={agenda.isDone} isActive={agenda.isActive} loadItems={loadAgenda} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        </>
                                                    )}
                                            </tr>

                                        )
                                    })}
                                    {active.agenda && active.agenda.length > 0 && active.agenda.filter(agendaItem => {
                                        if (active.type === "All") {
                                            return true;
                                        } else if (active.type === "Upcoming") {
                                            return agendaItem.isActive === "0" && agendaItem.isDone === "0";
                                        }
                                        else if (active.type === "Happening Now") {
                                            return agendaItem.isActive === "1";
                                        }
                                        else if (active.type === "Done") {
                                            return agendaItem.isDone === "1";
                                        }
                                    }).length <= 0 &&
                                        <>
                                            <h3 className='my-5 text-center pt-5'>No Records Found!</h3>
                                        </>}
                                </Table>
                            </div>

                        </Col>
                    </Row>
                </CustomScrollbars >}
            {
                listingState.data && listingState.data.items && listingState.data.items.length <= 0 &&
                <Row className='w-100 mx-auto'>
                    <Col className='agendaHolder'>
                        <h3 className='my-5 text-center pt-5'>No Records Found!</h3>
                    </Col>
                </Row>
            }


            <ModalBody
                handleClose={handleClose}
                handleShow={handleShow}
                heading="Speaker Details"
                show={active.speakerModal}
                CloseButton={true}
                content={<SpeakerDetailsModal details={active.speakerDetails} handleClose={handleClose} />}
            />

        </>
    )
}

export default Agenda