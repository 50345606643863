import React, { useRef, useState, useContext, useEffect, useReducer } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { Button, Col, Row } from "react-bootstrap";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import {
    getAllPeopleGroup,
    getAttendeeDetails,
    getAvailablePeopleGroups,
    getCompanyNameFromGroup,
    getIdFromPeopleGroup,
    getPeopleGroupFromGroup,
    getTitleArray,
    headerItems,
} from "../../../../../helper/sideHeaderHelper";
import axios from "../../../../../config/axios";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import Loading from "../../../../UI/shared/loading/Loading";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
const EditAttendee = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const { attendee, setAttendee } = useContext(AttendeeContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const details = getAttendeeDetails(attendee.activeAttendeeDetailsId);

    const fields = eventData && eventData.formFields;
    const myRef = useRef(null);
    const [formInitialData, setFormInitialData] = useState(null);
    const countries = eventData && eventData.countries ? eventData.countries : [];
    const executeScroll = () => myRef.current.scrollIntoView();
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const handleScroll = (error) => {
        if (Object.keys(error).length > 0) {
            const element = document.getElementsByName(Object.keys(error)[0])[0];
            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 20);
        } else {
            executeScroll();
        }
    };
    function updateAttendeeById(data) {
        return attendeesContent && attendeesContent.attendees.map(attendee => {
            if (attendee._id === data.id) {
                // Return a new object with the updated properties
                return { ...attendee, ...data };
            }
            // Return the original object if the id does not match
            return attendee;
        });
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const updatedUpdates = {
            ...attendeesContent,
            attendees: updateAttendeeById(data),
        };

        localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
        try {
            const res = await axios.post(`edit-attendee/${data.id}`, data);

            setAttendee((prevState) => ({
                ...prevState,
                reset: true
            }))

            setLayout((prevState) => ({
                ...prevState,
                leftSideMenu: true,
                activePage: "attendeesList",
                activePageItems: headerItems("attendeesList")
            }))
            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });

        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };
    let data = {}

    if (details != null) {
        if (
            details.peopleGroup === "Sponsors" &&
            details.sponsorId
        ) {
            data = { ...data, peopleGroup: "sponsor__" + details.sponsorId, sponsorId: details.sponsorId }
        }
        if (
            details.peopleGroup === "Partners" &&
            details.partnerId
        ) {

            data = { ...data, peopleGroup: "partner__" + details.partnerId, partnerId: details.partnerId }
        }
        data = {
            ...details,
            id: details._id,
            peopleGroup: data.peopleGroup ? data.peopleGroup : details.peopleGroup ? details.peopleGroup : "",
            title: details.title ? details.title : "",
            firstName: details.firstName ? details.firstName : "",
            lastName: details.lastName ? details.lastName : "",
            nameArabic: details.nameArabic ? details.nameArabic : "",
            company: details.company ? details.company : "",
            companyArabic: details.companyArabic ? details.companyArabic : "",
            designation: details.designation ? details.designation : "",
            email: details.email ? details.email : "",
            mobile: details.mobile ? details.mobile : "",
            telephone: details.telephone ? details.telephone : "",
            sector: details.sector ? details.sector : "",
            address: details.address ? details.address : "",
            city: details.city ? details.city : "",
            country: details.country ? details.country : "",
            nationality: details.nationality ? details.nationality : "",
            gender: details.gender ? details.gender : "",
            companions: details.companions ? details.companions : "",
            unifiedId: details.unifiedId ? details.unifiedId : "",
            tableInfo: details.tableInfo ? details.tableInfo : "",
        };

    }
    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });
        setFormInitialData(data)
    }, [])


    return (
        <>
            <GrayHeader />

            <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
                {formInitialData != null &&

                    <Formik
                        initialValues={formInitialData}
                        validationSchema={yup.object().shape({
                            peopleGroup: yup.string().required("Select People Group"),
                            title:
                                fields.title === "Yes"
                                    ? yup.string().required("Enter Title")
                                    : yup.string(),
                            firstName:
                                fields.firstName === "Yes"
                                    ? yup.string().required("Enter First Name")
                                    : yup.string(),
                            lastName:
                                fields.lastName === "Yes"
                                    ? yup.string().required("Enter Last Name")
                                    : yup.string(),
                            nameArabic:
                                fields.nameArabic === "Yes"
                                    ? yup.string().required("Enter Arabic Name")
                                    : yup.string(),
                            company:
                                fields.company === "Yes"
                                    ? yup.string().required("Enter company")
                                    : yup.string(),
                            companyArabic:
                                fields.companyArabic === "Yes"
                                    ? yup.string().required("Enter Company Arabic")
                                    : yup.string(),
                            designation:
                                fields.designation === "Yes"
                                    ? yup.string().required("Enter Designation")
                                    : yup.string(),
                            email:
                                fields.email === "Yes"
                                    ? yup.string().email("Enter valid email").required("Enter Email")
                                    : yup.string().email("Enter valid email"),
                            mobile:
                                fields.mobile === "Yes"
                                    ? yup.string().required("Enter Mobile")
                                    : yup.string(),
                            telephone:
                                fields.telephone === "Yes"
                                    ? yup.string().required("Enter Telephone")
                                    : yup.string(),
                            sector:
                                fields.sector === "Yes"
                                    ? yup.string().required("Enter Sector")
                                    : yup.string(),
                            address:
                                fields.address === "Yes"
                                    ? yup.string().required("Enter Address")
                                    : yup.string(),
                            city:
                                fields.city === "Yes"
                                    ? yup.string().required("Enter City")
                                    : yup.string(),
                            country:
                                fields.country === "Yes"
                                    ? yup.string().required("Select country")
                                    : yup.string(),
                            nationality:
                                fields.nationality === "Yes"
                                    ? yup.string().required("Select nationality")
                                    : yup.string(),

                            gender:
                                fields.gender === "Yes"
                                    ? yup.string().required("Select gender")
                                    : yup.string(),

                            companions:
                                fields.companions === "Yes"
                                    ? yup.string().required("Enter Companions")
                                    : yup.string(),
                            unifiedId:
                                fields.unifiedId === "Yes"
                                    ? yup.string().required("Enter Unified Id")
                                    : yup.string(),
                            tableInfo:
                                fields.tableInfo === "Yes"
                                    ? yup.string().required("Enter Unified Id")
                                    : yup.string(),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values) => {

                            if (getPeopleGroupFromGroup(values.peopleGroup) === "Sponsor") {
                                delete values.partnerId;
                                values = { ...values, sponsorId: getIdFromPeopleGroup(values.peopleGroup) }
                                values = { ...values, peopleGroup: "Sponsors" }

                            }
                            else if (getPeopleGroupFromGroup(values.peopleGroup) === "Partner") {
                                delete values.sponsorId;
                                values = { ...values, partnerId: getIdFromPeopleGroup(values.peopleGroup) }
                                values = { ...values, peopleGroup: "Partners" }
                            }
                            else if (values.peopleGroup === "Owners" || values.peopleGroup === "Customers") {
                                delete values.partnerId;
                                delete values.sponsorId;
                            }

                            values = { ...values, fullName: values.firstName + " " + values.lastName, companions: values.companions ? parseInt(values.companions) : "" }
                            delete values.checkinTime
                            delete values.checkin


                            onSubmit(values)
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>

                                <Row className={`editAttendeeRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`} ref={myRef}>

                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="peopleGroup"
                                            placeholder={{ label: "Select People Group", value: "" }}
                                            form={form}
                                            onChange={(e) => { form.handleChange(e); form.setFieldValue("company", getCompanyNameFromGroup(e.target.value)) }}
                                            options={getAllPeopleGroup()}
                                            required={true}
                                        />
                                    </Col>
                                    {fields.title && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="title"
                                                placeholder={{ label: "Select Title", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={getTitleArray()}
                                                required={fields.title === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.firstName && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="firstName"
                                                placeholder="First Name"
                                                form={form}
                                                required={fields.firstName === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.lastName && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="lastName"
                                                placeholder="Last Name"
                                                form={form}
                                                required={fields.lastName === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.nameArabic && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="nameArabic"
                                                placeholder="Name (Arabic)"
                                                form={form}
                                                required={fields.nameArabic === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.company && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="company"
                                                placeholder="Company"
                                                disabled={form.values.peopleGroup ? getCompanyNameFromGroup(form.values.peopleGroup) === "" ? false : true : false}
                                                //value={(form.values.peopleGroup === "Customers" || form.values.peopleGroup === "") ? "" : getCompanyNameFromGroup(form.values.peopleGroup)}
                                                form={form}
                                                required={fields.company === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.companyArabic && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="companyArabic"
                                                placeholder="Company (Arabic)"
                                                form={form}
                                                required={fields.companyArabic === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.designation && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="designation"
                                                placeholder="Designation"
                                                form={form}
                                                required={fields.designation === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.email && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="email"
                                                placeholder="Email"
                                                form={form}
                                                required={fields.email === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.mobile && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="mobile"
                                                placeholder="Mobile"
                                                form={form}
                                                required={fields.mobile === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.telephone && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="telephone"
                                                placeholder="Telephone"
                                                form={form}
                                                required={fields.telephone === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.sector && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="sector"
                                                placeholder="Sector"
                                                form={form}
                                                required={fields.sector === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.address && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="address"
                                                placeholder="Address"
                                                form={form}
                                                required={fields.address === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.city && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="city"
                                                placeholder="City"
                                                form={form}
                                                required={fields.city === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.country && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="country"
                                                placeholder={{ label: "Select Country", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={handleFormatArray(countries)}
                                                required={fields.country === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.nationality && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="nationality"
                                                placeholder={{ label: "Select Nationality", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={handleFormatArray(countries)}
                                                required={fields.nationality === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.gender && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="gender"
                                                placeholder={{ label: "Select Gender", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={[
                                                    { label: "Male", value: "Male" },
                                                    { label: "Female", value: "Female" },
                                                ]}
                                                required={fields.gender === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.companions && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="companions"
                                                placeholder="Companions"
                                                form={form}
                                                required={fields.companions === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.unifiedId && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="unifiedId"
                                                placeholder="Unified Id"
                                                form={form}
                                                required={fields.unifiedId === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                      {fields.tableInfo && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="tableInfo"
                                                placeholder="Table Info"
                                                form={form}
                                                required={fields.tableInfo === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-4 mb-3 d-flex align-items-center justify-content-center"
                                    >
                                        <button
                                            className="formSubmissionButton "
                                            type="submit"
                                            onClick={(e) => {
                                                form.handleSubmit(e);
                                                handleScroll(form.errors);
                                            }}
                                        >
                                            {updateState.isLoading ? "PROCESSING" : "SAVE"}
                                        </button>
                                    </Col>
                                    {!updateState.isLoading && updateState.status === "submitFailed" &&
                                        <Col xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed">
                                            <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                                            Action Failed! Try again.
                                        </Col>}
                                </Row>
                            </Form>
                        )}
                    </Formik>



                }
            </CustomScrollbars>
        </>
    );
};

export default EditAttendee;
