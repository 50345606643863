import React, { useRef, useState, useContext, useEffect, useReducer } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { Col, Row } from "react-bootstrap";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import axios from "../../../../../config/axios";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import { AuthContext } from "../../../../../context/AuthContext";
import CMSFormImage from "../../../../UI/shared/formFields/CMSFormImage";
import CMSFormVideoInput from "../../../../UI/shared/formFields/CMSFormVideoInput";
import CMSFormImageWithCrop from "../../../../UI/shared/formFields/CMSFormImageWithCrop";
const EditMediaGallery = ({ details, handleClose, loadItems }) => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const { userAuthState } = useContext(AuthContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );

    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const addSpeaker = async () => {
            try {
                const res = await axios.post(`update-media-gallery/${details._id}`, data);

                if (res.status === 201) {


                    updateDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                        payload: res.data,
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                        loadItems();
                        handleClose();
                    }, 3000);
                } else {
                    updateDispatch({
                        type: "FORM_SUBMISSION_FAILURE",
                        payload: "error",
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                    }, 2000);
                }

            } catch (error) {
                console.error("There was a problem with the operation:", error);
                updateDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: error,
                });
                setTimeout(() => {
                    updateDispatch({
                        type: "FETCH_SUCCESS",
                    });
                }, 2000);
            }
        }
        if (layout.isServerBusy === true) {
            setTimeout(() => {
                addSpeaker();
            }, 2000);
        } else {
            addSpeaker();
        }

    };

    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });

    }, [])

    let imageRequirement = {
        dimension: {
            width: 600,
            height: 600,
        },
        allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
        size: 1024 * 1024 * 2,
    };
    let videoRequirement = {
        allowedExtensions: ["video/mp4", "video/quicktime", "video/x-msvideo"],
        size: 1024 * 1024 * 20,
    };
    return (
        <>

            <>
                {updateState.isSubmitted ?
                    <div className="successMessage">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>Document Updated Successfully</h2>

                    </div> :
                    <Formik

                        initialValues={{
                            activityId: userAuthState.user && userAuthState.user.activityId,
                            type: details.type ? details.type : "image",
                            title: details.title ? details.title : "",
                            file: details.type === "video" ? details.video : details.type === "image" ? details.image : "",
                            status: details.status ? details.status : "1",
                        }}
                        validationSchema={yup.object({
                            type: yup.string().required("Required"),
                            title: yup.string().required("Required"),
                            file: yup.mixed()
                                .required("Required")
                                .test('file-type', 'Invalid file type', value => {
                                    if (!value || typeof value === 'string') {
                                        return true;
                                    }
                                    let isValid = false;
                                    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                                    const validVideoTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo'];

                                    if (validImageTypes.includes(value.type)) {
                                        isValid = true;
                                    } else if (validVideoTypes.includes(value.type)) {
                                        isValid = true; // For now, we assume all video types pass
                                    }

                                    return isValid;
                                })
                                .test('image-size', 'File size should not exceed 2 MB', value => {
                                    if (!value || typeof value === 'string') {
                                        return true;
                                    }
                                    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                                    if (validImageTypes.includes(value?.type)) {
                                        return value.size <= imageRequirement.size;
                                    }
                                    return true;
                                })
                                .test('video-duration', 'File length should not exceed 30 seconds', async value => {
                                    if (!value || !value.type || !value.type.startsWith('video/') || typeof value === 'string') return true; // No video file provided or not a video
                                    const url = URL.createObjectURL(value);
                                    const videoElement = document.createElement('video');
                                    videoElement.src = url;

                                    return new Promise(resolve => {
                                        videoElement.onloadedmetadata = () => {
                                            const duration = Math.floor(videoElement.duration);
                                            URL.revokeObjectURL(url);
                                            resolve(duration <= 30); // Assuming duration is in seconds
                                        };
                                    });
                                })
                                .test('video-size', 'File size should not exceed 20 MB', value => {
                                    if (!value || !value.type || !value.type.startsWith('video/') || typeof value === 'string') return true; // No file provided
                                    if (value.type && value.type.startsWith('video/')) {
                                        return value.size <= videoRequirement.size;
                                    }
                                    return true;
                                }),
                            status: yup.string().required("Required"),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values, { resetForm }) => {


                            const formData = new FormData();
                            for (let name in values) {
                                if (Array.isArray(values[name])) {
                                    formData.append(
                                        name,
                                        JSON.stringify(values[name])
                                    );
                                } else {
                                    formData.append(name, values[name]);
                                }
                            }

                            onSubmit(formData, { resetForm });
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                                <Row
                                    className={`notesFormRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`}
                                >
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="title"
                                            placeholder="Title"
                                            form={form}
                                            required={true}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="type"
                                            placeholder={{ label: "Select Type", value: "" }}
                                            form={form}
                                            required={true}
                                            onChange={form.handleChange}
                                            options={[
                                                { label: "Image", value: "image" },
                                                { label: "Video", value: "video" },
                                            ]}
                                        />

                                    </Col>


                                    {(form.values.type === 'image') && <>
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormImageWithCrop
                                                placeholder="Image"
                                                name="file"
                                                required={true}
                                                form={form}
                                            />
                                        </Col>
                                    </>}


                                    {(form.values.type === 'video') &&
                                        <>
                                            <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                <CMSFormVideoInput
                                                    placeholder="Video"
                                                    name="file"
                                                    required={true}
                                                    form={form}
                                                />
                                            </Col>

                                        </>}
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="status"
                                            placeholder={{ label: "Select Status", value: "" }}
                                            form={form}
                                            required={true}
                                            onChange={form.handleChange}
                                            options={[
                                                { label: "Active", value: "1" },
                                                { label: "Archived", value: "0" },
                                            ]}
                                        />
                                    </Col>
                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-3 mb-3 d-flex align-items-center justify-content-center"
                                    >


                                        <button
                                            className="formSubmissionButton dark "
                                            type="submit"
                                            onClick={(e) => {
                                                form.handleSubmit(e);
                                            }}
                                        >
                                            SAVE
                                        </button>
                                    </Col>

                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed"
                                    >
                                        {!updateState.isLoading &&
                                            updateState.status === "submitFailed" && (
                                                <p className="mb-0">
                                                    <i
                                                        className="fa fa-exclamation-circle me-2"
                                                        aria-hidden="true"
                                                    ></i>
                                                    Action Failed! Try again.
                                                </p>
                                            )}
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>}
            </>




        </>
    );
};

export default EditMediaGallery;
