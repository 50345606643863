import React, { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AttendeeContext } from '../../../../../../context/AttendeeContext';
import { FiguresContext } from '../../../../../../context/FiguresContext';
import CustomScrollbars from '../../../../../UI/shared/customScrollbar/CustomScrollbar';
import { TotalAttendeesByDayBreakout, headerItems } from '../../../../../../helper/sideHeaderHelper';
import { HomeScreenContext } from '../../../../../../context/HomeScreenContext';
import { CombinedContext } from '../../../../../../context/CombinedContext';
const OverviewDayDetails = ({ day }) => {
    const [show, setShow] = useState(false);
    let activeDay = localStorage.getItem("figuresActiveDay");
    let activeBreakout = localStorage.getItem("figuresActiveBreakout");
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { combined, setCombined } = useContext(CombinedContext);
    function groupByDayAndTitle(data) {
        const groupedData = {};

        data.forEach(item => {
            // Check if the day already exists in the groupedData object
            if (!groupedData[item.day]) {
                groupedData[item.day] = {};
            }

            // Find or add the title to the day's group
            let titlesGroup = groupedData[item.day];
            if (!titlesGroup[item.title]) {
                titlesGroup[item.title] = [];
            }

            // Add the item to the corresponding title group
            titlesGroup[item.title].push(item);
        });

        return groupedData;
    }

    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)

    return (
        <>
            <CustomScrollbars>
                <Col>
                    <div className="overViewDetailsRight">
                        {eventData && eventData.eventDays && eventData.eventDays.length > 1 && eventData.eventDays.map((day, i) => {
                            if (parseInt(day.day) === parseInt(activeDay)) return (
                                <>
                                    <div class="block plenary">
                                        <div class="block_grid">
                                            <div class="sub_block left" onClick={() => {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    leftSideMenu: true,
                                                    combinedDetailBack:"figuresDetails",
                                                    leftFullDisplay: true,
                                                    activePage: "combinedDetails",
                                                    activePageItems: headerItems("combinedDetails")
                                                }))
                                                setCombined((prevState) => ({
                                                    ...prevState,
                                                    day: day.day,
                                                    breakout: null,
                                                    activeSession: day.title,
                                                    graphPartSelection: "Registered",
                                                    typeSelection: "dayBreakout",
                                                }));
                                            }}>
                                                <div class="title">Registered</div>

                                                <div class="number ">{TotalAttendeesByDayBreakout("Yes", undefined, "Registered", day.day, null)}</div>
                                            </div>

                                            <div class="sub_block middle" onClick={() => {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    leftSideMenu: true,
                                                    combinedDetailBack:"figuresDetails",
                                                    leftFullDisplay: true,
                                                    activePage: "combinedDetails",
                                                    activePageItems: headerItems("combinedDetails")
                                                }))
                                                setCombined((prevState) => ({
                                                    ...prevState,
                                                    day: day.day,
                                                    breakout: null,
                                                    activeSession: day.title,
                                                    graphPartSelection: undefined,
                                                    typeSelection: "dayBreakout",
                                                }));
                                            }}>
                                                <div class="title">Plenary</div>

                                                <div class="number ">{TotalAttendeesByDayBreakout("Yes", undefined, undefined, day.day, null)}</div>
                                            </div>

                                            <div class="sub_block right" onClick={() => {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    leftSideMenu: true,
                                                    leftFullDisplay: true,
                                                    combinedDetailBack:"figuresDetails",
                                                    activePage: "combinedDetails",
                                                    activePageItems: headerItems("combinedDetails")
                                                }))
                                                setCombined((prevState) => ({
                                                    ...prevState,
                                                    day: day.day,
                                                    breakout: null,
                                                    activeSession: day.title,
                                                    graphPartSelection: "Walkin",
                                                    typeSelection: "dayBreakout",
                                                }));
                                            }}>
                                                <div class="title">Walk-in</div>

                                                <div class="number ">{TotalAttendeesByDayBreakout("Yes", undefined, "Walkin", day.day, null)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {Object.entries(breakoutByGroup).map(([day, titles]) => (
                                        <>

                                            {parseInt(activeDay) === parseInt(day) &&
                                                <>

                                                    <div key={day}>
                                                        {Object.entries(titles).map(([title, items]) => (
                                                            <>

                                                                {items.map((session, sessionIndex) => (
                                                                    <div class="block breakout">
                                                                        <div class="block_grid">
                                                                            <div class="sub_block left" onClick={() => {
                                                                                setLayout((prevState) => ({
                                                                                    ...prevState,
                                                                                    leftSideMenu: true,
                                                                                    leftFullDisplay: true,
                                                                                    combinedDetailBack:"figuresDetails",
                                                                                    activePage: "combinedDetails",
                                                                                    activePageItems: headerItems("combinedDetails")
                                                                                }))
                                                                                setCombined((prevState) => ({
                                                                                    ...prevState,
                                                                                    day: session.day,
                                                                                    breakout: session._id,
                                                                                    activeSession: session.sessionTitle,
                                                                                    graphPartSelection: "Registered",
                                                                                    typeSelection: "dayBreakout",
                                                                                }));
                                                                            }} >
                                                                                <div class="title">Registered</div>

                                                                                <div class="number ">{TotalAttendeesByDayBreakout("Yes", undefined, "Registered", day, session._id)}</div>
                                                                            </div>

                                                                            <div class="sub_block middle" onClick={() => {
                                                                                setLayout((prevState) => ({
                                                                                    ...prevState,
                                                                                    leftSideMenu: true,
                                                                                    leftFullDisplay: true,
                                                                                    combinedDetailBack:"figuresDetails",
                                                                                    activePage: "combinedDetails",
                                                                                    activePageItems: headerItems("combinedDetails")
                                                                                }))
                                                                                setCombined((prevState) => ({
                                                                                    ...prevState,
                                                                                    day: session.day,
                                                                                    breakout: session._id,
                                                                                    activeSession: session.sessionTitle,
                                                                                    graphPartSelection: undefined,
                                                                                    typeSelection: "dayBreakout",
                                                                                }));
                                                                            }} >
                                                                                <div class="title">{session.sessionTitle}</div>

                                                                                <div class="number ">{TotalAttendeesByDayBreakout("Yes", undefined, undefined, day, session._id)}</div>
                                                                            </div>

                                                                            <div class="sub_block right" onClick={() => {
                                                                                setLayout((prevState) => ({
                                                                                    ...prevState,
                                                                                    leftSideMenu: true,
                                                                                    combinedDetailBack:"figuresDetails",
                                                                                    leftFullDisplay: true,
                                                                                    activePage: "combinedDetails",
                                                                                    activePageItems: headerItems("combinedDetails")
                                                                                }))
                                                                                setCombined((prevState) => ({
                                                                                    ...prevState,
                                                                                    day: session.day,
                                                                                    breakout: session._id,
                                                                                    activeSession: session.sessionTitle,
                                                                                    graphPartSelection: "Walkin",
                                                                                    typeSelection: "dayBreakout",
                                                                                }));
                                                                            }}>
                                                                                <div class="title">Walk-in</div>

                                                                                <div class="number ">{TotalAttendeesByDayBreakout("Yes", undefined, "Walkin", day, session._id)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>

                                                        ))}
                                                    </div>
                                                </>
                                            }
                                        </>
                                    ))}
                                </>
                            )

                        })}


                    </div>
                </Col>




            </CustomScrollbars>
        </>
    )
}

export default OverviewDayDetails