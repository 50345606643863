import React, { createContext, useState, useContext, useEffect } from 'react';
import { HomeScreenContext } from './HomeScreenContext';
export const SpeakerContext = createContext({});
const SpeakerContextProvider = (props) => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [speaker, setSpeaker] = useState({
        searchTerm: "",
        filters: {},
        reset: false,
        listSort: undefined,
        allSpeakers: [],
        activeSpeaker: null,
        editSpeaker: null,
        template: "Column",
        speakerSettings: null,
    })
    useEffect(() => {
        if ((layout.activePage === "speakerDetails") || (layout.activePage === "speakers") || (layout.activePage === "editSpeaker") || (layout.activePage === "addSpeaker")) {
            setSpeaker((prevState) => ({
                ...prevState,
                filters: speaker.filters,
                searchTerm: speaker.searchTerm,
            }));
        }
        else {
            setSpeaker((prevState) => ({
                ...prevState,
                filters: {},
                searchTerm: "",
            }));
        }
    }, [layout.activePage])
    return (
        <SpeakerContext.Provider value={{ speaker, setSpeaker }}>
            {props.children}
        </SpeakerContext.Provider>
    );
};
export default SpeakerContextProvider;

