import React, { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AttendeeContext } from '../../../../../context/AttendeeContext';
import { titleOfBreakout, titleOfDay } from '../../../../../helper/commonHelper';
import { FiguresContext } from '../../../../../context/FiguresContext';
const TopGraySection = ({ dayOverview }) => {
  const [show, setShow] = useState(false);
  let activeDay = localStorage.getItem("figuresActiveDay");
  let activeBreakout = localStorage.getItem("figuresActiveBreakout");
  const { attendee, setAttendee } = useContext(AttendeeContext);
  const { figures, setFigures } = useContext(FiguresContext);
  function groupByDayAndTitle(data) {
    const groupedData = {};

    data.forEach(item => {
      // Check if the day already exists in the groupedData object
      if (!groupedData[item.day]) {
        groupedData[item.day] = {};
      }

      // Find or add the title to the day's group
      let titlesGroup = groupedData[item.day];
      if (!titlesGroup[item.title]) {
        titlesGroup[item.title] = [];
      }

      // Add the item to the corresponding title group
      titlesGroup[item.title].push(item);
    });

    return groupedData;
  }

  const eventData = JSON.parse(localStorage.getItem("eventData"));
  const breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)
  return (
    <>
      <Row className='infoRow figuresSection'>
        <Col xl={9} lg={9} md={9} sm={9} xs={9} >
          <div className='daysAndBreakoutValue'>
            {figures.activeBreakout === `overview-${figures.activeDay}` ? <p>{titleOfDay(activeDay)}<span className='ms-1'>(Overview)</span></p> : (figures.activeBreakout != null) ? <p>{titleOfBreakout(activeBreakout)}<span className='ms-1'>({titleOfDay(activeDay)})</span></p> : <p>{titleOfDay(activeDay)} {breakoutByGroup[activeDay] && <span className='ms-1'>(Plenary)</span>}</p>}
          </div>
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
          <div className='daysAndBreakout'>
            {(eventData && eventData.eventDays && eventData.eventDays.length > 1 || breakoutByGroup[1]) &&
              <button title='Click to select session' className='btn-clickexpand selectDayBreakoutIcon' onClick={() => setShow(!show)} />}


          </div>
        </Col>
    
        <div className={`selectionList ${show ? "selectionListDisplay" : "selectionListHide"}`}>
          <Row className='w-100 mx-auto'>
            {eventData && eventData.eventDays && eventData.eventDays.length > 0 && eventData.eventDays.map((day, i) => {
              return (
                <>

                  <Col key={i} xl={4} lg={5} md={6} className='mb-3'>
                    <div className={`${figures.activeBreakout != null ? "hideInnerColor" : ""} ${parseInt(figures.activeDay) === parseInt(day.day) ? "active" : ""} customRadioHolder big`} onClick={(e) => {
                      setFigures((prevState) => ({
                        ...prevState,
                        activeDay: parseInt(day.day),
                        activeBreakout: null,
                      }))
                      localStorage.setItem("figuresActiveDay", day.day);
                      localStorage.setItem("figuresActiveBreakout", null);
                    }}>
                      <div className={`${parseInt(figures.activeDay) === parseInt(day.day) ? "active" : ""} customRadioButton`}>
                        {parseInt(figures.activeDay) === parseInt(day.day) &&
                          <div className="customRadioButtonActive">
                          </div>}
                      </div>
                      <p className="customRadioLabel mb-0">{day.title}</p>
                    </div>

                  </Col >

                </>
              )

            })}

            {Object.entries(breakoutByGroup).map(([day, titles]) => (
              <>

                {parseInt(activeDay) === parseInt(day) &&
                  <>
                    {dayOverview != false &&
                      <div>
                        <div className="alertBoxLight pb-2 mt-0 mx-4 ">

                          <div className="">
                            <Col xl={12} lg={12} md={12} className='ms-xl-2 ms-md-2 ms-2'>
                              <div className={`${figures.activeBreakout === `overview-${figures.activeDay}` ? "active" : ""} customRadioHolder`} onClick={(e) => {
                                setFigures((prevState) => ({
                                  ...prevState,
                                  activeBreakout: `overview-${figures.activeDay}`,
                                }))
                                localStorage.setItem("figuresActiveBreakout", `overview-${figures.activeDay}`);
                              }}>
                                <div className={`${figures.activeBreakout === `overview-${figures.activeDay}` ? "active" : ""} customRadioButton`}>
                                  {figures.activeBreakout === `overview-${figures.activeDay}` &&
                                    <div className="customRadioButtonActive">
                                    </div>}
                                </div>
                                <p className="customRadioLabel mb-0">Day Overview</p>
                              </div>

                            </Col >
                          </div>

                        </div>
                      </div>}
                    <div key={day}>
                      {Object.entries(titles).map(([title, items]) => (
                        <div className="alertBoxLight mt-1 mx-4">
                          <div className='alertHeadLight pb-2 mb-1'>
                            {title}
                          </div>
                          {items.map((session, sessionIndex) => (
                            <div key={sessionIndex} className="mt-2">
                              <Col xl={12} lg={12} md={12} className='ms-xl-4 ms-md-2 ms-2'>
                                <div className={`${figures.activeBreakout === session._id ? "active" : ""} customRadioHolder`} onClick={(e) => {
                                  setFigures((prevState) => ({
                                    ...prevState,
                                    activeBreakout: session._id,
                                  }))
                                  localStorage.setItem("figuresActiveBreakout", session._id);
                                }}>
                                  <div className={`${figures.activeBreakout === session._id ? "active" : ""} customRadioButton`}>
                                    {figures.activeBreakout === session._id &&
                                      <div className="customRadioButtonActive">
                                      </div>}
                                  </div>
                                  <p className="customRadioLabel mb-0">{session.sessionTitle}</p>
                                </div>

                              </Col >
                            </div>
                          ))}
                        </div>

                      ))}
                    </div>
                  </>
                }
              </>
            ))}
            {/* {eventData && eventData.eventBreakouts && eventData.eventBreakouts.map((breakout, i) => {
                            if (parseInt(activeDay) === breakout.day) return (
                                <div className="mt-2">

                                    <Col key={i} xl={12} lg={12} md={12} className='ms-4'>
                                        <div className={`${attendee.activeBreakout=== breakout._id ? "active" : ""} customRadioHolder`} onClick={(e) => {
                                            setAttendee((prevState) => ({
                                                ...prevState,
                                                activeBreakout: breakout._id,
                                            }))
                                            localStorage.setItem("activeBreakout", breakout._id);
                                        }}>
                                            <div className={`${attendee.activeBreakout === breakout._id ? "active" : ""} customRadioButton`}>
                                                {attendee.activeBreakout === breakout._id &&
                                                    <div className="customRadioButtonActive">
                                                    </div>}
                                            </div>
                                            <p className="customRadioLabel mb-0">{breakout.title}</p>
                                        </div>

                                    </Col >

                                </div>
                            )
                        })} */}
          </Row>
        </div>


      </Row>
    </>
  )
}

export default TopGraySection