import React, { useState, useEffect, useReducer, useContext } from "react";
import { CloseButton, Col, Modal, Row } from "react-bootstrap";
import "./featuresConfiguration.scss";
import { bottomSwiperItems, bottomSwiperItemsAll } from "../../../../../helper/sideHeaderHelper";
import CMSFormCheckBox from "../../../../UI/shared/formFields/CMSFormCheckBox";
import {
  updateInitialState,
  updateReducer,
} from "../../../../../reducers/updateReducer";
import axios from "../../../../../config/axios";
import { AuthContext } from "../../../../../context/AuthContext";
import { Field, Form, Formik } from "formik";
import CMSFormCustomCheckBox from "../../../../UI/shared/formFields/CMSFormCustomCheckBox";
import FeatureToggle from "./FeatureToggle";
import { checkFeature } from "../../../../../helper/commonHelper";
const FeaturesConfiguration = ({
  show,
  handleClose,
  handleShow,
  className,
}) => {
  const [state, setState] = useState({
    featureType: "all",
  });

  const { userAuthState } = useContext(AuthContext);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const features = bottomSwiperItemsAll();
  function formatString(str) {
    let words = str.split(/(?=[A-Z])/);
    let formattedStr =
      words[0].charAt(0).toUpperCase() +
      words[0].slice(1) +
      " " +
      words.slice(1).join("");
    return formattedStr;
  }
  const onSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    delete data.icon;
    delete data.mode;
    delete data.name;
    delete data.pageName;
    data = { ...data, status: (data.status === true || data.status === "1") ? "1" : "0", isAllowedInBasic: (data.isAllowedInBasic === true || data.isAllowedInBasic === "1") ? "1" : "0" }


    try {
      const res = await axios.post(
        `update-features/${userAuthState.user && userAuthState.user.activityId
        }`,
        data
      );

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      console.error("There was a problem with the delete operation:", error);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 2000);
    }
  };
  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
    });
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className={"featuresConfigurationModal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="closeIconRow">
          <div className="featuresType">
            <p
              className={state.featureType === "all" ? "active" : ""}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  featureType: "all",
                }))
              }
            >
              All
            </p>
            {features && features.filter(feature => "Global" === feature.featureType).length > 0 &&
              <p
                className={state.featureType === "Global" ? "active" : ""}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    featureType: "Global",
                  }))
                }
              >
                Global Features{" "}
              </p>}
            {features && features.filter(feature => "OSMA Only" === feature.featureType).length > 0 &&
              <p
                className={state.featureType === "OSMA Only" ? "active" : ""}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    featureType: "OSMA Only",
                  }))
                }
              >
                OSMA Features
              </p>}
          </div>
          <i
            className="fa fa-times closeIcon"
            title="Click to close"
            onClick={handleClose}
            aria-hidden="true"
          ></i>
        </div>
        <div className="featuresListing">
          {features &&
            features.map((feature, index) => {
              if (
                (state.featureType === "all" ||
                  state.featureType === feature.featureType) && checkFeature(feature.feature)
              )
                return (
                  <div className={"featureItem"} key={feature.name}>
                    <div className="left">
                      <div className="checkBoxHolder">

                        <div class="control-group">
                          <label class="control control-checkbox">

                            <input type="checkbox" defaultChecked={feature.status === "1"} onChange={(e) => onSubmit({ ...feature, status: e.target.checked })} />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                      </div>
                      <div className="featureDetail">
                        <i className={`${feature.icon} icon `}></i>
                        <p className="mb-0">{formatString(feature.name)}</p>
                      </div>
                    </div>
                    <FeatureToggle onSubmit={onSubmit} feature={feature} />

                    {/* <div class="setting_select_app_mode">
                      <p className={feature.isAllowedInBasic === "0" ? "active mb-0" : "mb-0"}>Advanced</p>
                      <label class="switch">
                        <input
                          type="checkbox"
                          defaultChecked={feature.isAllowedInBasic === "1"}
                          onChange={(e) => onSubmit({ ...feature, isAllowedInBasic: e.target.checked })}
                          class="ng-pristine ng-untouched ng-valid ng-empty"
                        />
                        <span class="slider round"></span>
                      </label>
                      <p className={feature.isAllowedInBasic === "1" ? "active mb-0" : "mb-0"}>Basic</p>
                    </div> */}
                  </div>
                );
            })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeaturesConfiguration;
