import React, { useState, useContext, useEffect, useReducer } from "react";
import TopGraySectionEmpty from "../figures/TopGraySectionEmpty";
import Gallery from "../../../../UI/shared/fancybox/Fancybox";
import { Button, Col, Row } from "react-bootstrap";
import "./gallery.scss";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { headerItems } from "../../../../../helper/sideHeaderHelper";
import {
    updateInitialState,
    updateReducer,
} from "../../../../../reducers/updateReducer";
import ModalBody from "../../../../UI/shared/modal/ModalBody";
import {
    listingInitialState,
    listingReducer,
} from "../../../../../reducers/listingReducer";
import DeleteDownload from "../downloads/DeleteDownload";
import { AuthContext } from "../../../../../context/AuthContext";
import axios from "../../../../../config/axios";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import Loading from "../../../../UI/shared/loading/Loading";
import EditMediaGallery from "./EditMediaGallery";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const MediaGallery = () => {
    const [screen, setScreen] = useState({ showActions: false });
    const { setLayout } = useContext(HomeScreenContext);
    const { userAuthState } = useContext(AuthContext);
    const [show, setShow] = useState({
        editModel: false,
        editDetails: null,
        deleteId: null,
        deleteShow: false,
        filter: "",
    });

    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const [deleteState, deleteDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );

    const handleClose = () =>
        setShow((prevState) => ({
            ...prevState,
            editModel: false,
        }));
    const handleShow = (item) =>
        setShow((prevState) => ({
            ...prevState,
            editModel: true,
            editDetails: item,
        }));
    const handleDeleteClose = () =>
        setShow((prevState) => ({
            ...prevState,
            deleteShow: false,
        }));
    const handleDeleteShow = (id) =>
        setShow((prevState) => ({
            ...prevState,
            deleteShow: true,
            deleteId: id,
        }));

    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );

    const loadItems = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });

        try {
            const res = await axios.get(
                `get-media-gallery/${userAuthState.user && userAuthState.user.activityId
                }`
            );

            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };

    const handleDelete = async (id) => {
        deleteDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.delete(`media-gallery/${id}`);

            deleteDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            loadItems();
        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            deleteDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                deleteDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };
    useEffect(() => {
        loadItems();
        updateDispatch({
            type: "FETCH_SUCCESS",
        });
        deleteDispatch({
            type: "FETCH_SUCCESS",
        });
    }, []);
    return (
        <>
            {deleteState.isLoading && <Loading />}
            <TopGraySectionEmpty>
                <Row className="galleryTopSection w-100 mx-auto">
                    <Col xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12} className="leftSection p-0">
                        {listingState.data &&
                            listingState.data.items &&
                            listingState.data.items.length > 0 && (
                                <Formik
                                    initialValues={{ filter: show.filter }}
                                    validationSchema={yup.object().shape({
                                        filter: yup.string(),
                                    })}
                                    enableReinitialize={true}
                                    validateOnChange={true}
                                    validateOnBlur={true}
                                    onSubmit={(values) => {
                                        setShow((prevState) => ({
                                            ...prevState,
                                            filter: values.filter,
                                        }));
                                    }}
                                >
                                    {(form) => (
                                        <Form className="formWidth">
                                            <div className={`attendeeFiltersRow`}>
                                                <div className="pe-1 pb-1">
                                                    <CMSFormSelect
                                                        name="filter"
                                                        placeholder={{ label: "All", value: "" }}
                                                        form={form}
                                                        onChange={(e) => {
                                                            form.handleChange(e);
                                                            form.handleSubmit();
                                                        }}
                                                        options={[
                                                            { label: "Image", value: "image" },
                                                            { label: "Video", value: "video" },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                    </Col>

                    <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="rightSection p-0 mobileLeft"
                    >
                        {checkPermission("editMedia") && <>
                            {listingState.data &&
                                listingState.data.items &&
                                listingState.data.items.length > 0 &&
                                <div
                                    className="actionButton"
                                    onClick={() =>
                                        setScreen((prevState) => ({
                                            ...prevState,
                                            showActions: !screen.showActions,
                                        }))
                                    }
                                >
                                    <span className="me-2">
                                        {!screen.showActions ? "Show Actions" : "Hide Actions"}
                                    </span>
                                    {!screen.showActions ? (
                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                    ) : (
                                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                    )}
                                </div>}
                        </>}
                        <div>
                            {checkPermission("addMedia") &&
                                <Button
                                    variant="primaryColor"
                                    onClick={() =>
                                        setLayout((prevState) => ({
                                            ...prevState,
                                            leftSideMenu: true,
                                            activePage: "addMediaGallery",
                                            activePageItems: headerItems("addMediaGallery"),
                                        }))
                                    }
                                    className="rounded-0 buttonText"
                                >
                                    <i className="fa fa-plus-circle" aria-hidden="true"></i> ADD
                                    MEDIA
                                </Button>}
                        </div>
                    </Col>
                </Row>
            </TopGraySectionEmpty>
            <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
                <Row className="w-100 mx-auto py-3 flex-wrap">
                    <Col>
                        <Gallery
                            show={screen.showActions}
                            filter={show.filter}
                            listingState={listingState}
                            handleDeleteShow={handleDeleteShow}
                            handleShow={handleShow}
                        />
                    </Col>
                </Row>
            </CustomScrollbars>
            <ModalBody
                handleClose={handleDeleteClose}
                handleShow={handleDeleteShow}
                heading="Confirm!"
                className="undoCheckInModal"
                show={show.deleteShow}
                CloseButton={true}
                content={
                    <DeleteDownload
                        deleteId={show.deleteId}
                        deleteDownload={handleDelete}
                        handleClose={handleDeleteClose}
                    />
                }
            />
            <ModalBody
                handleClose={handleClose}
                handleShow={handleShow}
                heading="Edit Document"
                show={show.editModel}
                CloseButton={true}
                content={
                    <EditMediaGallery
                        details={show.editDetails}
                        handleClose={handleClose}
                        loadItems={loadItems}
                    />
                }
            />
        </>
    );
};

export default MediaGallery;
