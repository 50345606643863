import React, { useEffect, useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap';

const CMSFormVideoInput = (props) => {
    const { name, label, form, required, helpMessage, ...rest } = props;
    const initialImageURL = form.values[name] ? `${process.env.REACT_APP_BACKEND_URL + '/public/' + form.values[name]}` : null;
    const [source, setSource] = useState(initialImageURL);
    const [fileName, setFileName] = useState(form.values[name] ? form.values[name] : "Add File");
    const handleFileChange = async (e) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setSource(url);
            setFileName(file.name);
            await form.setFieldValue(name, file);

        } else {
            setSource(null);
            setFileName("Add File");
            await form.setFieldValue(name, '', true);
            form.setFieldTouched(name, true);
        }
    };

    return (
        <>


            <div className="form-group">
                <>
                    <div className={`input-group ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}>

                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                id={name}
                                name={name}
                                aria-describedby="inputGroupFileAddon01"
                                accept="video/*"
                                onChange={handleFileChange}
                                onBlur={form.handleBlur}
                            />
                            <label
                                className={` ${form.values[name] != "Add File" ? "pink" : ""} ${form.touched[name] && form.errors[name]
                                    ? "is-invalid custom-file-label"
                                    : "custom-file-label"
                                    }`}
                                htmlFor="inputGroupFile01"
                            >
                                <p className='mb-0'>{fileName}</p>
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </label>
                            {required === true && <div className="required"></div>}
                        </div>
                        {(form.errors[name]) &&
                            <span id={`${name}-error"`} className="errorMessage"  >
                                <ErrorMessage name={name} />
                            </span>
                        }
                    </div>


                </>
            </div>



            {
                source && (
                    <Row className="formImagePreviewRow">
                        <div className="formImagePreview ms-2">
                            <video
                                className="VideoInput_video"
                                width="100%"
                                controls
                                src={source}
                            />
                        </div>
                    </Row>
                )
            }
        </>
    );
};

export default CMSFormVideoInput;
