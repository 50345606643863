import React, { useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { HomeScreenContext } from '../../../../context/HomeScreenContext';

const HomeSwitchButton = () => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    return (
        <div className="homeSwitchHolder">
            <p className={`mb-0 ${layout.swiperDisplayMode === "Basic" ? "active" : ""}`}>Basic Mode</p>

            <Form>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={layout.swiperDisplayMode === "Basic" ? false : true}
                    onChange={() => {
                        setLayout((prevState) => ({
                            ...prevState,
                            swiperDisplayMode: layout.swiperDisplayMode === "Basic" ? "Advanced" : "Basic",
                        })); localStorage.setItem("swiperDisplayMode", JSON.stringify(layout.swiperDisplayMode === "Basic" ? "Advanced" : "Basic"));
                    }}
                />
            </Form>
            <p className={`mb-0 ${layout.swiperDisplayMode === "Advanced" ? "active" : ""}`}>Advanced Mode</p>
        </div>
    )
}

export default HomeSwitchButton