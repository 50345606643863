import React, { useContext, useReducer, useEffect, useState } from 'react'
import Filters from './Filters'
import { listingInitialState, listingReducer } from '../../../../../reducers/listingReducer';
import { AuthContext } from '../../../../../context/AuthContext';
import { Button, Col, Row } from 'react-bootstrap';
import axios from '../../../../../config/axios';
import "./speakers.scss"
import { SpeakerContext } from '../../../../../context/SpeakerContext';
import TopGraySectionEmpty from '../figures/TopGraySectionEmpty';
import { arraySortByProperty, getFilteredAttendeesForFigures } from '../../../../../helper/figuresListHelper';
import { headerItems, searchByName } from '../../../../../helper/sideHeaderHelper';
import dummy from '../../../../../assets/images/dummy.jpg';
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import { updateInitialState, updateReducer } from '../../../../../reducers/updateReducer';
import ModalBody from '../../../../UI/shared/modal/ModalBody';
import DeleteSpeaker from './DeleteSpeaker';
import Loading from '../../../../UI/shared/loading/Loading';
import CustomScrollbars from '../../../../UI/shared/customScrollbar/CustomScrollbar';
import SpeakerDetailsModal from '../agenda/SpeakerDetailsModal';
import { checkPermission } from '../../../../../helper/permissionsHelper';
const Speakers = () => {
    const { userAuthState } = useContext(AuthContext);
    const { setLayout } = useContext(HomeScreenContext);
    const { speaker, setSpeaker } = useContext(SpeakerContext);
    const [listOfSpeakers, setListOfSpeakers] = useState([])
    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );
    const [show, setShow] = useState({
        delete: false,
        deleteId: null,
        speakerModal: false,
        speakerDetails: null,
    });
    const [deleteState, deleteDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const handleDeleteClose = () => setShow((prevState) => ({
        ...prevState,
        delete: false,
    }));
    const handleDeleteShow = (data) => setShow((prevState) => ({
        ...prevState,
        delete: true,
    }));
    const handleClose = () => setShow((prevState) => ({
        ...prevState,
        speakerModal: false,
    }));
    const handleShow = () => setShow((prevState) => ({
        ...prevState,
        speakerModal: true,
    }));
    const loadItems = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });
        try {
            const res = await axios.get(`get-speakers/${userAuthState.user && userAuthState.user.activityId}`);
            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
            setListOfSpeakers(res.data && res.data.item)
            setSpeaker((prevState) => ({
                ...prevState,
                allSpeakers: res.data && res.data.item,
                speakerSettings: res.data && res.data.speakerSettings && res.data.speakerSettings,
                template: res.data && res.data.speakerSettings && res.data.speakerSettings.template
            }));

        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });

        }
    };
    const handleDelete = async (id) => {
        deleteDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });
        try {
            const res = await axios.get(`delete-speaker/${id}`);
            deleteDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            loadItems();

        } catch (error) {
            deleteDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });

        }
    };
    useEffect(() => {
        loadItems();
        deleteDispatch({
            type: "FETCH_SUCCESS",
        });
    }, [])
    useEffect(() => {
        const filteredSpeakers = getFilteredAttendeesForFigures(
            listingState.data && listingState.data.item,
            speaker.filters
        );

        setListOfSpeakers(filteredSpeakers);
        setListOfSpeakers(searchByName((filteredSpeakers), speaker.searchTerm, "speakers"))
    }, [speaker.searchTerm, speaker.filters, listingState.data && listingState.data.item])

    return (
        <>

            {listingState.isLoading && <Loading />}
            {(listingState.data && listingState.data.item && listingState.data.item.length > 0) && <>
                <TopGraySectionEmpty>
                    <Filters />
                </TopGraySectionEmpty>
            </>}

            <Row className="w-100 mx-auto">
                <Col className={`lightGraySort px-3 py-2 ${listingState.data && listingState.data.item && listingState.data.item.length > 0 ? "" : "right"}`}>
                    {listingState.data && listingState.data.item && listOfSpeakers &&
                        <div className="sortingLeft">
                            {arraySortByProperty(listOfSpeakers, speaker.listSort).length > 1 &&
                                <>
                                    <div>
                                        <a
                                            className={`${speaker.listSort === "name+" || speaker.listSort === "name-"
                                                ? "active"
                                                : ""
                                                } sortItem`}
                                            onClick={() => {
                                                setSpeaker((prevState) => ({
                                                    ...prevState,
                                                    listSort:
                                                        speaker.listSort === "name+" ? "name-" : "name+",
                                                }));
                                            }}
                                        >
                                            Name{" "}
                                            {speaker.listSort === "name+" ? (
                                                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                            ) : speaker.listSort === "name-" ? (
                                                <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                            ) : (
                                                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                            )}
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            className={`${speaker.listSort === "company+" ||
                                                speaker.listSort === "company-"
                                                ? "active"
                                                : ""
                                                } sortItem`}
                                            onClick={() => {
                                                setSpeaker((prevState) => ({
                                                    ...prevState,
                                                    listSort:
                                                        speaker.listSort === "company+"
                                                            ? "company-"
                                                            : "company+",
                                                }));
                                            }}
                                        >
                                            Company{" "}
                                            {speaker.listSort === "company+" ? (
                                                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                            ) : speaker.listSort === "company-" ? (
                                                <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                            ) : (
                                                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                            )}
                                        </a>
                                    </div>
                                    <div>
                                        {!speaker.listSort &&
                                            <a
                                                className="sortItem"
                                                onClick={() => {
                                                    setSpeaker((prevState) => ({
                                                        ...prevState,
                                                        listSort: "reverse-",
                                                    }));
                                                }}
                                            >
                                                Reverse Default <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>

                                            </a>}
                                        {speaker.listSort &&
                                            <a
                                                className="sortItem"
                                                onClick={() => {
                                                    setSpeaker((prevState) => ({
                                                        ...prevState,
                                                        listSort: undefined,
                                                    }));
                                                }}
                                            >
                                                Reset Sorting <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </a>}
                                    </div>
                                </>
                            }
                        </div>}

                    {/**
                            
                            
                    <div className="addRight">
                        {checkPermission("addSpeaker") &&
                            <Button variant='primaryColor' onClick={() => setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                activePage: "addSpeaker",
                                activePageItems: headerItems("addSpeaker")
                            }))} className='rounded-0 buttonText'><i className="fa fa-plus-circle" aria-hidden="true"></i> ADD SPEAKER</Button>}
                    </div>        
                            
                            
                             */}



                </Col>
            </Row>

            {listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
                <CustomScrollbars desktopHeight="calc(100vh - 240px)" mobileHeight="calc(100vh - 270px)">
                    <Row className=" p-4 w-100 mx-auto speakersListing">
                        {listOfSpeakers && arraySortByProperty(listOfSpeakers, speaker.listSort).map((item, i) => {
                            if (speaker.template === "Column" && item.firstName) return (
                                <>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                                        <div class="speaker_item enabled_speaker" >
                                            <div class="top_holder ng-scope">
                                                <div class="img_holder round_image" onClick={() => {

                                                    setSpeaker((prevState) => ({
                                                        ...prevState,
                                                        activeSpeaker: item,
                                                        filters: speaker.filters,
                                                        searchTerm: speaker.searchTerm
                                                    }))

                                                    setShow((prevState) => ({
                                                        ...prevState,
                                                        speakerDetails: item,
                                                    }));
                                                    handleShow();


                                                }}>
                                                    <img alt="" class="" src={item.photo ? process.env.REACT_APP_BACKEND_URL + "/public/" + item.photo : dummy} />

                                                </div>
                                                <div class="short_action_buttons">

                                                    {speaker.speakerSettings && speaker.speakerSettings.showEmail === "1" && item.email &&
                                                        <a href={`mailto:${item.email}`}>
                                                            <i class="fa fa-envelope-o mb-1" aria-hidden="true"></i>
                                                        </a>}
                                                    {speaker.speakerSettings && speaker.speakerSettings.showPhone === "1" && item.phone &&
                                                        <a href={`tel:${item.phone}`} class="like">
                                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                                        </a>}
                                                </div>
                                            </div>
                                            <div class="holder_info" onClick={() => {

                                                setSpeaker((prevState) => ({
                                                    ...prevState,
                                                    activeSpeaker: item,
                                                }))

                                                setShow((prevState) => ({
                                                    ...prevState,
                                                    speakerDetails: item,
                                                }));
                                                handleShow();
                                            }}>
                                                <h3 class="holder_name ng-binding">{item.firstName}{" "}{item.lastName}</h3>
                                                <h4 class="holder_designation ng-binding">{item.designation}</h4>
                                                <h5 class="holder_company ng-binding">{item.company}</h5>
                                                {item.peopleGroup &&
                                                    <p class="speaker_types ng-binding" >{item.peopleGroup}</p>}
                                            </div>

                                            <div class="superadmin_action_buttons" >
                                                {/* {checkPermission("editSpeaker") &&
                                                    <span onClick={() => {
                                                        setSpeaker((prevState) => ({
                                                            ...prevState,
                                                            editSpeaker: item,
                                                        }));
                                                        setLayout((prevState) => ({
                                                            ...prevState,
                                                            leftSideMenu: true,
                                                            activePage: "editSpeaker",
                                                            activePageItems: headerItems("editSpeaker")
                                                        }))
                                                    }}>
                                                        <i class="fa fa-pencil"></i>
                                                    </span>} */}
                                                {checkPermission("deleteSpeaker") &&
                                                    <span onClick={() => {
                                                        setShow((prevState) => ({
                                                            ...prevState,
                                                            deleteId: item._id,
                                                        }));
                                                        handleDeleteShow()

                                                    }}>
                                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </span>}
                                            </div>

                                        </div>


                                    </Col>
                                </>
                            )
                            else if (speaker.template === "Row" && item.firstName) return (
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="">
                                    <div class="speaker_item listLayout" >
                                        <div class="top_holder ng-scope">
                                            <div class="img_holder round_image" onClick={() => {

                                                setSpeaker((prevState) => ({
                                                    ...prevState,
                                                    activeSpeaker: item,
                                                    filters: speaker.filters,
                                                    searchTerm: speaker.searchTerm
                                                }))

                                                setShow((prevState) => ({
                                                    ...prevState,
                                                    speakerDetails: item,
                                                }));
                                                handleShow();


                                            }}>
                                                <img alt="" class="" src={item.photo ? process.env.REACT_APP_BACKEND_URL + "/public/" + item.photo : dummy} />

                                            </div>
                                            <div class="holder_info_list" onClick={() => {

                                                setSpeaker((prevState) => ({
                                                    ...prevState,
                                                    activeSpeaker: item,
                                                }))

                                                setShow((prevState) => ({
                                                    ...prevState,
                                                    speakerDetails: item,
                                                }));
                                                handleShow();
                                            }}>
                                                <h3 class="holder_name ng-binding">{item.firstName}{" "}{item.lastName}</h3>
                                                <h4 class="holder_designation ng-binding">{item.designation}</h4>
                                                <h5 class="holder_company ng-binding">{item.company}</h5>
                                                {item.peopleGroup &&
                                                    <p class="speaker_types ng-binding" >{item.peopleGroup}</p>}
                                                <div class="short_action_buttons">
                                                    {speaker.speakerSettings && speaker.speakerSettings.showEmail === "1" && item.email &&
                                                        <a href={`mailto:${item.email}`}>
                                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                        </a>}
                                                    {speaker.speakerSettings && speaker.speakerSettings.showPhone === "1" && item.mobile &&
                                                        <a href={`tel:${item.mobile}`} class="like">
                                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                                        </a>}
                                                </div>
                                            </div>

                                        </div>


                                        <div class="superadmin_action_buttons_list" >
                                            {/* {checkPermission("editSpeaker") &&
                                                <span onClick={() => {
                                                    setSpeaker((prevState) => ({
                                                        ...prevState,
                                                        editSpeaker: item,
                                                    }));
                                                    setLayout((prevState) => ({
                                                        ...prevState,
                                                        leftSideMenu: true,
                                                        activePage: "editSpeaker",
                                                        activePageItems: headerItems("editSpeaker")
                                                    }))
                                                }}>
                                                    <i class="fa fa-pencil"></i>
                                                </span>} */}
                                            {checkPermission("deleteSpeaker") &&
                                                <span onClick={() => {
                                                    setShow((prevState) => ({
                                                        ...prevState,
                                                        deleteId: item._id,
                                                    }));
                                                    handleDeleteShow()

                                                }}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </span>}
                                        </div>

                                    </div>
                                    <div className="line">

                                    </div>


                                </Col>
                            )
                        })}
                        {listOfSpeakers && listOfSpeakers.length <= 0 &&
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="text-center mt-5">
                                <h3>No Records Found!</h3>
                            </Col>
                        }
                    </Row>
                </CustomScrollbars>}
            {(listingState.data && listingState.data.item && listingState.data.item.length <= 0 || listingState.data && listingState.data.item === null) && <>
                <Row className='w-100 mx-auto'>
                    <Col className='agendaHolder'>
                        <h3 className='my-5 text-center pt-5'>No Records Found!</h3>
                    </Col>
                </Row>
            </>}
            <ModalBody
                handleClose={handleDeleteClose}
                handleShow={handleDeleteShow}
                closeButton={false}
                heading="Confirm!"
                className="undoCheckInModal"
                show={show.delete}
                content={<DeleteSpeaker handleClose={handleDeleteClose} deleteSpeaker={() => handleDelete(show.deleteId)} />}
            />

            <ModalBody
                handleClose={handleClose}
                handleShow={handleShow}
                heading="Speaker Details"
                show={show.speakerModal}
                CloseButton={true}
                content={<SpeakerDetailsModal details={show.speakerDetails} mode="max" handleClose={handleClose} />}
            />
        </>
    )
}

export default Speakers