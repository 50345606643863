
export const updateInitialState = {
    data: {},
    isLoading: true,
    status: 'fetching',//fetched,submitting,submitted
    error: "",
    // isFetching: false,
    // isSubmitted: false,
    isSubmitting: false,
    isSubmitted: false,
    // hasError: false,
    // error: ""
};

export const updateReducer = (state, action) => {
    switch (action.type) {


        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
                isSubmitted: false,
                status: 'fetched',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                data: action.payload
            };

        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
                isSubmitted: false,
                status: 'fetchFailed',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: action.payload
            };

        case "FORM_SUBMISSION_REQUEST":
            return {
                ...state,
                isLoading: true,
                isSubmitting: true,

                isSubmitted: false,
                status: 'submitting',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: ""
            };


        case "FORM_SUBMISSION_FAILURE":

            return {
                ...state,
                isLoading: false,
                isSubmitted: false,
                isSubmitting: false,
                status: 'submitFailed',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: action.payload
            };


        case "FORM_SUBMISSION_SUCCESS":
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
                isSubmitting: false,
                isSubmitted: true,
                status: 'submitted',
            };

        case "FORM_INITIAL_STATE":
            return {

                ...state,
                isLoading: false,
                isSubmitted: false,
                isSubmitting: false,
                status: '',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: ""
            };
        default:
            return state;
    }
};