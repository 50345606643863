import React, { useState, useEffect, useReducer, useContext } from "react";
import { CloseButton, Col, Modal, Row } from "react-bootstrap";
import {
  updateInitialState,
  updateReducer,
} from "../../../../../reducers/updateReducer";
import axios from "../../../../../config/axios";
import { AuthContext } from "../../../../../context/AuthContext";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
const UpdateLoginDetails = ({
  show,
  details,
  handleClose,
  loadCred,
  handleShow,
  className,
}) => {

  const { userAuthState } = useContext(AuthContext);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );

  const onSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `update-credentials`,
        data
      );

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
        handleClose();
        loadCred();
      }, 4000);
    } catch (error) {
      console.log("There was a problem with the delete operation:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("API Response Error:", error.response.data);
        console.log("API Response Status:", error.response.status);
        console.log("API Response Headers:", error.response.headers);
        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: error.response.data, // Dispatch the error response data
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received:", error.request);
        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: "No response received.",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Request setup error:", error.message);
        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: "Request setup error.",
        });
      }
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 4000);
    }
  };
  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
    });
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className={"UpdateLoginDetailsModal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="modal-title"
        >
          Update Login User Details

        </Modal.Title>

        <div className="closeIconRow">

          <i
            className="fa fa-times closeIcon"
            title="Click to close"
            onClick={handleClose}
            aria-hidden="true"
          ></i>
        </div>
      </Modal.Header>
      <Modal.Body>
        {updateState.isSubmitted ?
          <div className="successMessage my-5">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            <h2>Details Updated Successfully</h2>
          </div> :
          <Row className={`${updateState.isLoading ? "loading" : ""}`}>
            <Col>
              <div class="topDetails">
                {details.name &&
                  <div className="detail mt-2">
                    <p>Name: <span className="subDetail">{details.name} - {details.type}</span></p>
                  </div>}
                {details.email &&
                  <div className="detail mt-2">
                    <p>Email: <span className="subDetail">{details.email} </span></p>
                  </div>}
              </div>

              <Formik

                initialValues={{
                  userName: details.userName ? details.userName : "",
                  password: details.password ? details.password : "",
                }}
                validationSchema={yup.object({
                  userName: yup.string().required("Required"),
                  password: yup.string().required("Required"),
                })}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { resetForm }) => {
                  const data = { details }
                  console.log({ ...details, userName: values.userName, password: values.password })

                  onSubmit({ ...details, userName: values.userName, password: values.password }, { resetForm });
                }}
              >
                {(form) => (
                  <Form onSubmit={form.handleSubmit}>
                    <Row
                      className={`notesFormRow mt-4 mx-auto w-100`}
                    >
                      <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                        <CMSFormInput
                          name="userName"
                          placeholder="Username"
                          form={form}
                          required={true}
                        />
                      </Col>

                      <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                        <CMSFormInput
                          name="password"
                          placeholder="Password"
                          form={form}
                          required={true}
                        />
                      </Col>




                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mt-3 mb-3 d-flex align-items-center justify-content-center"
                      >


                        <button
                          className="formSubmissionButton dark "
                          type="submit"
                          onClick={(e) => {
                            form.handleSubmit(e);
                          }}
                        >
                          SAVE
                        </button>
                      </Col>

                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed"
                      >
                        {!updateState.isLoading &&
                          updateState.status === "submitFailed" && (
                            <p className="mb-0 text-center">
                              <i
                                className="fa fa-exclamation-circle me-2"
                                aria-hidden="true"
                              ></i>
                              Action Failed! Try again.
                              {console.log(updateState.error)} <br />
                              {updateState.error && updateState.error.message}
                            </p>
                          )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>}


      </Modal.Body>
    </Modal>
  );
};

export default UpdateLoginDetails;
