import React, { useRef, useState, useContext, useEffect, useReducer } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { Col, Row } from "react-bootstrap";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import { headerItems } from "../../../../../helper/sideHeaderHelper";
import axios from "../../../../../config/axios";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { AuthContext } from "../../../../../context/AuthContext";
import CMSFormTextArea from "../../../../UI/shared/formFields/CMSFormTextArea";
import CMSFormImage from "../../../../UI/shared/formFields/CMSFormImage";
import { SpeakerContext } from "../../../../../context/SpeakerContext";
import CMSFormImageWithCrop from "../../../../UI/shared/formFields/CMSFormImageWithCrop";
const EditSpeaker = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const { userAuthState } = useContext(AuthContext);
    const { speaker, setSpeaker } = useContext(SpeakerContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const fields = eventData && eventData.formFields;
    const myRef = useRef(null);
    const speakerEditing = speaker.editSpeaker;
    const [formInitialData, setFormInitialData] = useState(null);
    const countries = eventData && eventData.countries ? eventData.countries : [];
    const nationalities = eventData && eventData.nationalities ? eventData.nationalities : [];
    const executeScroll = () => myRef.current.scrollIntoView();
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const clientId = eventData.clientId
    const handleScroll = (error) => {

        if (Object.keys(error).length > 0) {
            const element = document.getElementsByName(Object.keys(error)[0])[0];

            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 20);
        }
    };

    const printBadge = eventData.badge && eventData.badge.printBadge
    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const addSpeaker = async () => {
            try {
                const res = await axios.post(`update-speaker/${speakerEditing._id}`, data);

                if (res.status === 201) {
                    setSpeaker((prevState) => ({
                        ...prevState,
                        reset: true,
                    }))

                    updateDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                        payload: res.data,
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                        setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            activePage: "speakers",
                            activePageItems: headerItems("speakers")
                        }))

                    }, 4000);
                } else {
                    updateDispatch({
                        type: "FORM_SUBMISSION_FAILURE",
                        payload: "error",
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                    }, 2000);
                }

            } catch (error) {
                console.error("There was a problem with the operation:", error);
                updateDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: error,
                });
                setTimeout(() => {
                    updateDispatch({
                        type: "FETCH_SUCCESS",
                    });
                }, 2000);
            }
        }
        if (layout.isServerBusy === true) {
            setTimeout(() => {
                addSpeaker();
            }, 2000);
        } else {
            addSpeaker();
        }

    };

    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });

    }, [])

    const stripHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };
    return (
        <>
            <GrayHeader />
            <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
                {updateState.isSubmitted ?
                    <div className="successMessage">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>Speaker Updated Successfully</h2>

                    </div> :
                    <Formik
                        initialValues={{
                            clientId: clientId,
                            peopleGroup: speakerEditing.peopleGroup ? speakerEditing.peopleGroup : "",
                            firstName: speakerEditing.firstName,
                            lastName: speakerEditing.lastName,
                            company: speakerEditing.company,
                            designation: speakerEditing.designation,
                            email: speakerEditing.email,
                            phone: speakerEditing.phone,
                            country: speakerEditing.country,
                            nationality: speakerEditing.nationality,
                            introduction: speakerEditing.introduction ? stripHtml(speakerEditing.introduction) : "",
                            twitterProfile: speakerEditing.twitterProfile ? speakerEditing.twitterProfile : "",
                            instagramProfile: speakerEditing.instagramProfile ? speakerEditing.instagramProfile : "",
                            linkedinProfile: speakerEditing.linkedinProfile ? speakerEditing.linkedinProfile : "",
                            facebookProfile: speakerEditing.facebookProfile ? speakerEditing.facebookProfile : "",
                            photo: speakerEditing.photo ? speakerEditing.photo : "",
                            status: speakerEditing.status ? speakerEditing.status : "1",
                            sortOrder: speakerEditing.sortOrder ? speakerEditing.sortOrder : "99",
                        }}
                        validationSchema={yup.object().shape({

                            peopleGroup: yup.string(),
                            firstName: yup.string().required("Enter First Name"),
                            lastName: yup.string().required("Enter Last Name"),
                            company: yup.string().required("Enter company"),
                            designation: yup.string(),
                            email: yup.string().email("Enter valid email"),
                            phone: yup.string(),
                            country: yup.string(),
                            nationality: yup.string(),
                            introduction: yup.string(),
                            twitterProfile: yup.string().url("Enter valid URL"),
                            instagramProfile: yup.string().url("Enter valid URL"),
                            linkedinProfile: yup.string().url("Enter valid URL"),
                            facebookProfile: yup.string().url("Enter valid URL"),
                            photo: yup
                                .mixed()
                                .test(
                                    "mime",
                                    `Please upload file in "png","jpg" or "jpeg" format only.`,
                                    (value) => {
                                   
                                        return value && value.type
                                            ? value.type === "image/jpeg" ||
                                                value.type === "image/jpg" ||
                                                value.type === "image/png"
                                                ? true
                                                : false
                                            : true;
                                    }
                                )
                                .test(
                                    "size",
                                    "Uploaded file is too big (2MB maximum are allowed).",
                                    (value) => {
                                      
                                        return value && value.size ? (value.size < 1024 * 1024 * 2 ? true : false) : true;
                                    }
                                ),
                            status: yup.string(),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values) => {
                            const formData = new FormData();
                            for (let name in values) {
                                if (Array.isArray(values[name])) {
                                    formData.append(
                                        name,
                                        JSON.stringify(values[name])
                                    );
                                } else {
                                    formData.append(name, values[name]);
                                }
                            }
                            onSubmit(formData);
                          
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>


                                <Row className={`editAttendeeRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`} ref={myRef}>

                                    {/* <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="peopleGroup"
                                            placeholder={{ label: "Select People Group", value: "" }}
                                            form={form}
                                            onChange={(e) => { form.handleChange(e); form.setFieldValue("company", getCompanyNameFromGroup(e.target.value)) }}
                                            options={getAllPeopleGroup()}
                                            required={true}
                                        />
                                    </Col>
                                     */}



                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="firstName"
                                            placeholder="First Name"
                                            form={form}
                                            required={true}
                                        />
                                    </Col>


                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="lastName"
                                            placeholder="Last Name"
                                            form={form}
                                            required={true}
                                        />
                                    </Col>


                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="designation"
                                            placeholder="Designation"
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="company"
                                            placeholder="Company"
                                            form={form}
                                            required={true}
                                        />
                                    </Col>



                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="email"
                                            placeholder="Email"
                                            form={form}
                                        />
                                    </Col>


                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="phone"
                                            placeholder="Phone"
                                            form={form}
                                        />
                                    </Col>


                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="country"
                                            placeholder={{ label: "Select Country", value: "" }}
                                            form={form}
                                            onChange={form.handleChange}
                                            options={handleFormatArray(countries)}
                                            required={fields.country === "Yes" ? true : false}
                                        />
                                    </Col>

                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="nationality"
                                            placeholder={{ label: "Select Nationality", value: "" }}
                                            form={form}
                                            onChange={form.handleChange}
                                            options={handleFormatArray(nationalities)}
                                            required={fields.nationality === "Yes" ? true : false}
                                        />
                                    </Col>

                                    <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                        <CMSFormTextArea
                                            name="introduction"
                                            className={"small"}
                                            maxLength={200}
                                            placeholder={"Introduction"}
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="twitterProfile"
                                            placeholder="Twitter Profile"
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="instagramProfile"
                                            placeholder="Instagram Profile"
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="linkedinProfile"
                                            placeholder="Linkedin Profile"
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="facebookProfile"
                                            placeholder="Facebook Profile"
                                            form={form}
                                        />
                                    </Col>

                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="sortOrder"
                                            placeholder="Sort Order"
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="status"
                                            placeholder={{ label: "Select Status", value: "" }}
                                            form={form}
                                            onChange={form.handleChange}
                                            options={[
                                                { label: "Enabled", value: "1" },
                                                { label: "Disabled", value: "0" },
                                            ]}
                                        />
                                    </Col>

                                    <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                        <CMSFormImageWithCrop
                                            name="photo"
                                            label="Image"
                                            className="boldPink"
                                            moduleName="speakers"
                                            placeholder="Add File"
                                            id={speakerEditing._id}
                                            form={form}
                                        />
                                    </Col>
                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-4 mb-3 d-flex align-items-center justify-content-center"
                                    >
                                        <button
                                            className="formSubmissionButton lightGray me-4"
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default form submission
                                                const fieldToKeep = form.values["photo"]; // Store the value of the field you want to keep

                                                form.resetForm(); // Reset the form

                                                form.setFieldValue("photo", fieldToKeep);

                                            }}
                                        >
                                            RESET
                                        </button>
                                        <button
                                            className="formSubmissionButton "
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                form.handleSubmit(e);
                                                handleScroll(form.errors);
                                            }}
                                        >
                                            {updateState.isLoading ? "PROCESSING" : "SAVE"}
                                        </button>
                                    </Col>

                                    <Col xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed">
                                        {!updateState.isLoading && updateState.status === "submitFailed" && <p className="mb-0">
                                            <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                                            Action Failed! Try again.
                                        </p>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>}
            </CustomScrollbars>




        </>
    );
};

export default EditSpeaker;
