import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
function CMSFormCustomCheckBox(props) {
  const { label, value, name, options, form, required, ...rest } = props;

  return (
    <div>
      <div class="control-group">
        <label class="control control-checkbox">

          <Field
            name={name}
            type="checkbox"
            className="checkbox"
            value={value}
          
          />
          <div class="control_indicator"></div>
        </label>
      </div>
    </div>

  );
}

export default CMSFormCustomCheckBox;
