import React, { createContext, useEffect, useState } from 'react';
import { attendeesList, personActions, searchByName, updatedAttendeesList, updatedAttendeesListForFigures } from '../helper/sideHeaderHelper';
export const FiguresContext = createContext({});
const FiguresContextProvider = (props) => {
    let mainActiveDay = localStorage.getItem("activeDay");
    let mainActiveBreakout = localStorage.getItem("activeBreakout");
    let activeDay = localStorage.getItem("figuresActiveDay");
    let activeBreakout = localStorage.getItem("figuresActiveBreakout");
    const activeDayNumber = parseInt(activeDay);
    const activeBreakoutNumber = activeBreakout;
    const [figures, setFigures] = useState({
        typeSelection: "",
        peopleGroupSelection: undefined,
        graphPartSelection: undefined,
        listSort: undefined,
        attendeeListFilters: {},
        companyListFilters: {},
        countryListFilters: {},
        reset: false,
        figuresAttendees: updatedAttendeesListForFigures(),
        activeDay: (activeDay === "null" || activeDay === null) ? null : activeDayNumber,
        activeBreakout: (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakoutNumber,
    })
    useEffect(() => {
        setFigures((prevState) => ({
            ...prevState,
            activeDay: (activeDay === "null" || activeDay === null) ? null : mainActiveDay,
        }))
        // localStorage.setItem("activeDay", attendee.activeDay);
    }, [mainActiveDay])
    useEffect(() => {
        if (figures.reset) {
            setTimeout(() => {
                setFigures((prevState) => ({
                    ...prevState,
                    reset: false
                }))
            }, 1000);
        }
    }, [figures.reset])
    useEffect(() => {
        setFigures((prevState) => ({
            ...prevState,
            activeBreakout: (activeBreakout === "null" || activeBreakout === null) ? null : mainActiveBreakout,
        }))
        // localStorage.setItem("activeDay", attendee.activeDay);
    }, [mainActiveBreakout])
    return (
        <FiguresContext.Provider value={{ figures, setFigures }}>
            {props.children}
        </FiguresContext.Provider>
    );
};
export default FiguresContextProvider;

