import React, { useReducer, useEffect, useContext, useState } from "react";
import "./login.scss";
import LoginFooter from "../../UI/shared/footer/LoginFooter";
import { Formik } from "formik";
import * as yup from "yup";
import CMSFormInput from "../../UI/shared/formFields/CMSFormInput";
import { Col, Form, Row } from "react-bootstrap";
import {
    updateInitialState,
    updateReducer,
} from "../../../reducers/updateReducer";
import { useNavigate } from "react-router-dom";
import axios from "../../../config/axios";
import { AuthContext } from "../../../context/AuthContext";
import CMSFormPassword from "../../UI/shared/formFields/CMSFormPassword";
import { authReducer, userAuthInitialState } from "../../../reducers/authReducer";
const Login = () => {
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const { user, userAuthState, login, } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const login = async (data) => {
    //     userAuthDispatch({
    //         type: 'LOGIN_REQUEST'
    //     })
    //     setLoading(true);
    //     try {
    //         const response = await axios.post(`auth/signin`, data);

    //         const user = response.data && response.data.user;

    //         if (user && user._id) {
    //             setTimeout(() => {
    //                 setLoading(false);
    //                 localStorage.setItem("user", JSON.stringify(user));
    //                 setUser(user);
    //                 navigate("/")
    //             }, 2000);
    //             return true;
    //         }


    //     } catch (error) {
    //         console.error("There was a problem with the delete operation:", error);
    //         updateDispatch({
    //             type: "FORM_SUBMISSION_FAILURE",
    //             payload: "error",
    //         });
    //         setTimeout(() => {
    //             setLoading(false);
    //             return false;
    //         }, 2000);

    //     }
    // };
    // const handleLogin = async (data) => {
    //     const isAuthenticated = await login(data);
    //     if (isAuthenticated) {
    //         // Redirect to a protected route or show a success message
    //         updateDispatch({
    //             type: "FORM_SUBMISSION_SUCCESS",
    //             payload: "user logged in",
    //         });

    //         // navigate("/home");


    //     } else {
    //         // Show an error message
    //         updateDispatch({
    //             type: "FORM_SUBMISSION_FAILURE",
    //             payload: "error",
    //         });

    //         setTimeout(() => {
    //             updateDispatch({
    //                 type: "FETCH_SUCCESS",
    //             });
    //         }, 5000);
    //     }
    // };
    const userSaved = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
       
        if (userSaved != null) {
            //window.location.href = 'http://localhost:4000/'
            navigate("/")
            //return navigate("/")
        }
    }, [userSaved])
    return (
        <div className="loginContainer homeLayout">
            <div className="loginHolder">
                <Formik
                    initialValues={{
                        userName: "",
                        password: "",
                    }}
                    validationSchema={yup.object().shape({
                        userName: yup.string().required("Enter user name"),
                        password: yup.string().required("Enter password"),
                    })}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {

                        login(values);
                    }}
                >
                    {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                            <Row className={`loginRow ${userAuthState.isLoggingIn ? "submitting" : ""} pt-3 pb-2 mx-auto w-100`}>

                                <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="mt-2 mb-0 d-flex align-items-center justify-content-start"
                                >
                                    <div className="title">
                                        <i className="fa fa-sign-in me-2 mb-3" aria-hidden="true"></i>{" "}
                                        Login
                                    </div>
                                </Col>
                                <Col xl={12} lg={12} md={12} xs={12} className="pb-3 mb-1">
                                    <CMSFormInput
                                        name="userName"
                                        placeholder="Username"

                                        form={form}
                                        required={true}
                                    />
                                </Col>
                                <Col xl={12} lg={12} md={12} xs={12} className="pb-3">
                                    <CMSFormPassword
                                        name="password"
                                        placeholder="Password"
                                        form={form}
                                        type="password"
                                        required={true}
                                    />
                                </Col>
                                <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="mt-2 mb-0 d-flex align-items-center justify-content-start"
                                >
                                    <button
                                        className="formSubmissionButton"
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            form.handleSubmit(e);
                                        }}
                                    >
                                        {userAuthState.isLoggingIn ? "PROCESSING" : "LOGIN"}
                                    </button>


                                </Col>

                                {!userAuthState.isLoggingIn && userAuthState.loginError &&
                                    <Col xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-2 mb-0 d-flex align-items-center justify-content-start text-white errorMessageWhite">
                                        <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                                        Action Failed! Try again.
                                    </Col>}
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
            <LoginFooter />
        </div>
    );
};

export default Login;
