import React, { useContext } from 'react'
import "./footer.scss"
import hashIcon from "../../../../assets/images/ahead_hash_icon.svg"
import aheadLogo from "../../../../assets/images/ahead_logo.svg"
import { BsListNested } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { HomeScreenContext } from '../../../../context/HomeScreenContext';
import { bottomSwiperItemsAll, headerItems } from '../../../../helper/sideHeaderHelper';
import { FiguresContext } from '../../../../context/FiguresContext';
import { checkPermission } from '../../../../helper/permissionsHelper';
const Footer = () => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { figures, setFigures } = useContext(FiguresContext);
    const features = bottomSwiperItemsAll();
    const figureObject = features?.find(item => item.feature === "figures");
    return (
        <div className="footer_advance">
            <div>
                <div className="icon_hashtag" onClick={() => {
                    setLayout((prevState) => ({
                        ...prevState,
                        rightSideMenu: false,
                        leftSideMenu: false,
                        leftFullDisplay: false,
                        rightFullDisplay: false,
                        activePage: null,
                        activePageItems: null,
                    }))
                }} >
                    <img src={hashIcon} />
                </div>
            </div>
            <div className="footer_right_content">
                <div className="icon_ahead_logo">
                    <img src={aheadLogo} />
                </div>
                <div className="action_buttons">
                    {checkPermission("addAttendee") &&
                        <button className={layout.activePage === "addAttendee" ? "active" : ""} onClick={() => {
                            setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: false,
                                activePage: "addAttendee",
                                activePageItems: headerItems("addAttendee")
                            }))
                        }} ><i className="icon icon-register"></i> <span>Register New</span></button>}
                    {checkPermission("showModuleAttendees") &&
                        <button className={(layout.activePage === "attendeesList" || layout.activePage === "attendeeDetails" || layout.activePage === "editAttendee") ? "active" : ""} onClick={() => {
                            setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: false,
                                activePage: "attendeesList",
                                activePageItems: headerItems("attendeesList")
                            }))
                        }}><i className="fa fa-users"></i> <span>Attendees </span></button>}
                    {checkPermission("showModuleFigures") && figureObject && figureObject.status === "1" &&
                        <button className={(layout.activePage === "figures" || layout.activePage === "figuresDetails") ? "active" : ""} onClick={(e) => {

                            if (layout.activePage === "figures" || layout.activePage === "figuresDetails") {
                                e.preventDefault();
                                return; // Exit the function early
                            }

                            // Proceed with the original functionality if the conditions are not met
                            setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: false,
                                activePage: "figures",
                                activePageItems: headerItems("figures"),
                            }));

                            setFigures((prevState) => ({
                                ...prevState,
                                reset: true,
                            }));
                        }}><i className="icon icon-figures"></i><span>Figures</span></button>}

                </div>

                <div>
                    {checkPermission("showRightSidebar") && layout.swiperDisplayMode === "Advanced" &&
                        <a href="#" className="sidebar-btn" onClick={() => setLayout((prevState) => ({
                            ...prevState,
                            rightSideMenu: !layout.rightSideMenu,
                        }))} >
                            <div className="icon_menu">
                                {layout.rightSideMenu ? <i className="fa fa-times "></i> : <i className="icon icon-menuicon nomargin "></i>}

                            </div>
                        </a>}
                </div>

            </div>
        </div >
    )
}

export default Footer