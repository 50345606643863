import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import BadgeItem from './BadgeItem'
import { TotalAttendees, getClientName, getTotalAccounts, headerItems } from '../../../../../helper/sideHeaderHelper'
import { HomeScreenContext } from '../../../../../context/HomeScreenContext'
import { FiguresContext } from '../../../../../context/FiguresContext'

const OneItemCount = ({ color, text, background }) => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { figures, setFigures } = useContext(FiguresContext);
    return (
        <Row className="countsRow" style={{ border: `1px solid ${background}` }}>
            <Col>
                <div className="detailsCol" onClick={() => {
                    setFigures((prevState) => ({
                        ...prevState,
                        peopleGroupSelection: text,
                        graphPartSelection: "Walkin",
                        typeSelection: "attendees",
                    })); setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        leftFullDisplay: true,
                        activePage: "figuresDetails",
                        activePageItems: headerItems("figuresDetails")
                    }))
                }}>
                    <h1>{TotalAttendees("Yes", text, "Walkin")}</h1>
                    <p className='mb-2'>Walk-in</p>


                </div>

            </Col>
            <Col>
                <div className="detailsCol main" onClick={() => {
                    setFigures((prevState) => ({
                        ...prevState,
                        peopleGroupSelection: text,
                        graphPartSelection: undefined,
                        typeSelection: "attendees",
                    })); setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        leftFullDisplay: true,
                        activePage: "figuresDetails",
                        activePageItems: headerItems("figuresDetails")
                    }))
                }}>
                    <h1 >{TotalAttendees("Yes", text, undefined)}</h1>
                    <p className='mb-2'>{text === "Owners" ? getClientName() : text}</p>
                    {text === "Owners" ? null :
                        <BadgeItem text="Accounts" group={text} value={getTotalAccounts("Yes", text, undefined)} color={color} background={background} />}

                </div>
            </Col>
            <Col>

                <div className="detailsCol" onClick={() => {
                    setFigures((prevState) => ({
                        ...prevState,
                        peopleGroupSelection: text,
                        graphPartSelection: "Registered",
                        typeSelection: "attendees",
                    })); setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        leftFullDisplay: true,
                        activePage: "figuresDetails",
                        activePageItems: headerItems("figuresDetails")
                    }))
                }}>
                    <h1>{TotalAttendees("Yes", text, "Registered")}</h1>
                    <p className='mb-2'>Registered</p>
                </div>
            </Col>
        </Row>
    )
}

export default OneItemCount