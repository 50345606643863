import React, { useState, useContext, useEffect } from 'react'
import GrayHeader from '../../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader'
import BoxCount from './BoxCount'
import { Button, Col, Row } from 'react-bootstrap'
import CustomScrollbars from '../../../../../UI/shared/customScrollbar/CustomScrollbar'
import { TotalAttendeesCombined, getTotalAccountsOverview } from '../../../../../../helper/combinedReportHelper'
import { TotalAttendeesByDayBreakout, headerItems } from '../../../../../../helper/sideHeaderHelper'
import { HomeScreenContext } from '../../../../../../context/HomeScreenContext'
import { CombinedContext } from '../../../../../../context/CombinedContext'
import Loading from '../../../../../UI/shared/loading/Loading'

const CombinedReport = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])
    const [selectedDays, setSelectedDays] = useState([]);

    const toggleDay = (day) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter(d => d !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    };
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { combined, setCombined } = useContext(CombinedContext);
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    function groupByDayAndTitle(data) {
        const groupedData = {};

        data.forEach(item => {
            // Check if the day already exists in the groupedData object
            if (!groupedData[item.day]) {
                groupedData[item.day] = {};
            }

            // Find or add the title to the day's group
            let titlesGroup = groupedData[item.day];
            if (!titlesGroup[item.title]) {
                titlesGroup[item.title] = [];
            }

            // Add the item to the corresponding title group
            titlesGroup[item.title].push(item);
        });

        return groupedData;
    }

    let breakoutByGroup = null;
    if (eventData) {
        if (eventData.eventBreakouts) {
            breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)
        }
    }

    return (
        <>
            {loading && <Loading />}
            <GrayHeader />
            {attendees && attendees.length > 0 ?
                <div className='wholeFigures combinedReport'>
                    <CustomScrollbars style={{ height: `calc(100vh - 192px)` }}>
                        <Row className="topBigFigureRow py-3 w-100 mx-auto">
                            <Col onClick={() => {
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    leftFullDisplay: true,
                                    combinedDetailBack: "combinedReport",
                                    activePage: "combinedDetails",
                                    activePageItems: headerItems("combinedDetails")
                                }))
                                setCombined((prevState) => ({
                                    ...prevState,
                                    day: "",
                                    breakout: "",
                                    graphPartSelection: undefined,
                                    typeSelection: "attendees",
                                }));
                                console.log(combined,"inside table")
                            }}>
                                <i className="fa fa-users" aria-hidden="true"></i>
                            </Col>
                            <Col onClick={() => {
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    combinedDetailBack: "combinedReport",
                                    leftFullDisplay: true,
                                    activePage: "combinedDetails",
                                    activePageItems: headerItems("combinedDetails")
                                }))
                                setCombined((prevState) => ({
                                    ...prevState,
                                    day: "",
                                    breakout: "",
                                    graphPartSelection: undefined,
                                    typeSelection: "attendees",
                                }));
                            }}>
                                <h2 className="text-center">Total Attendees </h2>
                            </Col>
                            <Col onClick={() => {
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    combinedDetailBack: "combinedReport",
                                    leftFullDisplay: true,
                                    activePage: "combinedDetails",
                                    activePageItems: headerItems("combinedDetails")
                                }))
                                setCombined((prevState) => ({
                                    ...prevState,
                                    day: "",
                                    breakout: "",
                                    graphPartSelection: undefined,
                                    typeSelection: "attendees",
                                }));
                            }}>
                                <p className='totalCount'>{TotalAttendeesCombined("Yes", undefined, undefined)}</p>
                            </Col>
                            <Col className='px-3'>
                                <BoxCount partners={TotalAttendeesCombined("Yes", "Partners", undefined)} sponsors={TotalAttendeesCombined("Yes", "Sponsors", undefined)} customers={TotalAttendeesCombined("Yes", "Customers", undefined)} owners={TotalAttendeesCombined("Yes", "Owners", undefined)} />
                            </Col>
                        </Row>
                        <Row className="secondRow mt-3 mb-2 g-0 g-xl-1">

                            <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "teal" }} className=" px-0 pb-4  col borderLeft">
                                <span className="has-arrow has-arrow-top" style={{ borderBottomColor: "teal" }}></span>
                                <div className='holder' onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        combinedDetailBack: "combinedReport",
                                        leftFullDisplay: true,
                                        activePage: "combinedDetails",
                                        activePageItems: headerItems("combinedDetails")
                                    }))
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: "Registered",
                                        typeSelection: "attendees",
                                    }));
                                }} >
                                    <p className="number mb-0">{TotalAttendeesCombined("Yes", undefined, "Registered")}</p>
                                    <p className="text mb-3">Registered</p>
                                    <p className="percentage mb-0">(Total Registered: {TotalAttendeesCombined(undefined, undefined, "Registered")})</p>

                                </div>
                                <BoxCount partners={TotalAttendeesCombined("Yes", "Partners", "Registered")} sponsors={TotalAttendeesCombined("Yes", "Sponsors", "Registered")} customers={TotalAttendeesCombined("Yes", "Customers", "Registered")} owners={TotalAttendeesCombined("Yes", "Owners", "Registered")} />
                            </Col>

                            <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "teal" }} className="px-0 pb-4 col borderLeft">
                                <span className="has-arrow has-arrow-top" style={{ borderBottomColor: "teal" }}></span>
                                <div className='holder' onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        combinedDetailBack: "combinedReport",
                                        leftFullDisplay: true,
                                        activePage: "combinedDetails",
                                        activePageItems: headerItems("combinedDetails")
                                    }))
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: "Walkin",
                                        typeSelection: "attendees",
                                    }));
                                }}>
                                    <p className="number mb-0">{TotalAttendeesCombined("Yes", undefined, "Walkin")}</p>
                                    <p className="text mb-3">Walk-Ins</p>
                                    <p className="percentage"></p>

                                </div>
                                <BoxCount partners={TotalAttendeesCombined("Yes", "Partners", "Walkin")} sponsors={TotalAttendeesCombined("Yes", "Sponsors", "Walkin")} customers={TotalAttendeesCombined("Yes", "Customers", "Walkin")} owners={TotalAttendeesCombined("Yes", "Owners", "Walkin")} />
                            </Col>

                            <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "teal" }} className="px-0 pb-4 col borderLeft">
                                <span className="has-arrow has-arrow-top" style={{ borderBottomColor: "teal" }}></span>
                                <div className='holder' onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        leftFullDisplay: true,
                                        combinedDetailBack: "combinedReport",
                                        activePage: "combinedDetails",
                                        activePageItems: headerItems("combinedDetails")
                                    }))
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: "Shows",
                                        typeSelection: "company",
                                    }));
                                }}>
                                    <p className="number mb-0">{getTotalAccountsOverview(undefined, undefined, undefined)}</p>
                                    <p className="text mb-3">Accounts</p>
                                    <p className="percentage"></p>
                                </div>

                            </Col>


                        </Row>
                         {/* <Row className="secondRow mt-3 mb-2 g-0 g-xl-1">

                            <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "teal" }} className=" px-0 pb-4  col borderLeft">
                                <span className="has-arrow has-arrow-top" style={{ borderBottomColor: "teal" }}></span>
                                <div className='holder' onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        combinedDetailBack: "combinedReport",
                                        leftFullDisplay: true,
                                        activePage: "combinedDetails",
                                        activePageItems: headerItems("combinedDetails")
                                    }))
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: "Registered",
                                        typeSelection: "attendees",
                                    }));
                                }} >
                                    <p className="number mb-0">{TotalAttendeesCombined("Yes", undefined, "Registered")}</p>
                                    <p className="text mb-3">Registered</p>
                                    <p className="percentage mb-0">(Total Registered: {TotalAttendeesCombined(undefined, undefined, "Registered")})</p>

                                </div>
                                <BoxCount partners={TotalAttendeesCombined("Yes", "Partners", "Registered")} sponsors={TotalAttendeesCombined("Yes", "Sponsors", "Registered")} customers={TotalAttendeesCombined("Yes", "Customers", "Registered")} owners={TotalAttendeesCombined("Yes", "Owners", "Registered")} />
                            </Col>

                            <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "teal" }} className="px-0 pb-4 col borderLeft">
                                <span className="has-arrow has-arrow-top" style={{ borderBottomColor: "teal" }}></span>
                                <div className='holder' onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        combinedDetailBack: "combinedReport",
                                        leftFullDisplay: true,
                                        activePage: "combinedDetails",
                                        activePageItems: headerItems("combinedDetails")
                                    }))
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: "Walkin",
                                        typeSelection: "attendees",
                                    }));
                                }}>
                                    <p className="number mb-0">{TotalAttendeesCombined("Yes", undefined, "Walkin")}</p>
                                    <p className="text mb-3">Walk-Ins</p>
                                    <p className="percentage"></p>

                                </div>
                                <BoxCount partners={TotalAttendeesCombined("Yes", "Partners", "Walkin")} sponsors={TotalAttendeesCombined("Yes", "Sponsors", "Walkin")} customers={TotalAttendeesCombined("Yes", "Customers", "Walkin")} owners={TotalAttendeesCombined("Yes", "Owners", "Walkin")} />
                            </Col>

                            <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "teal" }} className="px-0 pb-4 col borderLeft">
                                <span className="has-arrow has-arrow-top" style={{ borderBottomColor: "teal" }}></span>
                                <div className='holder' onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        leftFullDisplay: true,
                                        combinedDetailBack: "combinedReport",
                                        activePage: "combinedDetails",
                                        activePageItems: headerItems("combinedDetails")
                                    }))
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        day: "",
                                        breakout: "",
                                        graphPartSelection: "Shows",
                                        typeSelection: "company",
                                    }));
                                }}>
                                    <p className="number mb-0">{getTotalAccountsOverview(undefined, undefined, undefined)}</p>
                                    <p className="text mb-3">Accounts</p>
                                    <p className="percentage"></p>
                                </div>

                            </Col>


                        </Row> */}
                        {eventData && eventData.eventDays && eventData.eventDays.length > 1 && eventData.eventDays.map((item, i) => {
                            return (
                                <>
                                    {breakoutByGroup[item.day] &&
                                        <Row className="buttonRow mt-2 g-0">
                                            <Col style={{ color: "#fff", backgroundColor: "#6064a5" }} className="">
                                                <Button className='btn-primary' onClick={() => toggleDay(item.day)}>
                                                    {selectedDays.includes(item.day) ? "Hide Breakouts" : "View Breakouts"}
                                                </Button>
                                            </Col>
                                        </Row>}
                                    <Row className={`secondRow  g-0 ${breakoutByGroup[item.day] ? "" : "mt-2"}`}>

                                        <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "#6064a5" }} className="pt-2 pb-4 col">
                                            <div className='holder' onClick={() => {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    leftSideMenu: true,
                                                    combinedDetailBack: "combinedReport",
                                                    leftFullDisplay: true,
                                                    activePage: "combinedDetails",
                                                    activePageItems: headerItems("combinedDetails")
                                                }))
                                                setCombined((prevState) => ({
                                                    ...prevState,
                                                    day: item.day,
                                                    breakout: null,
                                                    activeSession: item.title,
                                                    graphPartSelection: "Registered",
                                                    typeSelection: "dayBreakout",
                                                }));
                                            }}>
                                                <p className="number ">{TotalAttendeesByDayBreakout("Yes", undefined, "Registered", item.day, null)}</p>
                                                <p className="text mb-1">Registered</p>


                                            </div>

                                        </Col>
                                        <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "#6064a5" }} className="pt-2 pb-4 col">
                                            <div className='holder' onClick={() => {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    leftSideMenu: true,
                                                    leftFullDisplay: true,
                                                    combinedDetailBack: "combinedReport",
                                                    activePage: "combinedDetails",
                                                    activePageItems: headerItems("combinedDetails")
                                                }))
                                                setCombined((prevState) => ({
                                                    ...prevState,
                                                    day: item.day,
                                                    breakout: null,
                                                    activeSession: item.title,
                                                    graphPartSelection: undefined,
                                                    typeSelection: "dayBreakout",
                                                }));
                                            }}>
                                                <p className="number">{TotalAttendeesByDayBreakout("Yes", undefined, undefined, item.day, null)}</p>
                                                <p className="text">{item.title} (Plenary)</p>
                                                <BoxCount partners={TotalAttendeesByDayBreakout("Yes", "Partners", undefined, item.day, null)} sponsors={TotalAttendeesByDayBreakout("Yes", "Sponsors", undefined, item.day, null)} customers={TotalAttendeesByDayBreakout("Yes", "Customers", undefined, item.day, null)} owners={TotalAttendeesByDayBreakout("Yes", "Owners", undefined, item.day, null)} />
                                            </div>

                                        </Col>
                                        <Col xl={4} xs={12} style={{ color: "#fff", backgroundColor: "#6064a5" }} className="pt-2 pb-4 col">
                                            <div className='holder' onClick={() => {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    leftSideMenu: true,
                                                    combinedDetailBack: "combinedReport",
                                                    leftFullDisplay: true,
                                                    activePage: "combinedDetails",
                                                    activePageItems: headerItems("combinedDetails")
                                                }))
                                                setCombined((prevState) => ({
                                                    ...prevState,
                                                    day: item.day,
                                                    breakout: null,
                                                    activeSession: item.title,
                                                    graphPartSelection: "Walkin",
                                                    typeSelection: "dayBreakout",
                                                }));
                                            }}>
                                                <p className="number">{TotalAttendeesByDayBreakout("Yes", undefined, "Walkin", item.day, null)}</p>
                                                <p className="text mb-1">Walk-Ins</p>


                                            </div>

                                        </Col>



                                    </Row>

                                    {Object.entries(breakoutByGroup).map(([day, titles]) => (
                                        <>

                                            {parseInt(item.day) === parseInt(day) && selectedDays.includes(item.day) &&
                                                <div key={day} >
                                                    {Object.entries(titles).map(([title, items]) => (
                                                        <div className="alertBoxCombined">
                                                            <div className='alertHeadCombined pb-2 mb-1'>
                                                                {title}
                                                            </div>
                                                            {items.map((session, sessionIndex) => (
                                                                <Row className="secondRow breakout  g-0">

                                                                    <Col xl={4} xs={12} className="pb-4 col">
                                                                        <div className='holder' onClick={() => {
                                                                            setLayout((prevState) => ({
                                                                                ...prevState,
                                                                                leftSideMenu: true,
                                                                                leftFullDisplay: true,
                                                                                activePage: "combinedDetails",
                                                                                activePageItems: headerItems("combinedDetails")
                                                                            }))
                                                                            setCombined((prevState) => ({
                                                                                ...prevState,
                                                                                day: item.day,
                                                                                breakout: session._id,
                                                                                activeSession: session.sessionTitle,
                                                                                graphPartSelection: "Registered",
                                                                                typeSelection: "dayBreakout",
                                                                            }));
                                                                        }} >
                                                                            <p className="number mb-0">{TotalAttendeesByDayBreakout("Yes", undefined, "Registered", item.day, session._id)}</p>
                                                                            <p className="text mb-1">Registered</p>


                                                                        </div>

                                                                    </Col>
                                                                    <Col xl={4} xs={12} className="pb-4 col">
                                                                        <div className='holder' onClick={() => {
                                                                            setLayout((prevState) => ({
                                                                                ...prevState,
                                                                                leftSideMenu: true,
                                                                                leftFullDisplay: true,
                                                                                activePage: "combinedDetails",
                                                                                activePageItems: headerItems("combinedDetails")
                                                                            }))
                                                                            setCombined((prevState) => ({
                                                                                ...prevState,
                                                                                day: item.day,
                                                                                breakout: session._id,
                                                                                activeSession: session.sessionTitle,
                                                                                graphPartSelection: undefined,
                                                                                typeSelection: "dayBreakout",
                                                                            }));
                                                                        }} >
                                                                            <p className="number mb-0">{TotalAttendeesByDayBreakout("Yes", undefined, undefined, item.day, session._id)}</p>
                                                                            <p className="text">{session.sessionTitle}</p>
                                                                            <BoxCount partners={TotalAttendeesByDayBreakout("Yes", "Partners", undefined, item.day, session._id)} sponsors={TotalAttendeesByDayBreakout("Yes", "Sponsors", undefined, item.day, session._id)} customers={TotalAttendeesByDayBreakout("Yes", "Customers", undefined, item.day, session._id)} owners={TotalAttendeesByDayBreakout("Yes", "Owners", undefined, item.day, session._id)} />
                                                                        </div>

                                                                    </Col>
                                                                    <Col xl={4} xs={12} className="pb-4 col">
                                                                        <div className='holder' onClick={() => {
                                                                            setLayout((prevState) => ({
                                                                                ...prevState,
                                                                                leftSideMenu: true,
                                                                                leftFullDisplay: true,
                                                                                activePage: "combinedDetails",
                                                                                activePageItems: headerItems("combinedDetails")
                                                                            }))
                                                                            setCombined((prevState) => ({
                                                                                ...prevState,
                                                                                day: item.day,
                                                                                breakout: session._id,
                                                                                activeSession: session.sessionTitle,
                                                                                graphPartSelection: "Walkin",
                                                                                typeSelection: "dayBreakout",
                                                                            }));
                                                                        }}>
                                                                            <p className="number mb-0">{TotalAttendeesByDayBreakout("Yes", undefined, "Walkin", item.day, session._id)}</p>
                                                                            <p className="text mb-1">Walk-Ins</p>


                                                                        </div>

                                                                    </Col>



                                                                </Row>
                                                            ))}
                                                        </div>

                                                    ))}
                                                </div>}
                                        </>
                                    ))}


                                </>
                            )

                        })}

                    </CustomScrollbars>
                </div> : !loading && <h4 className="d-flex align-items-center justify-content-center py-5 my-5">Stats Not Available</h4>}
        </>
    )
}

export default CombinedReport