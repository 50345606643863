// useApiUpload.js
import { useContext } from 'react';
import axios from '../../../../config/axios';
import { AuthContext } from '../../../../context/AuthContext';

const useApiUpload = () => {
    const { userAuthState } = useContext(AuthContext);

    const SaveToDownloads = async (file, fileName) => {
        const today = new Date();
        const hours = today.getHours();
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const formattedHours = String(hours).padStart(2, '0');
        const time = `${formattedHours}:${minutes}`;
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][today.getMonth()];
        const day = today.getDate();
        const year = today.getFullYear();
        const formattedDate = `${month} ${day}, ${year}`;

        const values = {
            activityId: userAuthState.user?.activityId,
            group: "Reports",
            file: file,
            fileName: fileName,
            uploaderType: userAuthState.user?.type || "",
            uploaderName: userAuthState.user?.name || "",
            time: time,
            date: formattedDate,
            isSystemGenerated: "0"
        };

        const formData = new FormData();
        for (let name in values) {
            formData.append(name, values[name]);
        }

        try {
            const res = await axios.post(`add-downloads/${userAuthState.user?.activityId}`, formData);
            return res.data;
        } catch (error) {
            console.error("There was a problem with the upload operation:", error);
            throw error;
        }
    };

    return { SaveToDownloads };
};

export default useApiUpload;
