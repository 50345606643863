import React, { useContext, useState, useReducer, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import Loading from '../../../UI/shared/loading/Loading';
import dummy from "../../../../assets/images/logo1.png"
import { updateInitialState, updateReducer } from '../../../../reducers/updateReducer';

const TopRow = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const { userAuthState, setUser, logout } = useContext(AuthContext);
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    function insertSpace(text) {
        if (text) {
            return text.replace(/([a-z])([A-Z])/g, '$1 $2');
        } else {
            return ""
        }

    }
    // const logout = async (data) => {
    //     updateDispatch({
    //         type: "FORM_SUBMISSION_REQUEST",
    //     });
    //     setLoading(true);
    //     try {
    //         const response = await axios.post(`auth/signout`);
    //         updateDispatch({
    //             type: "FORM_SUBMISSION_SUCCESS",
    //             payload: "",
    //         });


    //         setTimeout(() => {
    //             setLoading(false);
    //             setUser(null);
    //             localStorage.clear();

    //         }, 2000);
    //         return true;



    //     } catch (error) {
    //         console.error("There was a problem with the delete operation:", error);
    //         updateDispatch({
    //             type: "FORM_SUBMISSION_FAILURE",
    //             payload: "error",
    //         });
    //         setTimeout(() => {
    //             setLoading(false);
    //             return false;
    //         }, 2000);

    //     }
    // };
    const showClientLogo = eventData && eventData.content && eventData.content.showClientLogo ? eventData.content.showClientLogo : null;
    const logo = eventData && eventData.content && eventData.content.clientLogo ? eventData.content.clientLogo : null;
    const clientLogo = eventData && eventData.client && eventData.client.logo ? eventData.client.logo : null;
    return (
        <>
            {loading && <Loading />}
            <Row className="g-0">
                <Col className="showOnMobile mobileHeader">
                    <p>AEP - Onsite Management App </p>
                </Col>
            </Row>

            <Row className="g-0 topRow ">
                <Col className="leftCol">
                    {showClientLogo === "Show Client Logo" ? <div className="logoHolder">
                        <img src={process.env.REACT_APP_BACKEND_URL + "/public/" + clientLogo} alt="" />
                    </div> : showClientLogo === "Show Different Logo" ? <div className="logoHolder">
                        <img src={process.env.REACT_APP_BACKEND_URL + "/public/" + logo} alt="" />
                    </div> : <div className="welcomeTo">
                        <p>Welcome to</p>
                    </div>}

                </Col>
                <Col className="middleCol">
                    <h4>AEP - Onsite Management App </h4>
                </Col>
                <Col className="rightCol">
                    <div className="loginHolder">
                        <div className="logout" onClick={() => logout()}>
                            Logout  <i className="fa fa-sign-out topIcons" />
                        </div>
                        <div className="user">
                            <span className="textOverflow">{userAuthState.user && userAuthState.user.name}</span> <span className="role">({insertSpace(userAuthState.user && userAuthState.user.role)})</span><i className="fa fa-user topIcons" />
                        </div>
                    </div>
                </Col>
            </Row >
        </>
    )
}

export default TopRow