import React, { useState, useEffect, useReducer, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import CustomScrollbars from '../../../../UI/shared/customScrollbar/CustomScrollbar'
import { listingInitialState, listingReducer } from '../../../../../reducers/listingReducer';
import axios from '../../../../../config/axios';
import { AuthContext } from '../../../../../context/AuthContext';
import ModalBody from '../../../../UI/shared/modal/ModalBody';
import CheckinQuarantinedAttendee from './CheckinQuarantinedAttendee';
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import Loading from '../../../../UI/shared/loading/Loading';

const QuarantinedAttendee = () => {
  const [item, setItem] = useState({
    items: [],
    checkin: false,
    attendee: null,
    loading: false,
  })
  const { layout, setLayout } = useContext(HomeScreenContext);
  const handleCheckinClose = () => setItem((prevState) => ({
    ...prevState,
    checkin: false,
  }));
  const handleCheckinShow = () => setItem((prevState) => ({
    ...prevState,
    checkin: true,

  }));
  const { userAuthState } = useContext(AuthContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(
        `get-quarantined-attendees/${userAuthState.user && userAuthState.user.activityId}`
      );

      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setItem((prevState) => ({
        ...prevState,
        items: res.data && res.data.items,
      }));
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItems();
  }, []);


  return (
    <>
      {(layout.reloadAttendees || item.loading || listingState.isLoading) ? <Loading /> : null}
      <CustomScrollbars style={{ height: `calc(100vh - 140px)` }}>
        <Row className="w-100 mx-auto">
          <Col className='mx-2 my-4'>
            {item.items && item.items.length > 0 && item.items.map((item, i) => {
              return (
                <div className="duplicate_attendees_block ng-scope" >

                  <div className="duplicate_attendees_item ng-scope">
                    <ul>
                      <li className={`holder_name red`} >{item.firstName}{" "}{item.lastName}</li>
                      {item.email &&
                        <li className={`holder_email `} >{item.email}</li>}
                      {item.designation &&
                        <li className="holder_designation">{item.designation}</li>}
                      {item.company && <li className="holder_company">{item.company}</li>}


                    </ul>
                    <div className="" onClick={() => {
                      setItem((prevState) => ({
                        ...prevState,
                        attendee: item,
                      }));
                      handleCheckinShow()

                    }}>
                      <span className="btn icon-only icon-large dark-icon" >
                        <i className="fa fa-user-plus" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>

                </div>
              )
            })}
            {listingState.data && listingState.data.items && listingState.data.items.length <= 0 &&
              <h3 className='my-5 pt-5 text-center'>No Records Found!</h3>}

            <ModalBody
              handleClose={handleCheckinClose}
              handleShow={handleCheckinShow}
              closeButton={false}
              heading="Confirm!"
              className="undoCheckInModal"
              show={item.checkin}
              content={<CheckinQuarantinedAttendee loadItems={loadItems} item={item} setItem={setItem} handleClose={handleCheckinClose} data={item.attendee} />}
            />

          </Col>
        </Row>
      </CustomScrollbars>
    </>
  )
}

export default QuarantinedAttendee