import React from 'react'
import hashIcon from "../../../../assets/images/ahead_hash_icon.svg"
import aheadLogo from "../../../../assets/images/ahead_events_logo.svg"
import "./footer.scss"
const LoginFooter = () => {
    return (
        <footer className="footer_login">
            <div>
                <div className="icon_hashtag" >
                    <img src={hashIcon} />
                </div>
            </div>
            <div className="footer_right_content">
                <div className="icon_ahead_logo">
                    <img src={aheadLogo} />
                </div>
            
            </div>
        </footer >
    )
}

export default LoginFooter