import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalBody = ({ show, heading, onClose, content, handleClose, handleShow, CloseButton, className, backdropType }) => {
    // Ensure onClose is defined, otherwise use handleClose as fallback
    const handleCloseModal = onClose || handleClose;
    const backdrop = backdropType === "static" ? "static" : true; // Default to true if not static
    return (
        <Modal
            show={show}
            onHide={handleCloseModal} // Use handleCloseModal here
            size="lg"
            className={className}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={backdrop} // Apply the dynamic backdrop type
            keyboard={backdrop !== "static"} // Allow closing with Esc key if not static
        >
            <Modal.Header className="bg-primaryColor modal-header">
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modal-title"
                >
                    {heading}
                    {!CloseButton ? <span></span> : <i className="fa fa-times closeIcon" onClick={handleCloseModal} aria-hidden="true"></i>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
        </Modal>
    );
};

export default ModalBody;
