import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OverviewReportContext } from '../../../../../context/OverviewReportContext';
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import { BuildGraphDataForOverview } from '../../../../../helper/overviewReportHelper';
import { headerItems } from '../../../../../helper/sideHeaderHelper';
import { titleOfBreakout, titleOfDay } from '../../../../../helper/commonHelper';

const OverviewGraph = () => {
    const { overview, setOverview } = useContext(OverviewReportContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [chartOptions, setChartOptions] = useState(null);
    // New state to track if it's the first load or a subsequent update
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (overview) {
            if (isFirstLoad) {
                // Set isFirstLoad to false after the first load
                setIsFirstLoad(false);
                setChartOptions(null);
                const updatedChartOptions = BuildGraphDataForOverview(overview.graphPartSelection, overview, setOverview)?.chartConfig;
                setTimeout(() => {
                    setChartOptions(updatedChartOptions);
                }, 500);
            } else {
                // On subsequent updates, just update chartOptions without showing loading
                const updatedChartOptions = BuildGraphDataForOverview(overview.graphPartSelection, overview, setOverview)?.chartConfig;
                setChartOptions(updatedChartOptions);
            }
        }
    }, [overview, isFirstLoad]);

  

    return (
        <>
            {chartOptions === null?
                <div className='loadingHolder'>
                    {/* <Loading style={{ background: ' rgba(255, 255, 255, 0)' }} />  */}
                </div> :
                <div className='pieHolderDiv w-100 mt-2'>
                    {chartOptions!= undefined?
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} /> : <p className='text-white'>Graph not available</p>}
                </div>}
            {overview.graphPartSelection!== undefined && overview.graphPartSelection!== "" && (overview.graphPartSelection.includes("breakout")? (
                <p className='mx-4 my-3'>Selected Breakout: {titleOfBreakout(overview.breakout)}</p>
            ) : overview.graphPartSelection.includes("day")? (
                <p className='mx-4 my-3'>Selected Day: {titleOfDay(overview.day)}</p>
            ) : null)}
            
        </>
    );
};

export default OverviewGraph;
