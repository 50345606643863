import React, { useContext, useReducer, useEffect } from "react";
import TopGraySectionEmpty from "../figures/TopGraySectionEmpty";
import { Button, Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { OverviewReportContext } from "../../../../../context/OverviewReportContext";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import {
  TotalAttendeesByDayBreakout,
  getAvailablePeopleGroups,
} from "../../../../../helper/sideHeaderHelper";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import { TotalAttendeesCombined } from "../../../../../helper/combinedReportHelper";
import { TotalAttendeesByDayBreakoutList, TotalAttendeesByDayBreakoutOverview, TotalAttendeesCombinedList } from "../../../../../helper/overviewReportHelper";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import { arraySortByProperty } from "../../../../../helper/figuresListHelper";
import axios from "../../../../../config/axios";
import { AuthContext } from "../../../../../context/AuthContext";
import Download from "../../partials/downloadButton/Download";
import useApiUpload from "../../../../UI/shared/addToDownload/AddToDownload";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const OverviewTopSection = () => {
  const event = JSON.parse(localStorage.getItem("eventData"));
  const { overview, setOverview } = useContext(OverviewReportContext);
  const { layout, setLayout } = useContext(HomeScreenContext);
  const { userAuthState, setUser } = useContext(AuthContext);
  const availablePeopleGroups = getAvailablePeopleGroups();
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  function generateArrayOfIds(array) {
    return array.map(obj => obj._id);
  }
  const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
  const { SaveToDownloads } = useApiUpload();
  const handleDownload = async () => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    const attendees = TotalAttendeesByDayBreakoutList("Yes", overview.peopleGroupSelection, undefined, overview.day, overview.breakout);

    const ids = generateArrayOfIds(attendees)
    try {
      const res = await axios.post(`download/${userAuthState.user && userAuthState.user.activityId}`, { ids: ids, dayId: overview.day === "" ? null : overview.day, breakoutId: overview.breakout === "" ? null : overview.breakout, type: "overview" });
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      const peopleGroupSelected = (overview.peopleGroupSelection && overview.peopleGroupSelection !== "" && overview.peopleGroupSelection !== "undefined") ? overview.peopleGroupSelection : "Total";

      const utf8Bom = '\uFEFF'; // UTF-8 BOM
      const csvContent = utf8Bom + res.data;
      const blob = new Blob([csvContent], { type: "text/csv; charset=utf-8" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const filename = `Overview Report(${peopleGroupSelected}).csv`;
      const a = document.createElement("a");
      const file = new File([blob], filename, { type: "text/csv;charset=utf-8", lastModified: Date.now() });
      try {
        const result = await SaveToDownloads(file, filename);

      } catch (error) {
        console.error(error); // Handle failure
      }
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
      // setTimeout(() => {
      //     updateDispatch({
      //         type: "FETCH_SUCCESS",
      //     });
      // }, 4000);

    } catch (error) {
      console.error("There was a problem with the download operation:", error);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 2000);
    }
  };

  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
    });

  }, [])
  return (
    <div
      className={` hidden-element 
        `}
    >
      <TopGraySectionEmpty>
        {layout.showFigureListViewFullScreen === true ? <>
          <Col className="topHolder hidden-element">

            <div className="peopleGroupSelection">
              <Formik
                initialValues={{
                  peopleGroup:
                    overview.peopleGroupSelection && overview.peopleGroupSelection
                      ? overview.peopleGroupSelection
                      : "",
                }}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values) => { }}
              >
                {(form) => (
                  <Form className="" onSubmit={form.handleSubmit}>
                    <Row className="">
                      <Col className="pe-0 me-0">
                        <CMSFormSelect
                          name="peopleGroup"
                          placeholder={{ label: "All", value: "" }}
                          form={form}
                          onChange={(e) => {
                            form.handleChange(e);
                            form.handleSubmit();
                            setOverview((prevState) => ({
                              ...prevState,
                              peopleGroupSelection:
                                e.target.value === ""
                                  ? undefined
                                  : e.target.value,
                            }));
                          }}
                          options={handleFormatArray(availablePeopleGroups)}
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="d-flex align-items-center justify-content-between hideOnMobile">
              <i className="fa fa-users me-3 fs-3 hideOnMobile"></i>
              <p className="mb-0 mx-1 fs-5 hideOnMobile">Total Attendees</p>
              <h3 className="text-primaryColor ms-3 mb-0 fs-2 hideOnMobile">
                {TotalAttendeesByDayBreakoutOverview("Yes", overview.peopleGroupSelection, undefined, overview.day, overview.breakout)}
              </h3>
            </div>

            {layout.showFigureListViewFullScreen && (
              <div
                onClick={() =>
                  setLayout((prevState) => ({
                    ...prevState,
                    showFigureListViewFullScreen: false,
                  }))
                }
              >
                <i
                  className="fa fa-times text-primaryColor cursor-pointer fs-4"
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </Col>
        </> :
          <>
            <Col xl={6} lg={4} className="hidden-element hideOnMobileTablet">
              <p className={`mb-0 text-primaryColor`}>{event.title}</p>
            </Col>
            {attendees && attendees.length > 0 &&
              <Col className="topHolder hidden-element">
                {checkPermission("viewReportsInFullScreen") &&
                  <Button className="bg-primaryColor text-white rounded-0 hideOnMobile" onClick={() =>
                    setLayout((prevState) => ({
                      ...prevState,
                      showFigureListViewFullScreen: true,
                    }))
                  } >
                    VIEW FULL SCREEN
                  </Button>}
                <div className="peopleGroupSelection">
                  <Formik
                    initialValues={{
                      peopleGroup:
                        overview.peopleGroupSelection && overview.peopleGroupSelection
                          ? overview.peopleGroupSelection
                          : "",
                    }}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => { }}
                  >
                    {(form) => (
                      <Form className="" onSubmit={form.handleSubmit}>
                        <Row className="">
                          <Col className="pe-0 me-0">
                            <CMSFormSelect
                              name="peopleGroup"
                              placeholder={{ label: "All", value: "" }}
                              form={form}
                              onChange={(e) => {
                                form.handleChange(e);
                                form.handleSubmit();
                                setOverview((prevState) => ({
                                  ...prevState,
                                  peopleGroupSelection:
                                    e.target.value === ""
                                      ? undefined
                                      : e.target.value,
                                }));
                              }}
                              options={handleFormatArray(availablePeopleGroups)}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="d-flex align-items-center justify-content-between hideOnMobile">
                  <i className="fa fa-users me-3 fs-3 hideOnMobile"></i>
                  <p className="mb-0 mx-1 fs-5 hideOnMobile">Total Attendees</p>
                  <h3 className="text-primaryColor ms-3 mb-0 fs-2 hideOnMobile">
                    {/* {TotalAttendeesByDayBreakoutList("Yes", overview.peopleGroupSelection, undefined, overview.day, overview.breakout).length} */}
                    {TotalAttendeesByDayBreakoutOverview('Yes', overview.peopleGroupSelection, undefined, overview.day, overview.breakout)}
                  </h3>
                </div>
                {checkPermission("downloadReports") &&
                  <div className="d-flex hideOnMobile">
                    <Download className="hideOnMobile" style={{ top: '-5px', right: "30px" }} normal={() => handleDownload()} />
                  </div>}
                <div className="attendeeDownload">
                  <div className="d-flex align-items-center justify-content-between showOnMobile">
                    <i className="fa fa-users me-3 fs-3 showOnMobile"></i>
                    <p className="mb-0 mx-1 fs-5 showOnMobile">Total Attendees</p>
                    <h3 className="text-primaryColor ms-3 mb-0 fs-2 showOnMobile">
                      {TotalAttendeesByDayBreakoutOverview("Yes", overview.peopleGroupSelection, undefined, overview.day, overview.breakout)}
                    </h3>
                  </div>
                  {checkPermission("downloadReports") &&
                    <div className="d-flex showOnMobile">
                      <Download className="showOnMobile" style={{ top: '-10px', right: "30px" }} normal={() => handleDownload()} />
                    </div>}
                  {layout.showFigureListViewFullScreen && (
                    <div
                      onClick={() =>
                        setLayout((prevState) => ({
                          ...prevState,
                          showFigureListViewFullScreen: false,
                        }))
                      }
                    >
                      <i
                        className="fa fa-times text-primaryColor cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    </div>
                  )}
                </div>

              </Col>}
          </>}

      </TopGraySectionEmpty>

    </div>
  );
};

export default OverviewTopSection;
