import React, { useContext, useEffect, useState } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Col, Row } from "react-bootstrap";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import Loading from "../../../../UI/shared/loading/Loading";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import { getAvailableCountries, getAvailablePeopleGroups, getAvailableSectors } from "../../../../../helper/sideHeaderHelper";

const Filters = () => {
  const { attendee, setAttendee } = useContext(AttendeeContext);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (attendee.reset) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setAttendee((prevState) => ({
          ...prevState,
          reset: false,
        }));
      }, 100);
    }

  }, [attendee.reset])
  useEffect(() => {

    if (attendee.filters && !attendee.reset) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [attendee.filters])
  const countries = getAvailableCountries() && getAvailableCountries().length > 0;
  const sectors = getAvailableCountries() && getAvailableCountries().length > 0;
  const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
  return (
    <>
      {loading && <Loading />}

      <GrayHeader>
        {!loading && attendees && attendees.length > 0 &&
          <Formik
            initialValues={attendee.filters}
            validationSchema={yup.object().shape({
              checkin: yup.string(),
              peopleGroup: yup.string(),
              registrationType: yup.string(),
              country: yup.string(),
              sector: "",
            })}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              setAttendee((prevState) => ({
                ...prevState,
                filters: values,
                activeLetter: "",
              }))
            }}
          >
            {(form) => (
              <Form className="w-100">
                <div className={`attendeeFiltersRow ${(countries || sectors ? "adjustLength" : "")}`}>
                  <div className="pe-1 pb-1">
                    <CMSFormSelect
                      name="checkin"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={[
                        { label: "Checked-In", value: "Yes" },
                        { label: "Not Checked-In", value: "No" },
                      ]}

                    />
                  </div>
                  <div className="pe-1 pb-1">
                    <CMSFormSelect
                      name="registrationType"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={[
                        { label: "Registered", value: "Registered" },
                        { label: "Walkin", value: "Walkin" },
                      ]}

                    />
                  </div>
                  {getAvailablePeopleGroups() && getAvailablePeopleGroups().length > 0 &&
                    <div className="pe-1 pb-1">
                      <CMSFormSelect
                        name="peopleGroup"
                        placeholder={{ label: "All", value: "" }}
                        form={form}
                        onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                        options={handleFormatArray(getAvailablePeopleGroups())}

                      />
                    </div>}
                  {getAvailableCountries() && getAvailableCountries().length > 0 &&
                    <div className="pe-1 pb-1">
                      <CMSFormSelect
                        name="country"
                        placeholder={{ label: "All", value: "" }}
                        form={form}
                        onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                        options={handleFormatArray(getAvailableCountries())}

                      />
                    </div>}
                  {getAvailableSectors() && getAvailableSectors().length > 0 &&
                    <div className="pe-1 pb-1">
                      <CMSFormSelect
                        name="sector"
                        placeholder={{ label: "All", value: "" }}
                        form={form}
                        onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                        options={handleFormatArray(getAvailableSectors())}

                      />
                    </div>}
                </div>
                {/* <Row className="attendeeFiltersRow g-0 pt-2 pb-1">
                  <Col xl={4} lg={4} md={4} xs={12} className="pe-1 pb-1">
                    <CMSFormSelect
                      name="checkin"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={[
                        { label: "Checkin", value: "Yes" },
                        { label: "Not Checkedin", value: "No" },
                      ]}
                      required={true}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} xs={12} className="pe-1 pb-1">
                    <CMSFormSelect
                      name="registrationType"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={[
                        { label: "Registered", value: "Registered" },
                        { label: "Walkin", value: "Walkin" },
                      ]}
                      required={true}
                    />
                  </Col>

                  <Col xl={4} lg={4} md={4} xs={12} className="pb-1">
                    <CMSFormSelect
                      name="peopleGroup"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={handleFormatArray(getAvailablePeopleGroups())}
                      required={true}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} xs={12} className="pe-1 pb-1">
                    <CMSFormSelect
                      name="country"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={handleFormatArray(getAvailableCountries())}
                      required={true}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} xs={12} className="pe-1 pb-1">
                    <CMSFormSelect
                      name="sector"
                      placeholder={{ label: "All", value: "" }}
                      form={form}
                      onChange={(e) => { form.handleChange(e); form.handleSubmit() }}
                      options={handleFormatArray(getAvailableSectors())}
                      required={true}
                    />
                  </Col>
                </Row> */}
              </Form>
            )}
          </Formik>}
      </GrayHeader>


    </>
  );
};

export default Filters;
