import React, { useContext, useReducer, useEffect, useRef, useState } from 'react'
import HomeLayout from '../../UI/shared/HomeLayout'
import "./home.scss"
import TopRow from './partials/TopRow'
import EventInfo from './partials/EventInfo'
import CountSwiper from './partials/countSwiper/CountSwiper'
import { HomeScreenContext } from '../../../context/HomeScreenContext'
import AttendeesButton from './partials/AttendeesButton'
import HomeSwitchButton from './partials/HomeSwitchButton'
import ModuleSwiper from './partials/moduleSwiper/ModuleSwiper'
import axios from '../../../config/axios'
import { listingInitialState, listingReducer } from '../../../reducers/listingReducer'
import Loading from '../../UI/shared/loading/Loading'
import { AttendeeContext } from '../../../context/AttendeeContext'
import { getPrintBadgeValue, setDefaultDay } from '../../../helper/commonHelper'
import { Col, Row } from 'react-bootstrap'
import BadgePrint from './partials/badgeprint/BadgePrint'
import Figures from './leftSidePartials/figures/Figures'
import { AuthContext } from '../../../context/AuthContext'
import { bottomSwiperItems } from '../../../helper/sideHeaderHelper'
import { FiguresContext } from '../../../context/FiguresContext'
import { SpeakerContext } from '../../../context/SpeakerContext'
import { checkPermission } from '../../../helper/permissionsHelper'
import ModalBody from '../../UI/shared/modal/ModalBody'
import AddUsheretteName from './AddUsheretteName'
import HomeLayoutError from '../../UI/shared/HomeLayoutError'

const Home = () => {
  const { layout, setLayout } = useContext(HomeScreenContext);
  const { attendee, setAttendee } = useContext(AttendeeContext);
  const { speaker } = useContext(SpeakerContext);
  const { figures, setFigures } = useContext(FiguresContext);
  const { userAuthState, setUser, logout } = useContext(AuthContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [updatesState, updatesDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [show, setShow] = useState({
    usheretteName: false,
  });
  const handleClose = () => setShow((prevState) => ({
    ...prevState,
    usheretteName: false,
  }));
  const handleShow = () => setShow((prevState) => ({
    ...prevState,
    usheretteName: true,
  }));
  useEffect(() => {
    if (userAuthState.user && !userAuthState.user.name) {
      handleShow()
    }
  }, [userAuthState.user])

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();

    // Pad single digit hours, minutes, and seconds with a leading zero
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    // Format and return the time
    return `${hours}:${minutes}:${seconds}`;
  }



  // const user = JSON.parse(localStorage.getItem("user"));
  const loadUpdates = async () => {
    setLayout((prevState) => ({
      ...prevState,
      isServerBusy: true,
    }))
    updatesDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`get-updates/${userAuthState.user && userAuthState.user.activityId}`);

      localStorage.setItem("attendeesContent", JSON.stringify(res.data));
      setAttendee((prevState) => ({
        ...prevState,
        personActions: res.data && res.data.personActions
      }))
      setLayout((prevState) => ({
        ...prevState,
        isServerBusy: false,
        reloadAttendees: false,
      }))
      updatesDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });




    } catch (error) {
      updatesDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setLayout((prevState) => ({
        ...prevState,
        isServerBusy: false,
      }))
    }
  };
  const loadItems = async () => {

    listingDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(`get-content/${userAuthState.user && userAuthState.user.activityId}`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

      localStorage.setItem("eventData", JSON.stringify(res.data && res.data.event));
      localStorage.setItem("activeDay", setDefaultDay());
      localStorage.setItem("figuresActiveDay", setDefaultDay());
      setAttendee((prevState) => ({
        ...prevState,
        activeDay: setDefaultDay(),
        activeBreakout: null,
      }))
      setLayout((prevState) => ({
        ...prevState,
        reloadHome: false
      }))
      setFigures((prevState) => ({
        ...prevState,
        activeDay: setDefaultDay(),
        activeBreakout: null,
      }))
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      console.log(error.response);

      if (error.response && error.response.data && error.response.data.action === "logout") {
        logout()
      }

    }
  };
  const isFirstRender = useRef(true);

  useEffect(() => {

    // if (isFirstRender.current) {
    //   // Skip the effect on the first render
    //   isFirstRender.current = false;
    //   return;
    // }

    loadItems();
    // Call loadUpdates after loadItems completes
    loadUpdates();
    const intervalId = setInterval(async () => {
      loadUpdates();
    }, 10000); // Call the function every 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
      if (window.location.pathname === "/login") {
        clearInterval(intervalId);
      }
    };



  }, [])
  useEffect(() => {

    setAttendee((prevState) => ({
      ...prevState,
      activeAttendeeDetailsId: null
    }))
  }, [])
  useEffect(() => {
    if (layout.reloadHome) {
      loadItems();
    }

  }, [layout.reloadHome])
  useEffect(() => {
    if (layout.reloadAttendees) {
      loadUpdates();
    }

  }, [layout.reloadAttendees])
  const features = bottomSwiperItems();
  return (
    <>
      {layout.badgePrint && <BadgePrint />}
      {listingState.isLoading && <Loading />}
      {userAuthState.isLoggingOut && <Loading />}
      {listingState.data && (listingState.status != "fetchFailed") && <>

        <HomeLayout>
          <div className={`homePage marginBottom `}>

            {layout.leftSideMenu ? null : <TopRow />}
            <EventInfo data={listingState.data ? listingState.data : undefined} />
            {checkPermission("showModuleFigures") &&
              <CountSwiper />}


            <Row>
              {checkPermission("showModuleAttendees") &&
                <Col xl={12} lg={layout.leftSideMenu ? 12 : 6} md={layout.leftSideMenu ? 12 : 12} sm={12} xs={12}>
                  <AttendeesButton />
                </Col>}
              {checkPermission("modeSelection") && 
                <Col xl={12} lg={layout.leftSideMenu ? 12 : 6} md={layout.leftSideMenu ? 12 : 12} sm={12} xs={12}>
                  <HomeSwitchButton />
                </Col>}
            </Row>
            <ModuleSwiper />

          </div>
          <ModalBody
            handleClose={handleClose}
            handleShow={handleShow}
            closeButton={false}
            backdropType="static"
            heading="Dear Usherette"
            show={show.usheretteName}
            content={<AddUsheretteName handleClose={handleClose} />}
          />
        </HomeLayout>
      </>
      }
      {listingState.status === "fetchFailed" && <>
        <HomeLayoutError>
          <div className={`homePage marginBottom `}>

            {layout.leftSideMenu ? null : <TopRow />}
            <div className="topRow row my-5 d-flex align-items-center justify-content-center">
              <div className="detailsFetchError">
                <h1> <i class="fa fa-exclamation-circle text-white text-center mb-3 fs-1" aria-hidden="true"></i></h1>



                <h3 className='mb-1 text-white text-center'>Something went wrong!</h3>
                <p className='mb-2 text-white text-center'>Try Refreshing the page or try login again.</p>
              </div>
            </div>
          </div>

        </HomeLayoutError>
      </>}
    </>

  )
}

export default Home;