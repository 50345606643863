import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CMSFormPassword = (props) => {
    const {
        name,
        label,
        showPassword,
        form,
        type,
        value,
        disabled,
        required,
        autoFocus,
        description,
        placeholder,
        helpMessage,
        ...rest
    } = props;
    const [show, setShow] = useState(false);
    return (
        <div className="form-group position-relative">
            {/* Created Useable Component */}
            {/* <label
        htmlFor={name}
        className={` ${required == true ? "mandatory_field ps-2 " : " "
          } ps-1 mb-0`}
      >
        {" "}
        {label}
      </label> */}


            {/* Created Useable Component */}
            {helpMessage && helpMessage.message && (
                <OverlayTrigger
                    trigger="hover"
                    key={`info-popup-${name}`}
                    placement="right"
                    overlay={
                        <Popover id={`popover-positioned-${name}`}>
                            {helpMessage.heading && (
                                <Popover.Header as="h3">{helpMessage.heading}</Popover.Header>
                            )}

                            <Popover.Body>{helpMessage.message}</Popover.Body>
                        </Popover>
                    }
                >
                    <i className="fa fa-info-circle ml-2 infoIcon"></i>
                </OverlayTrigger>
            )}

            <Field
                name={name}
                value={value ? value : form.values[name]}
                autoFocus={autoFocus}
                disabled={disabled}
                type={show ? "text" : "password"}
                placeholder={placeholder}
                className={`form-control customInput  ${form.touched[name] && form.errors[name] ? "is-invalid" : " "
                    }`}
                {...rest}
            />

            <div className="showPassword" onClick={() => setShow(!show)}>
                {!show ? <i className="fa fa-eye" aria-hidden="true"></i>
                    : <i className="fa fa-eye-slash" aria-hidden="true"></i>
                }
            </div>
            {required === true && <div className="required"></div>}

            {form.touched[name] && form.errors[name] && (
                <span id={`${name}-error"`} className="errorMessage">
                    <ErrorMessage name={name} />
                </span>
                // <div className="errorMessage">{form.errors[name]}</div>
            )}
        </div>
    );
};

export default CMSFormPassword;
