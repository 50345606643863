import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { FiguresContext } from "../../../../../../context/FiguresContext";
import {
    TotalAttendeesFigures,
    findPercentage,
    getClientName,
    getColorOfPeopleGroup,
    getTotalAccounts,
    getTotalAccountsFigures,
    getTotalCountries,
} from "../../../../../../helper/sideHeaderHelper";

const SingleGroupCountDetails = () => {
    const { figures, setFigures } = useContext(FiguresContext);
    return (
        <>
            {figures.peopleGroupSelection != undefined ? (
                <>
                    {" "}
                    {(figures.typeSelection === "attendees" || figures.typeSelection === "company") ? <>
                        <Row className="topBigFigureRow singleDetail g-0">
                            <Col onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: figures.peopleGroupSelection,
                                    graphPartSelection: undefined,
                                    typeSelection: "attendees",
                                    attendeeListFilters: {}
                                }));
                            }}>
                                <i className="fa fa-users"></i>
                            </Col>
                            <Col onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: figures.peopleGroupSelection,
                                    graphPartSelection: undefined,
                                    typeSelection: "attendees",
                                    attendeeListFilters: {}
                                }));
                            }}>
                                <p>Total</p>
                            </Col>
                        </Row>
                        <Row className="secondRow singleDetail g-0">
                            <Col
                                className={`col  ${figures.graphPartSelection === undefined && figures.typeSelection === "attendees"
                                    ? "active"
                                    : ""
                                    }`}
                                style={{
                                    color: getColorOfPeopleGroup(
                                        "foreground",
                                        figures.peopleGroupSelection
                                    ),
                                    backgroundColor: getColorOfPeopleGroup(
                                        "background",
                                        figures.peopleGroupSelection
                                    ),
                                }}
                            >
                                <span
                                    className="has-arrow has-arrow-top"
                                    style={{
                                        borderBottomColor: getColorOfPeopleGroup(
                                            "background",
                                            figures.peopleGroupSelection
                                        ),
                                    }}
                                ></span>
                                <div
                                    className="holder"
                                    onClick={() => {
                                        setFigures((prevState) => ({
                                            ...prevState,
                                            peopleGroupSelection: figures.peopleGroupSelection,
                                            graphPartSelection: undefined,
                                            typeSelection: "attendees",
                                            attendeeListFilters: {}
                                        }));
                                    }}
                                >
                                    <p className="number">
                                        {TotalAttendeesFigures("Yes", figures.peopleGroupSelection, undefined)}
                                    </p>
                                    <p className="text">{figures.peopleGroupSelection}</p>
                                    <p className="percentage">
                                        {findPercentage(
                                            TotalAttendeesFigures("Yes", figures.peopleGroupSelection, undefined),
                                            TotalAttendeesFigures("Yes", undefined, undefined)
                                        )}
                                    </p>
                                </div>
                                <Row className="thirdRow g-0">
                                    <Col
                                        className={`col  ${figures.graphPartSelection === "Registered"
                                            ? "active"
                                            : ""
                                            }`}
                                        style={{
                                            color: getColorOfPeopleGroup(
                                                "foreground",
                                                figures.peopleGroupSelection
                                            ),
                                            backgroundColor: getColorOfPeopleGroup(
                                                "background",
                                                figures.peopleGroupSelection
                                            ),
                                        }}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: figures.peopleGroupSelection,
                                                graphPartSelection: "Registered",
                                                typeSelection: "attendees",
                                                attendeeListFilters: { ...figures.attendeeListFilters, registrationType: "Registered" }
                                            }));
                                        }}
                                    >
                                        <span
                                            className="has-arrow has-arrow-top"
                                            style={{
                                                borderBottomColor: getColorOfPeopleGroup(
                                                    "background",
                                                    figures.peopleGroupSelection
                                                ),
                                            }}
                                        ></span>
                                        <div className="holder">
                                            <p className="number">
                                                {TotalAttendeesFigures(
                                                    "Yes",
                                                    figures.peopleGroupSelection,
                                                    "Registered"
                                                )}
                                            </p>
                                            <p className="text">Registered</p>

                                            <p className="percentage">
                                                {findPercentage(
                                                    TotalAttendeesFigures(
                                                        "Yes",
                                                        figures.peopleGroupSelection,
                                                        "Registered"
                                                    ),
                                                    TotalAttendeesFigures("Yes", figures.peopleGroupSelection, undefined)
                                                )}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        className={`col  ${figures.graphPartSelection === "Walkin"
                                            ? "active"
                                            : ""
                                            }`}
                                        style={{
                                            color: getColorOfPeopleGroup(
                                                "foreground",
                                                figures.peopleGroupSelection
                                            ),
                                            backgroundColor: getColorOfPeopleGroup(
                                                "background",
                                                figures.peopleGroupSelection
                                            ),
                                        }}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: figures.peopleGroupSelection,
                                                graphPartSelection: "Walkin",
                                                typeSelection: "attendees",
                                                attendeeListFilters: { ...figures.attendeeListFilters, registrationType: "Walkin" }
                                            }));
                                        }}
                                    >
                                        <span
                                            className="has-arrow has-arrow-top"
                                            style={{
                                                borderBottomColor: getColorOfPeopleGroup(
                                                    "background",
                                                    figures.peopleGroupSelection
                                                ),
                                            }}
                                        ></span>
                                        <div className="holder">
                                            <p className="number">
                                                {TotalAttendeesFigures("Yes", figures.peopleGroupSelection, "Walkin")}
                                            </p>
                                            <p className="text">Walkin</p>

                                            <p className="percentage">
                                                {findPercentage(
                                                    TotalAttendeesFigures("Yes", figures.peopleGroupSelection, "Walkin"),
                                                    TotalAttendeesFigures("Yes", figures.peopleGroupSelection, undefined)
                                                )}
                                            </p>
                                        </div>
                                    </Col>
                                    {figures.peopleGroupSelection != "Owners" && (
                                        <Col onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: figures.peopleGroupSelection,
                                                graphPartSelection: undefined,
                                                typeSelection: "company",
                                            }));
                                        }}
                                            className={`col  ${(figures.graphPartSelection === undefined || figures.graphPartSelection === "Shows-Accounts" || figures.graphPartSelection === "No-Shows-Accounts") && figures.typeSelection === "company"
                                                ? "active"
                                                : ""
                                                }`}
                                            style={{
                                                color: getColorOfPeopleGroup(
                                                    "foreground",
                                                    figures.peopleGroupSelection
                                                ),
                                                backgroundColor: getColorOfPeopleGroup(
                                                    "background",
                                                    figures.peopleGroupSelection
                                                ),
                                            }}
                                        >
                                            <span
                                                className="has-arrow has-arrow-top"
                                                style={{
                                                    borderBottomColor: getColorOfPeopleGroup(
                                                        "background",
                                                        figures.peopleGroupSelection
                                                    ),
                                                }}
                                            ></span>
                                            <div className="holder">
                                                <p className="number">
                                                    {getTotalAccountsFigures(
                                                        "Yes",
                                                        figures.peopleGroupSelection,
                                                        undefined
                                                    )}
                                                </p>
                                                <p className="text">Accounts</p>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </> : figures.typeSelection === "registered" && <>
                        {/* <Row className="topBigFigureRow grayBg singleDetail g-0">
                            <Col>
                                <i className="fa fa-users"></i>
                            </Col>
                            <Col>
                                <p>Total</p>
                            </Col>
                        </Row> */}
                        <Row className="secondRow grayBg  singleDetail g-0">
                            <Col
                                className={`col  ${figures.graphPartSelection === undefined
                                    ? "active"
                                    : ""
                                    }`}
                                style={{
                                    background: '#eaeced',
                                    color: '#566a73',
                                }}
                            >
                                {/* <span
                                    className="has-arrow has-arrow-top"
                                    // style={{
                                    //     borderBottomColor: getColorOfPeopleGroup(
                                    //         "background",
                                    //         figures.peopleGroupSelection
                                    //     ),
                                    // }}
                                ></span> */}
                                <div
                                    className="holder"
                                    onClick={() => {
                                        setFigures((prevState) => ({
                                            ...prevState,
                                            peopleGroupSelection: figures.peopleGroupSelection,
                                            graphPartSelection: undefined,
                                            typeSelection: "registered",
                                            companyListFilters: { checkin: "" }
                                        }));
                                    }}
                                >
                                    <p className="number main">
                                        {TotalAttendeesFigures(undefined, figures.peopleGroupSelection, "Registered")}
                                    </p>
                                    <p className="text main">{figures.peopleGroupSelection === "Owners" ? getClientName() : figures.peopleGroupSelection}</p>

                                </div>
                                <Row className="thirdRow g-0">
                                    <Col
                                        className={`col   ${figures.graphPartSelection === "Shows-Accounts"
                                            ? "active"
                                            : ""
                                            }`}
                                        style={{
                                            background: '#eaeced',
                                            color: '#566a73',
                                        }}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: figures.peopleGroupSelection,
                                                graphPartSelection: "Shows-Accounts",
                                                typeSelection: "registered",
                                                companyListFilters: { checkin: "Shows-Accounts" }
                                            }));
                                        }}
                                    >
                                        <span
                                            className="has-arrow has-arrow-top"
                                            style={{
                                                borderBottomColor: '#eaeced',
                                            }}
                                        ></span>
                                        <div className="holder">
                                            <p className="number">
                                                {TotalAttendeesFigures(
                                                    "Yes",
                                                    figures.peopleGroupSelection,
                                                    "Registered"
                                                )}
                                            </p>
                                            <p className="text">Shows</p>

                                            <p className="percentage">

                                                {findPercentage((TotalAttendeesFigures("Yes", figures.peopleGroupSelection, "Registered")), (TotalAttendeesFigures(undefined, figures.peopleGroupSelection, "Registered")))}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        className={`col  ${figures.graphPartSelection === "No-Shows-Accounts"
                                            ? "active"
                                            : ""
                                            }`}
                                        style={{
                                            background: '#eaeced',
                                            color: '#566a73',
                                        }}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: figures.peopleGroupSelection,
                                                graphPartSelection: "No-Shows-Accounts",
                                                typeSelection: "registered",
                                                companyListFilters: { checkin: "No-Shows-Accounts" }
                                            }));
                                        }}
                                    >
                                        <span
                                            className="has-arrow has-arrow-top"
                                            style={{
                                                borderBottomColor: '#eaeced',
                                            }}
                                        ></span>
                                        <div className="holder">
                                            <p className="number">
                                                {TotalAttendeesFigures("No", figures.peopleGroupSelection, "Registered")}
                                            </p>
                                            <p className="text">No Shows </p>

                                            <p className="percentage">
                                                {findPercentage((TotalAttendeesFigures("No", figures.peopleGroupSelection, "Registered")), (TotalAttendeesFigures(undefined, figures.peopleGroupSelection, "Registered")))}
                                            </p>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </>}
                </>
            ) : figures &&
                (figures.typeSelection === "attendees" || figures.typeSelection === "country" ||
                    figures.typeSelection === "company") ? (
                <>
                    <Row className="attendeesCount singleDetail g-0 py-3">
                        <Col
                            xl={12} xs={12} lg={12} md={12}
                            className={`col main ${figures.graphPartSelection === undefined && figures.typeSelection === "attendees"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: undefined,
                                    attendeeListFilters: { peopleGroup: "", registrationType: "" },
                                    typeSelection: "attendees",
                                }));
                            }}
                        >
                            <i className="fa fa-users"></i>
                            <p>Total Attendees</p>
                            <p className="bigCount">
                                {TotalAttendeesFigures("Yes", undefined, undefined)}
                            </p>
                        </Col>
                        <Col
                            className={`col ${figures.graphPartSelection === "Registered"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: "Registered",
                                    attendeeListFilters: { peopleGroup: "", registrationType: "Registered" },
                                    typeSelection: "attendees",
                                }));
                            }}
                        >
                            <h1>{TotalAttendeesFigures("Yes", undefined, "Registered")}</h1>
                            <p>Registered</p>
                            <p>
                                {findPercentage(
                                    TotalAttendeesFigures("Yes", undefined, "Registered"),
                                    TotalAttendeesFigures("Yes", undefined, undefined)
                                )}
                            </p>
                        </Col>
                        <Col
                            className={`col ${figures.graphPartSelection === "Walkin"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: "Walkin",
                                    attendeeListFilters: { peopleGroup: "", registrationType: "Walkin" },
                                    typeSelection: "attendees",
                                }));
                            }}
                        >
                            <h1>{TotalAttendeesFigures("Yes", undefined, "Walkin")}</h1>
                            <p>Walkin</p>
                            <p>
                                {findPercentage(
                                    TotalAttendeesFigures("Yes", undefined, "Walkin"),
                                    TotalAttendeesFigures("Yes", undefined, undefined)
                                )}
                            </p>
                        </Col>
                    </Row>
                    <Row className="attendeesCount singleDetail g-0 py-3 mt-1 mb-2">
                        <Col
                            xl={12} xs={12} lg={12} md={12}
                            className={`col main ${figures.graphPartSelection === undefined && figures.peopleGroupSelection === undefined && figures.typeSelection === "company"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: undefined,
                                    typeSelection: "company",
                                    companyListFilters: { checkin: "" }
                                }));
                            }}
                        >
                            <p>Total Accounts</p>
                            <p className="bigCount">
                                {getTotalAccounts(undefined, undefined, undefined)}
                            </p>
                        </Col>
                        <Col
                            className={`col  ${figures.graphPartSelection === "Shows-Accounts" && figures.typeSelection === "company"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: "Shows-Accounts",
                                    typeSelection: "company",
                                    companyListFilters: { checkin: "Shows-Accounts" }
                                }));
                            }}
                        >
                            <h1>{getTotalAccounts("Yes", undefined, undefined)}</h1>
                            <p>Shows</p>
                            <p>({findPercentage(
                                getTotalAccounts("Yes", undefined, undefined),
                                getTotalAccounts(undefined, undefined, undefined)
                            )})</p>
                        </Col>
                        <Col
                            className={`col  ${figures.graphPartSelection === "No-Shows-Accounts" && figures.typeSelection === "company"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    typeSelection: "company",
                                    graphPartSelection: "No-Shows-Accounts",
                                    companyListFilters: { checkin: "No-Shows-Accounts" }
                                }));
                            }}
                        >
                            <h1>{getTotalAccounts(undefined, undefined, undefined) - getTotalAccounts("Yes", undefined, undefined)}</h1>
                            <p>No-Shows</p>
                            <p>({findPercentage(
                                (getTotalAccounts(undefined, undefined, undefined) - getTotalAccounts("Yes", undefined, undefined)),
                                getTotalAccounts(undefined, undefined, undefined)
                            )})</p>
                        </Col>
                    </Row>
                    {getTotalCountries(undefined, undefined, undefined) > 0 &&
                        <Row className="attendeesCount singleDetail g-0 py-3 mt-1 mb-2">
                            <Col
                                xl={12} xs={12} lg={12} md={12}
                                className={`col main ${figures.graphPartSelection === undefined && figures.peopleGroupSelection === undefined && figures.typeSelection === "country"
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() => {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        peopleGroupSelection: undefined,
                                        graphPartSelection: undefined,
                                        typeSelection: "country",
                                        countryListFilters: { checkin: "" }
                                    }));
                                }}
                            >
                                <p>Total Countries</p>
                                <p className="bigCount">
                                    {getTotalCountries(undefined, undefined, undefined)}
                                </p>
                            </Col>
                            <Col
                                className={`col  ${figures.graphPartSelection === "Shows-Accounts" && figures.typeSelection === "country"
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() => {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        peopleGroupSelection: undefined,
                                        graphPartSelection: "Shows-Accounts",
                                        typeSelection: "country",
                                        countryListFilters: { checkin: "Shows-Accounts" }
                                    }));
                                }}
                            >
                                <h1>{getTotalCountries("Yes", undefined, undefined)}</h1>
                                <p>Shows</p>
                                <p>({findPercentage(
                                    getTotalCountries("Yes", undefined, undefined),
                                    getTotalCountries(undefined, undefined, undefined)
                                )})</p>
                            </Col>
                            <Col
                                className={`col  ${figures.graphPartSelection === "No-Shows-Accounts" && figures.typeSelection === "country"
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() => {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        typeSelection: "country",
                                        graphPartSelection: "No-Shows-Accounts",
                                        countryListFilters: { checkin: "No-Shows-Accounts" }
                                    }));
                                }}
                            >
                                <h1>{getTotalCountries(undefined, undefined, undefined) - getTotalCountries("Yes", undefined, undefined)}</h1>
                                <p>No-Shows</p>
                                <p>({findPercentage(
                                    (getTotalCountries(undefined, undefined, undefined) - getTotalCountries("Yes", undefined, undefined)),
                                    getTotalCountries(undefined, undefined, undefined)
                                )})</p>
                            </Col>
                        </Row>}
                </>
            ) : (
                figures &&
                figures.typeSelection === "registered" && (
                    <Row className="attendeesCount singleDetail register g-0 py-3">
                        <Col
                            xl={12}
                            className={`col main ${figures.graphPartSelection === undefined
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    graphPartSelection: undefined,
                                    peopleGroupSelection: figures.peopleGroupSelection,
                                    typeSelection: "registered",
                                    companyListFilters: { checkin: "" }
                                }));
                            }}
                        >
                            <p>Total Registered</p>
                            <p className="bigCount">
                                {TotalAttendeesFigures(undefined, undefined, "Registered")}
                            </p>
                        </Col>
                        <Col
                            className={`col ${figures.graphPartSelection === "Shows-Accounts"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    graphPartSelection: "Shows-Accounts",
                                    peopleGroupSelection: figures.peopleGroupSelection,
                                    typeSelection: "registered",
                                    companyListFilters: { checkin: "Shows-Accounts" }
                                }));
                            }}
                        >
                            <h1>{TotalAttendeesFigures("Yes", undefined, "Registered")}</h1>
                            <p>Shows</p>
                            <p>
                                {findPercentage(
                                    TotalAttendeesFigures("Yes", undefined, "Registered"),
                                    TotalAttendeesFigures(undefined, undefined, "Registered")
                                )}
                            </p>
                        </Col>
                        <Col
                            className={`col ${figures.graphPartSelection === "No-Shows-Accounts"
                                ? "active"
                                : ""
                                }`}
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: figures.peopleGroupSelection,
                                    graphPartSelection: "No-Shows-Accounts",
                                    typeSelection: "registered",
                                    companyListFilters: { checkin: "No-Shows-Accounts" }
                                }));
                            }}
                        >
                            <h1>{TotalAttendeesFigures("No", undefined, "Registered")}</h1>
                            <p>No Shows</p>
                            <p>
                                {findPercentage(
                                    TotalAttendeesFigures("No", undefined, "Registered"),
                                    TotalAttendeesFigures(undefined, undefined, "Registered")
                                )}
                            </p>
                        </Col>
                    </Row>
                )
            )}
        </>
    );
};

export default SingleGroupCountDetails;
