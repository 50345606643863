import React, { useRef, useState, useContext, useEffect, useReducer } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { Col, Row } from "react-bootstrap";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import { handleFormatArray } from "../../../../../helper/commonHelper";
import { headerItems } from "../../../../../helper/sideHeaderHelper";
import axios from "../../../../../config/axios";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { AuthContext } from "../../../../../context/AuthContext";
import CMSFormTextArea from "../../../../UI/shared/formFields/CMSFormTextArea";
import CMSFormImage from "../../../../UI/shared/formFields/CMSFormImage";
import { SpeakerContext } from "../../../../../context/SpeakerContext";
import CMSFormImageWithCrop from "../../../../UI/shared/formFields/CMSFormImageWithCrop";
import CMSFormFile from "../../../../UI/shared/formFields/CMSFormFile";
const EditDownload = ({ details, handleClose,loadDownloads }) => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const { userAuthState } = useContext(AuthContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const fields = eventData && eventData.formFields;
    const myRef = useRef(null);
    const [formInitialData, setFormInitialData] = useState(null);
    const executeScroll = () => myRef.current.scrollIntoView();
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const clientId = eventData.clientId
    const handleScroll = (error) => {

        if (Object.keys(error).length > 0) {
            const element = document.getElementsByName(Object.keys(error)[0])[0];

            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 20);
        }
    };
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const addSpeaker = async () => {
            try {
                const res = await axios.post(`update-download/${details._id}`, data);

                if (res.status === 201) {


                    updateDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                        payload: res.data,
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                        loadDownloads();
                        handleClose();
                    }, 3000);
                } else {
                    updateDispatch({
                        type: "FORM_SUBMISSION_FAILURE",
                        payload: "error",
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                    }, 2000);
                }

            } catch (error) {
                console.error("There was a problem with the operation:", error);
                updateDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: error,
                });
                setTimeout(() => {
                    updateDispatch({
                        type: "FETCH_SUCCESS",
                    });
                }, 2000);
            }
        }
        if (layout.isServerBusy === true) {
            setTimeout(() => {
                addSpeaker();
            }, 2000);
        } else {
            addSpeaker();
        }

    };

    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });

    }, [])

    return (
        <>

            <>
                {updateState.isSubmitted ?
                    <div className="successMessage">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>Document Updated Successfully</h2>

                    </div> :
                    <Formik
                        initialValues={{
                            activityId: userAuthState.user && userAuthState.user.activityId,
                            group: details.group ? details.group : "",
                            file: details.file ? details.file : "",
                            fileName: details.fileName ? details.fileName : "",
                            uploaderType: details.uploaderType ? details.uploaderType : "",
                            uploaderName: details.uploaderName ? details.uploaderName : "",
                            time: details.time ? details.time : "",
                            date: details.date ? details.date : "",
                            isSystemGenerated: details.isSystemGenerated ? details.isSystemGenerated : "0"
                        }}
                        validationSchema={yup.object().shape({
                            activityId: yup.string().required("Required"),
                            group: yup.string().required("Required"),
                            time: yup.string(),
                            date: yup.string(),
                            fileName: yup.string().required("Required"),
                            isSystemGenerated: yup.string(),
                            file: yup.mixed(),

                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values, { resetForm }) => {
             
                            var today = new Date();
                            var hours = today.getHours();
                            var minutes = String(today.getMinutes()).padStart(2, '0');
                            var period = hours >= 12 ? 'PM' : 'AM';
                            hours = hours % 12 || 12;
                            var formattedHours = String(hours).padStart(2, '0');
                            var time = formattedHours + ":" + minutes;
                            const month = monthNames[today.getMonth()];
                            const day = today.getDate();
                            const year = today.getFullYear();
                            const formattedDate = `${month} ${day}, ${year}`;
                            values.time = time;
                            values.date = formattedDate;

                            const formData = new FormData();
                            for (let name in values) {
                                if (Array.isArray(values[name])) {
                                    formData.append(
                                        name,
                                        JSON.stringify(values[name])
                                    );
                                } else {
                                    formData.append(name, values[name]);
                                }
                            }

                            onSubmit(formData, { resetForm });
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                                <Row
                                    className={`notesFormRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`}
                                >

                                    {/* <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                        <CMSFormFile
                                            name="file"
                                            form={form}
                                        />
                                    </Col> */}
                                    <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                        <CMSFormInput
                                            name="fileName"
                                            required={true}
                                            placeholder="File Name"
                                            form={form}
                                        />
                                    </Col>
                                    <Col xl={12} lg={12} md={12} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="group"
                                            placeholder={{ label: "Select Group", value: "" }}
                                            form={form}
                                            required={true}
                                            onChange={form.handleChange}
                                            options={[
                                                { label: "Reports", value: "Reports" },
                                                { label: "Photos", value: "Photos" },
                                                { label: "Others", value: "Others" },
                                            ]}
                                        />
                                    </Col>

                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-3 mb-3 d-flex align-items-center justify-content-center"
                                    >
                                

                                        <button
                                            className="formSubmissionButton dark "
                                            type="submit"
                                            onClick={(e) => {
                                                form.handleSubmit(e);
                                            }}
                                        >
                                            SAVE
                                        </button>
                                    </Col>

                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed"
                                    >
                                        {!updateState.isLoading &&
                                            updateState.status === "submitFailed" && (
                                                <p className="mb-0">
                                                    <i
                                                        className="fa fa-exclamation-circle me-2"
                                                        aria-hidden="true"
                                                    ></i>
                                                    Action Failed! Try again.
                                                </p>
                                            )}
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>}
            </>




        </>
    );
};

export default EditDownload;
