import React from 'react'
import { Col, Row } from 'react-bootstrap'

const AboutAEP = () => {
    return (
        <Row className="w-100 mx-auto">
            <Col className="p-5 aboutAEP">
                <p>Want to increase your event visibility and compliance while saving on costs?</p>
                <p>Now it’s possible with AHEAD’s unique events management platform.</p>
                <p>With best-in-class technology, you can now run and handle your entire event or meeting from start to finish seamlessly- from sending e-invites, online registrations, check-in process and most importantly providing you with post-event analytics and reports to measure your event success.</p>
                <p>With a flexible and extremely user-friendly interface, this platform allows you to run your event from your smart phone, computer or tablet, anywhere, anytime.</p>
                <p>Major platform applications:</p>
                <ul>

                    <li>Events Registration and RSVP applications</li>
                    <li>Onsite attendees Management Application</li>
                    <li>Audience Generation and profiling Management application</li>
                    <li>Events Users Mobile application</li>
                    <li>Events Owners Interface Application</li>
                </ul>
            </Col>

        </Row>
    )
}

export default AboutAEP