import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
const CMSFormSelect = (props) => {

  const {
    label,
    name,
    form,
    options,
    selected,
    autoFocus,
    placeholder,
    required,
    onChange,
    filter,
    ...rest
  } = props;
  return (
    <div className="form-group position-relative">
      {label &&
        <label
          htmlFor={name}
          className={`${required == true ? "mandatory_field ps-2 selectLabel " : " "
            } ps-1 mb-3`}
        >
          {" "}
          {label}
        </label>}

      <div>
        <BsChevronDown className="dropIcon" />
      </div>
      <Field
        component="select"
        autoFocus={autoFocus}
        id={name}
        value={form.values[name]}
        name={name}
        onChange={onChange}
        className={`form-control  ${filter ? "filterPartial" : "customInput"
          }   select  ${form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
        {...rest}
      >
        {placeholder &&
          <option className="selectOption" value={placeholder.value}>{placeholder.label} </option>}

        {options && options.map((option) => {
          return (
            <option
              key={option.value}
              className="selectOption"
              value={option.value}
            >
              {option.label} 
            </option>
          );
        })}
      </Field>
      {required === true && <div className="required"></div>}
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormSelect;
