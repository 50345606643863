import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
    TotalAttendees,
    TotalAttendeesFigures,
    TotalAttendeesFiguresOverView,
    UniqueAttendeesFigures,
    findPercentage,
    getAvailablePeopleGroups,
    getClientName,
    getColorOfPeopleGroup,
    getTotalAccounts,
    getTotalAccountsFigures,
    getTotalAccountsOverview,
    headerItems,
    updatedAttendeesListForFigures,
} from "../../../../../helper/sideHeaderHelper";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { FiguresContext } from "../../../../../context/FiguresContext";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { AttendeeContext } from "../../../../../context/AttendeeContext";

const OnlyFigures = () => {
    const availablePeopleGroups = getAvailablePeopleGroups();
    const { layout, setLayout } = useContext(HomeScreenContext);
    const { figures, setFigures } = useContext(FiguresContext);
    const { attendee } = useContext(AttendeeContext);
    const overview = figures.activeBreakout === `overview-${figures.activeDay}` ? true : false
    useEffect(() => {
        setFigures((prevState) => ({
            ...prevState,
            figuresAttendees: updatedAttendeesListForFigures(),
        }));
    }, [])
    let newArray = [];
    if (availablePeopleGroups && availablePeopleGroups.length > 0) {

        if (availablePeopleGroups[3]) {
            newArray = [
                availablePeopleGroups[0], // Keep the first element as is
                availablePeopleGroups[2], // Move the third element to the second position
                availablePeopleGroups[1], // Move the second element to the third position
                availablePeopleGroups[3]  // Keep the fourth element as is
            ];
        }
        else if (!availablePeopleGroups[3] && availablePeopleGroups[2]) {
            newArray = [
                availablePeopleGroups[0], // Keep the first element as is
                availablePeopleGroups[2], // Move the third element to the second position
                availablePeopleGroups[1]
            ];
        }
        else {
            newArray = availablePeopleGroups
        }
    }
    return (
        <CustomScrollbars style={{ height: `calc(100vh - 185px)` }}>
            
            {figures && figures.figuresAttendees && figures.figuresAttendees.length > 0 ?
                <div className="wholeFigures">
                    <Row className="topBigFigureRow g-0">
                        <Col
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: undefined,
                                    attendeeListFilters: { peopleGroup: "", registrationType: "" },
                                    typeSelection: "attendees",
                                }));
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    leftFullDisplay: true,
                                    activePage: "figuresDetails",
                                    activePageItems: headerItems("figuresDetails"),
                                }));
                            }}
                        >
                            <i className="fa fa-users"></i>
                        </Col>
                        <Col
                            className="textCenter"
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: undefined,
                                    attendeeListFilters: { peopleGroup: "", registrationType: "" },
                                    typeSelection: "attendees",
                                }));
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    leftFullDisplay: true,
                                    activePage: "figuresDetails",
                                    activePageItems: headerItems("figuresDetails"),
                                }));
                            }}
                        >
                            <p>Total Attendees</p>
                        </Col>
                        <Col
                            onClick={() => {
                                setFigures((prevState) => ({
                                    ...prevState,
                                    peopleGroupSelection: undefined,
                                    graphPartSelection: undefined,
                                    attendeeListFilters: { peopleGroup: "", registrationType: "" },
                                    typeSelection: "attendees",
                                }));
                                setLayout((prevState) => ({
                                    ...prevState,
                                    leftSideMenu: true,
                                    leftFullDisplay: true,
                                    activePage: "figuresDetails",
                                    activePageItems: headerItems("figuresDetails"),
                                }));
                            }}
                        >
                            <p className="count">
                                {overview ? TotalAttendeesFiguresOverView("Yes", undefined, undefined) : TotalAttendeesFigures("Yes", undefined, undefined)}

                            </p>
                        </Col>
                    </Row>
                    <Row className="secondRow g-0">

                        <div className="figure_box_row">
                            {newArray &&
                                newArray.length > 0 &&
                                newArray.map((item, index) => {
                                    return (
                                        <div className="figure_box ng-scope">
                                            <div
                                                className="box figure_box_padding figure_box_margin_bottom "
                                                style={{
                                                    color: getColorOfPeopleGroup("foreground", item),
                                                    backgroundColor: getColorOfPeopleGroup(
                                                        "background",
                                                        item
                                                    ),
                                                }} onClick={() => {
                                                    setFigures((prevState) => ({
                                                        ...prevState,
                                                        peopleGroupSelection: item,
                                                        graphPartSelection: undefined,
                                                        attendeeListFilters: { ...figures.attendeeListFilters, registrationType: "" },
                                                        typeSelection: "attendees",
                                                    })); setLayout((prevState) => ({
                                                        ...prevState,
                                                        leftSideMenu: true,
                                                        leftFullDisplay: true,
                                                        activePage: "figuresDetails",
                                                        activePageItems: headerItems("figuresDetails")
                                                    }))
                                                }}
                                            >
                                                <span
                                                    className="has-arrow has-arrow-top"
                                                    style={{
                                                        borderBottomColor: getColorOfPeopleGroup(
                                                            "background",
                                                            item
                                                        ),
                                                    }}
                                                ></span>

                                                <ul className="data_column" >
                                                    <li>
                                                        <span className="data-row-01 ng-binding">{overview ? TotalAttendeesFiguresOverView("Yes", item, undefined) : TotalAttendeesFigures("Yes", item, undefined)} </span>
                                                    </li>

                                                    <li>
                                                        <span className="data-row-02 ng-binding">
                                                            {item === "Owners" ? getClientName() : item}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span className="data-row-03 ng-binding">

                                                            {overview ? findPercentage((TotalAttendeesFiguresOverView("Yes", item, undefined)), (TotalAttendeesFiguresOverView("Yes", undefined, undefined))) : findPercentage((TotalAttendeesFigures("Yes", item, undefined)), (TotalAttendeesFigures("Yes", undefined, undefined)))}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="figure_box_row sublevel">
                                                <div className="sub_box " >
                                                    <div className=" figure_box_padding" onClick={() => {
                                                        setFigures((prevState) => ({
                                                            ...prevState,
                                                            peopleGroupSelection: item,
                                                            graphPartSelection: "Registered",
                                                            typeSelection: "attendees",
                                                            attendeeListFilters: { ...figures.attendeeListFilters, registrationType: "Registered" },
                                                        })); setLayout((prevState) => ({
                                                            ...prevState,
                                                            leftSideMenu: true,
                                                            leftFullDisplay: true,
                                                            activePage: "figuresDetails",
                                                            activePageItems: headerItems("figuresDetails")
                                                        }))
                                                    }} style={{
                                                        color: getColorOfPeopleGroup("foreground", item),
                                                        backgroundColor: getColorOfPeopleGroup(
                                                            "background",
                                                            item
                                                        ),
                                                    }}>
                                                        <span className="has-arrow has-arrow-top" style={{
                                                            borderBottomColor: getColorOfPeopleGroup(
                                                                "background",
                                                                item
                                                            ),
                                                        }}></span>

                                                        <ul className="data_column">
                                                            <li>
                                                                <span className="data-row-01 ng-binding">
                                                                    {overview ? TotalAttendeesFiguresOverView("Yes", item, "Registered") : TotalAttendeesFigures("Yes", item, "Registered")}
                                                                </span>
                                                            </li>

                                                            <li>
                                                                <span className="data-row-02">Reg.</span>
                                                            </li>

                                                            <li>
                                                                <span className="data-row-03 ng-binding">
                                                                    {overview ? findPercentage((TotalAttendeesFiguresOverView("Yes", item, "Registered")), (TotalAttendeesFiguresOverView("Yes", item, undefined))) : findPercentage((TotalAttendeesFigures("Yes", item, "Registered")), (TotalAttendeesFigures("Yes", item, undefined)))}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="sub_box ">
                                                    <div className=" figure_box_padding" onClick={() => {
                                                        setFigures((prevState) => ({
                                                            ...prevState,
                                                            peopleGroupSelection: item,
                                                            graphPartSelection: "Walkin",
                                                            typeSelection: "attendees",
                                                            attendeeListFilters: { ...figures.attendeeListFilters, registrationType: "Walkin" },
                                                        })); setLayout((prevState) => ({
                                                            ...prevState,
                                                            leftSideMenu: true,
                                                            leftFullDisplay: true,
                                                            activePage: "figuresDetails",
                                                            activePageItems: headerItems("figuresDetails")
                                                        }))
                                                    }} style={{
                                                        color: getColorOfPeopleGroup("foreground", item),
                                                        backgroundColor: getColorOfPeopleGroup(
                                                            "background",
                                                            item
                                                        ),
                                                    }}>
                                                        <span className="has-arrow has-arrow-top" style={{
                                                            borderBottomColor: getColorOfPeopleGroup(
                                                                "background",
                                                                item
                                                            ),
                                                        }}></span>

                                                        <ul className="data_column">
                                                            <li>
                                                                <span className="data-row-01 ng-binding">
                                                                    {overview ? TotalAttendeesFiguresOverView("Yes", item, "Walkin") : TotalAttendeesFigures("Yes", item, "Walkin")}
                                                                </span>
                                                            </li>

                                                            <li>
                                                                <span className="data-row-02">Walk-in</span>
                                                            </li>

                                                            <li>
                                                                <span className="data-row-03 ng-binding">
                                                                    {overview ? findPercentage((TotalAttendeesFiguresOverView("Yes", item, "Walkin")), (TotalAttendeesFiguresOverView("Yes", item, undefined))) : findPercentage((TotalAttendeesFigures("Yes", item, "Walkin")), (TotalAttendeesFigures("Yes", item, undefined)))}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* overview updated till above */}
                                                {item != "Owners" &&
                                                    <div
                                                        className="sub_box "
                                                    >
                                                        <div className="figure_box_padding " onClick={() => {
                                                            setFigures((prevState) => ({
                                                                ...prevState,
                                                                peopleGroupSelection: item,
                                                                graphPartSelection: undefined,
                                                                typeSelection: "company",
                                                            }));
                                                            setLayout((prevState) => ({
                                                                ...prevState,
                                                                leftSideMenu: true,
                                                                leftFullDisplay: true,
                                                                activePage: "figuresDetails",
                                                                activePageItems: headerItems("figuresDetails")
                                                            }));
                                                        }} style={{
                                                            color: getColorOfPeopleGroup("foreground", item),
                                                            backgroundColor: getColorOfPeopleGroup(
                                                                "background",
                                                                item
                                                            ),
                                                        }}>
                                                            <span className="has-arrow has-arrow-top" style={{
                                                                borderBottomColor: getColorOfPeopleGroup(
                                                                    "background",
                                                                    item
                                                                ),
                                                            }}></span>

                                                            <ul className="data_column">
                                                                <li>
                                                                    <span className="data-row-01 ng-binding">
                                                                        {overview ? getTotalAccountsOverview(
                                                                            "Yes",
                                                                            item,
                                                                            undefined
                                                                        ) : getTotalAccountsFigures(
                                                                            "Yes",
                                                                            item,
                                                                            undefined
                                                                        )}
                                                                    </span>
                                                                </li>

                                                                <li>
                                                                    <span className="data-row-02">Accounts</span>
                                                                </li>

                                                                <li>
                                                                    <span className="data-row-03"></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                    </Row>
                    <Row className="secondBigRow  g-0">
                        <Col xl={4} xs={12} className="col" onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: undefined,
                                graphPartSelection: undefined,
                                typeSelection: "registered"
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }} >
                            <p className='p-3'>Total Registered</p>
                        </Col>
                        <Col xl={2} xs={12} className='col' onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: undefined,
                                graphPartSelection: undefined,
                                typeSelection: "registered"
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <p className='bigCount'>{overview ? TotalAttendeesFiguresOverView(undefined, undefined, "Registered") : TotalAttendeesFigures(undefined, undefined, "Registered")}</p>
                        </Col>

                        <Col onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: undefined,
                                graphPartSelection: "Shows-Accounts",
                                typeSelection: "registered"
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <div className='holder' >
                                <p className="number">{overview ? TotalAttendeesFiguresOverView("Yes", undefined, "Registered") : TotalAttendeesFigures("Yes", undefined, "Registered")}</p>
                                <p className="text">Shows</p>
                                <p className="percentage">{overview ? findPercentage((TotalAttendeesFiguresOverView("Yes", undefined, "Registered")), (TotalAttendeesFiguresOverView(undefined, undefined, "Registered"))) : findPercentage((TotalAttendeesFigures("Yes", undefined, "Registered")), (TotalAttendeesFigures(undefined, undefined, "Registered")))}</p>
                            </div>
                        </Col>
                        <Col onClick={() => {
                            setFigures((prevState) => ({
                                ...prevState,
                                peopleGroupSelection: undefined,
                                graphPartSelection: "No-Shows-Accounts",
                                typeSelection: "registered"
                            })); setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                leftFullDisplay: true,
                                activePage: "figuresDetails",
                                activePageItems: headerItems("figuresDetails")
                            }))
                        }}>
                            <div className='holder' >
                                <p className="number">{overview ? TotalAttendeesFiguresOverView("No", undefined, "Registered") : TotalAttendeesFigures("No", undefined, "Registered")}</p>
                                <p className="text">No Shows</p>
                                <p className="percentage">{overview ? findPercentage((TotalAttendeesFiguresOverView("No", undefined, "Registered")), (TotalAttendeesFiguresOverView(undefined, undefined, "Registered"))) : findPercentage((TotalAttendeesFigures("No", undefined, "Registered")), (TotalAttendeesFigures(undefined, undefined, "Registered")))}</p>
                            </div>
                        </Col>


                    </Row>
                    <Row className="registered g-0">
                        {availablePeopleGroups && availablePeopleGroups.length > 0 && availablePeopleGroups.map((item, index) => {
                            return (<>
                                <Col className='columns' key={index} >
                                    <span className="has-arrow has-arrow-top"></span>
                                    <div className='holder' onClick={() => {
                                        setFigures((prevState) => ({
                                            ...prevState,
                                            peopleGroupSelection: item,
                                            graphPartSelection: undefined,
                                            typeSelection: "registered",
                                            companyListFilters: { checkin: "" }
                                        })); setLayout((prevState) => ({
                                            ...prevState,
                                            leftSideMenu: true,
                                            leftFullDisplay: true,
                                            activePage: "figuresDetails",
                                            activePageItems: headerItems("figuresDetails")
                                        }))
                                    }} >
                                        <p className="number">{overview ? TotalAttendeesFiguresOverView(undefined, item, "Registered") : TotalAttendeesFigures(undefined, item, "Registered")}</p>
                                        <p className="text">  {item === "Owners" ? getClientName() : item}</p>
                                    </div>
                                    <Row className="thirdRow g-0">
                                        <Col onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: item,
                                                graphPartSelection: "Shows-Accounts",
                                                typeSelection: "registered",
                                                companyListFilters: { checkin: "Shows-Accounts" }
                                            })); setLayout((prevState) => ({
                                                ...prevState,
                                                leftSideMenu: true,
                                                leftFullDisplay: true,
                                                activePage: "figuresDetails",
                                                activePageItems: headerItems("figuresDetails")
                                            }))
                                        }}>
                                            <span className="has-arrow has-arrow-top"></span>
                                            <div className='holder' >
                                                <p className="number">{overview ? TotalAttendeesFiguresOverView("Yes", item, "Registered") : TotalAttendeesFigures("Yes", item, "Registered")}</p>
                                                <p className="text">Shows</p>
                                                <p className="percentage">{overview ? findPercentage((TotalAttendeesFiguresOverView("Yes", item, "Registered")), (TotalAttendeesFiguresOverView(undefined, item, "Registered"))) : findPercentage((TotalAttendeesFigures("Yes", item, "Registered")), (TotalAttendeesFigures(undefined, item, "Registered")))}</p>
                                            </div>
                                        </Col>
                                        <Col onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                peopleGroupSelection: item,
                                                graphPartSelection: "No-Shows-Accounts",
                                                typeSelection: "registered",
                                                companyListFilters: { checkin: "No-Shows-Accounts" }
                                            })); setLayout((prevState) => ({
                                                ...prevState,
                                                leftSideMenu: true,
                                                leftFullDisplay: true,
                                                activePage: "figuresDetails",
                                                activePageItems: headerItems("figuresDetails")
                                            }))
                                        }}>
                                            <span className="has-arrow has-arrow-top"></span>
                                            <div className='holder' >
                                                <p className="number">{overview ? TotalAttendeesFiguresOverView("No", item, "Registered") : TotalAttendeesFigures("No", item, "Registered")}</p>
                                                <p className="text">No Shows</p>
                                                <p className="percentage">{overview ? findPercentage((TotalAttendeesFiguresOverView("No", item, "Registered")), (TotalAttendeesFiguresOverView(undefined, item, "Registered"))) : findPercentage((TotalAttendeesFigures("No", item, "Registered")), (TotalAttendeesFigures(undefined, item, "Registered")))}</p>
                                            </div>
                                        </Col>

                                    </Row>
                                </Col>
                            </>)
                        })}
                    </Row>
                </div> : <h4 className="d-flex align-items-center justify-content-center h-75">Stats Not Available</h4>}
        </CustomScrollbars>
    );
};

export default OnlyFigures;
