import React, { useContext, useState } from 'react'
import "./rightSideMenu.scss"
// icons
import { HiMiniCog8Tooth } from "react-icons/hi2";
import { HiUsers } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { PiListBulletsFill } from "react-icons/pi";
import { FaUserClock } from "react-icons/fa6";
import { RiTwitterXFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { GiExpand } from "react-icons/gi";
import { HomeScreenContext } from '../../../../context/HomeScreenContext';
import { bottomSwiperItems, headerItems } from '../../../../helper/sideHeaderHelper';
import FeaturesConfiguration from '../../../module/home/rightSidePartials/featuresConfiguration/FeaturesConfiguration';
import { checkPermission } from '../../../../helper/permissionsHelper';
const RightSideMenu = () => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [show, setShow] = useState({
        modalShow: false,
    });
    const handleClose = () => {
        setShow((prevState) => ({
            ...prevState,
            modalShow: false,
        }));
        setLayout((prevState) => ({
            ...prevState,
            reloadHome: true
        }))
    }
    const handleShow = () => setShow((prevState) => ({
        ...prevState,
        modalShow: true,
    }));
    const features = bottomSwiperItems();
    return (
        <>
            <div className={`menuAsideRight ${layout.rightFullDisplay ? "rightFullDisplay" : "rightSmallDisplay"} ${layout.rightSideMenu === true ? 'show' : 'hideMenu'}`}>
                <div className='topIcons'>
                    <a className='hideOnMobile' onClick={() => setLayout((prevState) => ({
                        ...prevState,
                        rightFullDisplay: !layout.rightFullDisplay,
                        leftFullDisplay: false,
                        leftSideMenu: false,
                    }))}>
                        <GiExpand className='sideMenuCloseIcon' />
                    </a>
                    <CgClose className='sideMenuCloseIcon' onClick={() => setLayout((prevState) => ({
                        ...prevState,
                        rightSideMenu: false,
                        rightFullDisplay: false,
                    }))} />
                </div>
                <div className='menuHolder'>
                    <ul className={`menuItems`}>
                        {checkPermission("manageOsmaFeatures") && 
                            <li onClick={() => handleShow()}>
                                <HiMiniCog8Tooth className='menuIcon' />
                                Features Configuration
                            </li>}
                        {checkPermission("manageCredentials") &&
                            <li onClick={() => setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                rightSideMenu: false,
                                activePage: "manageCredentials",
                                activePageItems: headerItems("manageCredentials")
                            }))}>
                                <i className="icon icon-credentials menuIcon" />   Manage Credentials
                            </li>}
                        {/* {checkPermission("viewActivityLog") &&
                            <li >
                                <PiListBulletsFill className='menuIcon' />   Activity Log
                            </li>} */}
                        {checkPermission("viewOrganisersActivityLog") &&
                            <li onClick={() => setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                rightSideMenu: false,
                                activePage: "organizerLog",
                                activePageItems: headerItems("organizerLog")
                            }))}>
                                <PiListBulletsFill className='menuIcon' />  Checkin/Checkout Activity Log
                            </li>}
                        {/* {checkPermission("manageScreens") &&
                            <li>
                                <FaDesktop className='menuIcon' />  Manage Screens
                            </li>}
                        {checkPermission("manageSocialWall") &&
                            <li>
                                <RiTwitterXFill className='menuIcon' />  Manage Social Wall
                            </li>} */}
                        {checkPermission("manageDuplicateAttendees") &&
                            <li onClick={() => setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                rightSideMenu: false,
                                activePage: "duplicateAttendee",
                                activePageItems: headerItems("duplicateAttendee")
                            }))}>
                                <HiUsers className='menuIcon' />  Manage Duplicate Attendees
                            </li>}
                        {checkPermission("manageQuarantinedAttendees") &&
                            <li onClick={() => setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                rightSideMenu: false,
                                activePage: "quarantinedAttendee",
                                activePageItems: headerItems("quarantinedAttendee")
                            }))}>
                                <FaUserClock className='menuIcon' />  Manage Quarantined Attendees
                            </li>}
                        {/* {checkPermission("pendingBadgePrinting") &&
                            <li>
                                <IoQrCodeOutline className='menuIcon' />  Pending Badge Printing
                            </li>} */}
                        {checkPermission("aboutAepOsma") &&
                            <li onClick={() => setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                rightSideMenu: false,
                                activePage: "aboutAEP",
                                activePageItems: headerItems("aboutAEP")
                            }))}>
                                <i className="icon icon-aep_icon menuIcon" />  About AEP
                            </li>}
                    </ul>
                </div>

                <FeaturesConfiguration
                    handleClose={handleClose}
                    handleShow={handleShow}
                    closeButton={false}
                    show={show.modalShow}

                />
            </div>
        </>
    )
}

export default RightSideMenu