import React, { useState, useEffect, useContext, useReducer } from 'react'
import TopGraySectionEmpty from '../../leftSidePartials/figures/TopGraySectionEmpty'
import Loading from '../../../../UI/shared/loading/Loading';
import { AuthContext } from '../../../../../context/AuthContext';
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import { listingInitialState, listingReducer } from '../../../../../reducers/listingReducer';
import { updateInitialState, updateReducer } from '../../../../../reducers/updateReducer';
import axios from '../../../../../config/axios';
import { Button, Col, Row } from 'react-bootstrap';
import CustomScrollbars from '../../../../UI/shared/customScrollbar/CustomScrollbar';
import "./manageCred.scss"
import UpdateLoginDetails from './UpdateLoginDetails';
import { Link, useNavigate } from 'react-router-dom';
const ManageCredentials = () => {
  const [active, setActive] = useState("All");
  const { userAuthState } = useContext(AuthContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const navigate = useNavigate();
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );

  const [show, setShow] = useState({
    modalShow: false,
    details: null,
  });
  const handleClose = () => {
    setShow((prevState) => ({
      ...prevState,
      modalShow: false,
    }));
  }

  const handleShow = (item) => setShow((prevState) => ({
    ...prevState,
    modalShow: true,
    details: item,
  }));
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const handleLocalStorage = (data) => {
    localStorage.setItem("manageCredentialsData", JSON.stringify(data));
    localStorage.setItem("manageCredentialsType", active);
  }
  const loadCred = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    await sleep(100)
    try {
      const res = await axios.get(
        `get-credentials/${userAuthState.user && userAuthState.user.activityId}`
      );

      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const onGenerateUsherette = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`generate-usherette/${userAuthState.user && userAuthState.user.activityId}`);

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      loadCred();
      setTimeout(() => {
        updateDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 1000);
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    loadCred();
    updateDispatch({
      type: "FETCH_SUCCESS",
    });
  }, []);
  return (
    <>
      {(listingState.isLoading || updateState.isLoading) && <Loading />}
      <TopGraySectionEmpty>
        {listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
          <Row className="notesTopSection">
            <Col className="leftSection">
              <div className="notesType">
                <p
                  className={active === "All" ? "active" : ""}
                  onClick={() => setActive("All")}
                >
                  All
                </p>
                <p
                  className={active === "Organizer" ? "active" : ""}
                  onClick={() => setActive("Organizer")}
                >
                  Organizer
                </p>
                <p
                  className={active === "Usherette" ? "active" : ""}
                  onClick={() => setActive("Usherette")}
                >
                  Usherette
                </p>
                <p
                  className={active === "Owner" ? "active" : ""}
                  onClick={() => setActive("Owner")}
                >
                  Owner
                </p>
                <p
                  className={active === "Speaker" ? "active" : ""}
                  onClick={() => setActive("Speaker")}
                >
                  Speaker
                </p>
              </div>
            </Col>
            <Col xl={3} lg={2} md={3} sm={4} xs={4} className="rightSection mobileLeft">
              {listingState.data.item.length > 0 &&
                <Link className="addNoteButton" onClick={() => handleLocalStorage(listingState.data.item)} target="_blank" to={'/print'}>
                  <i className="fa fa-print" aria-hidden="true"></i>
                </Link>}
            </Col>
          </Row>}
      </TopGraySectionEmpty>
      <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
        {listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
          <Row className="w-100 mx-auto" >
            <Col className="notesListing mx-2">
              {active === "Usherette" && <button
                className="formSubmissionButton borderPink"
                onClick={() => onGenerateUsherette()}

              >
                Generate Usherette
              </button>}
              {listingState.data.item.filter(item => active === "All" || active === item.type).map((item, i) => {
                 return (
                  <div className="noteList">

                    <div className="note">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className={"noteText text-quaternaryColor mb-1"}>
                          Type: <span>{item.type}</span>
                        </div>
                        <div className="editCred " onClick={() => {

                          handleShow(item);
                        }}>
                          <i className="fa fa-pencil " aria-hidden="true"></i>
                        </div>
                      </div>

                      <div className="noteText text-quaternaryColor mb-1">
                        Name: {item.name}
                      </div>
                      <div className="noteText text-quaternaryColor mb-1">
                        Email: {item.email}
                      </div>
                      <div className="noteText text-quaternaryColor mb-1">
                        Username: <span className="text-primaryColor">{item.userName}</span>
                      </div>
                      <div className="noteText text-quaternaryColor">
                        Password: <span className="text-primaryColor">{item.password}</span>
                      </div>
                    </div>

                  </div>
                )
              })}
              {listingState.data.item.filter(item => active === "All" || active === item.type).length<=0 && <h3 className='my-5 pt-5 text-center'>No Records found!</h3>}


            </Col>
          </Row>}
        {listingState.data && listingState.data.item && listingState.data.item.length < 0 &&
          <h4>No Records Found!</h4>}
        {show.details != null &&
          <UpdateLoginDetails
            handleClose={handleClose}
            handleShow={handleShow}
            closeButton={false}
            show={show.modalShow}
            loadCred={loadCred}
            details={show.details}

          />}
      </CustomScrollbars>

    </>
  )
}

export default ManageCredentials