import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FiguresContext } from "../../../../../../context/FiguresContext";
import CustomScrollbars from "../../../../../UI/shared/customScrollbar/CustomScrollbar";
import TopGraySectionEmpty from "../TopGraySectionEmpty";
import SingleGroupCountDetails from "./details/SingleGroupCountDetails";
import ListSection from "./details/ListSection";
import "./combined.scss"
import PieChart from "./details/PieChart";
const CombinedDetails = () => {
    const { figures, setFigures } = useContext(FiguresContext);
    useEffect(() => { }, [figures]);
    const overview = figures.activeBreakout === `overview-${figures.activeDay}` ? true : false
    return (
        <>
            <TopGraySectionEmpty />

            <Row className="CombinedDetails  hideOnMobile">

                <Col xl={6} lg={6} md={6} xs={12} className="countWiseDisplay ">
                    <CustomScrollbars style={{ height: `calc(100vh - 185px)` }}>
                        <Row>

                            <Col xl={5} xs={12} className='pe-1'>
                                <SingleGroupCountDetails />
                            </Col>
                            <Col xl={7} xs={12} className='pieChart hideOnMobile mt-1'>
                                <PieChart />
                            </Col>

                        </Row>
                    </CustomScrollbars>
                </Col>

                <Col lg={6} md={6} xs={12} className='ps-1'>

                    <ListSection />
                </Col>

            </Row>
            <Row className="figuresDetails g-0 showOnMobile">
                <CustomScrollbars style={{ height: `calc(100vh - 185px)` }}>
                    <Col xl={6} lg={6} md={6} xs={12} className="countWiseDisplay ">

                        <Row>
                            <Col xl={5} xs={12} className='pe-1'>
                                <SingleGroupCountDetails />
                            </Col>
                            <Col xl={7} xs={12} className='pieChart hideOnMobile'>
                                <PieChart />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={6} md={6} xs={12} className='p-0'>

                        <ListSection />
                    </Col>
                </CustomScrollbars>
            </Row>
        </>

    );
};

export default CombinedDetails;
