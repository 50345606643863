import { ErrorMessage } from 'formik';
import React, { useState, useEffect, useCallback, useReducer } from 'react'
import ReactFancyBox from 'react-fancybox'
import { OverlayTrigger, Row, Tooltip, Col, Popover } from 'react-bootstrap'
import { updateInitialState, updateReducer } from '../../../../reducers/updateReducer';
import axios from '../../../../config/axios';
const CMSFormImage = (props) => {
  const { name, label, form, required, reLoad, moduleName, className, id, whiteColor, helpMessage } = props;
  let initialImageURL = form.values[name] ? process.env.REACT_APP_BACKEND_URL + '/public/' + form.values[name] : null;
  const [removeState, removeDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(initialImageURL);
  const [fileName, setFileName] = useState(form.values[name] ? form.values[name] : "Add File");
  useEffect(() => {
    if (!image || !image.name) return;
    setImageURL(URL.createObjectURL(image));
    setFileName(image.name);
  }, [image]);

  const onImageChange = (e) => {
    if (e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      form.setFieldValue(name, e.target.files[0]);
    } else {
      clearImage();
    }
  };
  const onRemoveFile = async (data) => {


    removeDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`/remove-file`, data); //
      removeDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      reLoad();
      clearImage();

    } catch (error) {
      clearImage();
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;


      removeDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        removeDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const clearImage = async () => {

    setImage(null);
    await form.setFieldValue(name, "");
    setImageURL(null);
    setFileName("Add File");
    form.setFieldTouched(name, false);
  };

  return (
    <>

      <div className="form-group image">


        <div
          className={` input-group  ${form.touched[name] && form.errors[name] ? "is-invalid" : " "
            }`}
        >

          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id={name}
              name={name}
              aria-describedby="inputGroupFileAddon01"
              accept="image/*"
              onChange={(e) => onImageChange(e)}
              onBlur={form.handleBlur}
            />
            <label
              className={` ${fileName != "Add File" ? "pink" : ""} ${form.touched[name] && form.errors[name]
                ? "is-invalid custom-file-label"
                : "custom-file-label"
                }`}
              htmlFor="inputGroupFile01"
            >
              <p className='mb-0'>{fileName}</p>
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
              {required === true && <div className="required"></div>}
            </label>

          </div>
          {form.touched[name] && form.errors[name] && (
            <span id={`${name}-error"`} className="errorMessage">
              <ErrorMessage name={name} />
            </span>
          )}
        </div>

      </div>


      {(form.values[name] && (form.values[name] != null || form.values[name] != "")) ? (
        <Row>
          <Col xs={6} md={2} lg={1} className={`pageContent ms-2 mt-2 p-0 ${whiteColor === true ? "white" : ""} `}>
            <img src={imageURL} className='thumbnail' />
            <div className="iconDiv ">
              <i
                class="fa fa-times"
                onClick={() => onRemoveFile({ moduleName: moduleName, fieldName: name, id: id })}
              />
            </div>


          </Col>
          <Col></Col>
        </Row >
      ) : null}

    </>
  );
};

export default CMSFormImage;
