import React, { createContext, useState } from 'react';
export const OrganizerContext = createContext({});
const OrganizerContextProvider = (props) => {
    const [organizer, setOrganizer] = useState({
        type: "",
        action: "",
        typeRaw:"",
        name: "",
        list: [],
        AllList: [],
    })
    return (
        <OrganizerContext.Provider value={{ organizer, setOrganizer }}>
            {props.children}
        </OrganizerContext.Provider>
    );
};
export default OrganizerContextProvider;

