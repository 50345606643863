import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { getAvailablePeopleGroups, getClientName, getColorOfPeopleGroup } from '../../../../../../helper/sideHeaderHelper'

const BoxCount = ({ partners, sponsors, customers, owners }) => {
    const availablePeopleGroup = getAvailablePeopleGroups()
    return (
        <>{availablePeopleGroup && availablePeopleGroup.length > 0 && <>
            <div className='boxCount '>
                {availablePeopleGroup[0] &&
                    <div className='cols' style={{
                        color: getColorOfPeopleGroup("foreground", availablePeopleGroup[0]),
                        backgroundColor: getColorOfPeopleGroup(
                            "background",
                            availablePeopleGroup[0]
                        ),
                    }}>
                        <h3>
                            {availablePeopleGroup[0] === "Partners" ? partners : availablePeopleGroup[0] === "Sponsors" ? sponsors : availablePeopleGroup[0] === "Owners" ? owners : availablePeopleGroup[0] === "Customers" ? customers : null}
                        </h3>
                        <p>{availablePeopleGroup[0] === "Owners" ? getClientName() : availablePeopleGroup[0]}</p>

                    </div>}
                {availablePeopleGroup[1] &&
                    <div className='cols' style={{
                        color: getColorOfPeopleGroup("foreground", availablePeopleGroup[1]),
                        backgroundColor: getColorOfPeopleGroup(
                            "background",
                            availablePeopleGroup[1]
                        ),
                    }}>
                        <h3>
                            {availablePeopleGroup[1] === "Partners" ? partners : availablePeopleGroup[1] === "Sponsors" ? sponsors : availablePeopleGroup[1] === "Owners" ? owners : availablePeopleGroup[1] === "Customers" ? customers : null}
                        </h3>
                        <p>{availablePeopleGroup[1] === "Owners" ? getClientName() : availablePeopleGroup[1]}</p>

                    </div>}
                {availablePeopleGroup[2] &&
                    <div className='cols' style={{
                        color: getColorOfPeopleGroup("foreground", availablePeopleGroup[2]),
                        backgroundColor: getColorOfPeopleGroup(
                            "background",
                            availablePeopleGroup[2]
                        ),
                    }}>
                        <h3>
                            {availablePeopleGroup[2] === "Partners" ? partners : availablePeopleGroup[2] === "Sponsors" ? sponsors : availablePeopleGroup[2] === "Owners" ? owners : availablePeopleGroup[2] === "Customers" ? customers : null}
                        </h3>
                        <p>{availablePeopleGroup[2] === "Owners" ? getClientName() : availablePeopleGroup[2]}</p>

                    </div>}
                {availablePeopleGroup[3] &&
                    <div className='cols' style={{
                        color: getColorOfPeopleGroup("foreground", availablePeopleGroup[3]),
                        backgroundColor: getColorOfPeopleGroup(
                            "background",
                            availablePeopleGroup[3]
                        ),
                    }}>
                        <h3>
                            {availablePeopleGroup[3] === "Partners" ? partners : availablePeopleGroup[3] === "Sponsors" ? sponsors : availablePeopleGroup[3] === "Owners" ? owners : availablePeopleGroup[3] === "Customers" ? customers : null}
                        </h3>
                        <p>{availablePeopleGroup[3] === "Owners" ? getClientName() : availablePeopleGroup[3]}</p>

                    </div>}
            </div>
        </>}
        </>

    )
}

export default BoxCount