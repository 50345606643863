import React, { useRef, useState, useContext, useEffect, useReducer } from "react";
import GrayHeader from "../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import { Button, Col, Row } from "react-bootstrap";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import { handleFormatArray, sleep } from "../../../../../helper/commonHelper";
import {
    getAllPeopleGroup,
    getAttendeeDetails,
    getAvailablePeopleGroups,
    getCompanyNameFromGroup,
    getIdFromPeopleGroup,
    getPeopleGroupFromGroup,
    getTitleArray,
    headerItems,
    highestIdAttendee,
    highestIdObject,
} from "../../../../../helper/sideHeaderHelper";
import axios from "../../../../../config/axios";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import Loading from "../../../../UI/shared/loading/Loading";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { AuthContext } from "../../../../../context/AuthContext";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const AddAttendee = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const { userAuthState, setUser } = useContext(AuthContext);
    const { attendee, setAttendee } = useContext(AttendeeContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const details = getAttendeeDetails(attendee.activeAttendeeDetailsId);
    const fields = eventData && eventData.formFields;
    const myRef = useRef(null);
    let activeDay = localStorage.getItem("activeDay");
    let activeBreakout = localStorage.getItem("activeBreakout");
    const [formInitialData, setFormInitialData] = useState(null);
    const countries = eventData && eventData.countries ? eventData.countries : [];
    const executeScroll = () => myRef.current.scrollIntoView();
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const handleScroll = (error) => {
        if (Object.keys(error).length > 0) {
            const element = document.getElementsByName(Object.keys(error)[0])[0];
            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 20);
        }
    };

    const printBadge = eventData.badge && eventData.badge.printBadge
    const onSubmit = async (data) => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes();
        const newData = { _id: highestIdAttendee() + "1", initialLetter: data.firstName.charAt(0).toLowerCase(), checkin: "Yes", checkinTime: time, ...data }
        const newDataAction = { activityId: userAuthState.user && userAuthState.user.activityId, dayId: attendee.activeDay, breakoutId: attendee.activeBreakout === null || attendee.activeBreakout === "null" ? null : attendee.activeBreakout, personId: highestIdAttendee() + "1", checkin: "Yes", checkinTime: time }
        const oldData = attendeesContent
        const updatedUpdates = {
            ...attendeesContent,
            attendees: [...attendeesContent.attendees, newData],
            personActions: [...attendeesContent.personActions, newDataAction]
        };

        //await sleep(1000)



        const addAttendee = async () => {
            try {
                const res = await axios.post(`add-attendee/${userAuthState.user && userAuthState.user.activityId}`, { ...data, activityId: userAuthState.user && userAuthState.user.activityId, dayId: activeDay, breakoutId: activeBreakout === null || activeBreakout === "null" ? null : activeBreakout });

                if (res.status === 201) {
                    setAttendee((prevState) => ({
                        ...prevState,
                        reset: true,
                    }))
                    if (printBadge === "Yes" && checkPermission("printBadge")) {
                        setTimeout(() => {
                            setLayout((prevState) => ({
                                ...prevState,
                                // leftSideMenu: false,
                                badgePrint: true,
                                badgePrintingAttendee: res.data && res.data.item,
                                badgeBack: "attendeesList"
                            }))
                        }, 500);

                    }
                    updateDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                        payload: res.data,
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                        if (layout.activePage === "addAttendee") {
                            setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                activePage: "attendeesList",
                                activePageItems: headerItems("attendeesList")
                            }))
                        }

                    }, 4000);
                } else {
                    setAttendee((prevState) => ({
                        ...prevState,
                        reset: true,
                    }))
                    localStorage.setItem("attendeesContent", JSON.stringify(oldData));
                    updateDispatch({
                        type: "FORM_SUBMISSION_FAILURE",
                        payload: "error",
                    });
                    setTimeout(() => {
                        updateDispatch({
                            type: "FETCH_SUCCESS",
                        });
                    }, 2000);
                }



            } catch (error) {
                console.error("There was a problem with the delete operation:", error);
                localStorage.setItem("attendeesContent", JSON.stringify(oldData));
                updateDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: error,
                });
                setTimeout(() => {
                    updateDispatch({
                        type: "FETCH_SUCCESS",
                    });
                }, 2000);
            }
        }
        if (layout.isServerBusy === true) {
            setTimeout(() => {
                localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
                addAttendee();
            }, 2000);
        } else {
            localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
            addAttendee();
        }

    };

    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });

    }, [])


    return (
        <>
            <GrayHeader />
            {/* {layout.isServerBusy ? "Busy" : ""} */}
            <CustomScrollbars style={{ height: `calc(100vh - 190px) ` }}>
                {updateState.isSubmitted ?
                    <div className="successMessage">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>Attendee Added Successfully</h2>

                    </div> :
                    <Formik
                        initialValues={{
                            peopleGroup: "",
                            srcOfReg: "Website",
                            registrationType: "Walkin",
                            title: "",
                            firstName: "",
                            lastName: "",
                            nameArabic: "",
                            company: "",
                            companyArabic: "",
                            designation: "",
                            email: "",
                            mobile: "",
                            telephone: "",
                            sector: "",
                            address: "",
                            city: "",
                            country: "",
                            nationality: "",
                            gender: "",
                            companions: "",
                            unifiedId: "",
                            tableInfo: "",
                            status: "1",
                        }}
                        validationSchema={yup.object().shape({
                            peopleGroup: yup.string().required("Select People Group"),
                            title:
                                fields.title === "Yes"
                                    ? yup.string().required("Enter Title")
                                    : yup.string(),
                            firstName:
                                fields.firstName === "Yes"
                                    ? yup.string().required("Enter First Name")
                                    : yup.string(),
                            lastName:
                                fields.lastName === "Yes"
                                    ? yup.string().required("Enter Last Name")
                                    : yup.string(),
                            nameArabic:
                                fields.nameArabic === "Yes"
                                    ? yup.string().required("Enter Arabic Name")
                                    : yup.string(),
                            company:
                                fields.company === "Yes"
                                    ? yup.string().required("Enter company")
                                    : yup.string(),
                            companyArabic:
                                fields.companyArabic === "Yes"
                                    ? yup.string().required("Enter Company Arabic")
                                    : yup.string(),
                            // designation:
                            //     fields.designation === "Yes"
                            //         ? yup.string().required("Enter Designation")
                            //         : yup.string(),

                            designation: yup.string().when('peopleGroup', ([peopleGroup], schema) => {
                                if (peopleGroup === 'Owners' || peopleGroup === 'Owner')
                                    return yup.string();
                                else
                                    return yup.string().required('Field is required');
                            }),
                            email:
                                fields.email === "Yes"
                                    ? yup.string().email("Enter valid email").required("Enter Email")
                                    : yup.string().email("Enter valid email"),
                            mobile:
                                fields.mobile === "Yes"
                                    ? yup.string().required("Enter Mobile")
                                    : yup.string(),
                            telephone:
                                fields.telephone === "Yes"
                                    ? yup.string().required("Enter Telephone")
                                    : yup.string(),
                            sector:
                                fields.sector === "Yes"
                                    ? yup.string().required("Enter Sector")
                                    : yup.string(),
                            address:
                                fields.address === "Yes"
                                    ? yup.string().required("Enter Address")
                                    : yup.string(),
                            city:
                                fields.city === "Yes"
                                    ? yup.string().required("Enter City")
                                    : yup.string(),
                            country:
                                fields.country === "Yes"
                                    ? yup.string().required("Select country")
                                    : yup.string(),
                            nationality:
                                fields.nationality === "Yes"
                                    ? yup.string().required("Select nationality")
                                    : yup.string(),

                            gender:
                                fields.gender === "Yes"
                                    ? yup.string().required("Select gender")
                                    : yup.string(),

                            companions:
                                fields.companions === "Yes"
                                    ? yup.string().required("Enter Companions")
                                    : yup.string(),
                            unifiedId:
                                fields.unifiedId === "Yes"
                                    ? yup.string().required("Enter Unified Id")
                                    : yup.string(),
                            tableInfo:
                                fields.tableInfo === "Yes"
                                    ? yup.string().required("Enter Unified Id")
                                    : yup.string(),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values) => {

                            if (getPeopleGroupFromGroup(values.peopleGroup) === "Sponsor") {

                                values = { ...values, sponsorId: getIdFromPeopleGroup(values.peopleGroup) }
                                values = { ...values, peopleGroup: "Sponsors" }

                            }
                            else if (getPeopleGroupFromGroup(values.peopleGroup) === "Partner") {

                                values = { ...values, partnerId: getIdFromPeopleGroup(values.peopleGroup) }
                                values = { ...values, peopleGroup: "Partners" }
                            }


                            values = { ...values, fullName: values.firstName + " " + values.lastName, companions: values.companions ? parseInt(values.companions) : "" }



                            onSubmit(values)
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>


                                <Row className={`editAttendeeRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`} ref={myRef}>

                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                        <CMSFormSelect
                                            name="peopleGroup"
                                            placeholder={{ label: "Select People Group", value: "" }}
                                            form={form}
                                            onChange={(e) => { form.handleChange(e); form.setFieldValue("company", getCompanyNameFromGroup(e.target.value)) }}
                                            options={getAllPeopleGroup()}
                                            required={true}
                                        />
                                    </Col>
                                    {fields.title && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="title"
                                                placeholder={{ label: "Select Title", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={getTitleArray()}
                                                required={fields.title === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.firstName && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="firstName"
                                                placeholder="First Name"
                                                form={form}
                                                required={fields.firstName === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.lastName && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="lastName"
                                                placeholder="Last Name"
                                                form={form}
                                                required={fields.lastName === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.nameArabic && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="nameArabic"
                                                placeholder="Name (Arabic)"
                                                form={form}
                                                required={fields.nameArabic === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.company && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="company"
                                                placeholder="Company"
                                                disabled={form.values.peopleGroup ? getCompanyNameFromGroup(form.values.peopleGroup) === "" ? false : true : false}
                                                //value={(form.values.peopleGroup === "Customers" || form.values.peopleGroup === "") ? "" : getCompanyNameFromGroup(form.values.peopleGroup)}
                                                form={form}
                                                required={fields.company === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.companyArabic && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="companyArabic"
                                                placeholder="Company (Arabic)"
                                                form={form}
                                                required={fields.companyArabic === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.designation && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="designation"
                                                placeholder="Designation"
                                                form={form}
                                                required={(fields.designation === "Yes" && form.values['peopleGroup'] !== 'Owners' && form.values['peopleGroup'] !== 'Owner') ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.email && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="email"
                                                placeholder="Email"
                                                form={form}
                                                required={fields.email === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.mobile && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="mobile"
                                                placeholder="Mobile"
                                                form={form}
                                                required={fields.mobile === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.telephone && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="telephone"
                                                placeholder="Telephone"
                                                form={form}
                                                required={fields.telephone === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.sector && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="sector"
                                                placeholder="Sector"
                                                form={form}
                                                required={fields.sector === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.address && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="address"
                                                placeholder="Address"
                                                form={form}
                                                required={fields.address === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.city && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="city"
                                                placeholder="City"
                                                form={form}
                                                required={fields.city === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.country && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="country"
                                                placeholder={{ label: "Select Country", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={handleFormatArray(countries)}
                                                required={fields.country === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.nationality && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="nationality"
                                                placeholder={{ label: "Select Nationality", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={handleFormatArray(countries)}
                                                required={fields.nationality === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.gender && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormSelect
                                                name="gender"
                                                placeholder={{ label: "Select Gender", value: "" }}
                                                form={form}
                                                onChange={form.handleChange}
                                                options={[
                                                    { label: "Male", value: "Male" },
                                                    { label: "Female", value: "Female" },
                                                ]}
                                                required={fields.gender === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.companions && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="companions"
                                                placeholder="Companions"
                                                form={form}
                                                required={fields.companions === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.unifiedId && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="unifiedId"
                                                placeholder="Unified Id"
                                                form={form}
                                                required={fields.unifiedId === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    {fields.tableInfo && (
                                        <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                            <CMSFormInput
                                                name="tableInfo"
                                                placeholder="Table Info"
                                                form={form}
                                                required={fields.tableInfo === "Yes" ? true : false}
                                            />
                                        </Col>
                                    )}
                                    <Col
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-4 mb-3 d-flex align-items-center justify-content-end"
                                    >
                                        <button
                                            className="formSubmissionButton lightGray me-2"
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default form submission
                                                form.resetForm(); // Reset form values to initial state

                                            }}
                                        >
                                            RESET
                                        </button>
                                        <button
                                            className="formSubmissionButton pink "
                                            type="submit"
                                            onClick={(e) => {
                                                form.handleSubmit(e);
                                                handleScroll(form.errors);
                                            }}
                                        >
                                            {updateState.isLoading ? "PROCESSING" : (printBadge === "Yes" && checkPermission("printBadge")) ? "SAVE & PRINT" : "SAVE"}
                                        </button>
                                    </Col>

                                    <Col xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed">
                                        {!updateState.isLoading && updateState.status === "submitFailed" && <p className="mb-0">
                                            <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                                            Action Failed! Try again.
                                        </p>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>}
            </CustomScrollbars>




        </>
    );
};

export default AddAttendee;
