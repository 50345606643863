import { updatedAttendeesListForCombined } from "./combinedReportHelper";
import { TotalAttendeesByDayBreakout } from "./sideHeaderHelper";
const generatedColors = {};
export const getRandomColor = (identifier) => {
    if (!generatedColors[identifier]) {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        generatedColors[identifier] = color;
    }
    return generatedColors[identifier];
}

export const BuildGraphDataForOverview = (graphPartSelection, overview, setOverview) => {

    const totalEventCheckedIns = TotalAttendeesByDayBreakout('Yes', overview.peopleGroupSelection, undefined, "", "");
    const totalCurrentCheckedIns = TotalAttendeesByDayBreakout('Yes', overview.peopleGroupSelection, undefined, overview.day, overview.breakout);
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    let days = eventData.eventDays;
    let sessions = eventData.eventBreakouts

    let result = [];

    days.forEach((day, index) => {
        const matchingSessions = sessions.filter(session => session.day === day.day);
        let dayKey = `day_${day.day}`;
        let dayObj = {
            day: day.day,
            breakout: null,
            color: graphPartSelection && graphPartSelection.toLowerCase() === dayKey.toLowerCase() ? '#CC23A7' : getRandomColor(dayKey),
            name: `${day.title}(${TotalAttendeesByDayBreakout('Yes', overview.peopleGroupSelection, undefined, day.day, null)})`,
            y: calculatePercentage(TotalAttendeesByDayBreakout('Yes', overview.peopleGroupSelection, undefined, day.day, null), totalEventCheckedIns),
            rawname: dayKey,
            selected: graphPartSelection === dayKey,
            sliced: graphPartSelection === dayKey,
        };
       
        result.push(dayObj);

        matchingSessions.forEach(session => {
            let sessionKey = `breakout_${session._id}`;
            let sessionObj = {
                breakout: session._id,
                day: session.day,
                color: graphPartSelection && graphPartSelection.toLowerCase() === sessionKey.toLowerCase() ? '#CC23A7' : getRandomColor(sessionKey),
                name: `${session.sessionTitle}(${TotalAttendeesByDayBreakout('Yes', overview.peopleGroupSelection, undefined, session.day, session._id)})`,
                y: calculatePercentage(TotalAttendeesByDayBreakout('Yes', overview.peopleGroupSelection, undefined, session.day, session._id), totalEventCheckedIns),
                rawname: sessionKey,
                selected: graphPartSelection === sessionKey,
                sliced: graphPartSelection === sessionKey,
            };
     
            result.push(sessionObj);
        });
    });

    function calculatePercentage(numerator, denominator) {
        return (numerator === 0) ? 0 : (numerator * 100 / denominator);
    }
    function getLastPart(str) {
        return str.split("_").pop();
    }
    if (totalEventCheckedIns === 0) {

        return false;
    } {
        return ({
            chartConfig: {
                chart: {
                    type: 'pie',
                    backgroundColor: "#fff",
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                title: {
                    text: '<div className="graph_title"> ' + 'Event Attendance Overview' + '</div>',
                    useHTML: true,
                    style: {
                        color: '#000',
                        fontWeight: 400,
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        enableMouseTracking: true,
                        allowZeroLevelPieDishes: true,
                        dataLabels: {
                            enabled: true,
                            distance: 40,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            connectorColoring: 'none' // Disable automatic coloring of connectors
                        },
                        events: {
                            click: function (event) {
                                setOverview((prevState) => ({
                                    ...prevState,
                                    graphPartSelection: event.point.rawname,
                                    day: event.point.day,
                                    breakout: event.point.breakout
                                }));
                            }
                        },
                    }
                },
                series: [{
                    data: result
                }]
            }
        })
    }
}


export const TotalAttendeesCombinedList = (checkinStatus, peopleGroup, peopleType) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const personActions = attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const attendees = updatedAttendeesListForCombined(filteredActions);
    const filter = (array, condition) => array && array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
        condition.peopleGroup = peopleGroup;
    }

    if (peopleType !== undefined) {
        condition.registrationType = peopleType;
    }

    const filteredAttendees = filter(attendees, condition);

    return filteredAttendees;
}
export const TotalAttendeesByDayBreakoutList = (checkinStatus, peopleGroup, peopleType, day, breakout) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else if (day === "" && breakout === "") {
        const value = TotalAttendeesCombinedList(checkinStatus, peopleGroup, peopleType);
        return value;
    }
    else {

        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];

        const newList = [];

        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === parseInt(day)) && (obj.breakoutId === breakout)).find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });
        const filter = (array, condition) => array && array.filter(item => {
            for (const key in condition) {
                if (item[key] !== condition[key]) {
                    return false;
                }
            }
            return true;
        });

        let condition = {};

        if (checkinStatus !== undefined) {
            condition.checkin = checkinStatus
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
            condition.peopleGroup = peopleGroup;
        }

        if (peopleType !== undefined) {
            condition.registrationType = peopleType;
        }

        const filteredAttendees = filter(newList, condition);
        let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
        let totalCompanions = 0;

        if (totalActualAttendees > 0) {
            filteredAttendees.forEach(attendee => {
                if (attendee.companions) {
                    totalCompanions += parseInt(attendee.companions);
                }
            });
        }

        return filteredAttendees;

    }

}

export const TotalAttendeesByDayBreakoutOverview = (checkinStatus, peopleGroup, peopleType, day, breakout) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else if (day === "" && breakout === "") {
        const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
        const personActions = attendeesContent.personActions ? attendeesContent.personActions : [];
        function removeDuplicatePersonIds() {
            return personActions && personActions.reduce((uniqueIds, obj) => {
                // Check if the current object's personId is not already in the uniqueIds array
                if (!uniqueIds.some(id => id.personId === obj.personId)) {
                    // Add the current object's personId to the uniqueIds array
                    uniqueIds.push(obj);
                }
                // Return the updated uniqueIds array for the next iteration
                return uniqueIds;
            }, []);
        }
        const filteredActions = removeDuplicatePersonIds();
        const attendees = updatedAttendeesListForCombined(filteredActions);
        const filter = (array, condition) => array && array.filter(item => {
            for (const key in condition) {
                if (item[key] !== condition[key]) {
                    return false;
                }
            }
            return true;
        });

        let condition = {};

        if (checkinStatus !== undefined) {
            condition.checkin = checkinStatus
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
            condition.peopleGroup = peopleGroup;
        }

        if (peopleType !== undefined) {
            condition.registrationType = peopleType;
        }

        const filteredAttendees = filter(attendees, condition);
        let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
        let totalCompanions = 0;

        if (totalActualAttendees > 0) {
            filteredAttendees.forEach(attendee => {
                if (attendee.companions) {
                    totalCompanions += parseInt(attendee.companions);
                }
            });
        }

        return totalActualAttendees + totalCompanions;
    }
    else {

        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];

        const newList = [];

        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === parseInt(day)) && (obj.breakoutId === breakout)).find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });
        const filter = (array, condition) => array && array.filter(item => {
            for (const key in condition) {
                if (item[key] !== condition[key]) {
                    return false;
                }
            }
            return true;
        });

        let condition = {};

        if (checkinStatus !== undefined) {
            condition.checkin = checkinStatus
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
            condition.peopleGroup = peopleGroup;
        }

        if (peopleType !== undefined) {
            condition.registrationType = peopleType;
        }

        const filteredAttendees = filter(newList, condition);
        let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
        let totalCompanions = 0;

        if (totalActualAttendees > 0) {
            filteredAttendees.forEach(attendee => {
                if (attendee.companions) {
                    totalCompanions += parseInt(attendee.companions);
                }
            });
        }

        return totalActualAttendees + totalCompanions;

    }

}