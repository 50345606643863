// useApiUpload.js
import { useContext } from 'react';
import axios from '../../../../config/axios';
import { AuthContext } from '../../../../context/AuthContext';
import { AttendeeContext } from '../../../../context/AttendeeContext';

const useApiPrintAction = () => {
    const { userAuthState } = useContext(AuthContext);
    const { attendee, setAttendee } = useContext(AttendeeContext);
    const PrintBadgeAction = async (id) => {
        const today = new Date();
        const hours = today.getHours();
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const formattedHours = String(hours).padStart(2, '0');
        const time = `${formattedHours}:${minutes}`;

        const values = {
            activityId: userAuthState.user?.activityId,
            attendeeId: id,
            action: "Print Badge",
            time: time,
            performerId: userAuthState.user && userAuthState.user._id,
            performerType: userAuthState.user && userAuthState.user.type,
            personName: userAuthState.user && userAuthState.user.name,
            appType: "Web",
            dayId: attendee.activeDay,
            breakoutId: attendee.activeBreakout,
        }
     
        try {
            const res = await axios.post(`add-organizer-action-log/${userAuthState.user?.activityId}`, values);
            return res.data;
        } catch (error) {
            console.error("There was a problem with the upload operation:", error);
            throw error;
        }
    };

    return { PrintBadgeAction };
};

export default useApiPrintAction;
