import React, { createContext, useContext, useState, useEffect } from 'react';
export const HomeScreenContext = createContext({});
const HomeScreenContextProvider = (props) => {
    let swiperDisplayMode = JSON.parse(localStorage.getItem("swiperDisplayMode"));

    const [layout, setLayout] = useState({
        rightSideMenu: false,
        leftSideMenu: false,
        noAnimation: false,
        imageExpandView: false,
        leftFullDisplay: false,
        rightFullDisplay: false,
        showFigureListViewFullScreen: false,
        swiperDisplayMode: (String(swiperDisplayMode) === null || String(swiperDisplayMode) === "null") ? "Basic" : String(swiperDisplayMode),
        activePage: null,
        activePageItems: null,
        isServerBusy: false,
        combinedDetailBack: "",
        badgePrint: false,
        badgePrintingAttendee: null,
        badgeBack: "",
        noteType: "",
        noteAttendeeId: null,
        reloadHome: false,
        reloadAttendees: false,
    })
    useEffect(() => {
        setLayout((prevState) => ({
            ...prevState,
            noAnimation: false,
        }))
    }, [layout.leftSideMenu])

    return (
        <HomeScreenContext.Provider value={{ layout, setLayout }}>
            {props.children}
        </HomeScreenContext.Provider>
    );
};
export default HomeScreenContextProvider;

