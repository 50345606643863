import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';


const PrivateRoute = ({ children }) => {
    const { userAuthState } = useContext(AuthContext);
  
    return (userAuthState.user && userAuthState.user !== null && userAuthState.user !== 'null') ? children : <Navigate  to="/login" replace />;
};
export default PrivateRoute
