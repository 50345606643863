import React, { useState, useEffect, useContext } from "react";
import { Card, Table } from "react-bootstrap";
import { TotalAttendeesByDayBreakoutList, TotalAttendeesCombinedList } from "../../../../../helper/overviewReportHelper";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import { getClientName, getColorOfPeopleGroup } from "../../../../../helper/sideHeaderHelper";
import { getCheckinValueByDayBreakout } from "../../../../../helper/combinedReportHelper";
import { GiCheckMark } from "react-icons/gi";
import { OverviewReportContext } from "../../../../../context/OverviewReportContext";
import Download from "../../partials/downloadButton/Download";
import OverviewTopSection from "./OverviewTopSection";
const OverviewTable = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const [attendees, setAttendees] = useState([])
    const { overview, setOverview } = useContext(OverviewReportContext);
    function groupByDayAndTitle(data) {
        const groupedData = {};

        data.forEach((item) => {
            if (!groupedData[item.day]) {
                groupedData[item.day] = {};
            }
            let titlesGroup = groupedData[item.day];
            if (!titlesGroup[item.title]) {
                titlesGroup[item.title] = [];
            }
            titlesGroup[item.title].push(item);
        });

        return groupedData;
    }

    let breakoutByGroup = null;
    if (eventData) {
        if (eventData.eventBreakouts) {
            breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts);
        }
    }
    useEffect(() => {
        const allAttendees = TotalAttendeesByDayBreakoutList("Yes", overview.peopleGroupSelection, undefined, overview.day, overview.breakout);
        setAttendees(allAttendees);
    }, [overview])
    const totalColumns = eventData?.eventDays?.reduce((total, day) => {
        const sessionsCount = breakoutByGroup?.[day.day]
            ? Object.values(breakoutByGroup[day.day]).flat().length + 1
            : 1;
        return total + sessionsCount;
    }, 1); // Start with 1 for the "Attendee Details" column

   
    return (
        <>


            <div className="hideOnMobile">

                <CustomScrollbars style={{ height: `calc(100vh - 200px) ` }}>

                    <div className="tableContainer">
                        <Table className="responsive_tableMain">
                            <thead>
                                <tr>
                                    <th className="text-primaryColor  fs-9">Attendee Details</th>
                                    {eventData?.eventDays?.length > 0 ? (
                                        eventData.eventDays.map((day, i) => {
                                            const sessionsCount = breakoutByGroup?.[day.day]
                                                ? Object.values(breakoutByGroup[day.day]).flat().length + 1
                                                : 1;
                                            return (
                                                <th
                                                    className="gray-bg text-center text-primaryColor  fs-9"
                                                    key={i}
                                                    colSpan={sessionsCount > 0 ? sessionsCount : 1}
                                                >
                                                    <p className="mb-0">{day.title}</p>
                                                </th>
                                            );
                                        })
                                    ) : null}
                                </tr>
                                <tr>
                                    <th className="gray-bg"></th>
                                    {eventData?.eventDays?.length > 0 ? (
                                        eventData.eventDays.map((day, i) => (
                                            breakoutByGroup?.[day.day] ? (
                                                <>
                                                    <th className="gray-bg fs-9 text-center" key={`plenary-${i}`}>Plenary</th>
                                                    {Object.entries(breakoutByGroup[day.day]).map(([titleKey, items], subIndex) => (
                                                        items.length > 0 ? (
                                                            items.map((session, sessionIndex) => (
                                                                <th className="gray-bg text-center fs-9" key={sessionIndex}>
                                                                    <p className="mb-0">{session.sessionTitle}</p>
                                                                </th>
                                                            ))
                                                        ) : (
                                                            <th className="gray-bg text-center fs-9" key={`placeholder-${subIndex}`}>
                                                                <p className="mb-0">-</p>
                                                            </th>
                                                        )
                                                    ))}
                                                </>
                                            ) : (
                                                <th className="gray-bg text-center fs-9" key={`no-session-${i}`}>
                                                    <p className="mb-0"></p>
                                                </th>
                                            )
                                        ))
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                <>

                                    {attendees && attendees.length > 0 && attendees.map((attendee, index) => {
                                        if (attendee.checkin === "Yes") return (
                                            <tr>
                                                <td className="gray-bg">
                                                    <div className="d-flex flex-column align-items-start justify-content-center overviewDetails">
                                                        <p className="mb-0 name">{attendee.fullName} {attendee.nameArabic && `(${attendee.nameArabic})`}</p>
                                                        {attendee.designation &&
                                                            <p className="mb-0  designation">{attendee.designation}</p>}
                                                        <p className="mb-1 company">{attendee.company} {attendee.companyArabic && (`(${attendee.companyArabic})`)}</p>
                                                        <div className="peopleGroup" style={{ color: getColorOfPeopleGroup('background', attendee.peopleGroup) }}>
                                                            {attendee.peopleGroup === "Owners" ? getClientName() : attendee.peopleGroup}
                                                        </div>
                                                        {
                                                            attendee.companions > 0 &&
                                                            <div className="" >
                                                                <p className="mb-1 mt-1" style={{ color: "cadetblue", fontSize: "12px" }}><i className="fa fa-users" aria-hidden="true"></i> {attendee.companions} Companion(s)</p>
                                                            </div>
                                                        }
                                                    </div>

                                                </td>
                                                {eventData?.eventDays?.length > 0 ? (
                                                    eventData.eventDays.map((day, i) => (
                                                        breakoutByGroup?.[day.day] ? (
                                                            <>
                                                                <td valign="middle" className="gray-bg fs-9 text-center" key={`plenary-${i}`}>{getCheckinValueByDayBreakout(attendee._id, day.day, null) === 1 ? <GiCheckMark className="checkIcon" /> : null}</td>
                                                                {Object.entries(breakoutByGroup[day.day]).map(([titleKey, items], subIndex) => (
                                                                    items.length > 0 ? (
                                                                        items.map((session, sessionIndex) => (
                                                                            <td valign="middle" className="gray-bg text-center fs-9" key={sessionIndex} >
                                                                                {getCheckinValueByDayBreakout(attendee._id, day.day, session._id) === 1 ? <GiCheckMark className="checkIcon" /> : null}
                                                                            </td>
                                                                        ))
                                                                    ) : (
                                                                        <td className="gray-bg text-center fs-9" key={`placeholder-${subIndex}`}>
                                                                            <p className="mb-0">-</p>
                                                                        </td>
                                                                    )
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <td valign="middle" className="gray-bg text-center fs-9" key={`no-session-${i}`}>
                                                                {getCheckinValueByDayBreakout(attendee._id, day.day, null) === 1 ? <GiCheckMark className="checkIcon" /> : null}
                                                            </td>
                                                        )
                                                    ))
                                                ) : null}
                                            </tr>
                                        )
                                    })}
                                    {attendees && attendees.length <= 0 && <tr style={{ height: '200px', fontSize: '1.5rem' }} className="text-center">
                                        <td colSpan={totalColumns} valign="middle"> No records found!
                                        </td></tr>}

                                </>

                            </tbody>

                        </Table>
                    </div>

                </CustomScrollbars>
            </div>
            <div className="showOnMobile">
                <CustomScrollbars style={{ height: `calc(100vh - 250px) ` }}>
                    {attendees && attendees.length > 0 && attendees.map((attendee, index) => {
                        if (attendee.checkin === "Yes") return (
                            <>
                                <Table className="mb-1 responsive_table ">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {/* <p className="mb-2 fs-6 capitalize">Attendee Details</p> */}
                                                <p className="mb-1 fs-6">{attendee.fullName} {attendee.nameArabic && `(${attendee.nameArabic})`}</p>
                                                <p className="mb-1 fs-8 text-tertiaryColor">{attendee.designation}</p>
                                                <p className="mb-1 fs-7 text-primaryColor">{attendee.company} {attendee.companyArabic && (`(${attendee.companyArabic})`)}</p>

                                                <div className="peopleGroup" style={{ color: getColorOfPeopleGroup('background', attendee.peopleGroup) }}>
                                                    {attendee.peopleGroup === "Owners" ? getClientName() : attendee.peopleGroup}
                                                </div>
                                            </td>
                                        </tr>

                                        <>
                                            {eventData?.eventDays?.length > 0 ? (
                                                eventData.eventDays.map((day, i) => (
                                                    <tr className="days" >
                                                        <td>
                                                            <div className="days">
                                                                {breakoutByGroup?.[day.day] ? <p className="py-2 mb-0">{day.title}</p> :
                                                                    <div className="d-flex align-items-center justify-content-between py-2" >
                                                                        <p className="mb-0">{day.title}</p>
                                                                        <p className="mb-0"> {getCheckinValueByDayBreakout(attendee._id, day.day, null) === 1 ? <GiCheckMark className="checkIcon" /> : null}</p>
                                                                    </div>}


                                                                <div className="mx-2">
                                                                    {breakoutByGroup?.[day.day] ? (
                                                                        <>
                                                                            <div className="breakout d-flex align-items-center justify-content-between" >
                                                                                <p className="mb-0">Plenary</p>
                                                                                <p className="mb-0"> {getCheckinValueByDayBreakout(attendee._id, day.day, null) === 1 ? <GiCheckMark className="checkIcon" /> : null}</p>
                                                                            </div>


                                                                            {Object.entries(breakoutByGroup[day.day]).map(([titleKey, items], subIndex) => (
                                                                                items.length > 0 ? (
                                                                                    items.map((session, sessionIndex) => (
                                                                                        <div className="breakout d-flex align-items-center justify-content-between" >
                                                                                            <p className="mb-0">{session.sessionTitle}</p>
                                                                                            <p className="mb-0"> {getCheckinValueByDayBreakout(attendee._id, day.day, session._id) === 1 ? <GiCheckMark className="checkIcon" /> : null}</p>
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <p className="breakout" key={`placeholder-${subIndex}`}>

                                                                                    </p>
                                                                                )
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <p className="mb-0" key={`no-session-${i}`}>

                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>




                                                    </tr>

                                                ))
                                            ) : null}
                                        </>
                                    </tbody>

                                </Table>


                            </>
                        )
                    })}

                </CustomScrollbars>
            </div>
        </>


    );
};

export default OverviewTable;
