import React, { useContext, useState, useEffect } from 'react'
import { OrganizerContext } from '../../../../../context/OrganizerContext';
import { Col, Row, Table } from 'react-bootstrap';
import CustomScrollbars from '../../../../UI/shared/customScrollbar/CustomScrollbar';
import TopGraySection from '../figures/TopGraySection';
import { getAttendeeName } from '../../../../../helper/sideHeaderHelper';
import { FiguresContext } from '../../../../../context/FiguresContext';
import Loading from '../../../../UI/shared/loading/Loading';

const DetailsTable = () => {
    const { organizer, setOrganizer } = useContext(OrganizerContext);
    const { figures } = useContext(FiguresContext);
    const [loading, setLoading] = useState(true)
    const getDisplay = () => {
        let action = ""
        if (organizer.action === "Checkin") {
            action = "Check-In"
        } else if (organizer.action === "Checkout") {
            action = "Check-Out"
        }
        else if (organizer.action === "Print Badge") {
            action = "Badge Printing"
        }
        return action
    }

    const getTitle = () => {
        let action = ""
        if (organizer.action === "Checkin") {
            action = "Check-In"
        } else if (organizer.action === "Checkout") {
            action = "Check-Out"
        }
        else if (organizer.action === "Print Badge") {
            action = "Badge Printing"
        }
        return `${getDisplay()} Activity Log of ${organizer.name ? organizer.name + `(${organizer.type})` : `${organizer.type}`} `
    }
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [figures.activeDay, figures.activeBreakout, organizer])
    const getListByAction = (groupedItems, type, action) => {
        if (type && groupedItems) {
            const filteredItems = groupedItems[type] && groupedItems[type].filter(obj => (parseInt(obj.dayId) === parseInt(figures.activeDay)) && (obj.breakoutId === figures.activeBreakout) && (obj.action === action));
            return filteredItems;
        }
        return groupedItems;
    };
    useEffect(() => {
        if (organizer.type != "") {
            console.log(getListByAction(organizer.AllList, organizer.typeRaw, organizer.action), organizer)
            setOrganizer((prevState) => ({
                ...prevState,
                list: getListByAction(organizer.AllList, organizer.typeRaw, organizer.action)
            }));
        }

    }, [figures.activeDay, figures.activeBreakout])
    return (
        <>

            {loading ? <Loading /> : <>
                <TopGraySection dayOverview={false} />
                <CustomScrollbars style={{ height: `calc(100vh - 190px)` }}>
                    <Row className="w-100 mx-auto">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <p className="tableTitle">{getTitle()}</p>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                            {organizer.list && organizer.list.length > 0 ?
                                <div className='mx-2'>
                                    <Table striped bordered>
                                        <tbody>


                                            <tr>
                                                <th>
                                                    Attendee Name
                                                </th>
                                                <th>
                                                    {getDisplay} Time
                                                </th>
                                                <th>
                                                    {getDisplay} Source
                                                </th>
                                            </tr>
                                            {organizer.list && organizer.list.length > 0 && organizer.list.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="time">
                                                                <i class="fa fa-user me-2" aria-hidden="true"></i>
                                                                {item.attendeeName}
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <div className="time">
                                                                <i class="fa fa-clock-o me-2" aria-hidden="true"></i>
                                                                {item.time}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="time">
                                                                {item.appType === "Web" ? <i class="fa fa-desktop me-2" aria-hidden="true"></i> : item.appType === "Mobile" ? <i class="fa fa-mobile me-2" aria-hidden="true"></i> : ""}

                                                                {item.appType}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div> : <h3 className='my-5 pt-5 text-center'>No Records Found!</h3>}

                        </Col>
                    </Row>
                </CustomScrollbars >
            </>}F
        </>
    )
}

export default DetailsTable