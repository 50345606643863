import React from 'react'

const Loading = ({style}) => {
    return (
        <div className="overlay" style={style}>
            <div className="loader03"></div>
        </div>
    )
}

export default Loading