import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { PiListBulletsFill } from "react-icons/pi";
import { HomeScreenContext } from '../../../../context/HomeScreenContext';
import { headerItems } from '../../../../helper/sideHeaderHelper';
const AttendeesButton = () => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    return (
        <>
            {layout.leftSideMenu ? null : <div className="attendeesButtonHolder hideOnMobile">
                <Button className="attendeesButton" onClick={() => {
                    setLayout((prevState) => ({
                        ...prevState,
                        leftSideMenu: true,
                        activePage: "attendeesList",
                        activePageItems: headerItems("attendeesList")
                    }))
                }}>
                    <i className="fa fa-list-ul attendeesButtonIcon"></i>
                    Attendees List
                </Button>
            </div>}
        </>

    )
}

export default AttendeesButton