import { resolveRegistrationType } from "./attendeeHelper";
import { updatedAttendeesList } from "./sideHeaderHelper";

export const updatedAttendeesListForCombined = (personActions) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {
        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const newList = [];
        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions && personActions.length > 0 && personActions.find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });

        return newList;
    }

}


export const TotalAttendeesCombined = (checkinStatus, peopleGroup, peopleType) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const personActions = attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const attendees = updatedAttendeesListForCombined(filteredActions);
    const filter = (array, condition) => array && array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
        condition.peopleGroup = peopleGroup;
    }

    if (peopleType !== undefined) {
        condition.registrationType = peopleType;
    }

    const filteredAttendees = filter(attendees, condition);

    let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
    let totalCompanions = 0;

    if (totalActualAttendees > 0) {
        filteredAttendees.forEach(attendee => {
            if (attendee.companions) {
                totalCompanions += parseInt(attendee.companions);
            }
        });
    }

    return totalActualAttendees + totalCompanions;
}

export const getTotalAccountsOverview = (checkinStatus, peopleGroup, peopleType) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const personActions = attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const attendees = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = attendees && attendees.filter((attendee) => {
        if (checkinStatus !== undefined && attendee.checkin !== checkinStatus) {
            return false;
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined' && attendee.peopleGroup !== peopleGroup) {
            return false;
        }

        if (peopleType !== undefined && resolveRegistrationType(attendee.registrationType) !== peopleType) {
            return false;
        }

        return true;
    });

    const accounts = [];

    filteredAttendees && filteredAttendees.forEach((attendee) => {
        if (attendee.company) {
            let isExisting = false;

            accounts.forEach((companyName) => {
                if (companyName.toLowerCase() === attendee.company.toLowerCase()) {
                    isExisting = true;
                    return;
                }
            });

            if (!isExisting) {
                accounts.push(attendee.company);
            }
        }
    });

    return accounts.length;
};

export const TotalAttendeesCombinedByDayBreakout = (checkinStatus, peopleGroup, peopleType, day, breakout) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {

        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];

        const newList = [];

        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === parseInt(day)) && (obj.breakoutId === breakout)).find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });
        const filter = (array, condition) => array && array.filter(item => {
            for (const key in condition) {
                if (item[key] !== condition[key]) {
                    return false;
                }
            }
            return true;
        });

        let condition = {};

        if (checkinStatus !== undefined) {
            condition.checkin = checkinStatus
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
            condition.peopleGroup = peopleGroup;
        }

        if (peopleType !== undefined) {
            condition.registrationType = peopleType;
        }

        const filteredAttendees = filter(newList, condition);
        let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
        let totalCompanions = 0;

        if (totalActualAttendees > 0) {
            filteredAttendees.forEach(attendee => {
                if (attendee.companions) {
                    totalCompanions += parseInt(attendee.companions);
                }
            });
        }

        return totalActualAttendees + totalCompanions;

    }

}

export const getFilteredAttendeesForCombined = (array, filters) => {

    return array && array.filter(attendee => {

        // Check if peopleGroup matches the filters (if provided)
        if (filters.checkin !== undefined && filters.checkin !== "" && attendee.checkin !== filters.checkin) {
            return false;
        }

        // Check if registrationType matches the filters (if provided)
        if (filters.registrationType !== undefined && filters.registrationType !== "" && attendee.registrationType !== filters.registrationType) {
            return false;
        }

        // All filters match, return true to keep this attendee
        return true;
    });
}
export const getConditionForCombined = (figures, setFigures) => {
    let checkin = undefined;
    let type = undefined;


    if (figures.typeSelection === "attendees" || figures.typeSelection === "dayBreakout") {
        checkin = "Yes";
        if (figures.graphPartSelection != undefined && figures.graphPartSelection != "") {
            type = figures.graphPartSelection;
        }
    }
    if (figures.typeSelection === "registered") {
        type = "Registered";
        if (figures.graphPartSelection != undefined && figures.graphPartSelection != "") {
            checkin = figures.graphPartSelection === "Shows-Accounts" ? "Yes" : "No";
        }
    }

    return { checkin, type }; // Return an object
}
export const getCombinedList = (checkinStatus, peopleType, day, breakout) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    const activeDayNumber = parseInt(day);
    const activeBreakoutNumber = (breakout === "null" || breakout === null) ? null : breakout;
    const newList = [];

    attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
        const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === activeDayNumber) && (obj.breakoutId === activeBreakoutNumber)).find(action => action.personId === attendee._id);


        if (action) {
            newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
        }
        else {
            newList.push({ ...attendee, checkin: "No" })
        }
    });

    const filter = (array, condition) => array && array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleType !== undefined) {
        condition.registrationType = resolveRegistrationType(peopleType);
    }
    let filteredAttendees = []
    if (day === "" && breakout === "") {
        function removeDuplicatePersonIds() {
            return personActions && personActions.reduce((uniqueIds, obj) => {
                // Check if the current object's personId is not already in the uniqueIds array
                if (!uniqueIds.some(id => id.personId === obj.personId)) {
                    // Add the current object's personId to the uniqueIds array
                    uniqueIds.push(obj);
                }
                // Return the updated uniqueIds array for the next iteration
                return uniqueIds;
            }, []);
        }
        const filteredActions = removeDuplicatePersonIds();
        const allAttendees = updatedAttendeesListForCombined(filteredActions);
        filteredAttendees = filter(allAttendees, condition);
    }
    else {
        filteredAttendees = filter(newList, condition);
    }

    return filteredAttendees;
}
export const getListOfAttended = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.company === companyName &&
        attendee.checkin === 'Yes'
    );

    // Return the count of filtered attendees
    return filteredAttendees;
}
export const getListOfNotAttended = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.company === companyName &&
        attendee.checkin === 'No'
    );

    // Return the count of filtered attendees
    return filteredAttendees;
}
export const getCountOfAttendedCombined = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.company === companyName &&
        attendee.checkin === 'Yes'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCountOfNotAttendedCombined = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.company === companyName &&
        attendee.checkin === 'No'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCheckinValueByDayBreakout = (id, day, breakout) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const activeBreakoutNumber = (breakout === "null" || breakout === null) ? null : breakout;
    const data = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    const action = data.find(action => (action.personId === id && parseInt(action.dayId) === parseInt(day) && action.breakoutId === activeBreakoutNumber));
    return action && action.checkin === "Yes" ? 1 : 0;
};

export const getListOfAttendedCountry = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.country === companyName &&
        attendee.checkin === 'Yes'
    );

    // Return the count of filtered attendees
    return filteredAttendees;
}
export const getListOfNotAttendedCountry = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.country === companyName &&
        attendee.checkin === 'No'
    );

    // Return the count of filtered attendees
    return filteredAttendees;
}

export const getCountOfAttendedCombinedCountries = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.country === companyName &&
        attendee.checkin === 'Yes'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}
export const getCountOfNotAttendedCombinedCountries = (companyName) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    function removeDuplicatePersonIds() {
        return personActions && personActions.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const list = updatedAttendeesListForCombined(filteredActions);
    const filteredAttendees = list.filter((attendee) =>
        attendee.country === companyName &&
        attendee.checkin === 'No'
    );

    // Return the count of filtered attendees
    return filteredAttendees.length;
}