import React, { useContext, useState, useEffect } from 'react'
import OverviewTopSection from './OverviewTopSection'
import OverviewReportContextProvider from '../../../../../context/OverviewReportContext'
import "./overview.scss"
import OverviewGraph from './OverviewGraph'
import { Col, Row } from 'react-bootstrap'
import OverviewTable from './OverviewTable'
import { HomeScreenContext } from '../../../../../context/HomeScreenContext'
import Loading from '../../../../UI/shared/loading/Loading'
import Download from '../../partials/downloadButton/Download'
const OverviewReport = () => {
  const { layout, setLayout } = useContext(HomeScreenContext);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])
  const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
  return (
    <OverviewReportContextProvider>
      {loading && <Loading />}
      <div
        className={`rightSectionHolder hidden-element ${layout.showFigureListViewFullScreen ? "fixed" : ""
          }`}
      >

        <OverviewTopSection />
        {attendees && attendees.length > 0 ?
          <Row >
            {!layout.showFigureListViewFullScreen &&
              <Col xl={4} className="hideOnMobileTablet">
                <OverviewGraph />
              </Col>}
            <Col className='m-2'>
              <OverviewTable />
            </Col>
          </Row> :!loading && <h4 className="d-flex align-items-center justify-content-center py-5 my-5">No Records found!</h4>}
      </div>



    </OverviewReportContextProvider>
  )
}

export default OverviewReport