import "./App.scss";
import ApplicationRoutes from "./config/ApplicationRoutes";
import { BrowserRouter } from "react-router-dom";
import HomeScreenContextProvider from "./context/HomeScreenContext";
import AttendeeContextProvider from "./context/AttendeeContext";
import FiguresContextProvider from "./context/FiguresContext";
import Login from "./components/module/login/Login";
import AuthContextProvider from "./context/AuthContext";
import CombinedContextProvider from "./context/CombinedContext";
import SpeakerContextProvider from "./context/SpeakerContext";
import OrganizerContextProvider from "./context/OrganizerContext";
function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <HomeScreenContextProvider>
          <AttendeeContextProvider>
            <FiguresContextProvider>
              <CombinedContextProvider>
                <SpeakerContextProvider>
                  <OrganizerContextProvider>
                    <ApplicationRoutes />
                  </OrganizerContextProvider>
                </SpeakerContextProvider>
              </CombinedContextProvider>
            </FiguresContextProvider>
          </AttendeeContextProvider>
        </HomeScreenContextProvider>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
