import React, { useContext, useEffect } from "react";
import TopGraySection from "../TopGraySection";
import { Col, Row } from "react-bootstrap";
import GrayHeader from "../../../../../UI/shared/leftSideSection/partials/grayHeader/GrayHeader";
import SingleGroupCountDetails from "./SingleGroupCountDetails";
import PieChart from "./PieChart";
import { FiguresContext } from "../../../../../../context/FiguresContext";
import ListSection from "./ListSection";
import CustomScrollbars from "../../../../../UI/shared/customScrollbar/CustomScrollbar";
import { TotalAttendeesFiguresOverView } from "../../../../../../helper/sideHeaderHelper";
import OverviewDayDetails from "./OverviewDayDetails";
const FiguresDetails = () => {
    const { figures, setFigures } = useContext(FiguresContext);
    useEffect(() => { }, [figures]);
    const overview = figures.activeBreakout === `overview-${figures.activeDay}` ? true : false
    return (
        <>
            <TopGraySection />
            {overview ?

                <Row className="overViewDetailsHolder g-0 mb-4">
                    <Col>
                        <div className="overViewDetailsLeft">
                            <div className="topContent">
                                <h3 className="topHeading">Day Overview</h3>
                                <p className="subText">
                                    Total unique attendees including Plenary and all Breakouts
                                </p>
                            </div>
                            <div className="middleContent">
                                <h3>
                                    <i class="fa fa-users me-2" aria-hidden="true"></i>
                                    Total Attendees
                                </h3>
                                <h1>{TotalAttendeesFiguresOverView("Yes", undefined, undefined)}</h1>
                            </div>
                            <div class="statsBreakdowns">
                                <div class="subBlockLeft">
                                    <div class="title">Registered</div>
                                    <div class="number ">{TotalAttendeesFiguresOverView("Yes", undefined, "Registered")}</div>
                                </div>

                                <div class="subBlockRight">
                                    <div class="title">Walk-in</div>
                                    <div class="number ">{TotalAttendeesFiguresOverView("Yes", undefined, "Walkin")}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <CustomScrollbars style={{ height: `calc(100vh - 190px)` }}>
                            <OverviewDayDetails day={figures.activeDay} />
                        </CustomScrollbars>
                    </Col>

                </Row> : <>

                    <Row className="figuresDetails g-0 hideOnMobile">

                        <Col xl={6} lg={6} md={6} xs={12} className="countWiseDisplay ">
                            <CustomScrollbars style={{ height: `calc(100vh - 185px)` }}>
                                <Row>
                                    <Col xl={5} xs={12} className='pe-1'>
                                        <SingleGroupCountDetails /> 
                                    </Col>
                                    <Col xl={7} xs={12} className='pieChart hideOnMobile'>
                                        <PieChart />
                                    </Col>
                                </Row>
                            </CustomScrollbars>
                        </Col>

                        <Col lg={6} md={6} xs={12} className='p-0'>

                            <ListSection />
                        </Col>


                    </Row>
                    <Row className="figuresDetails g-0 showOnMobile">
                        <CustomScrollbars style={{ height: `calc(100vh - 185px)` }}>
                            <Col xl={6} lg={6} md={6} xs={12} className="countWiseDisplay ">

                                <Row>
                                    <Col xl={5} xs={12} className='pe-1'>
                                        <SingleGroupCountDetails />
                                    </Col>
                                    <Col xl={7} xs={12} className='pieChart hideOnMobile'>
                                        <PieChart />
                                    </Col>
                                </Row>

                            </Col>

                            <Col lg={6} md={6} xs={12} className='p-0'>

                                <ListSection />
                            </Col>
                        </CustomScrollbars>
                    </Row>
                </>}
        </>
    );
};

export default FiguresDetails;
