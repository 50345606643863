import React, { useContext, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { AttendeeContext } from "../../../../context/AttendeeContext";

const CMSFormSearch = (props) => {
  const {
    name,
    label,
    form,
    required,
    autoFocus,
    description,
    placeholder,
    helpMessage,
    ...rest
  } = props;
  const { attendee, setAttendee } = useContext(AttendeeContext);
  // useEffect(() => {
  //   setAttendee((prevState) => ({
  //     ...prevState,
  //     searchTerm: form.values[name],
  //   }))
  // }, [form.values[name]])

  return (
    <div className="form-group search">
      {/* Created Useable Component */}
      {label &&
        <label
          htmlFor={name}
          className={` ${required == true ? "mandatory_field ps-2 " : " "
            } ps-1 mb-0`}
        >
          {" "}
          {label}
        </label>}


      {/* Created Useable Component */}
      {helpMessage && helpMessage.message && (
        <OverlayTrigger
          trigger="hover"
          key={`info-popup-${name}`}
          placement="right"
          overlay={
            <Popover id={`popover-positioned-${name}`}>
              {helpMessage.heading && (
                <Popover.Header as="h3">{helpMessage.heading}</Popover.Header>
              )}

              <Popover.Body>{helpMessage.message}</Popover.Body>
            </Popover>
          }
        >
          <i className="fa fa-info-circle ml-2 infoIcon"></i>
        </OverlayTrigger>
      )}

      <Field
        name={name}
        autoFocus={autoFocus}
        placeholder="Search"
        className={`form-control customSearch  ${form.touched[name] && form.errors[name] ? "is-invalid" : " "
          }`}
        {...rest}
      />
      <button className="search_btn" onClick={() => setAttendee((prevState) => ({
        ...prevState,
        searchTerm: form.values[name],
        activeLetter: "",
      }))} ><i className="fa fa-search" aria-hidden="true"></i></button>
      {required === true && <div className="required"></div>}

      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
        // <div className="errorMessage">{form.errors[name]}</div>
      )}
    </div>
  );
};

export default CMSFormSearch;
