import React, { useReducer, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { listingInitialState, listingReducer } from "../../../../../reducers/listingReducer";
import axios from "../../../../../config/axios";
import CMSFormCheckBoxSubmit from "../../../../UI/shared/formFields/CMSFormCheckBoxSubmit";
const AgendaListingMarking = ({ itemId, isDone, isActive, loadItems, displayClass, hideActive }) => {

  const [state, dispatch] = useReducer(listingReducer, listingInitialState);

  const onSubmit = async (id, undo = false) => {
    dispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`/select-active-session`, {
        id: itemId,
        undo: isActive,
      });
      loadItems();
    } catch (error) {
      dispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        dispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };
  const onSubmitDone = async (id, undo = false) => {
    dispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`/select-done-session`, {
        id: itemId,
        undo: isDone ? isDone : "0",
      });
      loadItems();

    } catch (error) {
      dispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        dispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  useEffect(() => {

    dispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);
  return (
    <div className={` ${displayClass}`}>

      <Formik
        initialValues={{
          done: isDone === "1" ? true : false,
          active: isActive === "1" ? true : false,
        }}
        // validationSchema={yup.object().shape({
        //   speakerId: yup.string(),
        //   status: yup.string()
        // })}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values) => {




        }}
      >
        {(form) => (
          <>


            {state.error && state.status === "fetchFailed" ? (
              <>
                <h3>Fetch Error</h3>
              </>
            ) : (
              <>
                <Form onSubmit={form.handleSubmit}>
                  <div className="d-flex formActions">
                
                      <CMSFormCheckBoxSubmit name="done"  label="Mark As Done" onChange={onSubmitDone} form={form} />
                      <span className="mx-2"></span>
                  
               
                      <CMSFormCheckBoxSubmit name="active" disabled={isDone === "1" ? true : false} label="Mark As Active Session" onChange={onSubmit} form={form} />
                      <span className="mx-2"></span>
                  
                  </div>


                </Form>
              </>
            )}
          </>
        )}
      </Formik>
      {/* <span title="Session Settings" onClick={(e) => onSubmit(e)} className={buttonClass ? buttonClass : "btn btn-outline-secondary btn-sm "}>
        {isActive === "1" ? "Inactive Session" : "Active Session"}
      </span> 
       <span title="Add to/ Remove from home page" onClick={(e) => onSubmitHomePage(e)} className={`${buttonClass ? buttonClass : "btn btn-outline-secondary btn-sm"} ms-1 mt-xl-0 mt-md-0 mt-lg-0 mt-1 `}>
        {showOnHomePage === "1" ? "Remove from Home Page" : "Add to Home Page"}
      </span>  */}

    </div>

  );
};

export default AgendaListingMarking;
