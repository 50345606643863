import React, { useEffect, useContext, useRef } from 'react'
import "./badgePrint.scss"
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import { headerItems } from '../../../../../helper/sideHeaderHelper';
const BadgePrint = () => {
    const { layout, setLayout } = useContext(HomeScreenContext);
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const badge = eventData.badge && eventData.badge;
    const width = badge.width + 'px';
    const padding = badge.padding + 'px';
    const textAlign = badge.textAlign;
    const topContentBox = badge.topContentBoxPosition + 'px';
    const bottomContentBox = badge.bottomContentBoxPosition + 'px';
    const qrCodeTop = badge.qrCode && badge.qrCode.top + 'px';
    const qrCodeLeft = badge.qrCode && badge.qrCode.left + 'px';
    const dtcmBarCodeTop = badge.dtcmBarCode && badge.dtcmBarCode.top + 'px';
    const dtcmBarCodeLeft = badge.dtcmBarCode && badge.dtcmBarCode.left + 'px';
    function printDetails(item, position) {
        // Extract relevant properties
        const details = [
            {
                name: layout.badgePrintingAttendee && layout.badgePrintingAttendee.fullName,
                show: "Yes",
                color: item.personColor,
                fontFamily: item.personFontFamily,
                fontWeight: item.personFontWeight,
                sortOrder: item.personSortOrder,
                topFontSize: item.topPersonFontSize,
                bottomFontSize: item.bottomPersonFontSize,
                marginTop: item.personMargin,
                breakpoint: item.personBreakpoint,
            },
            {
                name: layout.badgePrintingAttendee && layout.badgePrintingAttendee.nameArabic,
                show: "Yes",
                show: item.personArabicShow,
                color: item.personArabicColor,
                fontFamily: item.personArabicFontFamily,
                fontWeight: item.personArabicFontWeight,
                sortOrder: item.personArabicSortOrder,
                topFontSize: item.topPersonArabicFontSize,
                bottomFontSize: item.bottomPersonArabicFontSize,
                marginTop: item.personArabicMargin,
                breakpoint: item.personArabicBreakpoint,
            },
            {
                name: layout.badgePrintingAttendee && layout.badgePrintingAttendee.company,
                show: item.companyShow,
                color: item.companyColor,
                topFontSize: item.topCompanyFontSize,
                bottomFontSize: item.bottomCompanyFontSize,
                marginTop: item.companyMargin,
                breakpoint: item.companyBreakpoint,
                fontFamily: item.companyFontFamily,
                fontWeight: item.companyFontWeight,
                sortOrder: item.companySortOrder,
                topFontSize: item.topCompanyFontSize,
                bottomFontSize: item.bottomCompanyFontSize,
                marginTop: item.companyMargin,
                breakpoint: item.companyBreakpoint,
            },
            {
                name: layout.badgePrintingAttendee && layout.badgePrintingAttendee.companyArabic,
                show: item.companyArabicShow,
                color: item.companyArabicColor,
                topFontSize: item.topCompanyArabicFontSize,
                bottomFontSize: item.bottomCompanyArabicFontSize,
                marginTop: item.companyArabicMargin,
                breakpoint: item.companyArabicBreakpoint,
                fontFamily: item.companyArabicFontFamily,
                fontWeight: item.companyArabicFontWeight,
                sortOrder: item.companyArabicSortOrder,
                topFontSize: item.topCompanyArabicFontSize,
                bottomFontSize: item.bottomCompanyArabicFontSize,
                marginTop: item.companyArabicMargin,
                breakpoint: item.companyArabicBreakpoint,
            },
            {
                name: layout.badgePrintingAttendee && layout.badgePrintingAttendee.designation,
                show: item.designationShow,
                color: item.designationColor,
                fontFamily: item.designationFontFamily,
                fontWeight: item.designationFontWeight,
                sortOrder: item.designationSortOrder,
                topFontSize: item.topDesignationFontSize,
                bottomFontSize: item.bottomDesignationFontSize,
                marginTop: item.designationMargin,
                breakpoint: item.designationBreakpoint,
            },
            {
                name: layout.badgePrintingAttendee && layout.badgePrintingAttendee.country,
                color: item.countryColor,
                show: item.countryShow,
                fontFamily: item.countryFontFamily,
                fontWeight: item.countryFontWeight,
                sortOrder: item.countrySortOrder,
                topFontSize: item.topCountryFontSize,
                bottomFontSize: item.bottomCountryFontSize,
                marginTop: item.countryMargin,
                breakpoint: item.countryBreakpoint,
            }
        ];

        if (position === "top") {
            details.sort((a, b) => a.sortOrder - b.sortOrder);
            // Print sorted details
            return details.map((detail) => {
                if (detail.show === "Yes") return (
                    <p key={detail.name} style={{ textAlign: textAlign, fontFamily: detail.fontFamily, marginBottom: `${detail.marginTop}px`, maxWidth: `${detail.breakpoint}ch`, color: `#${detail.color}`, fontSize: `${detail.topFontSize}px`, fontWeight: detail.fontWeight, marginTop: `${detail.marginTop}px` }}>
                        {detail.name}
                    </p>
                )
            });
        }
        else if (position === "bottom") {
            details.sort((a, b) => a.sortOrder - b.sortOrder);
            // Print sorted details
            return details.map((detail) => {
                if (detail.show === "Yes") return (
                    <p key={detail.name} style={{ textAlign: textAlign, fontFamily: detail.fontFamily, marginBottom: `${detail.marginTop}px`, maxWidth: `${detail.breakpoint}ch`, color: `#${detail.color}`, fontSize: `${detail.bottomFontSize}px`, fontWeight: detail.fontWeight, marginTop: `${detail.marginTop}px` }}>
                        {detail.name}
                    </p>
                )
            });
        }
        // Sort details based on sortOrder

    }

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            // Skip the effect on the first render
            isFirstRender.current = false;
            return;
        }
        setTimeout(() => {
            window.print();
        }, 500);
    }, [])

    return (
        <div className="badgePrint" onClick={() => {
            setLayout((prevState) => ({
                ...prevState,
                leftSideMenu: true,
                leftFullDisplay: false,
                activePage: layout.badgeBack,
                activePageItems: headerItems(layout.badgeBack),
                badgePrint: false,
                badgePrintingAttendee: null,
                badgeBack: "",
            }))

        }}>
            <div style={{ position: 'relative', width: width, }}>

                {badge.showQrCode === "Yes" && layout.badgePrintingAttendee && layout.badgePrintingAttendee.scanningCode &&
                    <div>
                        <img style={{ position: 'absolute', width: '150px', height: '150px', top: qrCodeTop, left: qrCodeLeft }} src={layout.badgePrintingAttendee.qrCodeImageString} />
                    </div>}
                {/* {badge.showDtcmBarcode === "Yes" &&
                    <div>
                        <img style={{ position: 'absolute', width: '180px', height: '70px', top: qrCodeTop, left: qrCodeLeft }} src={layout.badgePrintingAttendee && layout.badgePrintingAttendee.scanningCode ? `https://ahead.events/aep//docs/qr_codes/${layout.badgePrintingAttendee && layout.badgePrintingAttendee.scanningCode}.png` : null} />
                    </div>} */}
                {badge.topPartItems && (badge.topPartItems[0] != null || badge.topPartItems[1] !== null) &&
                    <div className="top_container" ng-style="badge_top_container_class" style={{ position: 'absolute', top: topContentBox, right: '0px', left: '0px', textAlign: textAlign, padding: padding }}>
                        {printDetails(badge, "top")} 
                    </div>}

                <div className="bottom_container" ng-style="badge_bottom_container_class" style={{ position: 'absolute', top: bottomContentBox, right: '0px', textAlign: textAlign, left: '0px', padding: padding }}>
                    {printDetails(badge, "bottom")}

                </div>
                {/* {layout.badgePrintingAttendee && (layout.badgePrintingAttendee.registrationType === "Walkin" && (layout.badgePrintingAttendee.city && layout.badgePrintingAttendee.city != "")) &&
                    <div style={{ position: 'absolute', top: '560px', left: '50px', width: '220px' }}>
                        <h3 style={{ fontSize: '13px', fontFamily: 'Lato-Regular', color: 'rgb(62, 46, 40)', marginTop: '0px', marginBottom: '0px', textAlign: textAlign }} >
                            {layout.badgePrintingAttendee && layout.badgePrintingAttendee.city}
                        </h3>
                    </div>} */}
            </div>
        </div >
    )
}

export default BadgePrint