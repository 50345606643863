import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import AllCounts from './AllCounts';
import OneItemCount from './OneItemCount';
import { TotalAttendees, getAvailablePeopleGroups, getColorOfPeopleGroup } from '../../../../../helper/sideHeaderHelper';

const CountSwiper = () => {
    const availablePeopleGroups = getAvailablePeopleGroups()
  
    return (
        <div className="countSwiperHolder">
              
            <Swiper autoHeight={true} pagination={{
                clickable: true,
            }} modules={[Pagination]} className="countSwiper">
                <SwiperSlide><AllCounts /></SwiperSlide>
                <React.Fragment>
                    {availablePeopleGroups && availablePeopleGroups.length > 0 && availablePeopleGroups.map((item, index) => {
                        return (<SwiperSlide className='hideOnMobile' key={index} ><OneItemCount text={item} color={getColorOfPeopleGroup("foreground", item)} background={getColorOfPeopleGroup("background", item)} /></SwiperSlide>)
                    })}
                </React.Fragment>




            </Swiper>
        </div>
    )
}

export default CountSwiper