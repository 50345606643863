import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = ({ desktopHeight, mobileHeight, children, ...props }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Assuming 768px as the breakpoint

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const height = isMobile ? mobileHeight : desktopHeight;

    return (
        <Scrollbars
            style={{ height: `${height}`, width: "100%" }} // Set the height dynamically
            renderThumbHorizontal={(props) => <div {...props} />}
            renderThumbVertical={(props) => <div {...props} />}
            autoHide={true}
            thumbSize={30}
            {...props}
        >
            {children}
        </Scrollbars>
    );
};

export default CustomScrollbars;
